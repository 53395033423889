import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../actManage/actScheduleRegistration/AdvancedSearchResultTag';

// 這是一個用於管理商品圖片倉庫商品列表的 React 組件，具有以下主要功能和特點：

// 狀態管理
// - `isGetList`: 控制是否已成功獲取商品列表的狀態。
// - `salesKitClassList`: 保存商品列表數據的狀態。
// - `searchText`: 保存搜索文本的狀態。
// - `searchFilter`: 保存搜索過濾條件的狀態。
// - `sortBy` 和 `orderBy`: 用於控制排序方式的狀態。
// - `selectedSalesKitList`: 保存已選中商品的狀態。

// 進階搜索
// - `searchContentList`: 定義了進階搜索的各個項目，包括名稱、銷售分類代碼、銷售起始日期和銷售截止日期等。
// - `advancedSearchModalOpen`: 控制進階搜索模態框是否打開的狀態。
// - `advancedSearchDataList`: 保存進階搜索條件數據的狀態，用於實際搜索商品列表。
// - `displayList`: 保存進階搜索條件展示數據的狀態。

// 路由和導航
// - `location` 和 `navigate`: 使用 react-router-dom 提供的 `useLocation` 和 `useNavigate` 來獲取當前路由信息和實現路由導航。

// 常數和工具
// - `statusList`: 定義了商品狀態的對應關係。
// - `axios`: 使用 axios 來發送 HTTP 請求。
// - `dayjs`: 使用 dayjs 來格式化日期。

// 效能優化
// - 使用 useCallback 包裹 `getSalesKitClassList` 函數，以優化性能。
// - 使用 useEffect 加載商品列表。

// 功能函數
// - `resetFilterSalesKitClassList`: 重置搜索條件，重新加載商品列表。
// - `getSalesKitClassList`: 獲取商品列表的函數，使用 useCallback 進行性能優化。
// - `filterSalesKitClassList`: 根據搜索文本進行商品列表的過濾。
// - `fetchData`: 根據指定參數發送請求，獲取商品列表。
// - `handleSelect`, `handleSelectAll`: 選中商品的函數。
// - `handleInsertSalesKit`: 插入已選中商品的函數。

// 組件渲染
// - 渲染搜索框、進階搜索條件展示、商品列表表格等元素。
// - 判斷商品列表是否已獲取成功，並根據結果渲染商品列表或顯示無數據提示。
// - 使用 react-router-dom 實現點擊商品列表中的項目進行路由導航。
// - 使用 AdvancedSearchModal 組件實現進階搜索功能。
// - 使用 AdvancedSearchResultTag 組件展示進階搜索條件。
export default function SalSalesKitClass() {
    const [isGetList, setIsGetList] = useState(false);
    const [salesKitClassList, setSalesKitClassList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // Sorting states
    const [sortBy, setSortBy] = useState('statusTime');
    const [orderBy, setOrderBy] = useState('desc');

    const [selectedSalesKitList, setSelectedSalesKitList] = useState([]);

    //#region AdvancedSearch region

    const searchContentList = [
        // 'name',
        // 'phone',
        // 'time_Registry',
        // 'queueNo',
        // 'time_Playing',
        // 'score',
        // 'time_Checkin',
        // 'Status',
        {
            column: 'name',
            name: '商品名稱',
            type: 'text',
            tableColumn: 'name', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'name',
            selectOptionList: ['==', '!=', 'contains', '!contains'],
        },
        {
            column: 'salesKitClass',
            name: '銷售分類代碼',
            type: 'text',
            tableColumn: 'salesKitClass', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'salesKitClass',
            selectOptionList: ['==', '!='],
        },
        {
            column: 'startDate',
            name: '銷售起始日期',
            type: 'datetime-local',
            tableColumn: 'startDate',
            searchFromDataBase: 'startDate',
            selectOptionList: ['>', '<'],
        },
        {
            column: 'endDate',
            name: '銷售截止日期',
            type: 'datetime-local',
            tableColumn: 'endDate',
            searchFromDataBase: 'endDate',
            selectOptionList: ['>', '<'],
        },
    ];

    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    //過濾搜尋
    const resetFilterSalesKitClassList = () => {
        setSearchText('');
        fetchData();
    };

    const getSalesKitClassList = useCallback((query) => {
        const gettingSalesKitClassList = async (requestBody) => {
            await axios
                .post(`web/SalSalesKitClass/getSalesKitClassList`, requestBody)
                .then((res) => {
                    // console.log(res.data.data);
                    setSalesKitClassList(res.data.data);
                    setIsGetList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingSalesKitClassList(query);
    }, []);

    const filterSalesKitClassList = () => {
        if (searchText) {
            const advancedSearchDataList = [
                {
                    searchLogic: '',
                    SearchFilter: 'name_01',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_02',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_03',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
            ];

            fetchData(advancedSearchDataList, 100000, sortBy, 1);
        }
    };

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'name',
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['==', '!=', 'contains', '!contains'],
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    /**
     * Deletes a filter from the advanced search data list and the display list.
     *
     * @param {number} targetIndex - The index of the filter to delete.
     * @return {undefined} This function does not return a value.
     */

    const deleteFilter = (targetIndex) => {
        const newAdvancedSearchDataList = advancedSearchDataList.filter((_, index) => index !== targetIndex);
        const newDisplayList = displayList.filter((_, index) => index !== targetIndex);
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null);
    };

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/SalSalesKit/getPingtexPSalesKitList`;
        // console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sort} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        // console.log(reqBody);

        axios.post(url, reqBody).then((res) => {
            // console.log(res.data.query);

            setSalesKitClassList(res.data.data);
            setIsGetList(true);
        });
    };

    useEffect(() => {
        // axios
        //     .post(`web/SalSalesKitClass/getSalesKitClassList`)
        //     .then((res) => {
        //         console.log(res.data.salesKitClassList);
        //         setSalesKitClassList(res.data.salesKitClassList);
        //         setIsGetList(true);
        //     })
        //     .catch((err) => console.log(err));
        fetchData();
    }, []);

    const handleSelect = (e) => {
        const id = e.currentTarget.id;

        // 檢查是否已經存在
        if (!selectedSalesKitList.includes(id)) {
            // 如果不存在，則添加
            setSelectedSalesKitList((prev) => [...prev, id]);
        } else {
            // 如果已經存在，則可以選擇移除或執行其他操作
            // 這裡是一個示例，你可以根據需求進行調整
            setSelectedSalesKitList((prev) => prev.filter((item) => item !== id));
        }
    };

    const handleSelectAll = () => {
        if (selectedSalesKitList.length === salesKitClassList.length) {
            setSelectedSalesKitList([]);
        } else {
            setSelectedSalesKitList(salesKitClassList.map((item) => item.salesKitGuid));
        }
    };

    const handleInsertSalesKit = () => {
        const url = `web/SalSalesKit/insertSalesKit`;
        const reqBody = {
            SalesKitGuid_ERP: selectedSalesKitList,
        };
        axios.post(url, reqBody).then((res) => {
            console.log(res.data);
            setSelectedSalesKitList([]);
            fetchData();
        });
    };

    // console.log(selectedSalesKitList);
    return (
        <section id='section-main'>
            <div id='container-salSalesKitClass'>
                <h5 className='fw-bold mb-4'>商品圖片倉庫-商品列表</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        {/* <select
                            className="form-control me-2"
                            style={{ width: '160px' }}
                            name="searchColumn"
                            id="searchColumn"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            required
                        >
                            <option value="all">全部</option>
                            <option value="fullname" id="fullnameSearch">
                                显示名称
                            </option>
                            <option value="userID" id="userIDSearch">
                                用户名(ID)
                            </option>
                            <option value="phone1" id="phone1Search">
                                手机号码
                            </option>
                            <option value="email1" id="email1Search">
                                邮箱
                            </option>
                        </select> 
                    */}
                        <div className='searchWrapper bg-white  rounded-4'>
                            <div className='searchBox row flex-wrap'>
                                <div className='col-12 mb-2'>
                                    <button
                                        className='searchBtn col-2 btn btn-size-s btn-color-main'
                                        onClick={() => setAdvancedSearchModalOpen(true)}
                                    >
                                        搜尋
                                    </button>
                                </div>
                                <AdvancedSearchResultTag
                                    advancedSearchDataList={advancedSearchDataList}
                                    deleteAllFilters={deleteAllFilters}
                                    deleteFilter={deleteFilter}
                                    ns='lgsPurchaseOrder'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>商品列表</h6>{' '}
                        {/* <button
                            className="btn btn-primary text-white ms-auto me-2"
                            onClick={
                                handleInsertSalesKit
                            }
                        >
                            <svg
                                id="icn_Create"
                                className="me-2 mb-1"
                                viewBox="0 0 12 12"
                                width="12"
                                height="12"
                                fill="#fff"
                            >
                                <defs> </defs>
                                <path
                                    className="cls-1"
                                    id="_Color"
                                    d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
                                    data-name=" ↳Color"
                                ></path>
                            </svg>
                            <span>上架商品</span>
                        </button> */}
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey4 border-0'>
                                            #<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            簡中名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            英文名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            銷售商品分類<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            銷售起始日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            銷售截止日期<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            註記<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetList &&
                                        (salesKitClassList.length !== 0 ? (
                                            salesKitClassList.map((skClass, idx) => {
                                                // console.log(skClass);
                                                return (
                                                    <tr
                                                        id={skClass?.salesKitGuid}
                                                        key={skClass?.salesKitGuid}
                                                        onClick={() => navigate(`${skClass?.salesKitGuid}`)}
                                                    >
                                                        <td>{idx + 1}</td>
                                                        <td>{skClass?.name01}</td>
                                                        <td>{skClass?.name02}</td>
                                                        <td>{skClass?.name03}</td>
                                                        <td>{skClass?.salesKitClassName}</td>
                                                        <td>
                                                            {skClass.startDate
                                                                ? dayjs(skClass.startDate).format('YYYY-MM-DD HH:mm:ss')
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {skClass.endDate
                                                                ? dayjs(skClass.endDate).format('YYYY-MM-DD HH:mm:ss')
                                                                : ''}
                                                        </td>
                                                        <td>{skClass?.note}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='10'>無商品記錄</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>

                            {/* modal */}
                            <AdvancedSearchModal
                                isOpen={advancedSearchModalOpen}
                                handleClose={() => setAdvancedSearchModalOpen(false)}
                                advancedSearchDataTemp={advancedSearchDataTemp}
                                setAdvancedSearchDataList={setAdvancedSearchDataList}
                                displayList={[]}
                                searchContentList={searchContentList}
                                fetchData={fetchData}
                                ns='ActScheduleRegistration'
                                restSearchData={[]}
                            />
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
