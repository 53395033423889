import './myProduct.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';

function MyProduct() {
    const { t } = useTranslation(['product']); // i18n
    const navigate = useNavigate();

    const [productList, setProductList] = useState('');
    const [isGetUserProduct, setIsGetUserProduct] = useState(false);

    useEffect(() => {
        const getUserProduct = async () => {
            axiosApi('/web/product')
                .then((res) => {
                    setProductList(res.data.productList);
                    setIsGetUserProduct(true);
                })
                .catch((err) => console.log(err));
            // const res = await axios.get(`/web/product`);
            // setProductList(res.data.productList);
            // setIsGetUserProduct(true);
        };

        getUserProduct();
    }, []);

    //console.log("productList", productList);

    return (
        <article id='container-myProduct'>
            <div className='articleBoxWithSideBox'>
                <h1 className='containTitle'>{t('my_product')}</h1>
                <section className='row g-3 mb-3'>
                    <div className='col-12'>
                        <h2 className='mb-4'>{t('registration_process_easy')}</h2>
                        <h2>{t('benefits_of_product_registration')}</h2>
                        <ol className='list-unstyled'>
                            <li>{t('service_support')}</li>
                            {/* <li>{t('account_upgrade')}</li>
                            <li>{t('invite_family_members')}</li> */}
                        </ol>
                    </div>
                </section>
                <section className='d-flex justify-content-between align-items-center mb-3'>
                    <span>{t('registered_products')}</span>
                    <button
                        type='button'
                        className='registerProductBtn btn btn-color-main rounded-pill px-4'
                        onClick={() => navigate('/product/productRegister')}
                    >
                        {t('register_product')}
                    </button>
                </section>
                <section>
                    {/*<!-- 資料表格 -->*/}
                    <table className='table table-hover text-center' id='table-drill'>
                        <thead>
                            <tr className='bg-grey1'>
                                {/*<!-- 產品型號 -->*/}
                                <th scope='col'>{t('product_model')}</th>
                                {/*<!-- 设备序列号 -->*/}
                                <th scope='col'>{t('device_serial_number')}</th>
                                {/*<!-- 購買日期 -->*/}
                                <th scope='col'>{t('purchase_date')}</th>
                                {/*<!-- 保修期限 -->*/}
                                <th scope='col'>{t('warranty_period')}</th>
                                {/*<!-- 可授權的家族成員數量 -->*/}
                                {/* <th scope='col'>{t('authorized_family_members')}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {isGetUserProduct &&
                                (productList.length !== 0 ? (
                                    productList.map((list) => {
                                        console.log(productList);
                                        return (
                                            <tr key={list.productGuid}>
                                                <td>{list.modelID}</td>
                                                <td>{list.serialNo}</td>
                                                <td>{list.purchaseDate ? list.purchaseDate.split('T')[0] : ''}</td>
                                                <td>{list.warrantyDate ? list.warrantyDate.split('T')[0] : ''}</td>
                                                {/* <td>{list.vipQuantity}</td> */}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan='5'>{t('no_product_registration_records')}</td>
                                    </tr>
                                ))}
                            {/*{console.log(productList)}*/}
                        </tbody>
                    </table>

                    {/*讀取畫面*/}
                    <div className={`text-center loadingBox ${isGetUserProduct && 'd-none'}`} id='loadingBox'>
                        <h6 className='sr-only'>Loading...</h6>

                        <div className='spinner-border text-primary' role='status'></div>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default MyProduct;
