import './accountDelete.scss';
import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { SidebarHeightContext } from '../../../components/layoutAccount/LayoutAccount';
import { Visibility, VisibilityOff, Sync } from '@material-ui/icons';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';

function AccountDelete() {
    const { t } = useTranslation('userManage/accountDelete'); // i18n
    const [cookies, setCookie] = useCookies(['loginUser']);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const sidebarHeightContext = useContext(SidebarHeightContext);

    // if (!guid) {
    //     setCookie('loginUser', { status: null }, { path: '/' });
    //     setCookie('loginAuto', 'false', { path: '/' });
    //     navigate('/login?next=accountDelete');
    // }

    // 自定義sidebar高度(由於confirmBtn在外面)
    // const sidebarHeightContext = useContext(SidebarHeightContext);
    // sidebarHeightContext.setSidebarHeight('100% - confirmBtn');
    // console.log('sidebarHeight', sidebarHeightContext.sidebarHeight);

    const swalWithCustomStyling = Swal.mixin({
        customClass: {
            container: 'accountDelete-container-class',
            popup: 'accountDelete-popup-class',
            header: 'accountDelete-header-class',
            title: 'accountDelete-title-class',
            closeButton: 'accountDelete-close-button-class',
            icon: 'accountDelete-icon-class',
            image: 'accountDelete-image-class',
            content: 'accountDelete-content-class',
            input: 'accountDelete-input-class',
            actions: 'accountDelete-actions-class',
            confirmButton: 'btn btn-success accountDelete-confirm-button-class',
            cancelButton: 'btn btn-danger accountDelete-cancel-button-class',
            footer: 'accountDelete-footer-class',
        },
        buttonsStyling: false,
    });

    useEffect(() => {
        // 自定義sidebar高度(由於confirmBtn在外面)
        sidebarHeightContext.setSidebarHeight('100% - confirmBtn');
        // console.log(sidebarHeightContext.sidebarHeight);
    }, []);

    const confirmationAlert = () => {
        swalWithCustomStyling
            .fire({
                title: '',
                text: t('userManage/accountDelete:confirm_delete_account2'),
                html: t('userManage/accountDelete:confirm_delete_account3'),
                icon: 'warning',
                iconColor: '#CC3300',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: t('userManage/accountDelete:confirm_delete'),
                cancelButtonText: t('userManage/accountDelete:cancel'),
                reverseButtons: true,
            })
            .then(function (result) {
                console.log(result);
                if (result.isConfirmed) {
                    handleDeleteAccount();
                } else {
                }
            });
    };

    // Delete Account
    const handleDeleteAccount = async () => {
        // if (!guid) {
        //     setCookie('loginUser', { status: null }, { path: '/' });
        //     setCookie('loginAuto', 'false', { path: '/' });
        //     navigate('/login');
        // }

        axiosApi(`/web/user/accountdelete`, 'post', { password })
            .then((res) => {
                const status = res.data.status;
                if (status === '0') {
                    setCookie('loginUser', { status: null }, { path: '/' });
                    setCookie('loginAuto', 'false', { path: '/' });
                    navigate('/login');
                } else if (status === '1') {
                    swalWithCustomStyling.fire({
                        title: t('userManage/accountDelete:operation_failed_no_account_or_incorrect_password'),
                        text: t('userManage/accountDelete:please_login_again_before_retry'),
                        icon: 'error',
                        confirmButtonText: '確認',
                    });
                    setCookie('loginUser', { status: null }, { path: '/' });
                    setCookie('loginAuto', 'false', { path: '/' });
                    navigate('/login');
                } else {
                    swalWithCustomStyling.fire({
                        title: '操作失敗',
                        text: t('userManage/accountDelete:please_login_again_before_retry'),
                        icon: 'error',
                        confirmButtonText: t('userManage/accountDelete:confirm'),
                    });
                    setCookie('loginUser', { status: null }, { path: '/' });
                    setCookie('loginAuto', 'false', { path: '/' });
                    navigate('/login');
                }
            })
            .catch((err) => {
                swalWithCustomStyling.fire({
                    title: t('userManage/accountDelete:operation_failed_password_incorrect'),
                    text: t('userManage/accountDelete:please_login_again_before_retry'),
                    icon: 'error',
                    confirmButtonText: t('userManage/accountDelete:confirm'),
                });
            });

        // await axios({
        //     method: 'delete',
        //     url: '/web/user/accountdelete',
        //     headers: { 'Content-Type': 'application/json' },
        //     data,
        // })
        //     .then((res) => {
        //         const status = res.data.status;
        //         if (status === '0') {
        //             setCookie('loginUser', { status: null }, { path: '/' });
        //             setCookie('loginAuto', 'false', { path: '/' });
        //             navigate('/login');
        //         } else if (status === '1') {
        //             swalWithCustomStyling.fire({
        //                 title: '操作失敗，查無此帳號/密碼錯誤',
        //                 text: '請重新登入後再嘗試',
        //                 icon: 'error',
        //                 confirmButtonText: '確認',
        //             });
        //             setCookie('loginUser', { status: null }, { path: '/' });
        //             setCookie('loginAuto', 'false', { path: '/' });
        //             navigate('/login');
        //         } else {
        //             swalWithCustomStyling.fire({
        //                 title: '操作失敗',
        //                 text: '請重新登入後再嘗試',
        //                 icon: 'error',
        //                 confirmButtonText: '確認',
        //             });
        //             setCookie('loginUser', { status: null }, { path: '/' });
        //             setCookie('loginAuto', 'false', { path: '/' });
        //             navigate('/login');
        //         }
        //     })
        //     .catch((err) => {
        //         swalWithCustomStyling.fire({
        //             title: '操作失敗，密碼錯誤',
        //             text: '請重新登入後再嘗試',
        //             icon: 'error',
        //             confirmButtonText: '確認',
        //         });
        //     });
    };

    // 儲存資料
    async function handleSubmit(e) {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        // console.log('accessToken', accessToken);

        if (!password) {
            swalWithCustomStyling.fire({
                title: t('userManage/accountDelete:operation_failed_enter_password'),
                text: '',
                icon: 'error',
                confirmButtonText: t('userManage/accountDelete:confirm'),
            });
        } else {
            try {
                confirmationAlert();
            } catch (err) {
                console.log(err);
            }
        }
    }

    // 密碼顯示切換
    function switchPassword() {
        setShowPassword(!showPassword);
    }

    return (
        <article id='container-accountDelete'>
            <div className='articleBoxWithSideBox'>
                <h1 className='containTitle'>{t('userManage/accountDelete:delete_account')}</h1>
                <section className='row g-3 mb-3'>
                    <div className='col-12 warningWord1'>{t('userManage/accountDelete:confirm_delete_account')}</div>
                    <div className='col-12 warningWord2'>{t('userManage/accountDelete:delete_account_warning')}</div>
                    <div className='col-9 col-sm-6 warningWord2 mt-6'>
                        <label htmlFor='password' className='form-label'>
                            {t('userManage/accountDelete:enter_delete_account_password')}
                        </label>
                        <div className='input-group d-flex'>
                            <input
                                className='form-control'
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                placeholder={t('userManage/accountDelete:enter_password')}
                                value={password || ''}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />

                            <button
                                type='button'
                                className='btn btn-color-outline-auxiliary'
                                id='passwordSwitch'
                                tabIndex='-1'
                                onClick={switchPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            {/*submit button */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='btn-confirm'
                    className='btn btn-color-main rounded-pill'
                    onClick={handleSubmit}
                >
                    {t('userManage/accountDelete:delete_account_button')}
                </button>
            </div>
        </article>
    );
}

export default AccountDelete;
