import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';

/*
  這是一個用於顯示和更新使用者註冊數據的模態對話框組件。
  包含了使用 useEffect 來取得註冊詳細資訊的邏輯，
  並提供輸入欄位的變化處理、提交表單和刪除用戶的功能。
  使用了 dayjs 库來格式化日期和時間，以及 axios 來發送 HTTP 請求。
  此組件使用了 react-router-dom 中的 useNavigate 來實現路由導航。
*/
export default function UpdateActRegistrationModal({
    updateActRegistrationModalOpen,
    setUpdateActRegistrationModalOpen,
    registrationData,
    setRegistrationData,
}) {
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`web/ActRegistration/getRegistrationDetails/${registrationData.registrationGuid}`)
            .then((res) => {
                const fetchedData = { ...res.data.registration };
                console.log(fetchedData);

                fetchedData.registryDate = dayjs(fetchedData.time_Registry).format('YYYY-MM-DD');
                fetchedData.playingDate = dayjs(fetchedData.time_Playing).format('YYYY-MM-DD');
                fetchedData.registryTime = dayjs(fetchedData.time_Registry).format('HH:mm:ss');
                fetchedData.playingTime = dayjs(fetchedData.time_Playing).format('HH:mm:ss');
                fetchedData.checkinDate = dayjs(fetchedData.time_Checkin).format('YYYY-MM-DD');
                fetchedData.checkinTime = dayjs(fetchedData.time_Checkin).format('HH:mm:ss');

                setRegistrationData(fetchedData);
            })
            .catch((err) => console.log(err));
    }, [registrationData.registrationGuid]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setRegistrationData({
            ...registrationData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const { registryDate, registryTime, playingDate, playingTime, checkinDate, checkinTime } = registrationData;

        if (registrationData.status === '0') {
            registrationData.time_Checkin = null;
            registrationData.time_Playing = null;
        } else {
            // checkin time
            if (checkinTime === 'Invalid Date') {
                registrationData.time_Checkin = null;
            } else if (checkinTime !== 'Invalid Date' && checkinDate === 'Invalid Date') {
                registrationData.time_Checkin = `${registryDate}T${checkinTime}`;
            } else {
                registrationData.time_Checkin = `${checkinDate}T${checkinTime}`;
            }

            // playing time
            if (playingTime === 'Invalid Date') {
                registrationData.time_Playing = null;
            } else if (playingTime !== 'Invalid Date' && playingDate === 'Invalid Date') {
                registrationData.time_Playing = `${registryDate}T${playingTime}`;
            } else {
                registrationData.time_Playing = `${playingDate}T${playingTime}`;
            }
        }

        registrationData.time_Registry = `${registryDate}T${registryTime}`;

        // fetch put newUserData
        axios
            .put(`web/ActRegistration/updateRegistration`, registrationData)
            .then((res) => {
                console.log(res);
                navigate(0);
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteUser = () => {
        axios
            .post(`web/ActRegistration/deleteRegistration/${registrationData.registrationGuid}`, registrationData)
            .then((res) => {
                console.log(res);
                navigate(0);
            })
            .catch((err) => console.log(err));
    };

    return (
        <Modal
            className='updateActUserModal modal'
            show={updateActRegistrationModalOpen}
            onHide={() => setUpdateActRegistrationModalOpen(false)}
            centered
            dialogClassName='my-modal'
        >
            <Modal.Header closeButton className='border-bottom'>
                <Modal.Title>編輯使用者</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row g-3'>
                    <div className='col-12'>
                        <label htmlFor='name' className='fontSize75Rem mb-2'>
                            使用者名稱
                        </label>
                        <input
                            id='name'
                            name='name'
                            className='form-control mb-3'
                            type='text'
                            placeholder='eg. 小杰'
                            value={registrationData?.name || ''}
                            onChange={handleInputChange}
                        />
                        <label htmlFor='phone' className='fontSize75Rem mb-2'>
                            手機號碼
                        </label>
                        <input
                            id='phone'
                            name='phone'
                            className='form-control mb-3'
                            type='text'
                            pattern='[0-9]*'
                            placeholder='09123456789'
                            value={registrationData?.phone || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='registryTime' className='fontSize75Rem mb-2'>
                            報名時間
                        </label>
                        <input
                            id='registryTime'
                            name='registryTime'
                            className='form-control mb-3'
                            type='time'
                            value={registrationData?.registryTime || ''}
                            onChange={handleInputChange}
                            required
                            step='1'
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor='queueNo' className='fontSize75Rem mb-2'>
                            報名序號
                        </label>
                        <input
                            id='queueNo'
                            name='queueNo'
                            className='form-control mb-3'
                            type='number'
                            value={registrationData?.queueNo || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='registryTime' className='fontSize75Rem mb-2'>
                            報到時間
                        </label>
                        <input
                            id='checkinTime'
                            name='checkinTime'
                            className='form-control mb-3'
                            type='time'
                            value={registrationData?.status !== '0' ? registrationData?.checkinTime : ''}
                            onChange={handleInputChange}
                            required
                            step='1'
                            disabled={registrationData?.status === '0'}
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor='rank' className='fontSize75Rem mb-2'>
                            排名
                        </label>
                        <input
                            id='rank'
                            name='rank'
                            className='form-control mb-3'
                            type='number'
                            value={registrationData?.rank || ''}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                </div>
                <div className='row g-3 align-items-end'>
                    <div className='col-6'>
                        <label htmlFor='time_Playing' className='fontSize75Rem mb-2'>
                            遊戲時間
                        </label>
                        <input
                            id='playingTime'
                            name='playingTime'
                            className='form-control mb-3'
                            type='time'
                            value={registrationData?.status !== '0' ? registrationData?.playingTime : ''}
                            onChange={handleInputChange}
                            required
                            step='1'
                            disabled={registrationData?.status === '0'}
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor='score' className='fontSize75Rem mb-2'>
                            遊戲分數
                        </label>
                        <input
                            id='score'
                            name='score'
                            className='form-control mb-3'
                            type='number'
                            value={registrationData?.score || 0}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className='row g-3'>
                    <div className='col-12'>
                        <label htmlFor='status' className='fontSize75Rem mb-2'>
                            狀態
                        </label>
                        <select
                            className='form-control mb-3 w-100'
                            name='status'
                            id='status'
                            value={registrationData?.status || ''}
                            onChange={handleInputChange}
                        >
                            <option value=''>請選擇</option>
                            <option value='0'>0 : 未報到</option>
                            <option value='1'>1 : 已報到</option>
                            <option value='2'>2 : 已上場</option>
                            <option value='3'>3 : 鎖定（停用）</option>
                            <option value='4'>4 : 刪除</option>
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center mx-4'>
                <button type='button' className='btn btn-primary text-white px-4 me-2' onClick={handleSubmit}>
                    確定
                </button>
                <button
                    type='button'
                    className='btnCancel btn btn-outline-primary px-4'
                    onClick={() => setUpdateActRegistrationModalOpen(false)}
                >
                    取消
                </button>
                <button type='button' className='btnCancel btn btn-outline-danger px-3' onClick={handleDeleteUser}>
                    刪除使用者
                </button>
            </Modal.Footer>
        </Modal>
    );
}
