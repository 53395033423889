import './newsUpdate.scss';
import RenderIndexList from '../../../RenderIndexList';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

const NewsUpdate = () => {
    const [isGetNewsList, setIsGetNewsList] = useState(false);
    const [newsList, setNewsList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    //#region [code to name]

    // TODO : put all temps into utils.js ?
    const pageTitle = '新聞動態';
    const deleteApiURL = 'web/service/bulletin';
    const createURL = '/newsupdate/create';

    const titleList = [
        '順序',
        '型態',
        // '語言代碼',
        '新聞標題',
        '發佈時間',
        '結束時間',
        '狀態',
        '',
    ];

    const categoryCodeToName = {
        0: '最新消息',
        1: '產品發布',
        2: '展會活動',
    };

    const languageCodeToName = {
        ZH: '簡体中文',
        ZF: '繁体中文',
        EN: '英文',
    };

    const statusCodeToName = {
        0: '停用',
        1: '啟用',
    };

    //#endregion

    //#region [function]

    const getNewsList = useCallback(() => {
        setIsGetNewsList(false);

        const gettingNewsList = async () => {
            await axios
                .post('web/service/getBulletinList', {
                    data: { PageSize: 50 },
                })
                .then((res) => {
                    console.log(res.data.data);
                    const data = res.data.data;
                    let list = [];

                    // TODO : app display function with undefined
                    data.forEach((el) => {
                        el.guid = el.bulletinGuid;
                        el.detailUrl = `/newsupdate/edit/${el.guid}`;

                        el.dataList = [
                            el.displayOrder,
                            `${categoryCodeToName[el.category]}`,
                            // `${languageCodeToName[el.languageCode]}`,
                            el.title,
                            el.releaseTime.split('T')[0],
                            el.endTime.split('T')[0],
                            `${statusCodeToName[el.status]}`,
                        ];

                        const filterItemsObject = new Object();
                        filterItemsObject.guid = el.bulletinGuid;
                        filterItemsObject.displayOrder = el.displayOrder.toString();
                        filterItemsObject.categoryName = `${categoryCodeToName[el.category]}`;
                        // filterItemsObject.languageName = `${
                        // 	languageCodeToName[el.languageCode]
                        // }`;
                        filterItemsObject.title = el.title;
                        filterItemsObject.releaseTime = el.releaseTime;
                        filterItemsObject.endTime = el.endTime;
                        filterItemsObject.status = `${statusCodeToName[el.status]}`;

                        list = [filterItemsObject, ...list];
                    });

                    setFilterItemsObjectList(list);
                    setNewsList(data);
                    setFilterList(data);
                    setIsGetNewsList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingNewsList();
    }, []);

    //#endregion

    useEffect(() => {
        getNewsList();
    }, [getNewsList]);

    return (
        <section id='section-main'>
            <div id='container-newsUpdate'>
                <RenderIndexList
                    titleList={titleList}
                    contentList={newsList}
                    setList={setNewsList}
                    searchTextRef={searchTextRef}
                    isGetList={isGetNewsList}
                    setIsGetList={setIsGetNewsList}
                    getList={getNewsList}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    pageTitle={pageTitle}
                    filterItemsObjectList={filterItemsObjectList}
                    deleteApiURL={deleteApiURL}
                    createURL={createURL}
                />
            </div>
        </section>
    );
};

export default NewsUpdate;
