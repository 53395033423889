import './factoryRecordLog.scss';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { sortTable } from '../../../../components/utils/utils';
import { DialogCol } from './DialogCol';
import Pagination from '../../../../components/Pagination/Pagination';
import { ReactComponent as Icn_Reload } from '../../../../assets/images/icn_Reload.svg';
import { ReactComponent as Icn_Gear } from '../../../../assets/images/icn_Gear.svg';

const FactoryRecordLog = () => {
    const [isGetList, setIsGetList] = useState(false);
    const [filterList, setFilterList] = useState('');

    // pagination
    const [paginationData, setPaginationData] = useState({});
    const sortBy = ['transactionTime desc'];
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);

    // modal
    // state
    const [pageData, setPageData] = useState({});
    const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const paramsGuid = useParams('factoryRecordGuid');

    const pageTitle = '发球机出厂记录';
    const titleList = ['#', '时间', '异动內容', '人员'];

    const getList = useCallback((advancedSearchDataList = null, pageSize = 20, sort = sortBy, pageNumber = 1) => {
        setIsGetList(false);

        const gettingList = async () => {
            await axios
                .post(`/web/product/productLog/${paramsGuid.factoryRecordGuid}`, {
                    sort,
                    pageSize,
                    page: pageNumber,
                    // advancedSearchDataList,
                })
                .then((res) => {
                    console.log(res.data);

                    // pagination
                    const { numberOfData, totalPage } = res.data;
                    setPaginationData({
                        ...paginationData,
                        numberOfData: numberOfData,
                        totalPage: totalPage,
                        pageNumber: pageNumber,
                    });

                    const data = res.data.result;
                    let list = [];

                    data.forEach((el, index) => {
                        el.guid = el.logGuid;

                        el.dataList = [index + 1, el.transactionTime.split('T')[0], el.updateContent, el.userName];

                        const filterItemsObject = new Object();
                        filterItemsObject.guid = el.logGuid;
                        filterItemsObject.displayOrder = index + 1;

                        filterItemsObject.transactionTime = el.transactionTime;
                        filterItemsObject.updateContent = el.updateContent;
                        filterItemsObject.userName = el.userName;

                        list = [filterItemsObject, ...list];
                    });

                    setFilterList(data);
                    setIsGetList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingList();
    }, []);

    // DialogCol Modal open
    const handleOpen = (el) => {
        setModalData(el);
        setIsOpen(true);
    };

    useEffect(() => {
        getList();
        sortTable(1);
    }, [getList]);

    return (
        <section id='section-main'>
            <div id='container-exploreUpdate'>
                <>
                    <h5 className='fw-bold mb-4'>{pageTitle}</h5>

                    <div className='searchTableWrapper bg-white p-4 rounded-4'>
                        <div className='searchTableTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>异动记录</h6>

                            <span className='reloadAppList ms-auto me-2' onClick={getList}>
                                <Icn_Reload />
                            </span>
                            <span>
                                <Icn_Gear />
                            </span>
                        </div>
                        <div className='renderIndexListSearchTableBottom'>
                            <section>
                                <table className='appListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            {titleList.map((el) => (
                                                <th
                                                    key={titleList.indexOf(el)}
                                                    scope='col'
                                                    className='text-grey4 border-0'
                                                    onClick={() => sortTable(titleList.indexOf(el))}
                                                >
                                                    {el}
                                                    <span></span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {isGetList &&
                                            (filterList.length !== 0 ? (
                                                filterList.map((el) => {
                                                    return (
                                                        <tr key={el.guid} onClick={() => handleOpen(el)}>
                                                            {el.dataList.map((el) => (
                                                                <td key={Math.random()}>{el}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='7'>無版本紀錄</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/*讀取畫面*/}
                                <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                    <h6 className='sr-only'>Loading...</h6>

                                    <div className='spinner-border text-primary' role='status'></div>
                                </div>
                            </section>

                            <Pagination
                                paginationData={paginationData}
                                setPaginationData={setPaginationData}
                                fetchData={getList}
                                sortBy={sortBy}
                                advancedSearchDataList={advancedSearchDataList}
                            />

                            <DialogCol
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                modalData={modalData}
                                setModalData={setModalData}
                                btnKeyName={dialogBtnKeyName}
                                pageData={pageData}
                                setPageData={setPageData}
                            />
                        </div>
                    </div>
                </>
            </div>
        </section>
    );
};

export default FactoryRecordLog;
