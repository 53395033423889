import './orderIssueInvoiceChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const OrderIssueInvoiceChange = () => {
    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);
    const [orderData, setOrderData] = useState(null);

    const paramsGuid = useParams('orderGuid');
    const navigate = useNavigate();

    const orderStatus = {
        0: '待付款',
        1: '待理貨',
        2: '待出貨',
        3: '待收貨',
        4: '訂單完成',
        5: '待退貨',
        6: '待換貨',
        7: '待退款',
        8: '退換貨、退款完成',
        9: '訂單不成立',
    };

    const invoiceStatus = {
        0: '尚未開發票',
        1: '完成開發票',
        2: '部分開發票',
    };

    const paymentMethod = {
        0: '信用卡付款',
        1: '付現金',
        2: '匯款',
        3: '貨到付款',
    };

    //#endregion

    //#region [function]

    // 訂單狀態改為待出貨
    const handleOrderStatus = async () => {
        Swal.fire({
            title: '確定要改為待出貨嗎?',
            text: '注意!! 訂單狀態修改後將無法回到先前狀態',
            icon: 'warning',
            allowOutsideClick: true,
            confirmButtonText: '確定',
            confirmButtonColor: 'var(--primary)',
            allowEscapeKey: false,

            cancelButtonText: '取消',
            cancelButtonColor: 'var(--secondary)',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);

                await axios
                    .get(`web/SalSalesOrderAdmin/updateSalesOrderStatusForPicking/${paramsGuid.orderGuid}`)
                    .then((res) => {
                        setIsLoading(false);
                        console.log(res.data);

                        if (res.data.status === '0') {
                            getOrderData();
                            Swal.fire({
                                icon: 'success',
                                title: '訂單狀態修改成功，已可開立發票',
                            });
                        } else if (res.data.status === '400') {
                            Swal.fire({
                                icon: 'error',
                                title: res.data.message,
                            });
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: '網路不穩，儲存失敗',
                            text: '請再次儲存。',
                        });
                        console.log(err);
                    });
            }
        });
    };

    const handleIssueInvoice = async () => {
        setIsLoading(true);

        await axios
            .post(`web/SalSalesOrderAdmin/onlinevideo`, {})
            .then((res) => {
                console.log(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: '網路不穩，儲存失敗',
                    text: '請再次儲存。',
                });
                console.log(err);
            });
    };

    //#endregion

    const getOrderData = async () => {
        await axios.get(`web/SalSalesOrderAdmin/getSalesOrder/${paramsGuid.orderGuid}`).then((res) => {
            console.log(res.data.result);
            setOrderData(res.data.result);
        });
    };

    useEffect(() => {
        getOrderData();
    }, []);

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id='section-main'>
                <form id='container-orderIssueInvoiceChange'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>訂單開發票</h5>
                        <button
                            className='btnDelete btn btn-success px-4 ms-auto ms-auto me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleOrderStatus();
                            }}
                            type='button'
                            disabled={orderData?.status === '2' ? true : false}
                        >
                            {orderData?.status === '2' ? orderStatus[orderData?.status] : '訂單改為待出貨'}
                        </button>
                        <button
                            className='btn btn-primary text-white px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleIssueInvoice();
                            }}
                            disabled={orderData?.status === '2' ? false : true}
                        >
                            {orderData?.invoiceStatus === '0'
                                ? '開立發票'
                                : orderData?.invoiceStatus === '1'
                                  ? '完成開發票'
                                  : ''}
                        </button>
                        <button
                            className='btnCancel btn btn-outline-primary px-4'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/orderIssueInvoice');
                            }}
                            type='button'
                        >
                            回上頁
                        </button>
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>訂單編號：{orderData?.orderNo}</h6>
                        </div>
                        <div className='infoBottom row mb-3'>
                            <div className='col-4'>
                                <label htmlFor='fullName' className='fontSize75Rem mb-2'>
                                    訂購人
                                </label>
                                <input
                                    type='text'
                                    id='fullName'
                                    name='fullName'
                                    className='form-control mb-3'
                                    value={`${orderData?.lastname ?? ''} ${orderData?.firstname ?? ''}`}
                                    onChange={() => {}}
                                    disabled
                                />
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    訂單狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='status'
                                    id='status'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            status: e.target.value,
                                        })
                                    }
                                    value={orderData?.status ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(orderStatus).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='orderDate' className='fontSize75Rem mb-2'>
                                    銷售日期
                                </label>
                                <input
                                    type='datetime-local'
                                    id='orderDate'
                                    name='orderDate'
                                    className='form-control mb-3'
                                    value={orderData?.orderDate.split('+')[0].split('.')[0] ?? ''}
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            orderDate: e.target.value,
                                        })
                                    }
                                    disabled
                                />
                                <label htmlFor='invoiceStatus' className='fontSize75Rem mb-2'>
                                    發票狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='invoiceStatus'
                                    id='invoiceStatus'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            invoiceStatus: e.target.value,
                                        })
                                    }
                                    value={orderData?.invoiceStatus ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(invoiceStatus).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='grossAmount' className='fontSize75Rem mb-2'>
                                    本幣應稅總金額
                                </label>
                                <input
                                    type='text'
                                    id='grossAmount'
                                    name='grossAmount'
                                    className='form-control mb-3'
                                    value={orderData?.grossAmount ?? ''}
                                    onChange={() => {}}
                                    disabled
                                />
                                <label htmlFor='paymentMethod' className='fontSize75Rem mb-2'>
                                    付款方式
                                </label>
                                <select
                                    className='form-control'
                                    name='paymentMethod'
                                    id='paymentMethod'
                                    onChange={(e) =>
                                        setOrderData({
                                            ...orderData,
                                            paymentMethod: e.target.value,
                                        })
                                    }
                                    value={orderData?.paymentMethod ?? ''}
                                    disabled
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    {Object.values(paymentMethod).map((item, index) => {
                                        return (
                                            <option key={item} value={index}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <label htmlFor='note' className='fontSize75Rem mb-2'>
                                    備註
                                </label>
                                <textarea
                                    name=''
                                    id='note'
                                    className='form-control'
                                    rows='2'
                                    style={{
                                        resize: 'none',
                                    }}
                                    value={orderData?.note ?? ''}
                                    disabled
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>訂單明細</h6>
                        </div>
                        <div className='infoBottom row mb-3'>
                            <div className='col-12'>
                                <table
                                    className='table table-striped border'
                                    style={{
                                        tableLayout: 'fixed',
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '50px',
                                                }}
                                            >
                                                #
                                            </th>
                                            <th scope='col' className='text-center'>
                                                商品名稱
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '50px',
                                                }}
                                            >
                                                數量
                                            </th>
                                            <th
                                                scope='col'
                                                className='text-center'
                                                style={{
                                                    width: '200px',
                                                }}
                                            >
                                                金額
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderData?.salesOrderItemList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope='col' className='text-center'>
                                                        {item?.displayOrder}
                                                    </td>
                                                    <td scope='col' className='text-start'>
                                                        {item?.name_01}
                                                    </td>
                                                    <td scope='col' className='text-center'>
                                                        {item?.quantitySales}
                                                    </td>
                                                    <td scope='col' className='text-center'>
                                                        {item?.grossAmount}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default OrderIssueInvoiceChange;
