import './actRegister.scss';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import Swal from 'sweetalert2';

function ActRegister() {
    const navigate = useNavigate();

    const [modalShow, setModalShow] = useState(false); // 活動規則modal

    const [data, setData] = useState({
        name: '',
        phone: '',
        email: '',
        isConfirmRule: '',
        nameWarning: '',
        phoneWarning: '',
        emailWarning: '',
        isConfirmRuleWarning: '',
        scheduleGuid: '', // 日程
        scheduleName: '', // 日程名稱，使用startDate作為select option顯示名稱
    });

    const [actInfo, setActInfo] = useState({}); // 後端-基礎活動資訊
    const [scheduleList, setScheduleList] = useState([]); // 後端-日程資訊
    const [kindOfFn, setKindOfFn] = useState('register'); // 顯示-報名register 或 查詢search
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false); // 控制submit按鈕是否可點
    const [topValue, setTopValue] = useState(0); // 初始的相對高度 top 值
    const [fullPageMinHeight, setFullPageMinHeight] = useState(0); // 頁面最小高度
    const { activityGuid } = useParams();
    const location = useLocation();

    // console.log('data', data);
    // console.log('scheduleList', scheduleList);

    // 驗證手機
    const handlePhoneInput = () => {
        const phoneReg = /^09\d{8}$/;

        if (!phoneReg.test(data.phone)) {
            setData({ ...data, phoneWarning: '請填寫正確手機號碼' });
        }
    };

    // 報名
    const handleRegister = async (e) => {
        e.preventDefault();

        const newData = { ...data };
        const registerKey = {
            0: 'phone',
            1: 'email',
        };

        const requiredFields = ['name', registerKey[actInfo.registrationKey], 'isConfirmRule'];

        // 驗證必填欄位有值且無錯誤訊息
        const isValid = (() => {
            return requiredFields.every((el) => data[el] !== '' && data[el + 'Warning'] === '');
        })();

        console.log('isValid', isValid);

        if (isValid) {
            const insertRegistration = async () => {
                // 鎖住submitBtn;
                setIsSubmitBtnDisabled(true);

                // 一段時間內不停地重複嘗試，這裡設定為 5 分鐘（300,000 毫秒）
                const retryDuration = 3000;

                const startTime = Date.now();

                const retry = async () => {
                    try {
                        const res = await axios.post('/web/ActRegistration/insertRegistration', {
                            scheduleGuid: data.scheduleGuid,
                            name: data.name,
                            phone: data.phone,
                            email: data.email,
                        });

                        console.log(res.data);

                        if (res.data.status === '0') {
                            // 如果成功，跳轉頁面
                            navigate(`/actRegisterInfoForUser/${res.data.registration.registrationGuid}`, {
                                state: {
                                    actName: actInfo.name,
                                    boothName: actInfo.boothName,
                                    scheduleName: data.scheduleName,
                                    listSchedule: actInfo.listSchedule,
                                    activityGuid: activityGuid,
                                    searchName: data.name,
                                    registrationKey: actInfo.registrationKey,
                                    playLimit: actInfo.playLimit,
                                    searchPhone: data.phone,
                                    searchEmail: data.email,
                                },
                            });

                            setIsSubmitBtnDisabled(false);
                        } else if (res.data.status === '800') {
                            Swal.fire({
                                icon: 'error',
                                title: '報名失敗',
                                text: '此活動報名時間已過',
                                confirmButtonText: '確認',
                                confirmButtonColor: '#007bff',
                            }).then((result) => {
                                setIsSubmitBtnDisabled(false);
                            });
                        } else if (res.data.status === '801') {
                            Swal.fire({
                                icon: 'error',
                                title: '報名失敗',
                                text: `此${actInfo.registrationKey === '0' ? '手機號碼' : 'email'}已報名`,
                                confirmButtonText: '確認',
                                confirmButtonColor: '#007bff',
                            }).then((result) => {
                                setIsSubmitBtnDisabled(false);
                            });
                        } else {
                            // 如果未成功，檢查是否已經超過指定的重試總時間
                            const currentTime = Date.now();
                            if (currentTime - startTime < retryDuration) {
                                // 在指定時間內，立即進行下一次重試
                                retry();
                            } else {
                                // 超過指定時間，停止重試
                                setIsSubmitBtnDisabled(false);
                            }
                        }
                    } catch (error) {
                        console.error('Error inserting registration:', error);
                        // 在錯誤的情況下，檢查是否已經超過指定的重試總時間
                        const currentTime = Date.now();
                        if (currentTime - startTime < retryDuration) {
                            // 在指定時間內，立即進行下一次重試
                            retry();
                        } else {
                            // 超過指定時間，停止重試
                            setIsSubmitBtnDisabled(false);
                        }
                    }
                };

                // 開始重試
                retry();
            };

            await insertRegistration();
        } else {
            // 驗證失敗，顯示錯誤訊息
            requiredFields.forEach((el) => {
                if (newData[el] === '') {
                    newData[`${el}Warning`] = '請輸入資料';
                }
            });

            setData(newData);
        }
    };

    // 查詢
    const handleSearchRegistration = async (e) => {
        e.preventDefault();
        const newData = { ...data };

        const registerKey = {
            0: 'phone',
            1: 'email',
        };

        const requiredFields = [registerKey[actInfo.registrationKey]];

        // 驗證必填欄位有值且無錯誤訊息
        const isValid = (() => {
            return requiredFields.every((el) => data[el] !== '' && data[el + 'Warning'] === '');
        })();

        console.log('isValid', isValid);

        if (isValid) {
            // 鎖住submitBtn
            setIsSubmitBtnDisabled(true);

            // 跳轉頁面
            navigate(`/getRegistrationList/${data.scheduleGuid}`, {
                state: {
                    actName: actInfo.name,
                    boothName: actInfo.boothName,
                    scheduleName: data.scheduleName,
                    listSchedule: actInfo.listSchedule,
                    activityGuid: activityGuid,
                    searchName: data.name,
                    registrationKey: actInfo.registrationKey,
                    playLimit: actInfo.playLimit,
                    searchPhone: data.phone,
                    searchEmail: data.email,
                },
            });

            setIsSubmitBtnDisabled(false);
        } else {
            // 驗證失敗，顯示錯誤訊息
            requiredFields.forEach((el) => {
                if (newData[el] === '') {
                    newData[`${el}Warning`] = '請輸入資料';
                }
            });

            setData(newData);
        }
    };

    // 廣播更新最高分使用者(連線)
    async function start(scheduleGuid) {
        try {
            await connection.start().then(() => {
                console.log('connection.state', connection.state);

                if (connection.state === signalR.HubConnectionState.Connected) {
                    connection
                        .invoke('SubscribeToActivity', scheduleGuid)
                        .then((response) => console.log(response))
                        .catch((error) => console.error('updateScore error', error));
                }
            });
        } catch (err) {
            console.log(err);
            setTimeout(() => start(scheduleGuid), 5000);
        }
    }

    const connection = new signalR.HubConnectionBuilder()
        // .withUrl('https://localhost:44375/pingtexHub') //本地端
        .withUrl('/pingtexHub') //外網
        .withAutomaticReconnect([0, 0, 0, 0])
        .build();

    useEffect(() => {
        // 1.取得活動資訊
        const getAllActInfo = async () => {
            await axios
                .get(`/web/ActRegistration/getActivityDetails/${activityGuid}`)
                .then((res) => {
                    // console.log('getAllActInfo', res.data.activity);
                    setActInfo({
                        ...res.data.activity,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        // 2.取得日程選單
        const getScheduleList = async () => {
            await axios
                .get(`/web/ActRegistration/getScheduleList?guid=${activityGuid}&status=1`)
                .then(async (res) => {
                    // console.log('getScheduleList', res.data);

                    const info = res.data.scheduleList.map((el) => {
                        if (el.activityGuid === activityGuid) {
                            return el;
                        }
                    });

                    // console.log('info', info);
                    setScheduleList(info);

                    // 日期選單預設: 1.本日 或 2.startDate最早的日期
                    const defaultSchedule =
                        info.find((el) => {
                            return el.startDate.split('T')[0] === new Date().toISOString().split('T')[0];
                        }) ||
                        info.sort((a, b) => {
                            return new Date(a.startDate) - new Date(b.startDate);
                        })[0];

                    setData({
                        ...data,
                        scheduleGuid: defaultSchedule.scheduleGuid,
                        scheduleName: defaultSchedule.startDate.split('T')[0],
                    });

                    // 獲取最高分
                    await axios(`/web/ActRegistration/getScheduleDetails/${defaultSchedule.scheduleGuid}`)
                        .then((res) => {
                            // console.log(res.data);

                            setActInfo((prevActInfo) => ({
                                ...prevActInfo,
                                registration: res.data.registration,
                            }));

                            // 掛載推播
                            handleConnection(defaultSchedule.scheduleGuid);
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        getAllActInfo();
        getScheduleList();

        // 3.廣播更新最高分使用者
        const handleConnection = async (scheduleGuid) => {
            // 後端更新資料後，即將call的function
            connection.on('ReloadRank', () => {
                // 獲取最高分
                axios(`/web/ActRegistration/getScheduleDetails/${scheduleGuid}`)
                    .then((res) => {
                        console.log(res.data);

                        setActInfo((prevActInfo) => ({
                            ...prevActInfo,
                            registration: res.data.registration,
                        }));
                    })
                    .catch((err) => console.log(err));
            });

            // connection.onreconnecting(() => {
            // 	console.assert(
            // 		connection.state === signalR.HubConnectionState.Reconnecting
            // 	);
            // });

            // connection.onreconnected((connectionId) => {
            // 	console.assert(
            // 		connection.state === signalR.HubConnectionState.Connected
            // 	);

            // 	setTimeout(() => start(scheduleGuid), 5000);
            // });

            // connection.onclose(async () => {
            // 	setTimeout(() => start(scheduleGuid), 5000);
            // });

            // console.log('connection.state', connection.state);
            // console.log('scheduleGuid', scheduleGuid);

            // 開始連線
            connection
                .start()
                .then(() => {
                    // console.log('connection.state', connection.state);

                    if (connection.state === signalR.HubConnectionState.Connected) {
                        connection
                            .invoke('SubscribeToActivity', scheduleGuid)
                            .then((response) => console.log(response))
                            .catch((error) => {
                                // console.error('updateScore error', error)
                            });
                    } else {
                        console.error('Connection is not in the Connected state.');
                    }
                })
                .catch((error) => console.error('Connection start error', error));

            // Cleanup function
            return () => {
                connection.stop();
            };
        };

        // 4.依照螢幕寬度調整與最上方圖片的相對高度
        // 函式：根據視窗寬度返回初始的 top 值
        const windowWidth = window.innerWidth;

        // 在組件掛載後計算初始的 top 值並設置
        const initialTop = windowWidth * 0.63;
        setTopValue(initialTop);

        const initialMinHeight = 1550 + windowWidth * 0.57;
        setFullPageMinHeight(initialMinHeight);

        const handleResize = () => {
            const windowWidth = window.innerWidth;

            // 根據視窗寬度調整值
            setTopValue(windowWidth * 0.63);
            setFullPageMinHeight(1550 + windowWidth * 0.57);
        };

        // 監聽視窗大小變化事件
        window.addEventListener('resize', handleResize);

        // 清除效果
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 捲動頁面改變nav背景顏色
    const [scrollTop, setScrollTop] = useState(0);

    function throttle(callback, delay) {
        let previousCall = new Date().getTime();
        return function () {
            const time = new Date().getTime();
            if (time - previousCall >= delay) {
                previousCall = time;
                callback.apply(null, arguments);
            }
        };
    }

    useEffect(() => {
        const header = document.getElementById('header-app');
        header.classList.add('customerHeader');
        header.classList.remove('bg-white');

        const handleScroll = () => {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
        };

        // const throttledScroll = throttle(handleScroll, 300); // 設置 200 毫秒的節流

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollTop]); // 只在 scrollTop 改變時觸發

    // console.log('actInfo', actInfo);

    return (
        <div className='container-fluid position-relative p-0'>
            {/*讀取畫面*/}
            <div
                className={`text-center loadingBox ${!isSubmitBtnDisabled && 'd-none'} position-absolute`}
                style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                }}
                id='loadingBox'
            >
                <div className='spinner-border text-primary' role='status'></div>
            </div>

            <article
                id='container-actRegister'
                className='bgImage bg-background flex-column align-items-center px-0'
                style={{ minHeight: `${fullPageMinHeight}px` }}
            >
                <picture>
                    <source
                        srcSet='../../../../images/actManage/actRegisterInfoForUser/topTitle_2x.png'
                        media='(min-width: 1025px)'
                    />
                    <img
                        className='w-100'
                        src='../../../../images/actManage/actRegisterInfoForUser/topTitle_1x.png'
                        alt='topTitle_1x'
                    />
                </picture>
                <article
                    className='position-absolute articleBox w-100 bg-transparent border-0 mx-0 my-4 my-lg-8'
                    style={{ top: `${topValue}px` }}
                >
                    <section className={`row g-3 h-100 registerPageShow text-center pt-lg-4 pb-lg-0 mx-0 mb-6`}>
                        <h1 className='pageTitle fw-bold mt-0 mb-2'>{actInfo.name ?? ''}</h1>
                        <p className='text-center fw-bold mt-0 mb-2'>
                            {`${actInfo.startDate?.split('T')[0] ?? ''}`}

                            {/* 如果活動超過一天 */}
                            {actInfo.startDate?.split('T')[0] !== actInfo.endDate?.split('T')[0] &&
                                ` 至 ${actInfo.endDate?.split('T')[0]}`}
                        </p>
                        <p className='text-center fw-bold mt-0 mb-2'>{actInfo.boothName ?? ''}</p>

                        {actInfo.registration?.name && actInfo.registration?.score !== 0 ? (
                            <p className='text-center fw-bold p-0 m-0' style={{ color: '#E33939' }}>
                                👑最高分：
                                {`${actInfo.registration?.score}分 (${actInfo.registration?.name} ${
                                    actInfo.registration?.phone || actInfo.registration?.email
                                })`}
                            </p>
                        ) : (
                            <></>
                        )}
                    </section>

                    <section className={`row h-100 registerPageShow px-lg-10 mb-4`}>
                        <div className='codeWrap  d-inline-block position-relative border border-3 border-dark px-6 px-lg-7 pt-6 pb-6 '>
                            <div className='codeTitle position-absolute fw-bold text-center px-1 mb-0'>
                                <div className='d-flex justify-content-center'>
                                    <button
                                        type='button'
                                        className={`btn d-flex justify-content-center align-items-center fw-bold text-white border-0 rounded-pill registerBtn mx-2`}
                                        style={{
                                            height: '34px',
                                            width: '100px',
                                            backgroundColor: kindOfFn === 'register' ? '#263BE3' : '#241B12',
                                            fontSize: '20px',
                                        }}
                                        onClick={() => {
                                            setKindOfFn('register');
                                            setData({
                                                ...data,
                                                name: '',
                                                nameWarning: '',
                                                phoneWarning: '',
                                                emailWarning: '',
                                                isConfirmRuleWarning: '',
                                            });
                                        }}
                                    >
                                        報 名
                                    </button>

                                    <button
                                        type='button'
                                        className={`btn d-flex justify-content-center align-items-center fw-bold text-white border-0 rounded-pill registerBtn mx-2`}
                                        style={{
                                            height: '34px',
                                            width: '100px',
                                            backgroundColor: kindOfFn === 'search' ? '#263BE3' : '#241B12',
                                            fontSize: '20px',
                                        }}
                                        onClick={() => {
                                            setKindOfFn('search');
                                            setData({
                                                ...data,
                                                name: '',
                                                nameWarning: '',
                                                phoneWarning: '',
                                                emailWarning: '',
                                                isConfirmRuleWarning: '',
                                            });
                                        }}
                                    >
                                        查 詢
                                    </button>
                                </div>
                            </div>

                            <div className='row g-2'>
                                {/*<!-- 日程場次 -->*/}
                                {actInfo.listSchedule && (
                                    <div className='col-12'>
                                        <select
                                            style={{
                                                cursor: actInfo.selectSchedule ? 'pointer' : 'auto',
                                                boxShadow: 'none',
                                                height: '45px',
                                                borderRadius: '12px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                            className='form-select border-0 fw-bold'
                                            id='scheduleGuid'
                                            name='scheduleGuid'
                                            value={data.scheduleGuid}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    scheduleGuid: e.target.value,
                                                    scheduleName: scheduleList
                                                        .find((el) => el.scheduleGuid === e.target.value)
                                                        ?.startDate?.split('T')[0],
                                                })
                                            }
                                            // 如果只有一場，不可選
                                            disabled={!actInfo.selectSchedule}
                                            required
                                        >
                                            {scheduleList.map((el) => (
                                                <option key={el.scheduleGuid} value={el.scheduleGuid}>
                                                    {el.startDate?.split('T')[0]}
                                                </option>
                                            ))}
                                        </select>
                                        <div>{data.scheduleGuidWarning}</div>
                                    </div>
                                )}

                                {/*<!-- 姓名 -->*/}
                                {kindOfFn === 'register' && (
                                    <div className='col-12'>
                                        <input
                                            style={{
                                                height: '45px',
                                                borderRadius: '12px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                            type='text'
                                            value={data.name}
                                            className={`form-control form-control-lg border-0`}
                                            id='name'
                                            name='name'
                                            placeholder={`姓名`}
                                            autoComplete='on'
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    name: e.target.value,
                                                    nameWarning: '',
                                                })
                                            }
                                            required
                                        />
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#E33939',
                                            }}
                                        >
                                            {data.nameWarning}
                                        </div>
                                    </div>
                                )}

                                {/* 手機平板 查詢版型會跑版，此元件可撐開，但不清楚原因 */}
                                {/* <div
									className="col-12 "
									style={{ height: '0' }}
								>
									<input
										style={{
											visibility: 'hidden',
										}}
									/>
								</div> */}

                                {/*<!-- 電話 -->*/}
                                {actInfo.registrationKey === '0' ? (
                                    <div className='col-12'>
                                        <input
                                            style={{
                                                height: '45px',
                                                borderRadius: '12px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    phone: e.target.value,
                                                    phoneWarning: '',
                                                });
                                            }}
                                            type='text'
                                            className='form-control form-control-lg border-0 pe-2'
                                            id='phone'
                                            name='phone'
                                            pattern='^[0-9]*$'
                                            placeholder='手機號碼 (確認中獎資訊)'
                                            autoComplete='on'
                                            required
                                        />

                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#E33939',
                                            }}
                                        >
                                            {data.phoneWarning}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='col-12'>
                                        {/*<!-- Email -->*/}
                                        <input
                                            style={{
                                                height: '45px',
                                                borderRadius: '12px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    email: e.target.value,
                                                    emailWarning: '',
                                                });
                                            }}
                                            type='text'
                                            className='form-control form-control-lg border-0'
                                            id='email'
                                            name='email'
                                            placeholder='電子信箱 (確認中獎資訊用)'
                                            autoComplete='on'
                                            required
                                        />
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#E33939',
                                            }}
                                        >
                                            {data.emailWarning}
                                        </div>
                                    </div>
                                )}

                                {/* 確認使用者條款 */}
                                {kindOfFn === 'register' && (
                                    <div className='col-12'>
                                        <label htmlFor='isConfirmRule'>
                                            <input
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    cursor: 'pointer',
                                                }}
                                                id='isConfirmRule'
                                                type='checkbox'
                                                value={data.isConfirmRule}
                                                className={`form-check-input me-2`}
                                                checked={data.isConfirmRule}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        isConfirmRule: e.target.checked,
                                                        isConfirmRuleWarning: e.target.checked
                                                            ? ''
                                                            : '請同意活動比賽規則',
                                                    })
                                                }
                                                required
                                            />
                                        </label>
                                        <span
                                            className='fw-bold'
                                            style={{
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }}
                                            onClick={() => setModalShow(true)}
                                        >
                                            我已閱讀並同意活動比賽規則
                                        </span>
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#E33939',
                                            }}
                                        >
                                            {data.isConfirmRuleWarning}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {kindOfFn === 'register' ? (
                                <div className='agreeBtnBox d-flex justify-content-center mt-6'>
                                    {/*<!-- 確認報名 -->*/}
                                    <button
                                        style={{
                                            backgroundColor: '#E33939',
                                            fontSize: '20px',
                                        }}
                                        type='button'
                                        id='registerAgreeBtn'
                                        className='btn d-flex justify-content-center align-items-center text-white rounded-pill fw-bold'
                                        onClick={handleRegister}
                                        disabled={isSubmitBtnDisabled ? true : false}
                                    >
                                        確 認 報 名
                                    </button>
                                </div>
                            ) : (
                                <div className='agreeBtnBox d-flex justify-content-center mt-6'>
                                    {/* 查詢 */}
                                    <button
                                        style={{
                                            backgroundColor: '#E33939',
                                            fontSize: '20px',
                                        }}
                                        type='button'
                                        id='registerAgreeBtn'
                                        className='btn d-flex justify-content-center align-items-center text-white rounded-pill fw-bold'
                                        onClick={handleSearchRegistration}
                                        disabled={isSubmitBtnDisabled ? true : false}
                                    >
                                        查詢
                                    </button>
                                    {/* <p
										className="mt-1 text-grey3"
										style={{ fontSize: '12px' }}
									>
										請至少填寫一項資料
									</p> */}
                                </div>
                            )}
                        </div>

                        <Modal size='lg' show={modalShow} onHide={() => setModalShow(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title className='fw-bold' style={{ fontSize: '20px' }}>
                                    鼓動人心競賽注意事項
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='px-3 px-lg-5'>
                                <div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            一、
                                        </div>
                                        <div>
                                            參賽者同意參與本活動，並遵守本同意書各項約定、比賽規則、各項公告及主辦單位之決議，並接受最後公布之比賽結果。
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            二、
                                        </div>
                                        <div>未成年人之參賽者，需有法定監護人陪同競賽並取得同意。</div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            三、
                                        </div>
                                        <div>
                                            本活動為體育競技類型，參加活動者請注意場地狀況與身體健康，並慎重考量自我健康狀況，如有不適，請勿逞強，如有心臟、血管、糖尿病等方面病歷者，請勿隱瞞病情並請勿參加，否則，後果自行負責。
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            四、
                                        </div>
                                        <div>
                                            參賽者同意主辦單位保留隨時修改活動規則之權益，任何有關的爭議，均依中華民國法律處理，並以臺灣臺北士林地方法院為第一審管轄法院。
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            五、
                                        </div>
                                        <div>
                                            如相關內容及辦法有未盡事宜，除依法律相關規定外，主辦單位保留修改之權益，並得另行補充
                                            (包含活動之任何異動、更新、修改)，並以本活動公告為依據。
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='me-2' style={{ width: '24px' }}>
                                            六、
                                        </div>
                                        <div>
                                            本活動不收取任何費用，領獎者須依中華民國稅法規定繳納稅金，獎品不得要求折現或替換其他商品，得獎者需依規定填寫並繳交相關單據（如領獎單）。若未配合者，視為放棄獲獎資格。
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='justify-content-center'>
                                <Button
                                    className='d-flex justify-content-center align-items-center rounded-pill fw-bold w-25'
                                    style={{
                                        backgroundColor: '#263be3',
                                        color: 'white',
                                        fontSize: '18px',
                                    }}
                                    onClick={() => setModalShow(false)}
                                >
                                    確 定
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </section>

                    <section className='row h-100 px-lg-6 mb-6 mb-lg-7'>
                        <div className='col-12 text-center mb-7'>
                            <p className='fw-bold mb-0' style={{ fontSize: '18px' }}>
                                報名完成後，請記得將
                            </p>
                            <p className='fw-bold mb-0' style={{ fontSize: '18px' }}>
                                QRCODE編號
                                <span style={{ color: '#263be3' }}>螢幕截圖保存</span>!
                            </p>
                        </div>

                        <div className='col-12 ruleTopTitle mb-7'>
                            <div className='position-relative border-2 border-bottom border-dark'>
                                <p
                                    className='position-absolute text-center rounded-3 fw-bold px-2 mb-0'
                                    style={{
                                        fontSize: '24px',
                                        color: '#263be3',
                                    }}
                                >
                                    活 動 規 則
                                </p>
                            </div>
                        </div>
                        <div className='col-12 ruleContentWrap mb-3'>
                            <div className='mb-4'>
                                <p className='ruleTitle mb-2'>1. 掃描QRCODE線上報名</p>
                                <div className='ruleContent'>
                                    <p className='mb-0'>
                                        請填寫您的
                                        <span style={{ color: '#263be3' }}>姓名及手機號碼</span>
                                        ，完成線上報名。
                                    </p>
                                    <p className='mb-0'>
                                        報名成功後，系統將給您一組QRCODE條碼，這是您的報名憑證，請截圖保存。
                                    </p>

                                    <p className='mb-0' style={{ fontSize: '12px' }}>
                                        ※ 每個電話號碼僅能報名一次，無法重複報名。
                                    </p>
                                </div>
                            </div>

                            <div className='mb-4'>
                                <p className='ruleTitle mb-2'>2. 挑戰「乒乓鼓動人心」</p>
                                <div className='ruleContent'>
                                    <p className='mb-0'>報名完成後，請依序排隊等待工作人員安排進場。</p>
                                    <p className='mb-0'>
                                        <div style={{ color: '#263be3' }}>您有5次挑戰機會，每次可以打30球。</div>
                                        <div>單次挑戰結束後，需再依序排隊進場挑戰 。</div>
                                        <div>請提前準備好您的QRCODE條碼，出示給工作人員掃描。</div>
                                    </p>
                                </div>
                            </div>

                            <div className='mb-3'>
                                <p className='ruleTitle mb-2'>
                                    3.冠軍獎品：
                                    <span style={{ color: '#E33939' }}>以你之名，傳遞愛心 !</span>
                                </p>
                                <div className='ruleContent'>
                                    <p className='mb-0'>
                                        活動期間分數最高的挑戰者，
                                        <span style={{ color: '#263be3' }}>將可獲得一組愛心捐贈機會。</span>
                                    </p>
                                    <p className='mb-0'>
                                        將為您提供一台價值新台幣38800元的「賓樂達乒乓球機器人」，以您的名義，捐助給您所指定的台灣中小學桌球隊!
                                    </p>
                                    <p className='mb-0'>
                                        翔睿德公司也同時捐贈10000元的球隊營養金，您的愛心價值高達
                                        <span style={{ color: '#E33939' }}>48800元!</span>
                                    </p>
                                    <p className='mb-0' style={{ fontSize: '12px' }}>
                                        ※請於報名時填寫
                                        <span style={{ color: '#263be3' }}>「真實手機號碼」</span>
                                        ，獲獎者將於本活動結束後電話通知 !
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 footerWrap mt-4'>
                            <p className='text-center fw-bold mb-0'>有錢出錢，有力出力 !</p>
                            <p className='text-center fw-bold mb-0'>我們一起共同為台灣桌球散播種子，</p>
                            <p className='text-center fw-bold mb-0'>加入灌溉幼苗的行列 !</p>
                        </div>
                    </section>
                </article>
            </article>
        </div>
    );
}

export default ActRegister;
