import './supportContactUs.scss';
import React from 'react';
import SupportTop from '../SupportTop';
import SupportBottom from '../SupportBottom';
import { Link } from 'react-router-dom';
import ScrollToTopOnRouteChange from '../../../components/scrollToTopOnRouteChange/ScrollToTopOnRouteChange';
import { useTranslation } from 'react-i18next';

function SupportContactUs() {
    const { t } = useTranslation(['supportContactUs']); // i18n

    return (
        <div className='container-fluid p-0'>
            <article id='container-supportContactUs' className='d-flex flex-column align-items-center px-0 mx-auto'>
                <SupportTop />

                <section className='supportContactUs-section section2 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-3 text-grey5'>{t('contact_us')}</h2>
                        <h6 className='px-6 mx-auto mb-0 text-grey5'>{t('contact_us_info')}</h6>
                    </div>
                </section>

                <section className='supportContactUs-section section3 w-100 px-5 px-lg-12 py-5 py-lg-7 row bg-background'>
                    <div
                        className='col-12 p-0
					'
                    >
                        <h5 className='mb-lg-3 text-grey5'>{t('consultation_aftersales_service')}</h5>
                        <p className='mb-0 mb-lg-1 text-grey5'>
                            <span className='me-2'>
                                <img
                                    className='icnPhone h-100 mx-0'
                                    src='./images/support/supportContactUs/icnPhone.png'
                                    alt='icnPhone'
                                />
                            </span>
                            <Link
                                to='#'
                                onClick={(e) => {
                                    window.location.href = 'tel:02-88666008';
                                    e.preventDefault();
                                }}
                            >
                                <span className='text-primary'>02-88666008</span>
                            </Link>
                        </p>
                        <p className='mb-0 mb-lg-1 text-grey5'>
                            <span className='me-2'>
                                <img
                                    className='icnMail h-100 mx-0'
                                    src='./images/support/supportContactUs/icnMail.png'
                                    alt='icnMail'
                                />
                            </span>
                            <Link
                                to='#'
                                onClick={(e) => {
                                    window.location.href = 'mailto:contact@synerter.com';
                                    e.preventDefault();
                                }}
                            >
                                <span className='text-primary'>contact@synerter.com</span>
                            </Link>
                        </p>
                        <p className='mb-0 mb-1 text-grey5'>
                            <span className='me-2'>
                                <img
                                    className='icnLocation h-100 mx-0'
                                    src='./images/support/supportContactUs/icnLocation.png'
                                    alt='icnLocation'
                                />
                            </span>
                            <span className='locationText'>{t('address')}</span>
                        </p>
                        <h6 className='mt-4'></h6>
                        <h6>{t('contact_during_working_hours')}</h6>
                    </div>
                </section>

                <section className='supportContactUs-section section4 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <h2 className='px-6 mx-auto mb-3 text-grey5'>{t('aftersales_service')}</h2>
                        <h6 className='px-6 mx-auto text-grey5'>{t('welcome_to_aftersales_service')}</h6>
                    </div>
                </section>

                <section className='supportContactUs-section section5 w-100 row bg-white'>
                    <div
                        className='col-12 p-0 text-center
					'
                    >
                        <div className='textWrap d-flex justify-content-between mx-auto mb-4 mb-lg-11 px-11 px-lg-0'>
                            <div>
                                <h2 className='mb-2 mb-lg-3 text-primary'>
                                    <img
                                        className='section5_1Img h-100 mx-0'
                                        src='./images/support/supportContactUs/section5_1_2x.png'
                                        alt='section5_1Img'
                                    />
                                </h2>
                                <p className='text-primary mb-0 mb-lg-2'>{t('professional_repair')}</p>
                            </div>
                            <div className=''>
                                <h2 className='mb-2 mb-lg-3 text-primary'>
                                    <img
                                        className='section5_2Img h-100 mx-0'
                                        src='./images/support/supportContactUs/section5_2_2x.png'
                                        alt='section5_2Img'
                                    />
                                </h2>
                                <p className='text-primary mb-0 mb-lg-2'>{t('worry_free_service')}</p>
                            </div>
                            <div className=''>
                                <h2 className='mb-2 mb-lg-3 text-primary'>
                                    <img
                                        className='section5_3Img h-100 mx-0'
                                        src='./images/support/supportContactUs/section5_3_2x.png'
                                        alt='section5_3Img'
                                    />
                                </h2>
                                <p className='text-primary mb-0 mb-lg-2'>{t('official_guarantee')}</p>
                            </div>
                        </div>

                        <h6 className='mx-auto text-center text-grey5'>
                            {t('purchased_products')}
                            <span className='d-block d-lg-inline mt-2 mt-lg-0'>{t('free_one_year_warranty')}</span>
                        </h6>
                        <h6 className='mx-auto text-center text-grey5'>{t('specific_service_projects')}</h6>
                    </div>
                </section>

                <section className='supportContactUs-section section6 w-100 mb-10 row justify-content-center bg-white'>
                    <div
                        className='col-12 col-lg-auto p-0 text-center
					'
                    >
                        <img
                            className='section6_1Img h-100 mx-0 d-none d-lg-block'
                            src='./images/support/supportContactUs/section6_1_2x.png'
                            alt='section6_1Img'
                        />
                        <img
                            className='section6_1Img h-100 mx-auto d-block d-lg-none'
                            src='./images/support/supportContactUs/section6_1_1x.png'
                            alt='section6_1Img'
                        />
                    </div>
                    <div
                        className='col-12 col-lg-auto p-4 px-lg-9 pt-lg-10 pb-lg-9 bg-background
					'
                    >
                        <p className='mb-2 mb-lg-1'>
                            {t('national_service_locations_query')}
                            <br />
                            {t('one_stop_solution')}
                        </p>
                        <h6 className='mb-3 mb-lg-5'>{t('service_details')}</h6>
                        <Link
                            to={{
                                pathname: '/physicalstore',
                                state: {
                                    initialLocation: '.section5',
                                },
                            }}
                        >
                            <div className='btn btn-primary rounded-pill text-white'>{t('learn_more')}</div>
                        </Link>
                    </div>
                </section>

                <SupportBottom
                    leftCol={{
                        title: t('frequently_asked_questions'),
                        text: t('see_more'),
                        link: `/support/question`,
                    }}
                    rightCol={{
                        title: t('download_center'),
                        text: t('see_more'),
                        link: `/support/downloadcenter`,
                    }}
                />
            </article>
        </div>
    );
}

export default SupportContactUs;
