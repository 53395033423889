import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { axiosApi } from '../../api';
import { AccountContext } from '../../App';

// #region styled-components

const Wrapper = styled.div`
    border: 1px solid #dee2e6;
    background: white;

    @media (max-width: 576px) {
        border: none;
    }
`;
const Item = styled.div`
    display: flex;
    justify-content: start;
    height: 100px;
    margin-bottom: 24px;
`;

const OrderItemsWrapper = styled.div`
    @media (max-width: 576px) {
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 8px;
    }
`;

const OrderItemName = styled.div`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* 控制顯示的最大行數 */
    max-height: 3em; /* 控制最大高度，這裡假設每行高度為1.5em */

    @media (max-width: 568px) {
        width: 75%;
    }
`;

const OrderButton = styled.button`
    width: 146px;

    &:hover {
        color: white;
    }
`;

const PaymentStatusText = styled.div`
    color: var(--primary);
    font-size: 18px;
`;

const OrderItemAmountText = styled.div`
    font-size: 18px;
`;

const OrderItemImage = styled.img`
    object-fit: cover;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    @media only screen and (max-width: 576px) {
        width: 72px;
        min-width: 72px;
        height: 72px;
    }

    @media only screen and (min-width: 576px) {
        width: 100px;
        min-width: 100px;
        height: 100px;
    }
`;

// #endregion
export default function OrderPreviewCard({ order, orderStatus, t }) {
    const navigate = useNavigate();
    const { lang } = useContext(AccountContext);

    //   console.log(order.salesOrderItemList);
    const handleRebuyOrder = (orderGuid) => {
        axiosApi('/web/salSalesOrder/rebuySalesOrder', 'post', orderGuid)
            .then((res) => {
                console.log(res);
                return navigate(`/cart`);
            })
            .catch((err) => console.log(err));
    };

    const handleCreditCardPayment = (orderGuid) => {
        // 從環境變數中獲取值
        const {
            NODE_ENV,
            REACT_APP_MER_ID,
            REACT_APP_MERCHANT_ID,
            REACT_APP_TERMINAL_ID,
            REACT_APP_MERCHANT_NAME,
            REACT_APP_CUSTOMIZE,
            REACT_APP_AUTO_CAP,
            REACT_APP_PAY_TYPE,
            REACT_APP_PERIOD_NUM,
            REACT_APP_SUB_MERCH_ID,
            REACT_APP_EN_CODE_TYPE,
            REACT_APP_TIMEOUT_SECS,
            REACT_APP_THREE_DS_AUTH_IND,
            REACT_APP_REQ_TOKEN,
            REACT_APP_LAG_SELECT,
            REACT_APP_AUTH_RES_URL_PROD,
            REACT_APP_AUTH_RES_URL_DEV,
            REACT_APP_FRONT_FAIL_URL_PROD,
            REACT_APP_FRONT_FAIL_URL_DEV,
        } = process.env;

        axiosApi(`/web/payment/getCreditCardInfo/${orderGuid}`).then((res) => {
            // console.log(res.data.result);

            const orderInfo = res.data.result;

            // 填充表单数据
            const keysToFrom = {
                MerchantID: REACT_APP_MERCHANT_ID,
                MerchantName: REACT_APP_MERCHANT_NAME,
                customize: REACT_APP_CUSTOMIZE,
                AutoCap: REACT_APP_AUTO_CAP,
                TerminalID: REACT_APP_TERMINAL_ID,
                frontFailUrl: NODE_ENV === 'development' ? REACT_APP_FRONT_FAIL_URL_DEV : REACT_APP_FRONT_FAIL_URL_PROD,
                AuthResURL: NODE_ENV === 'development' ? REACT_APP_AUTH_RES_URL_DEV : REACT_APP_AUTH_RES_URL_PROD,
                merID: REACT_APP_MER_ID,
                PayType: REACT_APP_PAY_TYPE,
                PeriodNum: REACT_APP_PERIOD_NUM,
                subMerchID: REACT_APP_SUB_MERCH_ID,
                enCodeType: REACT_APP_EN_CODE_TYPE,
                timeoutSecs: REACT_APP_TIMEOUT_SECS,
                lagSelect: lang === 'zh-TW' ? 0 : lang === 'zh-CN' ? 1 : lang === 'en' ? 2 : 0,
                reqToken: REACT_APP_REQ_TOKEN,
                threeDSAuthInd: REACT_APP_THREE_DS_AUTH_IND,

                lidm: orderInfo.lidm,
                purchAmt: orderInfo.purchAmt,
                LocalDate: orderInfo.localDate,
                LocalTime: orderInfo.localTime,
                timeoutDate: orderInfo.timeoutDate,
                timeoutTime: orderInfo.timeoutTime,
                mobileNbr: orderInfo.mobileNbr,
                walletVerifyCode: orderInfo.walletVerifyCode,
            };

            // 构建查询参数
            const queryParams = new URLSearchParams(keysToFrom).toString();

            // 重定向到目标页面
            window.location.href = `https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/?${queryParams}`;
        });
    };

    return (
        <>
            <div className='mt-2 mt-sm-4'>
                <Wrapper>
                    <OrderItemAmountText className='d-flex justify-content-between p-4 border-bottom'>
                        <PaymentStatusText>{orderStatus[order.status]}</PaymentStatusText>
                        <OrderItemAmountText>{`${t('order_amount')} : NT$${order.grossAmount}`}</OrderItemAmountText>
                    </OrderItemAmountText>
                    <div className='p-3 px-0 p-xl-4 p-lg-4 p-md-4 pt-5 d-flex justify-content-between flex-lg-row flex-md-row flex-column'>
                        <OrderItemsWrapper className='col-lg-8 col-md-8 px-3'>
                            {order.salesOrderItemList?.map((el) => (
                                <Item key={el.itemGuid}>
                                    <OrderItemImage
                                        className='order_item_image object-fit-cover'
                                        src={el.coverURL}
                                        alt={el.name}
                                    />
                                    <div className='d-flex flex-xl-column flex-lg-column flex-md-column flex-row ps-3 justify-content-between w-100'>
                                        <OrderItemName className=''>{el.name_01}</OrderItemName>
                                        <div>{`x${el.quantitySales}`}</div>
                                    </div>
                                </Item>
                            ))}
                        </OrderItemsWrapper>
                        <div className='d-flex align-items-end col-lg-4 col-md-4 flex-lg-column flex-md-column flex-row-reverse justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-between mt-xl-0 mt-lg-0 mt-md-0 mt-4 px-3'>
                            {/* 前往付款 */}
                            {order?.status === '0' && (
                                <>
                                    {(order?.paymentMethod === '0' || order?.paymentMethod === '2') && (
                                        <OrderButton
                                            className='btn btn-primary text-white mb-lg-2 mb-md-2 mb-0 ms-2'
                                            onClick={() => handleCreditCardPayment(order.orderGuid)}
                                        >
                                            {t('go_to_payment')}
                                        </OrderButton>
                                    )}
                                    <OrderButton
                                        className='btn btn-outline-primary'
                                        onClick={() => navigate(`/orderDetails/${order.orderGuid}`)}
                                    >
                                        {t('view')}
                                    </OrderButton>
                                </>
                            )}

                            {/* 再次購買 */}
                            {(order?.status === '4' || order?.status === '9') && (
                                <>
                                    <OrderButton
                                        className='btn btn-primary text-white mb-lg-2 mb-md-2 mb-0 ms-2'
                                        // 1. call api, 2. navigate("cart")
                                        onClick={() => handleRebuyOrder(order?.orderGuid)}
                                    >
                                        {t('buy_again')}
                                    </OrderButton>
                                    <OrderButton
                                        className='btn btn-outline-primary'
                                        onClick={() => navigate(`/orderDetails/${order.orderGuid}`)}
                                    >
                                        {t('view')}
                                    </OrderButton>
                                </>
                            )}

                            {/* 查閱 */}
                            {order?.status !== '0' && order?.status !== '4' && order?.status !== '9' && (
                                <OrderButton
                                    className='btn btn-primary text-white'
                                    onClick={() => navigate(`/orderDetails/${order.orderGuid}`)}
                                >
                                    {t('view')}
                                </OrderButton>
                            )}
                        </div>
                    </div>
                </Wrapper>
            </div>
        </>
    );
}
