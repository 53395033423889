import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './activityUpdate.scss';

const ActCallingSystem = () => {
    // const navigate = useNavigate();
    // const location = useLocation();
    const { scheduleGuid } = useParams('scheduleGuid');

    const [data, setData] = useState(null);
    const [lineupStart, setLineupStart] = useState(null);
    const [lineupEnd, setLineupEnd] = useState(null);
    const [interval, setInterval] = useState(null);
    const [waitingTime, setWaitingTime] = useState(null);
    const [waitingTimeError, setWaitingTimeError] = useState(waitingTime ? '' : '請填寫每人預計等待間(分鐘)！');
    const [isNextGroupEnabled, setIsNextGroupEnabled] = useState(true);
    const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);

    async function start() {
        try {
            await connection.start().then(() => {
                console.log('connection.state', connection.state);

                if (connection.state === signalR.HubConnectionState.Connected) {
                    connection
                        .invoke('SubscribeToActivity', scheduleGuid)
                        .then((response) => console.log(response))
                        .catch((error) => console.error('updateScore error', error));

                    setIsNextGroupEnabled(true);
                    setIsUpdateEnabled(true);
                }
            });
        } catch (err) {
            console.log(err);
            setTimeout(() => start(), 5000);
        }
    }

    const connection = new signalR.HubConnectionBuilder()
        .withUrl('https://localhost:44375/pingtexHub')
        // .withUrl('/pingtexHub')
        .withAutomaticReconnect([0, 0, 0, 0])
        .build();

    const [queueStatus, setQueueStatus] = useState({});

    useEffect(() => {
        axios.post(`web/ActRegistration/getQueueStatus`, { scheduleGuid }).then((res) => {
            console.log('getQueueStatus', res.data);
            setQueueStatus(res.data);
        });

        // 排行榜
        connection.on('ReloadQueue', () => {
            axios.post(`web/ActRegistration/getQueueStatus`, { scheduleGuid }).then((res) => {
                console.log('getQueueStatus', res.data);
                setQueueStatus(res.data);
            });
        });

        connection.onreconnecting(() => {
            console.assert(connection.state === signalR.HubConnectionState.Reconnecting);

            setIsUpdateEnabled(false);
            setIsNextGroupEnabled(false);
        });

        connection.onreconnected((connectionId) => {
            console.assert(connection.state === signalR.HubConnectionState.Connected);

            setTimeout(start, 5000);
        });

        connection.onclose(async () => {
            setTimeout(start, 5000);
        });

        console.log('connection.state', connection.state);

        // 開始連線
        connection
            .start()
            .then(() => {
                console.log('connection.state', connection.state);

                if (connection.state === signalR.HubConnectionState.Connected) {
                    connection
                        .invoke('SubscribeToActivity', scheduleGuid)
                        .then((response) => console.log(response))
                        .catch((error) => console.error('updateScore error', error));
                } else {
                    console.error('Connection is not in the Connected state.');
                }
            })
            .catch((error) => console.error('Connection start error', error));

        // Cleanup function
        return () => {
            connection.stop();
        };
    }, []);

    useEffect(() => {
        if (waitingTime) {
            setWaitingTimeError('');
        }
    }, [waitingTime]);

    useEffect(() => {
        axios
            .get(`web/ActRegistration/getScheduleDetails/${scheduleGuid}`)
            .then((res) => {
                setData(res.data.schedule);
                setLineupStart(res.data.schedule.lineupStart);
                setLineupEnd(res.data.schedule.lineupEnd);
                setInterval(res.data.schedule.interval);
                setWaitingTime(res.data.schedule.waitingTime);
            })
            .catch((err) => console.log(err));
    }, [scheduleGuid]);

    useEffect(() => {
        const newLineupEnd = lineupStart + interval - 1;
        setLineupEnd(newLineupEnd);
    }, [lineupStart, interval]);

    //   useEffect(() => {
    //     setIsNextGroupEnabled(!!waitingTime);
    //   }, [lineupStart, interval, waitingTime]);

    const handleUpdate = async (dt) => {
        const backEndData = {
            ...dt,

            scheduleGuid,
            lineupStart,
            lineupEnd,
            interval,
            waitingTime,
        };

        axios
            .put(`web/ActRegistration/updateSchedule?guid=${scheduleGuid}`, backEndData)
            .then((res) => {
                console.log('trying to put new data');
                if (res.data.status === '600') {
                    axios
                        .get(`web/ActRegistration/getScheduleDetails/${scheduleGuid}`)
                        .then((res) => {
                            console.log('trying to get data');
                            const newData = res.data.schedule;
                            setData(newData);
                            const reputData = {
                                ...newData,
                                lineupStart,
                                lineupEnd,
                                interval,
                                waitingTime,
                            };
                            handleUpdate(reputData);
                        })
                        .catch((err) => console.log(err));
                }

                setIsUpdateEnabled(false);
                setIsNextGroupEnabled(true);
            })

            .catch((err) => console.log(err));
    };

    const handleNextGroup = async (dt) => {
        const newLineupStart = lineupEnd + 1;
        const newLineupEnd = newLineupStart + interval - 1;
        setLineupStart(newLineupStart);
        setLineupEnd(newLineupEnd);

        const backEndData = {
            ...dt,
            scheduleGuid,
            lineupStart: newLineupStart,
            lineupEnd: newLineupEnd,
            interval,
            waitingTime,
        };

        axios
            .put(`web/ActRegistration/updateSchedule?guid=${scheduleGuid}`, backEndData)
            .then((res) => {
                console.log('trying to put new data');
                if (res.data.status === '600') {
                    axios
                        .get(`web/ActRegistration/getScheduleDetails/${scheduleGuid}`)
                        .then((res) => {
                            console.log('trying to get data');
                            const newData = res.data.schedule;
                            setData(newData);

                            const reputData = {
                                ...newData,
                                lineupStart: newLineupStart,
                                lineupEnd: newLineupEnd,
                                interval,
                                waitingTime,
                            };

                            // handleNextGroup
                            handleNextGroup(reputData);
                            //   axios
                            //     .put(
                            //       `web/ActRegistration/updateSchedule?guid=${scheduleGuid}`,
                            //       reputData
                            //     )
                            //     .then((res) => console.log(res.data));
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));
    };

    const handleStartNumberChange = (e) => {
        const inputStartNumber = parseInt(e.target.value);
        if (!isNaN(inputStartNumber)) {
            setLineupStart(inputStartNumber);
            setIsUpdateEnabled(true);
            setIsNextGroupEnabled(false);
        }
    };

    const handleIncrementChange = (newIncrement) => {
        setInterval(newIncrement);
        setIsUpdateEnabled(true);
        setIsNextGroupEnabled(false);
    };

    const handleWaitingTimeChange = (e) => {
        const value = e.target.value;
        setWaitingTime(value);
        setWaitingTimeError('');
        setIsNextGroupEnabled(!!value);

        if (!value) {
            setWaitingTimeError('請填寫每人預計等待間(分鐘)！');
        }
    };

    return (
        data && (
            <div className='container-fluid p-0'>
                <article
                    id='container-login'
                    className='py-lg-3 d-flex flex-column align-items-center justify-content-lg-center bg-background'
                >
                    <div className='articleBox d-flex flex-column justify-content-start'>
                        <h2 className='pageTitle_main text-center'>{'國際扶輪DEI嘉年華會'}</h2>
                        <h6 className='text-center'>{'乒乓鼓動人心'}叫號系統</h6>
                        <h6 className='text-center'>2023/12/16</h6>
                        <h6 className='text-center'>目前隊伍：{queueStatus?.teamRange}</h6>
                        <h6 className='text-center'>總等待人數：{queueStatus?.waitCount}</h6>
                        <h6 className='text-center'>區間等待人數：{queueStatus?.intervalWaitCount}</h6>
                        <h6 className='text-center mb-4'>
                            目前報名人數：
                            {queueStatus?.currentRegistrationCount}
                        </h6>
                        <section id='article-login'>
                            <form className='row g-3' autoComplete='off'>
                                <div className={`col-6 "d-none"}`}>
                                    <label className='form-label' htmlFor='lineupStart'>
                                        起始號碼
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg'
                                        id='lineupStart'
                                        name='lineupStart'
                                        value={lineupStart}
                                        onChange={handleStartNumberChange}
                                        required
                                    />
                                    <div className='invalid-feedback' id='lineupStart-info'></div>
                                </div>

                                <div className={`col-6  "d-none"}`}>
                                    <label className='form-label' htmlFor='lineupEnd'>
                                        結束號碼
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg'
                                        id='lineupEnd'
                                        name='lineupEnd'
                                        value={lineupEnd}
                                        readOnly
                                    />
                                    <div className='invalid-feedback' id='lineupEnd-info'></div>
                                </div>

                                <div className='col-12'>
                                    <label className='form-label' htmlFor='interval'>
                                        每組累進人數
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg'
                                        id='interval'
                                        name='interval'
                                        value={interval}
                                        onChange={(e) => handleIncrementChange(parseInt(e.target.value))}
                                        required
                                    />
                                </div>

                                <div className='submit_btn col-12 text-center mt-3 mb-0 mb-lg-3'>
                                    <button
                                        type='button'
                                        className={`w-50 btn btn-lg btn-color-main rounded-pill`}
                                        id='btn-next-group'
                                        onClick={() => handleUpdate(data)}
                                        disabled={!isUpdateEnabled}
                                    >
                                        更新
                                    </button>
                                </div>

                                <div className='col-12'>
                                    <label className='form-label' htmlFor='witingTime'>
                                        每人預計等待間(分鐘)
                                    </label>
                                    <input
                                        type='number'
                                        className={`form-control form-control-lg ${
                                            waitingTimeError ? 'is-invalid' : ''
                                        }`}
                                        id='witingTime'
                                        name='witingTime'
                                        placeholder=''
                                        value={waitingTime}
                                        onChange={handleWaitingTimeChange}
                                        required
                                    />
                                    {waitingTimeError && <div className='invalid-feedback'>{waitingTimeError}</div>}
                                </div>

                                <div className='submit_btn col-12 text-center mt-3 mb-0 mb-lg-3'>
                                    <button
                                        type='button'
                                        className={`w-50 btn btn-lg btn-color-main rounded-pill`}
                                        id='btn-next-group'
                                        onClick={() => handleNextGroup(data)}
                                        disabled={!isNextGroupEnabled}
                                    >
                                        下一組
                                    </button>
                                </div>
                            </form>
                        </section>
                    </div>
                </article>
            </div>
        )
    );
};

export default ActCallingSystem;
