import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useParams, useNavigate } from 'react-router-dom';

// #region styled-components
const Item = styled.div`
    display: flex;
    justify-content: start;
    height: 100px;
    margin-bottom: 24px;
`;

const OrderItemName = styled.div`
    width: 80%;

    @media (max-width: 568px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2; /* 控制顯示的最大行數 */
        max-height: 3em; /* 控制最大高度，這裡假設每行高度為1.5em */
        width: 75%;
    }
`;

const Title = styled.div``;

const OrderItemAmountText = styled.div`
    font-size: 18px;
`;

const OrderItemImage = styled.img`
    object-fit: cover;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    @media only screen and (max-width: 576px) {
        width: 72px;
        height: 72px;
    }

    @media only screen and (min-width: 576px) {
        width: 100px;
        height: 100px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 2rem;
    justify-content: end;
    align-items: center;

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        flex-direction: column-reverse;
    }
`;

const OrderButton = styled.button`
    width: 146px;

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        width: 100%;
        border-radius: 28px;
    }
`;

const CancelText = styled.div`
    margin-left: 8px;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;

    @media only screen and (min-width: 576px) and (max-width: 1020px) {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        text-align: center;
    }
`;
// #endregion
export default function OrderGoodsDetails({ order, t }) {
    const navigate = useNavigate();

    return (
        <>
            <div className='mt-4'>
                <section className='border-sm-0 border'>
                    <OrderItemAmountText className='d-flex justify-content-between p-4 border-bottom'>
                        <Title>{t('product_information')}</Title>
                        <OrderItemAmountText>{/* {`訂單金額 : NT$${order?.grossAmount}`} */}</OrderItemAmountText>
                    </OrderItemAmountText>
                    <div className='p-3 p-xl-4 p-lg-4 p-md-4 pt-5 d-flex justify-content-between flex-lg-row flex-md-row flex-column'>
                        <div className='col-lg-8 col-md-8 px-3 w-100'>
                            {order?.salesOrderItemList.map((el) => (
                                <div className='d-flex'>
                                    <Item key={el.itemGuid}>
                                        <OrderItemImage
                                            className='order_item_image object-fit-cover'
                                            src={el.coverURL}
                                            alt={el.name}
                                        />
                                        <div className='d-flex flex-xl-column flex-lg-column flex-md-column flex-row ps-3 justify-content-between w-100'>
                                            <OrderItemName className=''>{el.name_01}</OrderItemName>
                                            <div>{`x${el.quantitySales}`}</div>
                                        </div>
                                    </Item>

                                    <div className='ms-auto ps-5'>{`NT$${el.grossAmount}`}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='d-md-flex p-3'>
                        <div className='col'></div>
                        <div className='col'>
                            <div className='d-flex'>
                                <div className='col'>{t('subtotal')}</div>
                                <div className='col text-end'>{`NT$${order?.grossAmount - order?.deliveryFee}`}</div>
                            </div>
                            <div className='d-flex'>
                                <div className='col'>{t('shipping_fee')}</div>
                                {/* <div className="col text-end text-decoration-line-through"> */}
                                <div className='col text-end'>{`NT$${order?.deliveryFee}`}</div>
                            </div>
                            <div className='d-flex'>
                                <div className='col'>{t('discount_subtotal')}</div>
                                <div className='col text-end'>0</div>
                            </div>
                            <div className='d-flex'>
                                <div className='col fw-bold'>{t('total_order_amount')}</div>
                                <div className='col text-end fw-bold'>{`NT$${order?.grossAmount}`}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
