import React, { useCallback, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { axiosApi } from '../../../api.js';
import NotFoundPage from '../../../components/notFoundPage/NotFoundPage';
import './fileUpdateLayout.scss';

const FileUpdateLayout = ({ children, guid }) => {
    //後台登入帳號控制
    // console.log(guid);
    const [isSwiftAdmin, setSwiftAdmin] = useState(false);
    useEffect(() => {
        guid === '90277a3e-8b1f-ed11-a178-00155d070709' && setSwiftAdmin(true);
    }, [guid]);

    //sideBar active控制
    const [simplePage, setSimplePage] = useState('');
    const [appGuid, setAppGuid] = useState('');
    const [osCode, setOsCode] = useState('');
    const [otaGuid, setOtaGuid] = useState('');
    const [otaType, setOtaType] = useState('');
    const [scheduleGuid, setScheduleGuid] = useState('');

    const [appList, setAppList] = useState([]);
    const [oSList, setOSList] = useState([]);
    const [otaList, setOtaList] = useState([]);
    const [actRegistrationList, setActRegistrationList] = useState([]);

    const [cookies] = useCookies(['page']);

    useEffect(() => {
        let simplePageList = [
            // 系統設定 - app基本設定,ota基本設定,權限設定
            'appMainUpdate',
            'OTAMainUpdate',
            'authUpdate',

            // 用户管理
            'usersUpdate',

            // 網站管理
            'exploreupdate',
            'newsupdate',
            'physicalstoreupdate',
            'downloadcenterupdate',
            'questionupdate',
            'questioncategoryupdate',

            // 球譜管理
            'userDrill',
            'userDrillClass',
            'presetDrill',
            'drillCategoryUpdate',

            // 發球機生管
            'productionRecord',
            'factoryRecord',

            // 活動管理
            'actactivity',
            'actRegistration',

            // 商品管理
            'salSalesKitClass',
            'salSalesKitAppendix',
            'salSalesKit',

            // 訂單管理
            'orderIssueInvoice',
        ];

        simplePageList.forEach((page) => {
            if (window.location.pathname.includes(page)) {
                setSimplePage(page);
            }
        });

        // APP管理
        if (window.location.pathname === '/appupdate') {
            setAppGuid(window.location.search.split('?')[1].split('&')[0].split('=')[1]);
            setOsCode(window.location.search.split('?')[1].split('&')[1].split('=')[1]);
        }

        // OTA管理
        if (window.location.pathname === '/otafirmwareupdate') {
            setOtaGuid(window.location.search.split('?')[1].split('&')[0].split('=')[1]);
            setOtaType(window.location.search.split('?')[1].split('&')[1].split('=')[1]);
        }

        // OTA管理
        if (window.location.pathname === '/otaaudioupdate') {
            setOtaGuid(window.location.search.split('?')[1].split('&')[0].split('=')[1]);
            setOtaType(window.location.search.split('?')[1].split('&')[1].split('=')[1]);
        }
    }, [window.location.search, window.location.pathname]);

    // TODO: remove old axios function after confirmed
    // -----app管理-----
    // appList
    const getAppList = useCallback(() => {
        try {
            axiosApi('web/sys/app').then((res) => {
                // console.log(res);
                const reverseList = res.data.result.reverse(function (a, b) {
                    return a.updateTIme > b.updateTIme;
                });

                setAppList(reverseList);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // const getAppList = useCallback(() => {
    // 	const gettingAppList = async () => {
    // 		await axios
    // 			.get('web/sys/app')
    // 			.then((res) => {
    // 				// 建立時間倒敘
    // 				res.data.result.reverse(function (a, b) {
    // 					return a.updateTIme > b.updateTIme;
    // 				});
    // 				// console.log(res.data.result);
    // 				setAppList(res.data.result);
    // 			})
    // 			.catch((err) => console.log(err));
    // 	};

    // 	gettingAppList();
    // }, []);

    // TODO: remove old axios function after confirmed
    // -----os管理-----
    // osList
    const getOSList = useCallback(() => {
        try {
            axiosApi('web/sys/oSofApp').then((res) => {
                // console.log(res);
                setOSList(res.data.result);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // const getOSList = useCallback(() => {
    //     const gettingOSList = async () => {
    //         await axios
    //             .get('web/sys/oSofApp')
    //             .then((res) => {
    //                 setOSList(res.data.result);
    //             })
    //             .catch((err) => console.log(err));
    //     };

    //     gettingOSList();
    // }, []);

    // TODO: remove old axios function after confirmed
    // -----ota管理-----
    // otaList
    const getOtaList = useCallback(() => {
        try {
            axiosApi('web/sys/ota').then((res) => {
                // console.log(res);
                // 建立時間倒敘
                res.data.result.reverse(function (a, b) {
                    return a.updateTIme > b.updateTIme;
                });
                setOtaList(res.data.result);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // const getOtaList = useCallback(() => {
    //     const gettingOtaList = async () => {
    //         await axios
    //             .get('web/sys/ota')
    //             .then((res) => {
    //                 // 建立時間倒敘
    //                 res.data.result.reverse(function (a, b) {
    //                     return a.updateTIme > b.updateTIme;
    //                 });
    //                 // console.log(res.data.result);
    //                 setOtaList(res.data.result);
    //             })
    //             .catch((err) => console.log(err));
    //     };

    //     gettingOtaList();
    // }, []);

    // -----活動管理-----
    // appList
    const getActRegistrationList = useCallback(() => {
        try {
            //'web/ActRegistration/getActivityDetails/706c3c58-ed8c-ee11-a18f-00155d070505'
            axiosApi('web/ActRegistration/getScheduleSidebar').then((res) => {
                // console.log(res.data);

                setActRegistrationList([...res.data.result]);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getAppList();
        getOSList();
        getOtaList();
        getActRegistrationList();
    }, [getAppList, getOSList, getOtaList, getActRegistrationList]);

    return (
        //是否為後台管理員
        true ? (
            <div className={`container-fluid bg-background p-0`}>
                <div className='container-custom'>
                    <div className='row py-5'>
                        <div className='col-3 bg-background'>
                            <section className='w-100' id='section-sidebar'>
                                <Accordion
                                // defaultActiveKey="0"
                                >
                                    {cookies.page?.includes('authSystem') && (
                                        <Accordion.Item eventKey='0'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                系统设定
                                            </Accordion.Header>
                                            <Link to='/appMainUpdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'appMainUpdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    APP基本设定
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/OTAMainUpdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'OTAMainUpdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    OTA基本设定
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/authUpdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'authUpdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    权限设定
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}
                                    {cookies.page?.includes('authUser') && (
                                        <Accordion.Item eventKey='1'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                用户管理
                                            </Accordion.Header>
                                            <Link to='/usersUpdate'>
                                                <Accordion.Body
                                                    className={`ps-5 ${simplePage === 'usersUpdate' && 'active'}`}
                                                >
                                                    用户管理
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}

                                    {/* APP管理 */}
                                    {cookies.page?.includes('authApp') && (
                                        <Accordion.Item eventKey='2'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                APP管理
                                            </Accordion.Header>

                                            {appList.map((el) => (
                                                <Accordion.Body
                                                    key={el.appGuid}
                                                    className={`manageView pingtexApp p-0 m-0`}
                                                >
                                                    <div
                                                        className='header'
                                                        onClick={(e) => {
                                                            e.target.nextSibling.classList.toggle('d-none');
                                                        }}
                                                    >
                                                        <span className='me-2'>-</span>
                                                        {el.name}
                                                    </div>

                                                    <div className={appGuid === el.appGuid ? 'd-block' : 'd-none'}>
                                                        {oSList.map((item) => (
                                                            <Link
                                                                key={item.name}
                                                                to={`/appupdate?appguid=${el.appGuid}&oscode=${item.osCode}&appname=${el.name}`}
                                                            >
                                                                <Accordion.Body
                                                                    className={`ps-5 ${
                                                                        appGuid === el.appGuid &&
                                                                        osCode === item.osCode &&
                                                                        'active'
                                                                    }`}
                                                                >
                                                                    {item.name}
                                                                </Accordion.Body>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            ))}
                                        </Accordion.Item>
                                    )}
                                    {/* OTA管理 */}
                                    {cookies.page?.includes('authOta') && (
                                        <Accordion.Item eventKey='3'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                韌體OTA管理
                                            </Accordion.Header>

                                            {otaList.map((el) => (
                                                <Accordion.Body
                                                    key={el.otaGuid}
                                                    className={`manageView OTAManage p-0 m-0`}
                                                >
                                                    <div
                                                        className='header'
                                                        onClick={(e) => {
                                                            e.target.nextSibling.classList.toggle('d-none');
                                                        }}
                                                    >
                                                        <span className='me-2'>-</span>
                                                        {el.name}
                                                    </div>

                                                    <div className={otaGuid === el.otaGuid ? 'd-block' : 'd-none'}>
                                                        <Link
                                                            to={`/otafirmwareupdate?otaguid=${el.otaGuid}&otaType=firmware&machineName=${el.name}`}
                                                        >
                                                            <Accordion.Body
                                                                className={`ps-5 ${
                                                                    otaGuid === el.otaGuid &&
                                                                    otaType === 'firmware' &&
                                                                    'active'
                                                                }`}
                                                            >
                                                                firmware
                                                            </Accordion.Body>
                                                        </Link>
                                                        <Link
                                                            to={`/otaaudioupdate?otaguid=${el.otaGuid}&otaType=audio&machineName=${el.name}`}
                                                        >
                                                            <Accordion.Body
                                                                className={`ps-5 ${
                                                                    otaGuid === el.otaGuid &&
                                                                    otaType === 'audio' &&
                                                                    'active'
                                                                }`}
                                                            >
                                                                audio
                                                            </Accordion.Body>
                                                        </Link>
                                                    </div>
                                                </Accordion.Body>
                                            ))}
                                        </Accordion.Item>
                                    )}
                                    {/* 球譜管理 */}
                                    {cookies.page?.includes('authPresetDrill') && (
                                        <Accordion.Item eventKey='4'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                球譜管理
                                            </Accordion.Header>
                                            <Link to='/userDrill'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'userDrill' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    用戶球譜
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/userDrillClass'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'userDrillClass' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    用戶球譜型態
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/presetDrill'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'presetDrill' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    宾乐达球谱
                                                </Accordion.Body>
                                            </Link>
                                            {/* <Link to="/drillCategoryUpdate">
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage ===
                                                            'drillCategoryUpdate' &&
                                                        'active'
                                                    }`}
                                                >
                                                    <span className="me-2">
                                                        -
                                                    </span>
                                                    宾乐达球谱型态
                                                </Accordion.Body>
                                            </Link> */}
                                        </Accordion.Item>
                                    )}
                                    {/* 網站管理 */}
                                    {cookies.page?.includes('authWeb') && (
                                        <Accordion.Item eventKey='5'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                網站管理
                                            </Accordion.Header>
                                            <Link to='/exploreupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'exploreupdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    網上展示廳
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/newsupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'newsupdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    新聞動態
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/physicalstoreupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'physicalstoreupdate' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    銷售網點
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/downloadcenterupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'downloadcenterupdate' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    產品說明書
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/questionupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'questionupdate' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    常見問題
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/questioncategoryupdate'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'questioncategoryupdate' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    常見問題型態
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}
                                    {/* 发球机生管 */}
                                    {/* TODO: */}
                                    {cookies.page?.includes('authWeb') && (
                                        <Accordion.Item eventKey='6'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                发球机生管
                                            </Accordion.Header>
                                            <Link to='/productionRecord'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'productionRecord' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    生产记录
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/factoryRecord'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'factoryRecord' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    出厂记录
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}

                                    {/* 活動管理 */}
                                    {cookies.page?.includes('authActivity') && (
                                        <Accordion.Item eventKey='7'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                活動管理
                                            </Accordion.Header>

                                            <Link to='/admin/actactivity'>
                                                <Accordion.Body
                                                    className={`${simplePage === 'actactivity' && 'active'}`}
                                                >
                                                    活動主檔
                                                </Accordion.Body>
                                            </Link>

                                            {actRegistrationList.map((el) => (
                                                <Accordion.Body
                                                    key={el.scheduleGuid}
                                                    className={`manageView pingtexApp p-0 m-0`}
                                                >
                                                    <div
                                                        className='header d-flex'
                                                        style={{
                                                            color: 'var(--grey4)',
                                                        }}
                                                        onClick={(e) => {
                                                            e.target.nextSibling.classList.toggle('d-none');
                                                        }}
                                                    >
                                                        <span className='me-2'>-</span>
                                                        {el.name.split(' ')[0]}
                                                        <br />
                                                        {el.name.split(' ')[1]}
                                                    </div>

                                                    <div
                                                        className={
                                                            scheduleGuid === el.scheduleGuid ? 'd-block' : 'd-none'
                                                        }
                                                    >
                                                        <Link
                                                            to={`/admin/actscheduleregistration/${el.scheduleGuid}`}
                                                            state={{
                                                                activityGuid: el.activityGuid,
                                                            }}
                                                        >
                                                            <Accordion.Body
                                                                className={`ps-5 ${
                                                                    scheduleGuid === el.scheduleGuid && 'active'
                                                                }`}
                                                            >
                                                                活動報名
                                                            </Accordion.Body>
                                                        </Link>
                                                        <Link to={`/admin/activity/callingSystem/${el.scheduleGuid}`}>
                                                            <Accordion.Body
                                                                className={`ps-5 ${
                                                                    scheduleGuid === el.scheduleGuid && 'active'
                                                                }`}
                                                            >
                                                                活動叫號
                                                            </Accordion.Body>
                                                        </Link>
                                                        {/* {oSList.map((item) => (
															<Link
																key={item.name}
																to={`/appupdate?appguid=${el.appGuid}&oscode=${item.osCode}&appname=${el.name}`}
															>
																<Accordion.Body
																	className={`ps-5 ${
																		appGuid ===
																			el.appGuid &&
																		osCode ===
																			item.osCode &&
																		'active'
																	}`}
																>
																	{item.name}
																</Accordion.Body>
															</Link>
														))} */}
                                                    </div>
                                                </Accordion.Body>
                                            ))}
                                        </Accordion.Item>
                                    )}

                                    {/* 商品管理 */}
                                    {cookies.page?.includes('authWeb') && (
                                        <Accordion.Item eventKey='8'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                商品管理
                                            </Accordion.Header>
                                            <Link to='/salSalesKitClass'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'salSalesKitClass' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    商品分類
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/salSalesKitAppendix'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'salSalesKitAppendix' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    商品圖庫
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/salSalesKit/create'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'salSalesKit_create' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    商品新增（從erp選商品）
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/salSalesKit'>
                                                <Accordion.Body
                                                    className={`ms-0 ${
                                                        simplePage === 'salSalesKit_update' && 'active'
                                                    }`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    商品修改
                                                </Accordion.Body>
                                            </Link>
                                            <Link to='/salSalesOrder'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'salSalesOrder' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    銷售訂單
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}

                                    {/* 訂單管理 */}
                                    {cookies.page?.includes('authWeb') && (
                                        <Accordion.Item eventKey='9'>
                                            <Accordion.Header>
                                                <span className='circleIcon me-2'></span>
                                                訂單管理
                                            </Accordion.Header>
                                            <Link to='/orderIssueInvoice'>
                                                <Accordion.Body
                                                    className={`ms-0 ${simplePage === 'orderIssueInvoice' && 'active'}`}
                                                >
                                                    <span className='me-2'>-</span>
                                                    訂單開發票
                                                </Accordion.Body>
                                            </Link>
                                        </Accordion.Item>
                                    )}
                                </Accordion>
                            </section>
                        </div>
                        <div className='col-9 bg-background'>{children}</div>
                    </div>
                </div>
            </div>
        ) : (
            <NotFoundPage />
        )
    );
};

export default FileUpdateLayout;
