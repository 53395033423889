import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import './actActivity.scss';

export default function SalSalesKitClass() {
    const [isGetList, setIsGetList] = useState(false);
    const [salesKitClassList, setSalesKitClassList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // Sorting states
    const [sortBy, setSortBy] = useState('statusTime');
    const [orderBy, setOrderBy] = useState('desc');

    const location = useLocation();
    const navigate = useNavigate();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    //過濾搜尋
    const resetFilterSalesKitClassList = () => {
        setSearchText('');
        fetchData();
    };

    const getSalesKitClassList = useCallback((query) => {
        const gettingSalesKitClassList = async (requestBody) => {
            await axios
                .post(`web/SalSalesKitClass/getSalesKitClassList`, requestBody)
                .then((res) => {
                    // console.log(res.data.data);
                    setSalesKitClassList(res.data.data);
                    setIsGetList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingSalesKitClassList(query);
    }, []);

    const filterSalesKitClassList = () => {
        if (searchText) {
            const advancedSearchDataList = [
                {
                    searchLogic: '',
                    SearchFilter: 'name_01',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_02',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
                {
                    searchLogic: 'Or',
                    SearchFilter: 'name_03',
                    SearchOperator: 'contains',
                    searchValue: searchText,
                },
            ];

            fetchData(advancedSearchDataList, 100000, sortBy, 1);
        }
    };

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The list of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/SalSalesKitClass/getSalesKitClassList`;
        console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sort} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        console.log(reqBody);

        axios.post(url, reqBody).then((res) => {
            // console.log(res.data);

            setSalesKitClassList(res.data.data);
            setIsGetList(true);
        });
    };

    useEffect(() => {
        // axios
        //     .post(`web/SalSalesKitClass/getSalesKitClassList`)
        //     .then((res) => {
        //         console.log(res.data.salesKitClassList);
        //         setSalesKitClassList(res.data.salesKitClassList);
        //         setIsGetList(true);
        //     })
        //     .catch((err) => console.log(err));
        fetchData();
    }, []);

    // console.log(salesKitClassList);
    return (
        <section id='section-main'>
            <div id='container-salSalesKitClass'>
                <h5 className='fw-bold mb-4'>商品分類</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        {/* <select
                            className="form-control me-2"
                            style={{ width: '160px' }}
                            name="searchColumn"
                            id="searchColumn"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            value={searchFilter}
                            required
                        >
                            <option value="all">全部</option>
                            <option value="fullname" id="fullnameSearch">
                                显示名称
                            </option>
                            <option value="userID" id="userIDSearch">
                                用户名(ID)
                            </option>
                            <option value="phone1" id="phone1Search">
                                手机号码
                            </option>
                            <option value="email1" id="email1Search">
                                邮箱
                            </option>
                        </select> 
                    */}
                        <input
                            className='searchInput form-control me-2'
                            placeholder='搜尋名稱'
                            value={searchText}
                            style={{ width: '400px' }}
                            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                            onKeyDown={(e) => e.key === 'Enter' && filterSalesKitClassList()}
                        />
                        <button
                            className='btn btn-outline-primary btn-size-s me-2'
                            onClick={resetFilterSalesKitClassList}
                        >
                            重置
                        </button>
                        <button className='btn btn-primary btn-size-s text-white' onClick={filterSalesKitClassList}>
                            查詢
                        </button>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>分類查詢</h6>
                        <button
                            className='btn btn-primary text-white ms-auto me-2'
                            onClick={() => {
                                navigate('./create');
                            }}
                        >
                            <svg
                                id='icn_Create'
                                className='me-2 mb-1'
                                viewBox='0 0 12 12'
                                width='12'
                                height='12'
                                fill='#fff'
                            >
                                <defs> </defs>
                                <path
                                    className='cls-1'
                                    id='_Color'
                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                    data-name=' ↳Color'
                                ></path>
                            </svg>
                            <span>新增分類</span>
                        </button>
                        <span className='reloadUserDrillList' onClick={getSalesKitClassList}>
                            <svg width='32' height='32' viewBox='0 0 32 32'>
                                <defs>
                                    <clipPath id='clip-path-reload'>
                                        <rect
                                            id='Rectangle_3588'
                                            data-name='Rectangle 3588'
                                            width='14.149'
                                            height='16'
                                            fill='none'
                                        />
                                    </clipPath>
                                </defs>
                                <g id='Group_8495' data-name='Group 8495' transform='translate(-1232 -296)'>
                                    <rect
                                        id='Rectangle_3587'
                                        data-name='Rectangle 3587'
                                        width='32'
                                        height='32'
                                        transform='translate(1232 296)'
                                        fill='none'
                                    />
                                    <g id='Group_8494' data-name='Group 8494' transform='translate(1241 304)'>
                                        <g id='Group_8493' data-name='Group 8493' clipPath='url(#clip-path-reload)'>
                                            <path
                                                id='Path_1555'
                                                data-name='Path 1555'
                                                d='M0,0H2.126V2.716a7.434,7.434,0,1,1-1.1,10.632l1.719-1.259a5.311,5.311,0,1,0,.877-7.842H6.372V6.371H0Z'
                                                fill='#686868'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span>
                            <svg width='32' height='32' viewBox='0 0 32 32'>
                                <defs>
                                    <clipPath id='clip-path-gear'>
                                        <rect
                                            id='Rectangle_3562'
                                            data-name='Rectangle 3562'
                                            width='16'
                                            height='15.081'
                                            fill='#686868'
                                        />
                                    </clipPath>
                                </defs>
                                <g id='Group_8492' data-name='Group 8492' transform='translate(-1264 -296)'>
                                    <rect
                                        id='Rectangle_3586'
                                        data-name='Rectangle 3586'
                                        width='32'
                                        height='32'
                                        transform='translate(1264 296)'
                                        fill='none'
                                    />
                                    <g id='Group_8491' data-name='Group 8491' transform='translate(1272 304)'>
                                        <g
                                            id='Group_8450'
                                            data-name='Group 8450'
                                            transform='translate(0 0)'
                                            clipPath='url(#clip-path-gear)'
                                        >
                                            <path
                                                id='Path_1516'
                                                data-name='Path 1516'
                                                d='M2.622,12.655c.048-.139.144-.4.232-.66a4.859,4.859,0,0,0,.27-.867,1.063,1.063,0,0,0-.7-1.179,2.892,2.892,0,0,0-.871-.182A1.527,1.527,0,0,1,0,8.139C0,7.721,0,7.3,0,6.884a1.518,1.518,0,0,1,1.52-1.566,2.949,2.949,0,0,0,.788-.136A1.152,1.152,0,0,0,3.1,3.747a2.87,2.87,0,0,0-.255-.659A1.534,1.534,0,0,1,3.472.923C3.9.678,4.326.432,4.756.191A1.322,1.322,0,0,1,6.591.672a3.619,3.619,0,0,0,.582.747,1.152,1.152,0,0,0,1.665-.036A3.124,3.124,0,0,0,9.327.75,1.352,1.352,0,0,1,11.3.231c.417.239.835.475,1.25.717a1.525,1.525,0,0,1,.606,2.1,2.964,2.964,0,0,0-.289.84,1.112,1.112,0,0,0,.768,1.28,2.855,2.855,0,0,0,.8.149A1.541,1.541,0,0,1,16,6.91c.005.418,0,.837,0,1.255a1.522,1.522,0,0,1-1.547,1.6,3.014,3.014,0,0,0-.593.077,1.216,1.216,0,0,0-.929,1.645,3.244,3.244,0,0,0,.243.564,1.519,1.519,0,0,1-.588,2.075q-.662.388-1.332.762a1.346,1.346,0,0,1-1.879-.5,3.5,3.5,0,0,0-.561-.717,1.154,1.154,0,0,0-1.653.015,3.064,3.064,0,0,0-.5.644,1.353,1.353,0,0,1-1.968.528c-.423-.241-.846-.48-1.267-.724a1.591,1.591,0,0,1-.8-1.475m5.37-2.521A2.593,2.593,0,1,0,5.4,7.54a2.59,2.59,0,0,0,2.593,2.6'
                                                transform='translate(0 0)'
                                                fill='#686868'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey4 border-0'>
                                            順序<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            簡中名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            英文名稱<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            父層<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            狀態<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0'>
                                            狀態變更時間<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetList &&
                                        (salesKitClassList.length !== 0 ? (
                                            salesKitClassList.map((skClass, idx) => {
                                                // console.log(skClass);
                                                return (
                                                    <tr
                                                        id={skClass?.salesKitClassGuid}
                                                        key={skClass?.salesKitClassGuid}
                                                        onClick={() => navigate(`${skClass?.salesKitClassGuid}`)}
                                                    >
                                                        <td>{idx + 1}</td>
                                                        <td>{skClass?.name_01}</td>
                                                        <td>{skClass?.name_02}</td>
                                                        <td>{skClass?.name_03}</td>
                                                        <td>{skClass?.upperName}</td>
                                                        <td>{statusList[skClass?.status]}</td>
                                                        <td>
                                                            {skClass.statusTime
                                                                ? dayjs(skClass.statusTime).format(
                                                                      'YYYY-MM-DD HH:mm:ss'
                                                                  )
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='7'>无用戶纪录</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>请查询</h6>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
}
