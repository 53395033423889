import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import AdvancedSearchModal from '../../components/Modals/AdvancedSearchModal';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

// 總結：
// 這是一個 React 函數組件，管理與使用者註冊數據、排序和進階搜尋相關的狀態。
// 主要特點包括：
// 1. 狀態管理：
//    - 使用 useState 鉤子定義多個狀態變數，例如 isGetActUserList、registrationList、windowWidth、
//      updateActRegistrationModalOpen、registrationData、sortBy、orderBy 等。
// 2. 進階搜尋：
//    - 實現了一個帶有模態對話框（advancedSearchModalOpen）和相關狀態變數（advancedSearchDataList、
//      displayList）的進階搜尋功能。
//    - searchContentList 數組定義了不同的搜索標準，包括類型、列名和搜索選項。
//    - deleteAllFilters 和 deleteFilter 函數允許用戶管理和刪除進階搜索篩選器。
// 3. 排序：
//    - 使用 sortBy 和 orderBy 狀態變數實現排序功能。
//    - handleSortChange 函數處理排序偏好更改。
// 4. 事件監聽器和效應：
//    - handleResize 函數在窗口調整大小事件時更新 windowWidth 狀態。
//    - useEffect 鉤子用於添加和刪除窗口大小更改的事件監聽器。
// 5. API 交互：
//    - fetchData 函數負責從 API 端點（web/ActRegistration/getRegistrationList）獲取註冊數據。
//    - 使用 Axios 發送帶有排序和搜索參數的 POST 請求。
// 6. 初始化：
//    - useEffect 鉤子使用空依賴數組確保在組件掛載時以及 sortBy 或 orderBy 更改時調用 fetchData。

const ActScheduleRegistration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies();

    console.log(cookies);

    const { scheduleGuid } = useParams('scheduleGuid');

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [orderList, setOrderList] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [registrationData, setRegistrationData] = useState(null);
    const [activityData, setActivityData] = useState(null); // 活動資訊
    const [scheduleData, setScheduleData] = useState(null); // 活動資訊

    // Sorting states
    const [sortBy, setSortBy] = useState('updateTime');
    const [orderBy, setOrderBy] = useState('desc');

    const [password, setPassword] = useState('');
    const [isCorrectPassword, setIsCorrectPassword] = useState(true);
    const [isValid, setIsValid] = useState(false);

    const statusList = {
        0: '"0"：未報到',
        1: '"1"：已報到',
        2: '鎖定（停用）',
    };

    const realPassword = '55866301';
    const key = '02-88666008';

    //#region AdvancedSearch region
    const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    const searchContentList = [
        {
            column: 'fullname',
            name: '姓名',
            type: 'text',
            tableColumn: 'fullname', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'fullname',
            selectOptionList: ['contains', '!contains'],
        },
        {
            column: 'phone',
            name: '電話號碼',
            type: 'number',
            tableColumn: 'phone', // call fetchData 時 db 會去找的 column name
            searchFromDataBase: 'phone',
            selectOptionList: ['contains', '!contains'],
        },
        // {
        //     column: 'time_Registry',
        //     name: '報名時間',
        //     type: 'time',
        //     tableColumn: 'time_Registry',
        //     searchFromDataBase: 'time_Registry',
        //     selectOptionList: ['>', '<'],
        // },
        // {
        //     column: 'time_Checkin',
        //     name: '報到時間',
        //     type: 'time',
        //     tableColumn: 'time_Checkin',
        //     searchFromDataBase: 'time_Checkin',
        //     selectOptionList: ['>', '<'],
        // },
        // {
        //     column: 'time_Playing',
        //     name: '上場時間',
        //     type: 'time',
        //     tableColumn: 'time_Playing',
        //     searchFromDataBase: 'time_Playing',
        //     selectOptionList: ['>', '<'],
        // },
        // {
        //     column: 'queueNo',
        //     name: '報名號次',
        //     tableColumn: 'queueNo',
        //     searchFromDataBase: 'queueNo',
        //     type: 'number',
        //     selectOptionList: ['==', '!=', '>', '<'],
        // },
        // {
        //     column: 'score',
        //     name: '得分',
        //     tableColumn: 'score',
        //     searchFromDataBase: 'score',
        //     type: 'number',
        //     selectOptionList: ['==', '!=', '>', '<'],
        // },
        // {
        //     column: 'rank',
        //     name: '排名',
        //     tableColumn: 'rank',
        //     searchFromDataBase: 'rank',
        //     type: 'number',
        //     selectOptionList: ['==', '!=', '>', '<'],
        // },
    ];

    const paymentMethodList = {
        0: '信用卡付款',
        1: '付現金',
    };

    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: 'name',
        searchFilter: 'fullname',
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: ['contains', '!contains'],
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    /*
     * Deletes a filter from the advanced search data el and the display el.
     *
     * @param {number} targetIndex - The index of the filter to delete.
     * @return {undefined} This function does not return a value.
     */

    const deleteFilter = (targetIndex) => {
        const newAdvancedSearchDataList = advancedSearchDataList.filter((_, index) => index !== targetIndex);
        const newDisplayList = displayList.filter((_, index) => index !== targetIndex);
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null);
    };
    //#endregion

    // 使用 useState 來儲存視窗寬度的狀態

    // 處理視窗大小改變時的函數
    const handleResize = () => {
        // 當視窗大小變化時更新視窗寬度的狀態
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // 添加視窗大小改變事件監聽器
        window.addEventListener('resize', handleResize);

        // 在組件卸載時清除監聽器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 空陣列表示僅在組件掛載和卸載時運行一次

    /**
     * Fetches data from the API based on the provided parameters.
     *
     * @param {array} advancedSearchDataList - The el of advanced search data. Defaults to null.
     * @param {number} pageSize - The number of items per page. Defaults to 100000.
     * @param {string} sort - The sorting criteria. Defaults to the value of sortBy.
     * @param {number} pageNumber - The page number. Defaults to 1.
     * @return {Promise} A promise that resolves with the fetched data.
     */
    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 100000, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `web/ActOrder/getSalesOrderList`;
        // console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        const reqBody = {
            sort: [`${sortBy} ${orderBy}`],
            pageSize,
            page: pageNumber,
            advancedSearchDataList,
        };

        axios.post(url, reqBody).then((res) => {
            console.log(res.data.data);

            const newRegistrationList = res?.data?.data;
            setOrderList(newRegistrationList);
            setIsFetchingData(true);
        });
    };

    const handleSortChange = (newTarget) => {
        let newSortBy;
        let newOrderBy;

        const oldTarget = sortBy.split(' ')[0];

        if (oldTarget === newTarget) {
            newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
        } else {
            newOrderBy = 'desc';
        }

        newSortBy = `${newTarget}`;

        setSortBy(newSortBy);
        setOrderBy(newOrderBy);
    };

    // 加密
    const encrypt = () => {
        const result = AES.encrypt(realPassword, key).toString();

        return result;
    };

    // 解密
    const getOriginalPassword = (ciphertext) => {
        const bytes = AES.decrypt(ciphertext, key);
        const originalText = bytes.toString(encUtf8);

        return originalText;
    };

    const checkPasswordCorrect = () => {
        if (password === realPassword) {
            setIsCorrectPassword(true);
            setCookie('quickOrderPagePassword', encrypt(password), {
                expires: new Date(new Date().getTime() + 15 * 60 * 1000),
            });
            return setIsValid(true);
        } else {
            setIsCorrectPassword(false);
            setCookie('quickOrderPagePassword', password, { path: '/' });
            return setIsValid(false);
        }
    };

    useEffect(() => {
        // el data
        if (!cookies?.quickOrderPagePassword) return;

        if (getOriginalPassword(cookies?.quickOrderPagePassword) === realPassword) {
            setIsCorrectPassword(true);
            setIsValid(true);
        }

        if (isValid) {
            fetchData();
        }
    }, [sortBy, orderBy, isValid]);

    if (!isValid) {
        return (
            <section id='section-main'>
                <div id='container-actRegistration'>
                    <div className='articleBoxWithSideBox px-xl-5 px-lg-5 px-md-0 px-sm-0'>
                        <h2 className='pageTitle pageTitle_main'>賓樂達產品訂購單</h2>
                        <div
                            className='position-relative'
                            style={{
                                height: '800px',
                            }}
                        >
                            <div
                                className='position-absolute'
                                style={{
                                    top: '30%',
                                    left: '50%',
                                    transform: 'Translate(-50%, -50%)',
                                }}
                            >
                                <div className='input-group mb-2'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='請輸入密碼'
                                        aria-label='請輸入密碼'
                                        aria-describedby='basic-addon2'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className='input-group-append'>
                                        <button
                                            className='btn btn-outline-secondary'
                                            type='button'
                                            onClick={checkPasswordCorrect}
                                        >
                                            進入
                                        </button>
                                    </div>
                                </div>
                                {!isCorrectPassword && <span className='text-danger'>密碼錯誤，請重新輸入。</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section id='section-main'>
            <div id='container-actRegistration'>
                <div className='articleBoxWithSideBox px-xl-5 px-lg-5 px-md-0 px-sm-0'>
                    <h2 className='pageTitle pageTitle_main'>賓樂達產品訂購單</h2>
                    <div className='searchWrapper bg-white p-4 rounded-4'>
                        <div className='searchBox row flex-wrap'>
                            <button
                                className='searchBtn col-2 btn btn-size-s btn-color-main'
                                onClick={() => setAdvancedSearchModalOpen(true)}
                            >
                                搜尋
                            </button>
                            <button
                                className='searchBtn ms-auto col-2 btn btn-size-m btn-outline-primary'
                                // onClick={() => navigate('./create')}
                                onClick={() =>
                                    navigate(`./create`, {
                                        state: {
                                            password: realPassword,
                                        },
                                    })
                                }
                            >
                                新增訂單
                            </button>
                        </div>
                    </div>
                    <div className='searchTableWrapper bg-white p-2 rounded-4'>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='firmwareListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            <th width='48' scope='col' className='text-grey4 border-0'>
                                                #<span></span>
                                            </th>
                                            <th
                                                width='120'
                                                scope='col'
                                                className='text-grey4 border-0'
                                                onClick={() => handleSortChange('fullname')}
                                            >
                                                訂購者名稱
                                                {sortBy === 'fullname' &&
                                                    (orderBy === 'desc' ? (
                                                        <span className='ml-1'>▼</span>
                                                    ) : (
                                                        <span className='ml-1'>▲</span>
                                                    ))}
                                            </th>
                                            <th
                                                width='120'
                                                scope='col'
                                                className='text-grey4 border-0'
                                                onClick={() => handleSortChange('phone')}
                                            >
                                                手機號碼
                                                {sortBy === 'phone' &&
                                                    (orderBy === 'desc' ? (
                                                        <span className='ml-1'>▼</span>
                                                    ) : (
                                                        <span className='ml-1'>▲</span>
                                                    ))}
                                            </th>
                                            {windowWidth > 1024 && (
                                                <>
                                                    <th
                                                        width='160'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('email')}
                                                    >
                                                        郵件信箱
                                                        {sortBy === 'email' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='120'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('paymentMethod')}
                                                    >
                                                        付款方式
                                                        {sortBy === 'paymentMethod' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='84'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('unoQuantity')}
                                                    >
                                                        購買 UNO 機型的數量
                                                        {sortBy === 'unoQuantity' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='84'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('maxQuantity')}
                                                    >
                                                        購買 MAX 機型的數量
                                                        {sortBy === 'maxQuantity' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='300'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('note')}
                                                    >
                                                        註記
                                                        {sortBy === 'note' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                    <th
                                                        width='84'
                                                        scope='col'
                                                        className='text-grey4 border-0'
                                                        onClick={() => handleSortChange('updateTime')}
                                                    >
                                                        更新時間
                                                        {sortBy === 'updateTime' &&
                                                            (orderBy === 'desc' ? (
                                                                <span className='ml-1'>▼</span>
                                                            ) : (
                                                                <span className='ml-1'>▲</span>
                                                            ))}
                                                    </th>
                                                </>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {isFetchingData &&
                                            (orderList ? (
                                                orderList?.map((el, index) => {
                                                    return (
                                                        <tr
                                                            id={el.orderGuid}
                                                            key={el.orderGuid}
                                                            onClick={() =>
                                                                navigate(`./${el.orderGuid}`, {
                                                                    state: {
                                                                        password: realPassword,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>{el.fullname || ''}</td>
                                                            <td>{el.phone || ''}</td>
                                                            {windowWidth > 1024 && (
                                                                <>
                                                                    <td>{el.email || ''}</td>
                                                                    <td>{paymentMethodList[el.paymentMethod]}</td>
                                                                    <td>{el.unoQuantity || ''}</td>
                                                                    <td>{el.maxQuantity || ''}</td>
                                                                    <td>{el.note}</td>
                                                                    <td>
                                                                        {dayjs(el.updateTime).format('YYYY/MM/DD') ||
                                                                            ''}
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan='7'>無訂單紀錄</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/*讀取畫面*/}
                                <div className={`text-center loadingBox ${isFetchingData && 'd-none'}`} id='loadingBox'>
                                    <h6 className='sr-only'>...loading</h6>
                                </div>

                                {/* {registrationData && (
                                    <UpdateActScheduleRegistrationModal
                                        updateActRegistrationModalOpen={
                                            updateActRegistrationModalOpen
                                        }
                                        setUpdateActRegistrationModalOpen={
                                            setUpdateActRegistrationModalOpen
                                        }
                                        registrationData={registrationData}
                                        setRegistrationData={
                                            setRegistrationData
                                        }
                                    />
                                )} */}

                                <AdvancedSearchModal
                                    isOpen={advancedSearchModalOpen}
                                    handleClose={() => setAdvancedSearchModalOpen(false)}
                                    advancedSearchDataTemp={advancedSearchDataTemp}
                                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                                    displayList={[advancedSearchDataTemp]}
                                    searchContentList={searchContentList}
                                    fetchData={fetchData}
                                    ns='ActScheduleRegistration'
                                    restSearchData={[]}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ActScheduleRegistration;
