import { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';
import TabPage from '../../../components/TabPage/TabPage';
import TabContent from './TabContent';
import styled from 'styled-components';
import { ReactComponent as Icn_Search } from '../../../assets/images/icn_Search.svg';
import Pagination from '../../../components/Pagination/Pagination';

// #region styled-components
const OrderContainer = styled.article`
    max-width: 1216px;
    width: 100%;
    margin: auto;
`;

const Wrapper = styled.div`
    min-height: 752px;
    border: 1px solid #dadada;
    border-radius: 30px;

    @media only screen and (max-width: 1020px) {
        border: none;
    }
`;

const PageTitle = styled.div`
    font-size: 20px;
    padding: 2rem;

    @media only screen and (max-width: 458px) {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const SearchButton = styled.div`
    @media only screen and (min-width: 458px) {
        display: none;
    }
`;

const NoOrderWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0;
    border: 1px solid #dee2e6;

    @media only screen and (max-width: 1020px) {
        border: none;
    }
`;

const NoOrderText = styled.div`
    font-size: 18px;
`;

const ReturnToShop = styled.a`
    font-size: 14px;
    margin-top: 16px;
    text-decoration: underline;
`;
// #endregion

export default function OrderOverview() {
    const { t } = useTranslation(['order']); // i18n
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [isGettingList, setIsGettingList] = useState(true);
    const [allList, setAllList] = useState([]);

    // pagination
    const [paginationData, setPaginationData] = useState({});
    const sortBy = ['orderDate desc'];
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);

    // 判斷裝置類型
    function detectDeviceType() {
        const width = window.innerWidth;

        if (width <= 576) {
            // 寬度小於等於768px，視為手機板
            return 'mb';
        } else if (width <= 992) {
            // 寬度小於等於1024px，視為平板
            return 'pad';
        } else {
            // 寬度大於1024px，視為電腦版
            return 'pc';
        }
    }

    const deviceType = detectDeviceType();

    const handleSearch = async (val) => {
        if (!val) return getList();

        console.log(val);

        const newAdvancedSearchData = [
            {
                searchLogic: '',
                searchFilter: 'salesKitCode',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_01',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_02',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
            {
                searchLogic: 'OR',
                searchFilter: 'name_03',
                searchOperator: 'CONTAINS',
                searchValue: val,
            },
        ];

        await setAdvancedSearchDataList(newAdvancedSearchData);

        getList(newAdvancedSearchData);
    };

    const tabNavList = [
        t('all'),
        t('pending_payment'),
        t('pending_shipment'),
        t('pending_receipt'),
        t('completed'),
        t('incomplete'),
        // "退換貨/退款",
    ];

    const orderStatus = {
        0: t('pending_payment'),
        1: '待理貨',
        2: t('pending_shipment'),
        3: t('pending_receipt'),
        4: t('completed'),
        5: '待退貨',
        6: '待換貨',
        9: t('incomplete'),
    };

    //#region [function]

    const getList = (advancedSearchDataList = null, pageSize = 10, sort = sortBy, pageNumber = 1) => {
        axiosApi('/web/salSalesOrder/getSalesOrderList', 'post', {
            advancedSearchDataList,
            pageSize,
            sort,
            page: pageNumber,
        }).then((res) => {
            // console.log(res.data.data);

            // pagination
            const { numberOfData } = res.data;
            const totalPage = Math.ceil(numberOfData / pageSize);
            setPaginationData({
                ...paginationData,
                numberOfData: numberOfData,
                totalPage: totalPage,
                pageNumber: pageNumber,
            });

            deviceType === 'pc' ? setAllList(res.data.data) : setAllList([...allList, ...res.data.data]);
            setIsGettingList(false);
        });
    };

    //#endregion

    useEffect(() => {
        getList();
        // sortTable(5)
    }, []);

    const tabContent = [
        <TabContent
            t={t}
            list={allList}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
        />,
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '0')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
        />, // 待付款
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '1' || el.status === '2')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
        />, // 待出貨
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '3')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
        />, // 待收貨
        <TabContent
            t={t}
            list={allList?.filter((el) => el.status === '4')}
            orderStatus={orderStatus}
            handleSearch={handleSearch}
            deviceType={deviceType}
            paginationData={paginationData}
            advancedSearchDataList={advancedSearchDataList}
            getList={getList}
            sortBy={sortBy}
        />, // 已完成
        // <TabContent
        //   list={allList?.filter((el) => el.status === "9")}
        //   orderStatus={orderStatus}
        //   handleSearch={handleSearch}
        // deviceType={deviceType}
        // paginationData={paginationData}
        // advancedSearchDataList={advancedSearchDataList}
        // getList={getList}
        // sortBy={sortBy}
        // />, // 不成立
        // <TabContent
        //   list={allList?.filter((el) => el.status === "5" || el.status === "6")}
        //   orderStatus={orderStatus}
        //   handleSearch={handleSearch}
        // deviceType={deviceType}
        // paginationData={paginationData}
        // advancedSearchDataList={advancedSearchDataList}
        // getList={getList}
        // sortBy={sortBy}
        // />, // 退換貨/退款
    ];

    return (
        <OrderContainer>
            <Wrapper>
                <section>
                    <PageTitle>{t('order_overview')}</PageTitle>
                    {!isGettingList && (
                        <TabPage
                            t={t}
                            tabNavList={tabNavList}
                            // tabContent={
                            //   isGettingList ? (
                            //     <h1 className="text-center">loading...</h1>
                            //   ) : (
                            //     tabContent
                            //   )
                            // }
                            tabContent={tabContent}
                            defaultPage={0}
                        />
                    )}
                </section>
                {deviceType === 'pc' && (
                    <Pagination
                        t={t}
                        paginationData={paginationData}
                        setPaginationData={setPaginationData}
                        fetchData={getList}
                        sortBy={sortBy}
                        advancedSearchDataList={advancedSearchDataList}
                    />
                )}
            </Wrapper>
        </OrderContainer>
    );
}
