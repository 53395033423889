import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';
import * as dayjs from 'dayjs';

const UserDrillItem = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { action, activityGuid, scheduleGuid, activityName } = location?.state;

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    const [scheduleData, setScheduleData] = useState({
        status: '0',
        rankingCategories: 'A',
    });

    const [scheduleList, setScheduleList] = useState([]);

    // warning
    const [displayOrderWarning, setDisplayOrderWarning] = useState('');
    const [nameWarning, setNameWarning] = useState('');
    const [frequencyWarning, setFrequencyWarning] = useState('');
    const [quantityWarning, setQuantityWarning] = useState('');
    const [timerWarning, setTimerWarning] = useState('');

    // --複製成功dialog --
    const successAlert = () => {
        Swal.fire({
            title: '複製成功',
            html: `<h6>宾乐达球谱复制成功!</h6>`,
            icon: 'success',
            confirmButtonText: '返回',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // setModalShow(false);
            }
        });
    };

    // -- 確定刪除活動 --
    const deleteActivityAlert = () => {
        Swal.fire({
            title: '確定要刪除此活動嗎?',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,

            // i18n
            confirmButtonText: '確定',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteSchedule();
            }
        });
    };

    // 新增活動 POST
    const handleCreateSchedule = () => {
        axios
            .post(`web/ActRegistration/insertSchedule`, {
                activityGuid,
                ...scheduleData,
            })
            .then((res) => navigate(-1))
            .catch((err) => console.log(err));
    };

    // 編輯活動 PUT
    const handleUpdateSchedule = () => {
        axios
            .put(`web/ActRegistration/updateSchedule`, {
                activityGuid,
                ...scheduleData,
            })
            .then((res) => navigate(-1))
            .catch((err) => console.log(err));
    };

    // 刪除活動 POST
    const handleDeleteSchedule = () => {
        axiosApi(`web/ActRegistration/deleteSchedule/${scheduleGuid}`, 'post')
            .then((res) => {
                navigate(-1);
            })
            .catch((err) => console.log(err));
    };

    const handleScheduleDataChange = (e) => {
        setScheduleData({
            ...scheduleData,
            [e.target.name]: e.target.value,
        });
    };

    // edit getInfo
    useEffect(() => {
        if (location?.state?.scheduleGuid) {
            axiosApi(`/web/ActRegistration/getScheduleDetails/${location?.state?.scheduleGuid}`)
                .then((res) => {
                    setScheduleData(res.data.schedule);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    // 即時計算活動天數
    useEffect(() => {
        const startDateNoTime = dayjs(scheduleData?.startDate).format('YYYY-MM-DD');
        const endDateNoTime = dayjs(scheduleData?.endDate).format('YYYY-MM-DD');
        const diff = dayjs(endDateNoTime).diff(startDateNoTime, 'day');

        // console.log(startDateNoTime, endDateNoTime);
        // console.log(dayjs(endDateNoTime).diff(startDateNoTime, 'day'));
        // console.log(diff);

        setScheduleData({ ...scheduleData, days: diff });
    }, [scheduleData?.startDate, scheduleData?.endDate]);

    return (
        <>
            <section id='section-main'>
                <div id='container-userDrillItem'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>
                            {action === 'update' ? `編輯 "${activityName}" 的活動日程` : '新增活動日程'}
                        </h5>

                        {action === 'update' ? (
                            <>
                                <button
                                    className='btnCancel btn btn-success px-10 me-2 ms-auto'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(`/admin/actscheduleregistration/${location.state.scheduleGuid}`, {
                                            state: {
                                                activityName,
                                                activityGuid,
                                            },
                                        });
                                    }}
                                >
                                    編輯活動報名
                                </button>
                                <div
                                    className='me-2'
                                    style={{
                                        height: '32px',
                                        borderLeft: '1px solid #DADADA',
                                    }}
                                ></div>
                                <button className='btn btn-outline-danger px-2 me-2' onClick={deleteActivityAlert}>
                                    <svg
                                        id='icn_Trash'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                    >
                                        <defs>
                                            <clipPath id='clipPath'>
                                                <rect
                                                    id='Rectangle_3041'
                                                    data-name='Rectangle 3041'
                                                    width='13.263'
                                                    height='14'
                                                    fill='#ea4132'
                                                />
                                            </clipPath>
                                        </defs>
                                        <g id='Group_6120' data-name='Group 6120' transform='translate(1 1)'>
                                            <g id='Group_6119' data-name='Group 6119' clipPath='url(#clipPath)'>
                                                <path
                                                    id='Path_1388'
                                                    data-name='Path 1388'
                                                    d='M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z'
                                                    fill='#ea4132'
                                                    fillRule='evenodd'
                                                />
                                                <rect
                                                    id='Rectangle_3039'
                                                    data-name='Rectangle 3039'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(4.421 5.158)'
                                                    fill='#ea4132'
                                                />
                                                <rect
                                                    id='Rectangle_3040'
                                                    data-name='Rectangle 3040'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(7.368 5.158)'
                                                    fill='#ea4132'
                                                />
                                            </g>
                                        </g>
                                        <rect
                                            id='Rectangle_3042'
                                            data-name='Rectangle 3042'
                                            width='16'
                                            height='16'
                                            fill='none'
                                        />
                                    </svg>
                                </button>
                                <div
                                    className='me-2'
                                    style={{
                                        height: '32px',
                                        borderLeft: '1px solid #DADADA',
                                    }}
                                ></div>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    onClick={handleUpdateSchedule}
                                >
                                    儲存
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 '
                                    onClick={handleCreateSchedule}
                                >
                                    儲存
                                </button>
                            </>
                        )}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>活動日程</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='startDate' className='fontSize75Rem mb-2'>
                                    活動開始時間
                                </label>
                                <input
                                    id='startDate'
                                    name='startDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        scheduleData?.startDate
                                            ? dayjs(scheduleData?.startDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='endDate' className='fontSize75Rem mb-2'>
                                    活動結束時間
                                </label>
                                <input
                                    id='endDate'
                                    name='endDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        scheduleData?.endDate
                                            ? dayjs(scheduleData?.endDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='startDate_Registry' className='fontSize75Rem mb-2'>
                                    報名開始時間
                                </label>
                                <input
                                    id='startDate_Registry'
                                    name='startDate_Registry'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        scheduleData?.startDate_Registry
                                            ? dayjs(scheduleData?.startDate_Registry).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='endDate_Registry' className='fontSize75Rem mb-2'>
                                    報名結束時間
                                </label>
                                <input
                                    id='endDate_Registry'
                                    name='endDate_Registry'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        scheduleData?.endDate_Registry
                                            ? dayjs(scheduleData?.endDate_Registry).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='lineupStart' className='fontSize75Rem mb-2'>
                                    叫號起始號碼
                                </label>
                                <input
                                    id='lineupStart'
                                    name='lineupStart'
                                    className='form-control mb-3'
                                    type='number'
                                    value={scheduleData?.lineupStart || 0}
                                    onChange={handleScheduleDataChange}
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='lineupEnd' className='fontSize75Rem mb-2'>
                                    叫號結束號碼
                                </label>
                                <input
                                    id='lineupEnd'
                                    name='lineupEnd'
                                    className='form-control mb-3'
                                    type='number'
                                    value={scheduleData?.lineupEnd || 0}
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='interval' className='fontSize75Rem mb-2'>
                                    每次叫號級距
                                </label>
                                <input
                                    id='interval'
                                    name='interval'
                                    className='form-control mb-3'
                                    type='number'
                                    value={scheduleData?.interval || 0}
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='waitingTime' className='fontSize75Rem mb-2'>
                                    預估排隊等待時間（分鐘/人）
                                </label>
                                <input
                                    id='waitingTime'
                                    name='waitingTime'
                                    className='form-control mb-3'
                                    type='number'
                                    value={scheduleData?.waitingTime || 0}
                                    onChange={handleScheduleDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='attendance' className='fontSize75Rem mb-2'>
                                    實際參與人數
                                </label>
                                <input
                                    id='attendance'
                                    name='attendance'
                                    className='form-control mb-3'
                                    type='number'
                                    value={scheduleData?.attendance || 0}
                                    onChange={handleScheduleDataChange}
                                />
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    活動排名分組
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='rankingCategories'
                                    id='rankingCategories'
                                    value={scheduleData?.rankingCategories || ''}
                                    onChange={handleScheduleDataChange}
                                    required
                                >
                                    <option value='A'>A組</option>
                                    <option value='B'>B組</option>
                                    <option value='C'>C組</option>
                                    <option value='D'>D組</option>
                                    <option value='E'>E組</option>
                                    <option value='F'>F組</option>
                                    <option value='G'>G組</option>
                                    <option value='H'>H組</option>
                                    <option value='I'>I組</option>
                                    <option value='J'>J組</option>
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='status'
                                    id='status'
                                    value={scheduleData?.status || ''}
                                    onChange={handleScheduleDataChange}
                                    required
                                >
                                    <option value='0'>"0"：未启用</option>
                                    <option value='1'>"1"：启用中</option>
                                    <option value='2'>"2"：停用中</option>
                                    <option value='4'>"4"：刪除</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserDrillItem;
