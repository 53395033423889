import './forgotPwd.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../../App';

function ForgotPwd() {
    const { t } = useTranslation(['forgotPwd']); // i18n

    const navigate = useNavigate();
    const { lang } = useContext(AccountContext);

    const [page, setPage] = useState(1);

    const [accountType, setAccountType] = useState('0');
    const [email, setEmail] = useState('');
    const [countryCode, setCountryCode] = useState('886');
    const [phoneCode, setPhoneCode] = useState('');

    const [countdownBtnDisabled, setCountdownBtnDisabled] = useState();

    const [newPWD, setNewPWD] = useState('');
    const [confirmPWD, setConfirmPWD] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    const phoneCodeReg = /^[0-9]{6,15}$/;
    const PWDReg = /^[A-Za-z0-9]{6,20}$/;
    const verificationCodeReg = /^[0-9]{6}$/;

    // 切換登入帳號類型
    function accountChange(e) {
        e.preventDefault();

        if (accountType === '0') {
            setAccountType('1');
            setEmail('');
            document.querySelector('#email-info').textContent = '';
            document.querySelector('#email').style.borderColor = '#ced4da';
        } else if (accountType === '1') {
            setAccountType('0');
            setPhoneCode('');
            document.querySelector('#phoneCode-info').textContent = '';
            document.querySelector('#phoneCode').style.borderColor = '#ced4da';
        }
    }
    console.log('lang', lang);

    // 寄送驗證碼
    const sendVerificationCode = async () => {
        const emailPost = {
            accountType: '0',
            email: email,
            locale: navigator.language,
        };

        const phonePost = {
            accountType: '1',
            countryCode: countryCode,
            phoneCode: phoneCode,
            locale: navigator.language,
        };

        try {
            axiosApi('/web/user/sendverificationcode', 'post', accountType === '0' ? emailPost : phonePost, {
                headers: {
                    'Accept-Language': lang,
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === '0') {
                        setPage(2);
                        setVerificationCode('');
                        setCountdownBtnDisabled(10);
                    } else {
                        document.querySelector(
                            `${accountType === '0' ? '#email-info' : '#phoneCode-info'}`
                        ).textContent = t('account_not_exist');

                        document.querySelector(`${accountType === '0' ? '#email' : '#phoneCode'}`).style.borderColor =
                            '#CC3300';
                    }
                })
                .catch((err) => console.log(err));

            // const res = await axios.post(
            //     '/web/user/sendverificationcode',
            //     accountType === '0' ? emailPost : phonePost
            // );
            // //console.log(res.data);
            // if (res.data.status === '0') {
            //     setPage(2);
            //     setVerificationCode('');
            //     setCountdownBtnDisabled(10);
            // } else {
            //     document.querySelector(
            //         `${accountType === '0' ? '#email-info' : '#phoneCode-info'}`
            //     ).textContent = intl.formatMessage({
            //         id: 'auth.accountNoExist',
            //         defaultMessage: '帳號不存在',
            //     });

            //     document.querySelector(
            //         `${accountType === '0' ? '#email' : '#phoneCode'}`
            //     ).style.borderColor = '#CC3300';
            // }
        } catch (error) {
            console.log(error);
        }
    };

    // 確認驗證碼
    const checkVerificationCode = async () => {
        const emailPost = {
            accountType: '0',
            email: email,
            verificationCode: verificationCode,
        };

        const phonePost = {
            accountType: '1',
            countryCode: countryCode,
            phoneCode: phoneCode,
            verificationCode: verificationCode,
        };

        try {
            axiosApi('/web/user/checkVerificationCode', 'post', accountType === '0' ? emailPost : phonePost)
                .then((res) => {
                    if (res.data.status === '0') {
                        setPage(3);
                    } else if (res.data.status === '1') {
                        document.querySelector('#verificationCode-info').textContent = t('verification_code_error');

                        document.querySelector('#verificationCode').style.borderColor = '#CC3300';
                    }
                })
                .catch((err) => console.log(err));
            // await axios
            //     .post(
            //         '/web/user/checkVerificationCode',
            //         accountType === '0' ? emailPost : phonePost
            //     )
            //     .then((res) => {
            //         if (res.data.status === '0') {
            //             setPage(3);
            //         } else if (res.data.status === '1') {
            //             document.querySelector(
            //                 '#verificationCode-info'
            //             ).textContent = intl.formatMessage({
            //                 id: 'startup.verificationCodeIncorrect',
            //                 defaultMessage: '驗證碼錯誤',
            //             });

            //             document.querySelector(
            //                 '#verificationCode'
            //             ).style.borderColor = '#CC3300';
            //         }
            //     });
        } catch (error) {
            console.log(error);
        }
    };

    // 重設密碼 sweetAlert
    let timerInterval;

    const successAlert = () => {
        Swal.fire({
            title: t('update_success'),

            html: '<b></b>' + ' ' + t('redirect_to_login'),

            icon: 'success',
            timer: 5300,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = parseInt(Swal.getTimerLeft() / 1000);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            navigate('/login');

            if (result.dismiss === Swal.DismissReason.timer) {
                // console.log('I was closed by the timer');
                navigate('/login');
            }
        });
    };

    const errorAlert = () => {
        Swal.fire({
            title: t('set_new_password_failed'),
            text: t('check_again'),
            icon: 'error',
        });
    };

    //// 獲取網址列資訊
    // let location = useLocation();
    // //console.log("location", location.search);

    // let queryData = qs.parse(location.search, { ignoreQueryPrefix: true });
    // //console.log(queryData);

    // useEffect(() => {
    // 	setUserGuid(queryData.ug);
    // 	setSecurityStamp(queryData.ss);
    // }, [queryData.ug, queryData.ss]);
    // //console.log(userGuid);
    // //console.log(securityStamp);

    function pageDataConfirm(confirm) {
        switch (confirm) {
            case 1: // page1:檢查帳號並發送驗證碼
                // 前端檢查帳號格式
                // email
                if (accountType === '0') {
                    if (!emailReg.test(email)) {
                        document.querySelector('#email-info').textContent = t('format_error');
                        document.querySelector('#email').style.borderColor = '#CC3300';
                    } else {
                        document.querySelector('#email-info').textContent = '';
                        sendVerificationCode();

                        document.querySelector('#email').style.borderColor = '#ced4da';
                    }
                    // phone
                } else if (accountType === '1') {
                    if (!phoneCodeReg.test(phoneCode)) {
                        document.querySelector('#phoneCode-info').textContent = t('format_error');

                        document.querySelector('#phoneCode').style.borderColor = '#CC3300';
                    } else {
                        document.querySelector('#phoneCode-info').textContent = '';
                        document.querySelector('#phoneCode').style.borderColor = '#ced4da';
                        sendVerificationCode();
                    }
                }
                break;
            case 2: // page2:確認驗證碼
                if (!verificationCodeReg.test(verificationCode)) {
                    document.querySelector('#verificationCode-info').textContent = t('format_error');
                    document.querySelector('#verificationCode').style.borderColor = '#CC3300';
                } else {
                    document.querySelector('#verificationCode-info').textContent = '';
                    document.querySelector('#verificationCode').style.borderColor = '#ced4da';
                    checkVerificationCode();
                }

                break;
            case 3: // page3:設定新密碼
                // 重設使用者密碼，表單驗證提交
                async function handleSubmit() {
                    let newPWDValidated = false;
                    let confirmPWDValidated = false;

                    try {
                        //驗證欄位 顯示錯誤訊息
                        const emailResetPasswordData = {
                            accountType: accountType,
                            email: email,
                            verificationCode: verificationCode,
                            password: newPWD,
                        };

                        const phoneResetPasswordData = {
                            accountType: accountType,
                            countryCode: countryCode,
                            phoneCode: phoneCode,
                            verificationCode: verificationCode,
                            password: newPWD,
                        };

                        if (!PWDReg.test(newPWD)) {
                            document.querySelector('#newPWD-info').textContent = t('format_error');
                            document.querySelector('#newPWD').style.borderColor = '#CC3300';
                        } else {
                            document.querySelector('#newPWD-info').textContent = '';
                            document.querySelector('#newPWD').style.borderColor = '#ced4da';
                            newPWDValidated = true;
                        }

                        if (!PWDReg.test(confirmPWD)) {
                            document.querySelector('#confirmPWD-info').textContent = t('format_error');
                            document.querySelector('#confirmPWD').style.borderColor = '#CC3300';
                        } else {
                            document.querySelector('#confirmPWD-info').textContent = '';
                            confirmPWDValidated = true;
                            document.querySelector('#confirmPWD').style.borderColor = '#ced4da';
                        }

                        if (newPWD !== confirmPWD) {
                            document.querySelector('#confirmPWD-info').textContent = t('new_password_must_match');
                            document.querySelector('#confirmPWD').style.borderColor = '#CC3300';
                        } else if (newPWDValidated && confirmPWDValidated) {
                            document.querySelector('#confirmPWD-info').textContent = '';
                            document.querySelector('#confirmPWD').style.borderColor = '#ced4da';

                            axiosApi(
                                `/web/user/resetpassword`,
                                'put',
                                accountType === '0' ? emailResetPasswordData : phoneResetPasswordData
                            )
                                .then((res) => {
                                    if (res.data.status === '0') {
                                        successAlert();
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            // const res = await axios.put(
                            //     `/web/user/resetpassword`,
                            //     accountType === '0'
                            //         ? emailResetPasswordData
                            //         : phoneResetPasswordData
                            // );

                            // if (res.data.status === '0') {
                            //     successAlert();
                            // }
                        }
                    } catch (error) {
                        console.log(error);
                        errorAlert();
                    }
                }

                handleSubmit();
                break;
            default:
                break;
        }
    }

    //倒數計時
    const [secondsBackToLogin, setSecondsBackToLogin] = useState();
    useEffect(() => {
        const interval = setInterval(() => {
            if (secondsBackToLogin >= 0) {
                setSecondsBackToLogin((secondsBackToLogin) => secondsBackToLogin - 1);
            }
        }, 1000);

        if (secondsBackToLogin === 0) {
            //window.location.replace('/login');
            navigate('/login');
        }

        return () => clearInterval(interval);
    }, [secondsBackToLogin, navigate]);
    //console.log(secondsBackToLogin);

    //驗證碼重新发送 限制點擊
    // console.log('a', countdownBtnDisabled);
    useEffect(() => {
        // 解決useEffect慢一秒的問題
        document.querySelector('#resendVerifyBtn').textContent = countdownBtnDisabled;
        document.querySelector('#resendVerifyBtn').setAttribute('disabled', '');

        const interval = setInterval(() => {
            if (countdownBtnDisabled > 0) {
                document.querySelector('#resendVerifyBtn').setAttribute('disabled', '');
                document.querySelector('#resendVerifyBtn').textContent = countdownBtnDisabled;
                // console.log('b', countdownBtnDisabled);

                setCountdownBtnDisabled((countdownBtnDisabled) => countdownBtnDisabled - 1);
            } else if (countdownBtnDisabled === 0) {
                document.querySelector('#resendVerifyBtn').removeAttribute('disabled');
                document.querySelector('#resendVerifyBtn').textContent = t('resend');
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdownBtnDisabled]);

    return (
        <div className='container-fluid p-0'>
            <article
                id='container-forgotPwd'
                className='py-lg-5 d-flex flex-column align-items-center justify-content-lg-center bg-background '
            >
                <h2 className='pageTitle-mobile d-block d-lg-none'>
                    {page === 3 ? t('set_password') : t('forgot_password')}
                </h2>
                <div className='articleBox d-flex flex-column justify-content-start'>
                    <h2 className='pageTitle d-none d-lg-block'>
                        {page === 3 ? t('set_password') : t('forgot_password')}
                    </h2>

                    <section className={`row g-3 ${page !== 1 && 'd-none'}`} id='forgotPwdPage_1'>
                        <div className='col-12 contentBox'>
                            <div className='row g-3'>
                                {/* email */}
                                <div className={`col-12 ${accountType === '1' && 'd-none'}`}>
                                    {/* <div className="custom-control custom-radio">
											<input
												type="radio"
												id="customRadio1"
												name="accountType"
												className="custom-control-input"
												value="0"
											/>
											<label
												className="custom-control-label"
												htmlFor="customRadio1"
											>
												<FormattedMessage
													id="user.email"
													defaultMessage="电子邮箱"
												/>
											</label>
										</div> */}
                                    {/* <label className="form-label" htmlFor="email">
										<FormattedMessage
											id="user.email"
											defaultMessage="电子邮箱"
										/>
									</label> */}

                                    <input
                                        type='email'
                                        className='form-control form-control-lg'
                                        id='email'
                                        autoComplete='off'
                                        placeholder={t('enter_email')}
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className='invalid-feedback' id='email-info'></div>
                                </div>
                                {/* 電話 */}
                                <div className={`col-12 ${accountType === '0' && 'd-none'}`}>
                                    {/* <div className="custom-control custom-radio">
											<input
												type="radio"
												id="customRadio2"
												name="accountType"
												className="custom-control-input"
												value="1"
											/>
											<label
												className="custom-control-label"
												htmlFor="customRadio2"
											>
												<FormattedMessage
													id="auth.phoneNumber"
													defaultMessage="手机号"
												/>
											</label>
										</div> */}
                                    {/* <label
										className="form-label"
										htmlFor="phoneCode"
									>
										<FormattedMessage
											id="auth.phoneNumber"
											defaultMessage="手机号"
										/>
									</label> */}
                                    <div className='row'>
                                        <div className='col-4 col-lg-4'>
                                            <select
                                                className='form-select form-select-lg'
                                                id='countryCode'
                                                value={countryCode}
                                                onChange={(e) => setCountryCode(e.target.value)}
                                            >
                                                <option value='886'>+886</option>
                                                <option value='852'>+852</option>
                                                <option value='86'>+86</option>
                                            </select>
                                        </div>
                                        <div className='col-8 col-lg-8'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg'
                                                id='phoneCode'
                                                placeholder={t('enter_phone_number')}
                                                required
                                                value={phoneCode}
                                                onChange={(e) => setPhoneCode(e.target.value)}
                                            />
                                            <div className='invalid-feedback' id='phoneCode-info'></div>
                                        </div>
                                    </div>
                                </div>
                                {/* 提示文字 */}
                                <div className='promptMessageBox col-12'>
                                    <p>{t('reset_password_instruction')}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='confirmBtnBox'>
                                <button
                                    className='confirmBtn btn btn-size-l btn-color-main rounded-pill'
                                    onClick={() => pageDataConfirm(1)}
                                >
                                    {t('confirm')}
                                </button>

                                {/*切換登入 / 註冊*/}
                                <div className='changeBox text-start'>
                                    <Link
                                        to=''
                                        className={`text-decoration-none ${accountType === '1' && 'd-none'}`}
                                        onClick={accountChange}
                                    >
                                        {t('get_verification_code_phone')}
                                    </Link>
                                    <Link
                                        to=''
                                        className={`text-decoration-none ${accountType === '0' && 'd-none'}`}
                                        onClick={accountChange}
                                    >
                                        {t('get_verification_code_email')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={`row g-3 ${page !== 2 && 'd-none'}`} id='forgotPwdPage_2'>
                        <div className='col-12 contentBox'>
                            <div className='row g-3'>
                                {/*<!-- 帳號驗證碼 -->*/}
                                <div className='col-12'>
                                    {/* <label htmlFor="verificationCode">
										<FormattedMessage
											id="auth.verificationCode"
											defaultMessage="驗證碼"
										/>
									</label> */}
                                    <div className='row'>
                                        <div className='col-8'>
                                            <input
                                                className='form-control form-control-lg'
                                                type='text'
                                                id='verificationCode'
                                                name='verificationCode'
                                                placeholder={t('enter_verification_code')}
                                                value={verificationCode}
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <button
                                                type='button'
                                                className='btn btn-color-auxiliary'
                                                id='resendVerifyBtn'
                                                onClick={sendVerificationCode}
                                            >
                                                {t('resend')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className='invalid-feedback' id='verificationCode-info'></div>
                                </div>
                                <div id='col-12 promptMessageBox'>
                                    <p>{t('verification_code_sent')}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <button
                                className='btn btn-size-l btn-color-main rounded-pill'
                                onClick={() => pageDataConfirm(2)}
                            >
                                {t('confirm')}
                            </button>
                        </div>
                    </section>
                    <section className={`row g-3 ${page !== 3 && 'd-none'}`} id='forgotPwdPage_3'>
                        <div className='col-12 contentBox'>
                            <div className='row g-3'>
                                {/*<!-- 新密碼 -->*/}
                                <div className='col-12'>
                                    <input
                                        type='password'
                                        className='form-control form-control-lg'
                                        id='newPWD'
                                        name='newPWD'
                                        placeholder={t('enter_new_password')}
                                        pattern='[a-zA-Z0-9]{6,16}'
                                        required
                                        autoComplete='new-password'
                                        onChange={(e) => setNewPWD(e.target.value)}
                                    />
                                    <p className='m-1 text-grey3'>{t('enter_password')}</p>
                                    <div className='invalid-feedback' id='newPWD-info'></div>
                                </div>
                                {/*<!-- 確認新密碼 -->*/}
                                <div className='col-12'>
                                    <input
                                        type='password'
                                        className='form-control form-control-lg'
                                        id='confirmPWD'
                                        name='confirmPWD'
                                        placeholder={t('confirm_new_password')}
                                        pattern='[a-zA-Z0-9]{6,16}'
                                        required
                                        autoComplete='new-password'
                                        onChange={(e) => setConfirmPWD(e.target.value)}
                                    />
                                    <div className='invalid-feedback' id='confirmPWD-info'></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <button
                                className='btn btn-size-l btn-color-main rounded-pill'
                                onClick={() => pageDataConfirm(3)}
                            >
                                {t('confirm')}
                            </button>
                        </div>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default ForgotPwd;
