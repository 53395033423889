import './style.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { axiosApi } from '../../api';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const PdUnpaid = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { orderGuid } = useParams('orderGuid');
    const [isGettingData, setIsGettingData] = useState(true);
    const [order, setOrder] = useState(undefined);
    const navigate = useNavigate();

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getList = useCallback(() => {
        const gettingList = async () => {
            axiosApi(`/web/salSalesOrder/getSalesOrder/${orderGuid}`)
                .then((res) => {
                    const data = res.data.result;
                    data.paymentDueDate = dayjs(data.orderDate).add(48, 'h').format('YYYY-MM-DD HH:mm:ss');

                    setOrder(res.data.result);
                    setIsGettingData(false);

                    // if (res.data.status === "1") {
                    // } else if (res.data.status === "2") {
                    // } else if (res.data.status === "3") {
                    // } else if (res.data.status === "0") {
                    // }
                })
                .catch((err) => console.log(err));
        };

        gettingList();
    }, []);

    useEffect(() => {
        getList();
        // sortTable(5)
    }, [getList]);

    return (
        <section id='section-main'>
            <div id='container-pdUnpaid'>
                <div className='articleBoxWithSideBox px-xl-5 px-lg-5 px-md-0 px-sm-0'>
                    {order && (
                        <>
                            <h2 className='pageTitle pageTitle_main'>訂購完成!請前往完成付款</h2>

                            {/* Added box with payment instructions */}
                            <div className='paymentInstructions'>
                                <div className='paymentInstructionsIn'>
                                    <p>
                                        <strong>
                                            {`請於 ${order?.paymentDueDate} 前使用 ATM
                      轉帳至收款帳號付款。`}
                                        </strong>
                                    </p>
                                    <p>
                                        <strong>銀行代碼 :</strong>
                                        006
                                    </p>
                                    <p>
                                        <strong>收款帳號 :</strong>
                                        5089-898-100889
                                    </p>
                                    <p>
                                        <strong>匯款金額 :</strong>
                                        {`NT$${order?.grossAmount}`}
                                    </p>
                                </div>
                            </div>
                            <div className='searchTableWrapper bg-white p-2 rounded-4'>
                                <div className='searchTableBottom'>
                                    <section>
                                        <table className='firmwareListTable table text-center' id='table-drill'>
                                            <thead>
                                                <tr className='titleHead'>
                                                    <th>訂購明細</th>
                                                </tr>

                                                <tr className='bg-grey table_subtitle'>
                                                    <th>商品資訊</th>
                                                    <th>小計</th>
                                                </tr>
                                            </thead>
                                            <tbody className='border-top-0'>
                                                {order?.salesOrderItemList?.map((item) => (
                                                    <tr key={item?.itemGuid}>
                                                        <th>{item?.name_01}</th>

                                                        <th>{`NT$${item?.grossAmount}`}</th>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th>運費</th>
                                                    <th>{`N$$${order?.deliveryFee}`}</th>
                                                </tr>
                                                <tr>
                                                    <th className='text-rainbow-animation-removed fw-bold text-bold'>
                                                        實際付款金額
                                                    </th>
                                                    <th className='text-rainbow-animation-removed fw-bold text-bold'>
                                                        {`NT$${order?.grossAmount}`}
                                                    </th>
                                                </tr>
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td colSpan='2'>
                                                        親愛的用戶您好，感謝您選擇 synerter
                                                        的商品。訂單成立後約1-3個工作天出貨，提醒您：若需更改配送地址，皆只能取消訂單請您重新訂購。
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div className='w-100 d-flex justify-content-center'>
                                            <button
                                                id='back_to_shop_button'
                                                type='button'
                                                className='btn btn-size-l btn-color-main rounded-pill text-white'
                                                onClick={() => navigate('/onlineStore')}
                                            >
                                                返回商店首頁
                                            </button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default PdUnpaid;
