import './userDrillClassItem.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import { axiosApi } from '../../../api';

const UserDrillClassItem = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // edit : useParams('classGuid')
    // create : location.state.paramsGuid
    const paramsGuid = useParams('classGuid') || location.state.paramsGuid;

    const [isLoading, setIsLoading] = useState(false);
    const [userDrillInfo, setUserDrillInfo] = useState({
        classGuid: null,
        displayOrder: null,
        name: null,
        updateTime: null,
        userDrill: null,
        userGuid: location.state.userGuid,
    });
    console.log(userDrillInfo);

    // warning
    const [displayOrderWarning, setDisplayOrderWarning] = useState('');
    const [nameWarning, setNameWarning] = useState('');

    // 檢查必填
    let isValid = false;

    const isDisplayOrderValid = () => {
        if (userDrillInfo.displayOrder <= 0) {
            setDisplayOrderWarning('請輸入正確顯示順序 (大於 0)');
            return false;
        } else {
            return true;
        }
    };

    const isNameValid = () => {
        if (!userDrillInfo.name) {
            setNameWarning('請輸入球谱名称');
            return false;
        } else {
            return true;
        }
    };

    // 新增球譜 確定
    const handleInsertUserDrillSubmit = () => {
        setDisplayOrderWarning('');
        setNameWarning('');

        const { action, userGuid } = location.state;
        console.log(userGuid, action);
        console.log(userDrillInfo);

        // isDisplayOrderValid();
        isNameValid();

        if (
            // isDisplayOrderValid() &&
            isNameValid()
        ) {
            isValid = true;
        }

        if (isValid) {
            axiosApi(`web/sysDrill/userDrillClass`, 'post', userDrillInfo).then((res) =>
                navigate(`/userDrillClass/${userGuid}`, {
                    state: {
                        fullname: location.state.fullname,
                    },
                })
            );
        }
    };

    // 編輯球路 確定
    const handleEditUserDrillSubmit = () => {
        const { userGuid } = location.state;
        const guid = paramsGuid.classGuid;
        // console.log(userDrillInfo);

        isDisplayOrderValid();
        isNameValid();

        if (isDisplayOrderValid() && isNameValid()) {
            isValid = true;
        }

        if (isValid) {
            axiosApi(`web/sysDrill/userDrillClass/${guid}`, 'put', userDrillInfo).then((res) =>
                navigate(`/userDrillClass/${userGuid}`, {
                    state: {
                        fullname: location.state.fullname,
                    },
                })
            );
        }
    };

    // 刪除球譜
    const handleDeleteUserDrill = () => {
        const { userGuid } = location.state;
        // console.log(paramsGuid.classGuid);
        const guid = paramsGuid.classGuid;

        axiosApi(`web/sysDrill/userDrillClass/${guid}`, 'delete').then((res) =>
            navigate(`/userDrillClass/${userGuid}`, {
                state: {
                    fullname: location.state.fullname,
                },
            })
        );
    };

    // edit getInfo
    useEffect(() => {
        if (Object.keys(paramsGuid).length && location.state.action !== 'create')
            (async () => {
                await axios
                    .get(`web/sysDrill/userDrillClass/${paramsGuid.classGuid}`)
                    .then((res) => {
                        // console.log(
                        //     Object.keys(paramsGuid).length,
                        //     location.state.action !== 'create'
                        // );
                        const data = res.data.result;
                        setUserDrillInfo(data);
                    })
                    .catch((err) => console.log(err));
            })();
    }, [paramsGuid]);

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id='section-main'>
                <div id='container-userDrillClassItem'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>{location.state.fullname}的自編球譜型態</h5>

                        {location.state.action === 'create' ? (
                            <>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(`/userDrillClass/${location.state.userGuid}`, {
                                            state: {
                                                fullname: location.state.fullname,
                                            },
                                        });
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 '
                                    // onClick={handleCopyUserDrill}
                                    onClick={handleInsertUserDrillSubmit}
                                >
                                    储存
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className='btn btn-outline-danger px-2 me-2 ms-auto'
                                    onClick={() => handleDeleteUserDrill()}
                                >
                                    <svg
                                        id='icn_Trash'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                    >
                                        <defs>
                                            <clipPath id='clipPath'>
                                                <rect
                                                    id='Rectangle_3041'
                                                    data-name='Rectangle 3041'
                                                    width='13.263'
                                                    height='14'
                                                    fill='#ea4132'
                                                />
                                            </clipPath>
                                        </defs>
                                        <g id='Group_6120' data-name='Group 6120' transform='translate(1 1)'>
                                            <g id='Group_6119' data-name='Group 6119' clipPath='url(#clipPath)'>
                                                <path
                                                    id='Path_1388'
                                                    data-name='Path 1388'
                                                    d='M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z'
                                                    fill='#ea4132'
                                                    fillRule='evenodd'
                                                />
                                                <rect
                                                    id='Rectangle_3039'
                                                    data-name='Rectangle 3039'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(4.421 5.158)'
                                                    fill='#ea4132'
                                                />
                                                <rect
                                                    id='Rectangle_3040'
                                                    data-name='Rectangle 3040'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(7.368 5.158)'
                                                    fill='#ea4132'
                                                />
                                            </g>
                                        </g>
                                        <rect
                                            id='Rectangle_3042'
                                            data-name='Rectangle 3042'
                                            width='16'
                                            height='16'
                                            fill='none'
                                        />
                                    </svg>
                                </button>
                                <div
                                    className='me-2'
                                    style={{
                                        height: '32px',
                                        borderLeft: '1px solid #DADADA',
                                    }}
                                ></div>

                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(`/userDrillClass/${location.state.userGuid}`, {
                                            state: {
                                                fullname: location.state.fullname,
                                            },
                                        });
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 '
                                    onClick={handleEditUserDrillSubmit}
                                >
                                    储存
                                </button>
                            </>
                        )}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>球谱型態</h6>
                        </div>
                        <div className='infoBottom row mb-3'>
                            <div className='col-8'>
                                <label htmlFor='displayOrder' className='fontSize75Rem mb-2'>
                                    显示顺序
                                </label>
                                <input
                                    id='displayOrder'
                                    name='displayOrder'
                                    type='number'
                                    className='form-control'
                                    value={userDrillInfo.displayOrder || ''}
                                    onChange={(e) => {
                                        setUserDrillInfo({
                                            ...userDrillInfo,
                                            displayOrder: e.target.value,
                                        });
                                        setDisplayOrderWarning('');
                                    }}
                                    required
                                    disabled={!Object.keys(paramsGuid).length}
                                />
                                <span className='text-danger'>{displayOrderWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    球谱型態名称
                                </label>
                                <input
                                    id='familyMax'
                                    name='familyMax'
                                    className='form-control'
                                    value={userDrillInfo.name || ''}
                                    onChange={(e) => {
                                        setUserDrillInfo({
                                            ...userDrillInfo,
                                            name: e.target.value,
                                        });
                                        setNameWarning('');
                                    }}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserDrillClassItem;
