import axios from 'axios';
import useAuth from './useAuth';

export default function useRefreshToken() {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refreshToken', {
            withCredentials: true,
        });
        setAuth((prev) => {
            console.log(JSON.stringfy(prev));
            console.log(response.data);
            return { ...prev, ...response.data };
        });
        return response.data;
    };

    return refresh;
}
