import './authUpdate.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';

const AuthUpdate = () => {
    const [userGuid, setUserGuid] = useState('');
    // 右欄-tab目前頁面('成員' or '權限)
    const [tabPage, setTabPage] = useState('memberPage');
    const navigate = useNavigate();

    /*	1.左欄資訊
		1-1 獲取左欄-所有權限分組 資訊
		1-2 獲取左欄-被選取權限分組-authorizationGuid
		1-3 獲取左欄-被選取權限分組name。 ex: '完整權限' 
	*/
    const [authGroupList, setAuthGroupList] = useState('');
    const [authorizationGuid, setAuthorizationGuid] = useState('');
    const [authGroupName, setAuthGroupName] = useState('');

    const getAuthGroupList = useCallback(() => {
        axiosApi(`web/SysAuthorization/authorization`)
            .then((res) => {
                // 1-1
                setAuthGroupList(res.data.result);

                // 1-2 設定初始選到的權限分組
                authorizationGuid === '' && setAuthorizationGuid(res.data.result[0].authorizationGuid);

                // 1-2 權限分組name,文字用以傳給 權限分組修改頁面
                res.data.result.forEach((el) => {
                    authorizationGuid === el.authorizationGuid && setAuthGroupName(el.name);
                });
            })
            .catch((err) => console.log(err));

        // const axiosAuthGroupList = async () => {
        //     await axios
        //         .get(`web/SysAuthorization/authorization`)
        //         .then((res) => {
        //             // 1-1
        //             setAuthGroupList(res.data.result);

        //             // 1-2 設定初始選到的權限分組
        //             authorizationGuid === '' &&
        //                 setAuthorizationGuid(
        //                     res.data.result[0].authorizationGuid
        //                 );

        //             // 1-2 權限分組name,文字用以傳給 權限分組修改頁面
        //             res.data.result.forEach((el) => {
        //                 authorizationGuid === el.authorizationGuid &&
        //                     setAuthGroupName(el.name);
        //             });
        //         })
        //         .catch((err) => console.log(err));
        // };
    }, [authorizationGuid]);

    /*	2.右欄資訊
		成員-被選取的權限分組-所有成員資訊
	*/
    const [pageAuthMemberList, setPageAuthMemberList] = useState('');

    const getPageAuthMemberList = useCallback(() => {
        authorizationGuid &&
            axiosApi(`web/SysAuthorization/authorizationUserList?guid=${authorizationGuid}`)
                .then((res) => {
                    // console.log(res.data.result);
                    setPageAuthMemberList(res.data.result);
                })
                .catch((err) => console.log(err));

        // const gettingPageAuthMemberList = async () => {
        //     await axios
        //         .get(
        //             `web/SysAuthorization/authorizationUserList?guid=${authorizationGuid}`
        //         )
        //         .then((res) => {
        //             // console.log(res.data.result);
        //             setPageAuthMemberList(res.data.result);
        //         })
        //         .catch((err) => console.log(err));
        // };
        // authorizationGuid && gettingPageAuthMemberList();
    }, [authorizationGuid]);

    /*	3.右欄資訊
		成員-被選取成員的-隶属群组(所屬權限分組)name array。 ex: ['完整權限', '網站權限']
	*/
    const [pageAuthMemberDetailsGroup, setPageAuthMemberDetailsGroup] = useState('');

    const getPageAuthMemberDetails = useCallback(() => {
        userGuid &&
            axiosApi(`web/SysAuthorization/authorizationUser?guid=${userGuid}`)
                .then((res) => {
                    // console.log(res.data.result);
                    let nameList = [];
                    res.data.result.forEach((element) => {
                        nameList.push(element.name);
                    });
                    setPageAuthMemberDetailsGroup(nameList);
                })
                .catch((err) => console.log(err));

        // const gettingPageAuthMemberDetails = async () => {
        //     await axios
        //         .get(`web/SysAuthorization/authorizationUser?guid=${userGuid}`)
        //         .then((res) => {
        //             // console.log(res.data.result);
        //             let nameList = [];
        //             res.data.result.forEach((element) => {
        //                 nameList.push(element.name);
        //             });
        //             setPageAuthMemberDetailsGroup(nameList);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // userGuid && gettingPageAuthMemberDetails();
    }, [userGuid]);

    /*	4.右欄資訊
		權限-全部 資料表-"需要授權的頁面" 資訊
	*/
    const [pageAuthList, setPageAuthList] = useState('');

    const getPageAuthList = useCallback(() => {
        axiosApi(`web/SysAuthorization/pageAuthorization`)
            .then((res) => {
                setPageAuthList(res.data.result);
            })
            .catch((err) => console.log(err));

        // const gettingPageAuthList = async () => {
        //     await axios
        //         .get(`web/SysAuthorization/pageAuthorization`)
        //         .then((res) => {
        //             setPageAuthList(res.data.result);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // gettingPageAuthList();
    }, []);

    /*	5.右欄資訊
		5-1 權限-被選取的權限分組- 資料表-"授權的權限" 資訊
		5-2 權限-被選取的權限分組- TransactionCode array 作為checkbox判斷
	*/
    const [pageAuthPermissionList, setPageAuthPermissionList] = useState('');
    const [pageAuthPermissionTransactionCode, setPageAuthPermissionTransactionCode] = useState('');

    const getPageAuthPermissionList = useCallback(() => {
        authorizationGuid &&
            axiosApi(`web/SysAuthorization/authorizationPermission/${authorizationGuid}`)
                .then((res) => {
                    // 5-1
                    setPageAuthPermissionList(res.data.result);

                    // 5-2
                    let transactionCodeList = [];
                    res.data.result.forEach((el) => {
                        transactionCodeList.push(el.transactionCode);
                    });
                    setPageAuthPermissionTransactionCode(transactionCodeList);
                })
                .catch((err) => console.log(err));

        // const gettingPageAuthPermissionList = async () => {
        //     await axios
        //         .get(
        //             `web/SysAuthorization/authorizationPermission/${authorizationGuid}`
        //         )
        //         .then((res) => {
        //             // 5-1
        //             setPageAuthPermissionList(res.data.result);

        //             // 5-2
        //             let transactionCodeList = [];
        //             res.data.result.forEach((el) => {
        //                 transactionCodeList.push(el.transactionCode);
        //             });
        //             setPageAuthPermissionTransactionCode(transactionCodeList);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // authorizationGuid && gettingPageAuthPermissionList();
    }, [authorizationGuid]);

    useEffect(() => {
        getAuthGroupList();
        getPageAuthMemberList();
        getPageAuthMemberDetails();
        getPageAuthList();
        getPageAuthPermissionList();
    }, [getAuthGroupList, getPageAuthMemberList, getPageAuthMemberDetails, getPageAuthList, getPageAuthPermissionList]);
    // console.log('1-1所有權限分組', authGroupList);
    // console.log('2.被選取的權限分組-所有成員資訊', pageAuthMemberList);
    // console.log('3.被選取成員隶属群组name array', pageAuthMemberDetailsGroup);
    // console.log('4.全部需要授權的頁面', pageAuthList);
    // console.log('5.被選取權限分組-授權的權限', pageAuthPermissionList);

    // 6. 權限分組 delete
    const handleDelete = async (e, guid) => {
        e.preventDefault();

        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed delete');

                axios
                    .delete(`/web/SysAuthorization/authorization/${guid}`)
                    .then(() => {
                        getAuthGroupList();
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    // 7. 表格排序
    function sortTable(n, alwaysDesc) {
        //(必填)n:表格欄位(0,1,2...)
        //(選填)alwaysDesc:切換不同欄位排序時，永遠為desc
        let table,
            rows,
            switching,
            i,
            x,
            y,
            shouldSwitch,
            dir,
            switchCount = 0,
            isFirstTimeSort = false;

        //sort which table?
        table = document.querySelector('.table');

        switching = true;

        //若欄位在sort前就是asc排列，switchCount將為0，將造成類似double click的誤判
        //因此記錄isFirstTimeSort，透過該th span是否已經有排序符號來判別
        if (table.rows[0].querySelectorAll('th span')[n].innerHTML === '') {
            isFirstTimeSort = true;
        }

        //Set the sorting direction to ascending:(初始排序方向)
        dir = 'desc';
        //清空排序圖示
        table.rows[0].querySelectorAll('th span').forEach((i) => (i.innerHTML = ''));
        //加入desc排序圖示
        table.rows[0].querySelectorAll('th span')[n].innerHTML = `
					<svg className="align-bottom ml-1" viewBox="0 0 17 20" width="17" height="20">
                        <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" transform="matrix(1 0 0 -1 0 20)" data-name=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
                    </svg>
        `;

        /*Make a loop that will continue until no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the first, which contains table headers):*/
            for (i = 1; i < rows.length - 1; i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
            one from current row and one from the next:*/
                x = rows[i].querySelectorAll('td')[n];
                y = rows[i + 1].querySelectorAll('td')[n];
                /*check if the two rows should switch place,
            based on the direction, asc or desc:*/
                if (dir === 'asc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === 1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === 'desc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === -1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }

            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                //Each time a switch is done, increase this count by 1:
                switchCount++;
            } else {
                //在切換不同分組時，邏輯判斷永遠為desc排序
                if (alwaysDesc === 'desc') {
                    //console.log("desc");
                }
                //第一次點擊時，已經desc，完成排序
                else if (switchCount === 0 && dir === 'desc' && isFirstTimeSort === true) {
                    //console.log("FirstTimeSort");
                }
                //desc後，第二次點擊轉換為asc
                /*If no switching has been done AND the direction is "desc",
            set the direction to "asc" and run the while loop again.*/
                else if (switchCount === 0 && dir === 'desc') {
                    dir = 'asc';
                    switching = true;
                    table.rows[0].querySelectorAll('th span')[n].innerHTML = `
                       <svg className="align-bottom ml-1" viewBox="0 0 18 20" width="18" height="20">
                    <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" dataName=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
            		</svg>
                `;
                }
            }
        }
    }

    return (
        <section id='section-main'>
            <div id='container-authUpdate' className='container'>
                <h5 className='fw-bold mb-4'>权限设定</h5>
                <div className='authWrapper row'>
                    {/* 左欄 */}
                    <div className='authLeftWrapper col-3 px-2'>
                        <div className='bg-white py-3 rounded-4'>
                            <div className='titleWrapper px-3 pb-2'>
                                <div className='d-flex align-items-center'>
                                    <h6 className='title fw-bold mb-0'>权限分组</h6>
                                    <div className='btnWrapper ms-auto'>
                                        <button
                                            className='btn btn-primary p-0'
                                            onClick={() => navigate('/authUpdate/authUpdateGroupName/create')}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='mb-1'
                                                width='12'
                                                height='12'
                                                viewBox='0 0 12 12'
                                            >
                                                <path
                                                    id='_Color'
                                                    data-name=' ↳Color'
                                                    d='M12,6.857H6.857V12H5.143V6.857H0V5.143H5.143V0H6.857V5.143H12Z'
                                                    fill='#fff'
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='tableWrapper'>
                                {authGroupList &&
                                    authGroupList.map((el) => {
                                        return (
                                            <div
                                                key={el.authorizationGuid}
                                                className={`authGroupWrapper ps-3 pe-2 py-2 ${
                                                    el.authorizationGuid === authorizationGuid && 'active'
                                                }`}
                                                onClick={() => {
                                                    setAuthorizationGuid(el.authorizationGuid);

                                                    // 換權限分組時，清空右欄
                                                    setUserGuid('');
                                                    setPageAuthMemberDetailsGroup([]);
                                                }}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='groupName mb-0'>{el.name}</h6>
                                                    <div className='authBtnGroupWrapper ms-auto'>
                                                        <button
                                                            className='editBtn text-primary fontSize75Rem border-0 bg-transparent'
                                                            onClick={() =>
                                                                navigate(
                                                                    `/authUpdate/authUpdateGroupName/edit/${el.authorizationGuid}`
                                                                )
                                                            }
                                                        >
                                                            修改
                                                        </button>
                                                        <button
                                                            className='deleteBtn text-danger fontSize75Rem border-0 bg-transparent'
                                                            onClick={(e) => handleDelete(e, el.authorizationGuid)}
                                                        >
                                                            刪除
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    {/* 右欄 */}
                    <div className='authRightWrapper col-9 px-2'>
                        <div className='bg-white pb-3 rounded-4'>
                            <div className='tabWrapper d-flex align-items-center'>
                                <div
                                    className={`tabBtn text-center fw-bold ${tabPage === 'memberPage' && 'active'}`}
                                    onClick={() => setTabPage('memberPage')}
                                >
                                    成员
                                </div>
                                <div
                                    className={`tabBtn text-center fw-bold ${tabPage === 'authPage' && 'active'}`}
                                    onClick={() => setTabPage('authPage')}
                                >
                                    权限
                                </div>
                                <button
                                    className='btn btn-primary editBtn text-white ms-auto me-3 my-2'
                                    // 將資料透過url往下一頁傳
                                    onClick={() => {
                                        navigate(
                                            `${
                                                tabPage === 'memberPage'
                                                    ? `/authUpdate/memberPage/edit?authorizationGuid=${authorizationGuid}&authGroupName=${authGroupName}`
                                                    : `/authUpdate/authPage/edit/${authorizationGuid}`
                                            }`
                                        );
                                    }}
                                >
                                    修改
                                </button>
                            </div>

                            {/* 右欄下方顯示 成員內容 或 權限內容 */}
                            {tabPage === 'memberPage' ? (
                                <div className='tabContentWrapper_member row'>
                                    <div className='col-9 pt-3 ps-5 pe-2'>
                                        <div className='tableWrapper'>
                                            <table className='table table-hover text-center' id='table-memberList'>
                                                <thead>
                                                    <tr className='bg-background'>
                                                        <th
                                                            scope='col'
                                                            className='text-grey4 border-0'
                                                            onClick={() => sortTable(0)}
                                                        >
                                                            #<span></span>
                                                        </th>
                                                        <th
                                                            scope='col'
                                                            className='text-start text-grey4 border-0'
                                                            onClick={() => sortTable(1)}
                                                        >
                                                            用户名称
                                                            <span></span>
                                                        </th>
                                                        <th
                                                            scope='col'
                                                            className='text-start text-grey4 border-0'
                                                            onClick={() => sortTable(2)}
                                                        >
                                                            手機號碼
                                                            <span></span>
                                                        </th>
                                                        <th
                                                            scope='col'
                                                            className='text-start text-grey4 border-0'
                                                            onClick={() => sortTable(3)}
                                                        >
                                                            邮箱<span></span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='border-top-0'>
                                                    {pageAuthMemberList ? (
                                                        pageAuthMemberList.map((list, index) => {
                                                            return (
                                                                <tr
                                                                    key={list.authorizationUserGuid}
                                                                    className={`${
                                                                        list.userGuid === userGuid ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => setUserGuid(list.userGuid)}
                                                                >
                                                                    <td className='text-start'>{index + 1}</td>
                                                                    <td className='text-start'>{list.fullname}</td>
                                                                    <td className='text-start'>{list.phone1}</td>
                                                                    <td className='text-start'>{list.email1}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        // tbody不可為空，需寫<tr></tr>否則會報錯
                                                        <tr></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-3 pt-3 ps-2 pe-4'>
                                        <div className='memberGroupWrapper bg-background rounded-4 p-3'>
                                            <div className='title fw-bold mb-2'>隶属群组</div>
                                            {pageAuthMemberDetailsGroup &&
                                                pageAuthMemberDetailsGroup.map((el) => (
                                                    <div key={el} className='memberGroupItem py-2'>
                                                        √ {el}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='tabContentWrapper_auth row'>
                                    <div className='col-9 pt-3 ps-5 pe-2'>
                                        <div className='tableWrapper'>
                                            <table className='table table-hover text-center'>
                                                <thead>
                                                    <tr className='bg-background'>
                                                        <th
                                                            scope='col'
                                                            className='text-grey4 border-0'
                                                            onClick={() => sortTable(0)}
                                                        >
                                                            <span></span>
                                                        </th>
                                                        <th
                                                            scope='col'
                                                            className='text-start text-grey4 border-0'
                                                            onClick={() => sortTable(1)}
                                                        >
                                                            网站功能权限
                                                            <span></span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='border-top-0'>
                                                    {pageAuthList &&
                                                        pageAuthList.map((list) => {
                                                            return (
                                                                <tr key={list.pageGuid}>
                                                                    <td>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={pageAuthPermissionTransactionCode.includes(
                                                                                list.transactionCode
                                                                            )}
                                                                            disabled={
                                                                                !pageAuthPermissionTransactionCode.includes(
                                                                                    list.transactionCode
                                                                                )
                                                                            }
                                                                            onChange={() => {}}
                                                                        />
                                                                    </td>
                                                                    <td className='text-start'>{list.name}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AuthUpdate;
