import './productVip.scss';
import React, { useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Clear, Group } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';

function ProductVip() {
    const { t } = useTranslation('productManage/productvip'); // i18n
    const navigate = useNavigate();
    const [memberList, setMemberList] = useState([]);
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const [invitation, setInvitation] = useState(null);
    const [headInfo, setHeadInfo] = useState(null);
    const [familyMax, setFamilyMax] = useState(0);

    // axios get family info
    const axiosGetFamilyInformation = useCallback(async () => {
        let apiRecallNumber = 0;
        const gettingFamilyInformation = async () => {
            await axiosApi(`/web/user/family`).then((res) => {
                console.log(res.data);
                setFamilyMax(res.data.familyMax);
                // console.log(res.data);
                const headInfo = {
                    headAvatar: res.data.photoLink,
                    headName: res.data.fullname,
                    familyGuid: res.data.familyGuid,
                };
                if (res.data.memberList) setMemberList(() => setMemberList(res.data.memberList));
                if (res.data.family === '0' && res.data.fullname) {
                    getInvitation(headInfo);
                }
                if (res.data.family === '1') {
                    setHeadInfo(headInfo);
                    setIsMember(true);
                }
            });

            // .catch((err) => {
            //     if (err.response.data.JwtStatus === 551) {
            //         if (apiRecallNumber < 10) {
            //             setTimeout(gettingFamilyInformation, 200);
            //             apiRecallNumber++;
            //         } else {
            //             window.location.href = '/login';
            //         }
            //     } else {
            //         window.location.href = '/login';
            //     }
            // });
        };

        gettingFamilyInformation();
    }, []);

    // remove family member
    const handleRemoveFamily = (familyGuid) => {
        const confirmRemoveFamily = async () => {
            await Swal.fire({
                html: t('productManage/productvip:confirm_remove_account_html'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: t('productManage/productvip:cancel'),
                showConfirmButton: true,
                confirmButtonText: t('productManage/productvip:confirm'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((res) => {
                if (res.dismiss === 'cancel') {
                    console.log('cancelled');
                    return;
                } else {
                    console.log('confirmed');
                    axiosDeleteFamily();
                }
            });
        };
        console.log(confirmRemoveFamily());

        const axiosDeleteFamily = () => {
            axiosApi.delete(`/web/user/removefamily`, { data: { familyGuid } }).then((res) => {
                console.log(res);
                axiosGetFamilyInformation();
            });
        };
    };

    // check is user admin
    const geIsUserAdmin = useCallback(() => {
        let apiRecallNumber = 0;
        axiosApi(`/web/user/family`).then((res) => {
            if (res.data.family === '2') {
                setIsUserAdmin(true);
            } else {
                setIsUserAdmin(false);
            }
        });
        // .catch((err) => {
        //     if (err.response.data.JwtStatus === 551) {
        //         if (apiRecallNumber < 10) {
        //             setTimeout(geIsUserAdmin, 200);
        //             apiRecallNumber++;
        //         } else {
        //             window.location.href = '/login';
        //         }
        //     } else {
        //         window.location.href = '/login';
        //     }
        // });
    }, []);

    // first render effect
    useLayoutEffect(() => {
        axiosGetFamilyInformation();
        geIsUserAdmin();
    }, [axiosGetFamilyInformation, geIsUserAdmin]);

    const getInvitation = (invitationInfo) => {
        setInvitation(invitationInfo);
    };

    const handleReplyFamily = (e, familyGuid, answer) => {
        e.preventDefault();
        const replyObj = {
            familyGuid,
            answer,
        };
        axiosApi(`/web/user/replyfamily`, 'put', replyObj).then((res) => {
            console.log(res);
            if (res.data.status === '0') {
                setInvitation(null);
                axiosGetFamilyInformation();
            }
        });
    };

    return (
        <article id='container-productVip'>
            {isUserAdmin ? (
                <div className='articleBoxWithSideBox'>
                    <p className='containTitle'>{t('productManage/productvip:user_family')}</p>
                    <section className='row g-3 mb-3'>
                        <div className='col12'>
                            <p className='contain-myProduct_description'>
                                {t('productManage/productvip:you_can_invite')}
                                {familyMax}
                                {t('productManage/productvip:members_to_join_family')}
                            </p>
                            <article className='contain row'>
                                <div className='col-md-12 vip-info'>
                                    <section className='d-flex justify-content-between align-items-end mb-3'>
                                        <div>
                                            <p className='d-none d-lg-block m-0'>
                                                {t('productManage/productvip:your_user_family_members')}
                                            </p>
                                        </div>
                                        <button
                                            type='button'
                                            className='addVipBtn btn btn-color-main rounded-pill'
                                            onClick={() => navigate('/member/productvipsearch')}
                                        >
                                            {t('productManage/productvip:add_member')}
                                        </button>
                                        {/*<p className="alarm">已到达授权人数上限</p>*/}
                                        {/*<input type="hidden" id="registerGuid" value="" />*/}
                                    </section>
                                    <section>
                                        {/*<!-- 資料表格 -->*/}
                                        <div className='table__title-rwd text-center d-flex justify-content-center align-items-center'>
                                            <div>{t('productManage/productvip:member_list')}</div>
                                        </div>
                                        <table className='table table-md text-center table-custom' id='table-drill'>
                                            <thead className='table-thead'>
                                                <tr className='table__tr'>
                                                    {/*<!-- 使用者頭貼 -->*/}
                                                    {/* <th
												scope="col"
												className="align-middle desktop"
											>
												照片
											</th> */}
                                                    {/*<!-- 授權名單 -->*/}
                                                    <th scope='col' className='align-middle desktop fw-normal'>
                                                        {t('productManage/productvip:member_list')}
                                                    </th>
                                                    <th scope='col' className='align-middle desktop'></th>
                                                    {/* 等待對方接受 */}
                                                    <th scope='col' className='align-middle desktop'></th>
                                                    {/*<!-- 刪除 -->*/}
                                                    <th scope='col' className='align-middle desktop fw-normal'>
                                                        {t('productManage/productvip:delete')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!memberList || !memberList.length) && (
                                                    <tr>
                                                        <td>
                                                            <p className='m-0'>無家族會員</p>
                                                        </td>
                                                    </tr>
                                                )}
                                                {memberList &&
                                                    memberList.map((member) => {
                                                        return (
                                                            <tr key={member['familyGuid']}>
                                                                <td className='align-middle friendAvatar-wrapper text-content'>
                                                                    <div className='friendAvatar mx-auto'>
                                                                        <img
                                                                            src={
                                                                                member['photoLink'] ||
                                                                                './images/user-default.png'
                                                                            }
                                                                            alt=''
                                                                        ></img>
                                                                    </div>
                                                                </td>
                                                                <td className='align-middle friendName text-content'>
                                                                    {member['fullname']}
                                                                </td>
                                                                <td className='align-middle isAccepted text-content'>
                                                                    {member['status'] === '1'
                                                                        ? ''
                                                                        : t(
                                                                              'productManage/productvip:waiting_for_acceptance'
                                                                          )}
                                                                </td>
                                                                <td className='align-middle text-content'>
                                                                    <div
                                                                        className='cancel__svg'
                                                                        id={member['userGuid']}
                                                                        onClick={(e) => {
                                                                            handleRemoveFamily(member.familyGuid);
                                                                        }}
                                                                    >
                                                                        <Clear />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                            </article>
                        </div>
                    </section>
                </div>
            ) : (
                <div className='articleBoxWithSideBox'>
                    <p className='containTitle'>用户家族</p>
                    <section>
                        <div className='col-md-12 vip-info'>
                            <div className='contain-myProduct'>
                                <div className='card'>
                                    {isMember && (
                                        <div className='contain-myProduct-invitation card-body'>
                                            <div
                                                style={{
                                                    width: '96px',
                                                    height: '96px',
                                                    borderRadius: '50%',
                                                    border: '1px solid white',
                                                    overflow: 'hidden',
                                                    margin: '0 auto',
                                                    marginBottom: '24px',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        margin: '0 auto',
                                                    }}
                                                    src={headInfo.headAvatar || './images/user-default.png'}
                                                    alt=''
                                                ></img>
                                            </div>
                                            <div
                                                className='card-text'
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                您是 {headInfo.headName} 的家族成員
                                            </div>
                                        </div>
                                    )}
                                    {!isMember && invitation && (
                                        <div className='contain-myProduct-invitation card-body'>
                                            <div className='img__wrapper'>
                                                <img
                                                    style={{
                                                        margin: '0 auto',
                                                    }}
                                                    src={invitation.headAvatar || './images/user-default.png'}
                                                    alt=''
                                                ></img>
                                            </div>
                                            <div className='card-title'>
                                                用户：
                                                {invitation.headName}
                                            </div>
                                            <p className='card-text p-0'>
                                                {invitation.headName}
                                                邀請您成為他的家族成員
                                            </p>
                                            <section className='section-footer'>
                                                <button
                                                    type='button'
                                                    className='btn btn-color-auxiliary btn-size-s btn__confirm me-3'
                                                    onClick={(e) => {
                                                        handleReplyFamily(e, invitation.familyGuid, 'Y');
                                                    }}
                                                >
                                                    接受邀請
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-color-outline-white btn-size-s btn-color-white btn__cancel'
                                                    onClick={(e) => handleReplyFamily(e, invitation.familyGuid, 'N')}
                                                >
                                                    拒絕
                                                </button>
                                            </section>
                                        </div>
                                    )}
                                    {!isMember && !invitation && (
                                        <div className='no-frd-div'>
                                            <div className='group__svg'>
                                                <Group />
                                            </div>
                                            <p className='card-text'>
                                                {t('productManage/productvip:not_joined_any_user_family')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </article>
    );
}

export default ProductVip;
