import './questionCategoryUpdate.scss';
import RenderIndexList from '../../../RenderIndexList';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

const QuestionCategoryUpdate = () => {
    const [isGetOnlineVideoList, setIsGetOnlineVideoList] = useState(false);
    const [onlineVideoList, setOnlineVideoList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    //#region [code to name]

    // TODO : put all temps into utils.js ?
    const pageTitle = '常見問題型態';
    const deleteApiURL = 'web/backstage/qacategory';
    const createURL = '/questioncategoryupdate/create';

    const titleList = ['順序', '型態代碼', '中文名稱(繁)', '中文名稱(簡)', '狀態', ''];

    const statusCodeToName = {
        0: '停用',
        1: '啟用',
    };

    //#endregion

    //#region [function]

    const getOnlineVideoList = useCallback(() => {
        setIsGetOnlineVideoList(false);

        const gettingOnlineVideoList = async () => {
            await axios
                .get(`web/backstage/qacategory`)
                .then((res) => {
                    console.log(res.data.result);
                    const data = res.data.result;
                    let list = [];

                    // TODO : app display function with undefined
                    data.forEach((el) => {
                        el.guid = el.categoryCode;
                        el.detailUrl = `/questioncategoryupdate/edit/${el.guid}`;

                        el.dataList = [
                            el.displayOrder,
                            el.categoryCode,
                            el.name_ZF,
                            el.name_ZH,
                            `${statusCodeToName[el.status]}`,
                        ];

                        const filterItemsObject = new Object();
                        filterItemsObject.guid = el.categoryCode;
                        filterItemsObject.displayOrder = el.displayOrder.toString();
                        filterItemsObject.categoryCode = el.categoryCode;
                        filterItemsObject.name_ZF = el.name_ZF;
                        filterItemsObject.name_ZH = el.name_ZH;
                        filterItemsObject.status = `${statusCodeToName[el.status]}`;

                        list = [filterItemsObject, ...list];
                    });

                    setFilterItemsObjectList(list);
                    setOnlineVideoList(data);
                    setFilterList(data);
                    setIsGetOnlineVideoList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingOnlineVideoList();
    }, []);

    //#endregion

    useEffect(() => {
        getOnlineVideoList();
    }, [getOnlineVideoList]);

    return (
        <section id='section-main'>
            <div id='container-questionCategoryUpdate'>
                <RenderIndexList
                    titleList={titleList}
                    contentList={onlineVideoList}
                    setList={setOnlineVideoList}
                    searchTextRef={searchTextRef}
                    isGetList={isGetOnlineVideoList}
                    setIsGetList={setIsGetOnlineVideoList}
                    getList={getOnlineVideoList}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    pageTitle={pageTitle}
                    filterItemsObjectList={filterItemsObjectList}
                    deleteApiURL={deleteApiURL}
                    createURL={createURL}
                />
            </div>
        </section>
    );
};

export default QuestionCategoryUpdate;
