import './pwdReset.scss';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import { SidebarHeightContecxt } from '../../../components/layoutAccount/LayoutAccount';
import { axiosApi } from '../../../api';
import { useTranslation } from 'react-i18next';

function PwdReset() {
    const { t } = useTranslation('userManage/pwdReset'); // i18n
    const navigate = useNavigate();
    const pwdReg = /^[A-Za-z0-9]{6,16}$/;

    const [oldPWD, setOldPWD] = useState('');
    const [newPWD, setNewPWD] = useState('');
    const [confirmPWD, setConfirmPWD] = useState('');

    const [cookies, setCookie] = useCookies(['loginUser']);

    // 自定義sidebar高度(由於confirmBtn在外面)
    // const sidebarHeightContext = useContext(SidebarHeightContext);
    // sidebarHeightContext.setSidebarHeight('100% - confirmBtn');
    // console.log(sidebarHeightContext.sidebarHeight);

    //sweetAlert
    let timerInterval;

    const successAlert = () => {
        Swal.fire({
            title: '更新成功',

            html: '<b></b>' + ' 秒後自動跳至登入頁面',

            icon: 'success',
            timer: 5300,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = parseInt(Swal.getTimerLeft() / 1000);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            navigate('/login');

            if (result.dismiss === Swal.DismissReason.timer) {
                navigate('/login');
            }
        });
    };

    const errorAlert = () => {
        Swal.fire({
            title: t('userManage/pwdReset:incorrect_old_password'),
            text: t('userManage/pwdReset:confirm_password2'),
            icon: 'error',
        });
    };

    let oldPWDValidated = false;
    let newPWDValidated = false;
    let confirmPWDValidated = false;

    const checkOldPWD = () => {
        if (!oldPWD) {
            document.querySelector('#oldPWD-info').textContent = t('userManage/pwdReset:password_not_empty');

            document.querySelector('#oldPWD').style.borderColor = '#CC3300';
            return 1;
        } else if (!pwdReg.test(oldPWD)) {
            document.querySelector('#oldPWD-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#oldPWD').style.borderColor = '#CC3300';
            return 1;
        } else {
            document.querySelector('#oldPWD-info').textContent = '';
            document.querySelector('#oldPWD').style.borderColor = '#ced4da';
            oldPWDValidated = true;
        }
    };

    const checkNewPWD = () => {
        if (!newPWD) {
            document.querySelector('#newPWD-info').textContent = t('userManage/pwdReset:password_not_empty');
            document.querySelector('#newPWD').style.borderColor = '#CC3300';
        } else if (!pwdReg.test(newPWD)) {
            document.querySelector('#newPWD-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#newPWD').style.borderColor = '#CC3300';
        } else {
            document.querySelector('#newPWD-info').textContent = '';
            document.querySelector('#newPWD').style.borderColor = '#ced4da';
            newPWDValidated = true;
        }
    };

    const checkConfirmPWD = () => {
        if (!confirmPWD) {
            document.querySelector('#confirmPWD-info').textContent = t('userManage/pwdReset:password_not_empty');
            document.querySelector('#confirmPWD').style.borderColor = '#CC3300';
        } else if (!pwdReg.test(confirmPWD)) {
            document.querySelector('#confirmPWD-info').textContent = '格式錯誤 請重新輸入';
            document.querySelector('#confirmPWD').style.borderColor = '#CC3300';
        } else if (newPWD !== confirmPWD) {
            document.querySelector('#confirmPWD-info').textContent = '確認密碼 與 密碼 必須相同';
        } else {
            document.querySelector('#confirmPWD-info').textContent = '';
            document.querySelector('#confirmPWD').style.borderColor = '#ced4da';
            confirmPWDValidated = true;
        }
    };

    // 儲存資料
    async function handleSubmit(e) {
        e.preventDefault();

        checkOldPWD();
        checkNewPWD();
        checkConfirmPWD();

        const accessToken = localStorage.getItem('accessToken');
        console.log('accessToken', accessToken);

        try {
            //驗證欄位 顯示錯誤訊息
            const updatePWDData = {
                oldPassword: oldPWD,
                newPassword: newPWD,
            };

            if (oldPWDValidated && newPWDValidated && confirmPWDValidated) {
                document.querySelector('#confirmPWD-info').textContent = '';
                axiosApi('/web/user/password', 'put', updatePWDData).then((res) => {
                    if (res.data.status === '1') {
                        document.querySelector('#oldPWD-info').textContent = t(
                            'userManage/pwdReset:incorrect_old_password'
                        );
                        document.querySelector('#oldPWD').style.borderColor = '#CC3300';
                        errorAlert();
                    } else if (res.data.status === '0') {
                        document.querySelector('#oldPWD').style.borderColor = '#ced4da';
                        setCookie('loginUser', { status: '1' }, { path: '/' });
                        setCookie('page', [], { path: '/' });
                        successAlert();
                    }
                });

                // const res = await axios.put(
                //     `/web/user/password`,
                //     updatePWDData,
                //     {
                //         headers: {
                //             Authorization: 'Bearer ' + accessToken,
                //         },
                //     }
                // );
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <article id='container-pwdReset'>
            <div className='articleBoxWithSideBox'>
                <h1 className='containTitle'>{t('userManage/pwdReset:change_password')}</h1>
                <section>
                    <form className='row g-3' autoComplete='off' noValidate>
                        {/*<!-- 舊密碼 -->*/}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='oldPWD'>
                                {t('userManage/pwdReset:old_password')}
                            </label>
                            <input
                                type='password'
                                className='form-control form-control-lg'
                                id='oldPWD'
                                name='oldPWD'
                                placeholder={t('userManage/pwdReset:enter_old_password')}
                                value={oldPWD}
                                onChange={(e) => {
                                    setOldPWD(e.target.value);
                                }}
                                onBlur={checkOldPWD}
                                autoComplete='new-password'
                            />
                            <div className='invalid-feedback' id='oldPWD-info'></div>
                        </div>
                        <div className='w-100 d-none d-lg-block m-0'></div>
                        {/*<!-- 新密碼 -->*/}
                        <div className='col-lg-6 '>
                            <label className='form-label' htmlFor='newPWD'>
                                {t('userManage/pwdReset:new_password')}
                            </label>
                            <input
                                type='password'
                                className='form-control form-control-lg'
                                id='newPWD'
                                name='newPWD'
                                placeholder={t('userManage/pwdReset:enter_new_password')}
                                value={newPWD}
                                onChange={(e) => setNewPWD(e.target.value)}
                                onBlur={checkNewPWD}
                                autoComplete='new-password'
                            />
                            <p className='pt-2 m-0 text-grey3'>
                                {t('userManage/pwdReset:password_length_case_sensitive')}
                            </p>
                            <div className='invalid-feedback mb-3' id='newPWD-info'></div>
                        </div>
                        <div className='w-100 d-none d-lg-block m-0'></div>

                        {/*<!-- 確認密碼 -->*/}
                        <div className='col-lg-6 mt-0'>
                            <label className='form-label' htmlFor='confirmPWD'>
                                {t('userManage/pwdReset:confirm_password')}
                            </label>
                            <input
                                type='password'
                                className='form-control form-control-lg'
                                id='confirmPWD'
                                name='confirmPWD'
                                placeholder={t('userManage/pwdReset:reenter_password')}
                                value={confirmPWD}
                                onChange={(e) => setConfirmPWD(e.target.value)}
                                onBlur={checkConfirmPWD}
                                autoComplete='new-password'
                            />
                            <div className='invalid-feedback' id='confirmPWD-info'></div>
                        </div>
                    </form>
                </section>
            </div>
            {/*submit button */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='btn-confirm'
                    className='btn btn-color-main rounded-pill'
                    onClick={handleSubmit}
                >
                    {t('userManage/pwdReset:confirm')}
                </button>
            </div>
        </article>
    );
}

export default PwdReset;
