import './userDrillClass.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const UserDrillClass = () => {
    const [isGetUserDrillList, setIsGetUserDrillList] = useState(false);
    const [userDrillList, setUserDrillList] = useState('');
    const [searchText, setSearchText] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const fullname = location.state.fullname;
    const paramsGuid = useParams('userGuid');

    const getUserDrillList = useCallback(() => {
        setIsGetUserDrillList(false);

        const gettingUserDrillList = async () => {
            await axios
                .get(`web/sysDrill/userDrillClass?guid=${paramsGuid.userGuid}`)
                .then((res) => {
                    const data = res.data.result;
                    console.log(data);

                    data.sort(function (a, b) {
                        return a.displayOrder - b.displayOrder;
                    });

                    setUserDrillList(data);
                    setIsGetUserDrillList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingUserDrillList();
    }, []);

    useEffect(() => {
        getUserDrillList();
    }, [getUserDrillList]);

    //過濾搜尋
    const filterUserDrillList = async () => {
        const filterList = userDrillList.filter((item) => {
            const searchItems = { ...item };
            delete searchItems.classGuid;

            let isInclude = false;
            let drillCategoryInfoArray = Object.values(searchItems);
            for (let i = 0; i < drillCategoryInfoArray.length; i++) {
                // console.log(drillCategoryInfoArray[i]);
                // if (i === 6)
                //     drillCategoryInfoArray[i] &&
                //         statusList[drillCategoryInfoArray[i]]
                //             .toString()
                //             .includes(searchText.toLowerCase());

                if (
                    drillCategoryInfoArray[i] &&
                    drillCategoryInfoArray[i].toString().toLowerCase().includes(searchText.toLowerCase())
                ) {
                    isInclude = true;
                }
            }

            return isInclude;
        });
        console.log(filterList);

        setUserDrillList(filterList);
    };

    //過濾搜尋
    const resetFilterUserDrillList = () => {
        setSearchText('');
        getUserDrillList();
    };

    //表格排序
    function sortTable(n, alwaysDesc) {
        //(必填)n:表格欄位(0,1,2...)
        //(選填)alwaysDesc:切換不同欄位排序時，永遠為desc
        let table,
            rows,
            switching,
            i,
            x,
            y,
            shouldSwitch,
            dir,
            switchCount = 0,
            isFirstTimeSort = false;

        //sort which table?
        table = document.querySelector('.table');

        switching = true;

        //若欄位在sort前就是asc排列，switchCount將為0，將造成類似double click的誤判
        //因此記錄isFirstTimeSort，透過該th span是否已經有排序符號來判別
        if (table.rows[0].querySelectorAll('th span')[n].innerHTML === '') {
            isFirstTimeSort = true;
        }

        //Set the sorting direction to ascending:(初始排序方向)
        dir = 'desc';
        //清空排序圖示
        table.rows[0].querySelectorAll('th span').forEach((i) => (i.innerHTML = ''));
        //加入desc排序圖示
        table.rows[0].querySelectorAll('th span')[n].innerHTML = `
					<svg className="align-bottom ml-1" viewBox="0 0 17 20" width="17" height="20">
                        <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" transform="matrix(1 0 0 -1 0 20)" data-name=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
                    </svg>
        `;

        /*Make a loop that will continue until no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the first, which contains table headers):*/
            for (i = 1; i < rows.length - 1; i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
            one from current row and one from the next:*/
                x = rows[i].querySelectorAll('td')[n];
                y = rows[i + 1].querySelectorAll('td')[n];
                /*check if the two rows should switch place,
            based on the direction, asc or desc:*/
                if (dir === 'asc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === 1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === 'desc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === -1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }

            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                //Each time a switch is done, increase this count by 1:
                switchCount++;
            } else {
                //在切換不同分組時，邏輯判斷永遠為desc排序
                if (alwaysDesc === 'desc') {
                    //console.log("desc");
                }
                //第一次點擊時，已經desc，完成排序
                else if (switchCount === 0 && dir === 'desc' && isFirstTimeSort === true) {
                    //console.log("FirstTimeSort");
                }
                //desc後，第二次點擊轉換為asc
                /*If no switching has been done AND the direction is "desc",
            set the direction to "asc" and run the while loop again.*/
                else if (switchCount === 0 && dir === 'desc') {
                    dir = 'asc';
                    switching = true;
                    table.rows[0].querySelectorAll('th span')[n].innerHTML = `
                       <svg className="align-bottom ml-1" viewBox="0 0 18 20" width="18" height="20">
                    <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" dataName=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
            		</svg>
                `;
                }
            }
        }
    }

    return (
        <section id='section-main'>
            <div id='container-userDrillClass'>
                <h5 className='fw-bold mb-4'>{fullname}的自编球谱型態</h5>
                <div className='searchWrapper bg-white p-4 rounded-4 mb-3'>
                    <div className='searchBox d-flex'>
                        <input
                            className='searchInput form-control me-2'
                            placeholder='搜寻'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                            onKeyDown={(e) => e.key === 'Enter' && filterUserDrillList()}
                        />
                        <button className='btn btn-outline-primary btn-size-s me-2' onClick={resetFilterUserDrillList}>
                            重置
                        </button>
                        <button className='btn btn-primary btn-size-s text-white' onClick={filterUserDrillList}>
                            查询
                        </button>
                    </div>
                </div>

                <div className='searchTableWrapper bg-white p-4 rounded-4'>
                    <div className='searchTableTop d-flex align-items-center mb-3 '>
                        <h6 className='fw-bold'>球谱型態</h6>
                        <button
                            className='btn btn-primary text-white ms-auto me-2'
                            onClick={() =>
                                navigate('/userDrillClassItem/create', {
                                    state: {
                                        fullname: location.state.fullname,
                                        action: 'create',
                                        userGuid: paramsGuid.userGuid,
                                    },
                                })
                            }
                        >
                            <svg
                                id='icn_Create'
                                className='me-2 mb-1'
                                viewBox='0 0 12 12'
                                width='12'
                                height='12'
                                fill='#fff'
                            >
                                <defs> </defs>
                                <path
                                    className='cls-1'
                                    id='_Color'
                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                    data-name=' ↳Color'
                                ></path>
                            </svg>
                            <span>新增球谱型態</span>
                        </button>
                        <span className='reloadUserDrillList me-2' onClick={getUserDrillList}>
                            <svg width='32' height='32' viewBox='0 0 32 32'>
                                <defs>
                                    <clipPath id='clip-path-reload'>
                                        <rect
                                            id='Rectangle_3588'
                                            data-name='Rectangle 3588'
                                            width='14.149'
                                            height='16'
                                            fill='none'
                                        />
                                    </clipPath>
                                </defs>
                                <g id='Group_8495' data-name='Group 8495' transform='translate(-1232 -296)'>
                                    <rect
                                        id='Rectangle_3587'
                                        data-name='Rectangle 3587'
                                        width='32'
                                        height='32'
                                        transform='translate(1232 296)'
                                        fill='none'
                                    />
                                    <g id='Group_8494' data-name='Group 8494' transform='translate(1241 304)'>
                                        <g id='Group_8493' data-name='Group 8493' clipPath='url(#clip-path-reload)'>
                                            <path
                                                id='Path_1555'
                                                data-name='Path 1555'
                                                d='M0,0H2.126V2.716a7.434,7.434,0,1,1-1.1,10.632l1.719-1.259a5.311,5.311,0,1,0,.877-7.842H6.372V6.371H0Z'
                                                fill='#686868'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span>
                            <svg width='32' height='32' viewBox='0 0 32 32'>
                                <defs>
                                    <clipPath id='clip-path-gear'>
                                        <rect
                                            id='Rectangle_3562'
                                            data-name='Rectangle 3562'
                                            width='16'
                                            height='15.081'
                                            fill='#686868'
                                        />
                                    </clipPath>
                                </defs>
                                <g id='Group_8492' data-name='Group 8492' transform='translate(-1264 -296)'>
                                    <rect
                                        id='Rectangle_3586'
                                        data-name='Rectangle 3586'
                                        width='32'
                                        height='32'
                                        transform='translate(1264 296)'
                                        fill='none'
                                    />
                                    <g id='Group_8491' data-name='Group 8491' transform='translate(1272 304)'>
                                        <g
                                            id='Group_8450'
                                            data-name='Group 8450'
                                            transform='translate(0 0)'
                                            clipPath='url(#clip-path-gear)'
                                        >
                                            <path
                                                id='Path_1516'
                                                data-name='Path 1516'
                                                d='M2.622,12.655c.048-.139.144-.4.232-.66a4.859,4.859,0,0,0,.27-.867,1.063,1.063,0,0,0-.7-1.179,2.892,2.892,0,0,0-.871-.182A1.527,1.527,0,0,1,0,8.139C0,7.721,0,7.3,0,6.884a1.518,1.518,0,0,1,1.52-1.566,2.949,2.949,0,0,0,.788-.136A1.152,1.152,0,0,0,3.1,3.747a2.87,2.87,0,0,0-.255-.659A1.534,1.534,0,0,1,3.472.923C3.9.678,4.326.432,4.756.191A1.322,1.322,0,0,1,6.591.672a3.619,3.619,0,0,0,.582.747,1.152,1.152,0,0,0,1.665-.036A3.124,3.124,0,0,0,9.327.75,1.352,1.352,0,0,1,11.3.231c.417.239.835.475,1.25.717a1.525,1.525,0,0,1,.606,2.1,2.964,2.964,0,0,0-.289.84,1.112,1.112,0,0,0,.768,1.28,2.855,2.855,0,0,0,.8.149A1.541,1.541,0,0,1,16,6.91c.005.418,0,.837,0,1.255a1.522,1.522,0,0,1-1.547,1.6,3.014,3.014,0,0,0-.593.077,1.216,1.216,0,0,0-.929,1.645,3.244,3.244,0,0,0,.243.564,1.519,1.519,0,0,1-.588,2.075q-.662.388-1.332.762a1.346,1.346,0,0,1-1.879-.5,3.5,3.5,0,0,0-.561-.717,1.154,1.154,0,0,0-1.653.015,3.064,3.064,0,0,0-.5.644,1.353,1.353,0,0,1-1.968.528c-.423-.241-.846-.48-1.267-.724a1.591,1.591,0,0,1-.8-1.475m5.37-2.521A2.593,2.593,0,1,0,5.4,7.54a2.59,2.59,0,0,0,2.593,2.6'
                                                transform='translate(0 0)'
                                                fill='#686868'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className='searchTableBottom'>
                        <section>
                            <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                <thead>
                                    <tr className='bg-background'>
                                        <th scope='col' className='text-grey4 border-0' onClick={() => sortTable(0)}>
                                            顺序<span></span>
                                        </th>
                                        <th
                                            scope='col'
                                            className='text-grey4 border-0 text-start'
                                            onClick={() => sortTable(1)}
                                            style={{ width: '40%' }}
                                        >
                                            球谱型態名称<span></span>
                                        </th>
                                        <th scope='col' className='text-grey4 border-0' onClick={() => sortTable(2)}>
                                            更新时间<span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-top-0'>
                                    {isGetUserDrillList &&
                                        (userDrillList.length !== 0 ? (
                                            userDrillList.map((list) => {
                                                return (
                                                    <tr
                                                        id={list.classGuid}
                                                        key={list.classGuid}
                                                        onClick={() =>
                                                            navigate(`/userDrillClassItem/${list.classGuid}`, {
                                                                state: {
                                                                    fullname,
                                                                    userGuid: paramsGuid.userGuid,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <td>{list.displayOrder}</td>
                                                        <td className='text-start'>{list.name}</td>
                                                        <td>{list.updateTime.split('T')[0]}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan='8'>無球谱紀錄</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {/*讀取畫面*/}
                            <div className={`text-center loadingBox ${isGetUserDrillList && 'd-none'}`} id='loadingBox'>
                                <h6 className='sr-only'>Loading...</h6>

                                <div className='spinner-border text-primary' role='status'></div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserDrillClass;
