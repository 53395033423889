import './physicalStoreUpdateChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const RenderIndexList = () => {
    //#region [States initialization]
    const [isLoading, setIsLoading] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState(null);

    const [storeName, setStoreName] = useState('');
    const [address, setAddress] = useState('');
    const [telephone, setTelephone] = useState('');
    const [displayOrder, setDisplayOrder] = useState('');
    const [category, setCategory] = useState('');
    const [picture, setPicture] = useState('../images/physicalStore/fallbackSrc.png');
    const [status, setStatus] = useState(null);
    const [rowStamp, setRowStamp] = useState('');
    const [province, setProvince] = useState('');
    const [countryCode, setCountryCode] = useState('');

    const paramsGuid = useParams('appGuid');
    const navigate = useNavigate();

    //#endregion

    //#region [function]

    const handleSetImage = (e) => {
        const strArr = e.target.files[0].name.split('.');
        const imgType = strArr[strArr.length - 1];
        setPicture(URL.createObjectURL(e.target.files[0]));
        setImageName(`${paramsGuid.storeGuid ? paramsGuid.storeGuid : '[guid]'}.${imgType}`);
        setImageFile(URL.createObjectURL(e.target.files[0]));
    };

    // edit delete
    const handleDeleteItem = async () => {
        await axios
            .delete(`web/backstage/physicalstore/${paramsGuid.storeGuid}`)
            .then((res) => {
                console.log(res.data);
                navigate('/physicalstoreupdate');
            })

            .catch((err) => console.log(err));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        // picture 不是 input, 需要手動加進 formData 裡
        // 把 storeName 的值放到 name 裡
        form.append('picture', picture);
        form.append('name', storeName);

        // for (var pair of form.entries()) {
        //     console.log(pair[0] + ' - ' + pair[1]);
        // }

        if (Object.keys(paramsGuid).length) {
            //edit
            setPicture(null);
            setIsLoading(true);

            await axios
                .put(`web/backstage/physicalstore/${paramsGuid.storeGuid}`, form)
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/physicalstoreupdate');
                })
                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        } else {
            //create
            if (imageFile === null) {
                Swal.fire({
                    icon: 'info',
                    title: '請上傳圖片',
                });
            } else {
                setIsLoading(true);

                await axios
                    .post(`web/backstage/physicalstore`, form)
                    .then((res) => {
                        console.log(res.data);
                        setIsLoading(false);
                        navigate('/physicalstoreupdate');
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: '網路不穩，儲存失敗',
                            text: '請再次儲存。',
                        });
                        console.log(err);
                    });
            }
        }
    };

    //#endregion

    //#region [useEffect]

    // getDetails
    useEffect(() => {
        const fetchPhysicalStoreDetails = async () => {
            await axios.get(`web/backstage/physicalstore/${paramsGuid.storeGuid}`).then((res) => {
                // console.log(res.data.result);
                const data = res.data.result;

                setDisplayOrder(data.displayOrder);
                setCategory(data.category);
                setStoreName(data.name);
                setCountryCode(data.countryCode);
                setProvince(data.province);
                setPicture(data.picture);
                setAddress(data.address);
                setTelephone(data.telephone);
                setStatus(data.status);
                setRowStamp(data.rowStamp);

                setImageName(data.picture.split('/')[data.picture.split('/').length - 1]);
            });
        };

        if (Object.keys(paramsGuid).length) {
            fetchPhysicalStoreDetails();
        }
    }, [paramsGuid]);

    //#endregion

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id='section-main'>
                <form id='container-physicalStoreUpdateChange' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>銷售網點</h5>
                        <button
                            className='btnDelete btn btn-outline-danger px-4 ms-auto me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                            type='button'
                        >
                            刪除
                        </button>
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/physicalstoreupdate');
                            }}
                            type='button'
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4' type='submit'>
                            儲存
                        </button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <div className='d-flex'>
                            <div className='col-6'>
                                <h6 className='fw-bold mb-3'>檔案管理</h6>
                                <h6 className='fontSize75Rem mb-2'>顯示順序</h6>
                                <input
                                    type='number'
                                    id='displayOrder'
                                    name='displayOrder'
                                    max={10000}
                                    className='form-control mb-3'
                                    value={displayOrder || ''}
                                    onChange={(e) => setDisplayOrder(e.target.value)}
                                    required
                                />
                                <h6 className='fontSize75Rem mb-2'>型態</h6>
                                <select
                                    className='form-control mb-3'
                                    name='category'
                                    id='category'
                                    onChange={(e) => setCategory(e.target.value)}
                                    value={category || ''}
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>0：直營</option>
                                    <option value='1'>1：合約俱樂部</option>
                                </select>
                                <h6 className='fontSize75Rem mb-2'>銷售網點名稱</h6>
                                <input
                                    id='storeName'
                                    name='storeName'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={storeName}
                                    onChange={(e) => setStoreName(e.target.value)}
                                />
                                <div className='row'>
                                    <div className='col-5 pe-0'>
                                        <h6 className='fontSize75Rem mb-2'>國家</h6>
                                        <select
                                            className='form-control'
                                            name='countryCode'
                                            id='countryCode'
                                            onChange={(e) => setCountryCode(e.target.value)}
                                            value={countryCode || ''}
                                            required
                                        >
                                            <option value='' disabled>
                                                -- 請選擇 --
                                            </option>
                                            <option value='CN'>CN：中國</option>
                                            <option value='TW'>TW：台灣</option>
                                            <option value='HK'>HK：香港</option>
                                        </select>
                                    </div>
                                    <div className='col-7'>
                                        <h6 className='fontSize75Rem mb-2'>省市</h6>
                                        <input
                                            id='province'
                                            name='province'
                                            className='form-control mb-3 w-100'
                                            required
                                            value={province}
                                            placeholder='直接記錄省市的名字'
                                            onChange={(e) => setProvince(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <h6 className='fontSize75Rem mb-2'>地址</h6>
                                <input
                                    id='address'
                                    name='address'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <h6 className='fontSize75Rem mb-2'>電話</h6>
                                <input
                                    id='telephone'
                                    name='telephone'
                                    className='form-control mb-3 w-100'
                                    required
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                />
                                <h6 className='fontSize75Rem mb-3'>附加檔案 |</h6>
                                <h6 className='fontSize75Rem mb-2'>銷售網點封面縮圖</h6>
                                <div className='fileBox d-flex align-items-center mb-2 w-100'>
                                    <div className={`fileView d-flex bg-grey1 text-primary px-2 py-1 w-100 rounded-3`}>
                                        <span
                                            className={`fontSize75Rem ${
                                                picture === null && Object.keys(paramsGuid).length === 0 && 'd-none'
                                            }`}
                                        >
                                            {imageName ? imageName : '[guid].[檔案類型]'}
                                        </span>
                                    </div>
                                    <label
                                        htmlFor='file'
                                        style={{
                                            minWidth: '134px',
                                            height: '32px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        <div className='btn btn-primary text-white px-4 py-1'>
                                            <svg
                                                id='icn_Create'
                                                className='me-2 mb-1'
                                                viewBox='0 0 12 12'
                                                width='12'
                                                height='12'
                                                fill='#fff'
                                            >
                                                <defs> </defs>
                                                <path
                                                    className='cls-1'
                                                    id='_Color'
                                                    d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                    data-name=' ↳Color'
                                                ></path>
                                            </svg>
                                            <span>上傳圖片</span>
                                        </div>
                                        <input
                                            type='file'
                                            className='form-control d-none'
                                            id='file'
                                            name='file'
                                            onChange={(e) =>
                                                checkAndSetFile(e.target, ['.jpg', '.png']) && handleSetImage(e)
                                            }
                                        />
                                    </label>
                                </div>
                                <div className='guideline mb-3'>
                                    建議採用 16:9 的長寬比且大小在 2 MB 以內的圖片，並以 JPG或PNG 等圖片格式上傳。
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='imageSection'>
                                    <div className=''>
                                        <div className='imageCard'>
                                            <img src={picture} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className='fontSize75Rem mb-2'>銷售網點封面位址</h6>
                        <div className='fileView bg-grey1  px-2 py-1 w-80 rounded-3 mb-3'>
                            <span className='fontSize75Rem'>
                                {`storage/PhysicalStore/${imageName || '[檔案名稱]'}`}
                            </span>
                        </div>
                        <div className='col-6'>
                            <h6 className='fontSize75Rem mb-2'>狀態</h6>
                            <select
                                className='form-control'
                                name='status'
                                id='status'
                                onChange={(e) => setStatus(e.target.value)}
                                value={status || ''}
                                required
                            >
                                <option value='' disabled>
                                    -- 請選擇 --
                                </option>
                                <option value='0'>0：停用</option>
                                <option value='1'>1：啟用</option>
                            </select>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default RenderIndexList;
