import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { axiosApi } from '../../../api';

const ActivityUpdate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const activityDataTemp = {
        registrationKey: '0',
        listSchedule: '0',
        selectSchedule: '0',
        securityStamp: '0',
        status: '0',
    };

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    const [activityData, setActivityData] = useState(null);
    const [scheduleList, setScheduleList] = useState([]);

    const { activityGuid } = useParams('activityGuid');

    // warning
    const [nameWarning, setNameWarning] = useState('');
    const [frequencyWarning, setFrequencyWarning] = useState('');

    // --複製成功dialog --
    const successAlert = () => {
        Swal.fire({
            title: '複製成功',
            html: `<h6>宾乐达球谱复制成功!</h6>`,
            icon: 'success',
            confirmButtonText: '返回',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // setModalShow(false);
            }
        });
    };

    // -- 確定刪除活動 --
    const deleteActivityAlert = () => {
        Swal.fire({
            title: '確定要刪除此活動嗎?',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,

            // i18n
            confirmButtonText: '確定',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteActivity();
            }
        });
    };

    // 新增活動 POST
    const handleCreateActivity = () => {
        const newActivityData = { ...activityData };

        const { listSchedule, selectSchedule, securityStamp } = newActivityData;

        newActivityData.listSchedule = listSchedule === '1' ? true : false;
        newActivityData.selectSchedule = selectSchedule === '1' ? true : false;
        newActivityData.securityStamp = securityStamp === '1' ? true : false;

        axios
            .post(`web/ActRegistration/insertActivity`, newActivityData)
            .then((res) => navigate(-1))
            .catch((err) => console.log(err));
    };

    // 編輯活動 PUT
    const handleUpdateActivity = () => {
        const newActivityData = { ...activityData };

        const { listSchedule, selectSchedule, securityStamp } = newActivityData;

        console.log({ listSchedule, selectSchedule, securityStamp });

        newActivityData.listSchedule = listSchedule === '1' ? true : false;
        newActivityData.selectSchedule = selectSchedule === '1' ? true : false;
        newActivityData.securityStamp = securityStamp === '1' ? true : false;

        console.log(newActivityData);

        axios
            .put(`web/ActRegistration/updateActivity`, newActivityData)
            .then((res) => navigate(-1))
            .catch((err) => console.log(err));
    };

    // 刪除活動 POST
    const handleDeleteActivity = () => {
        axios
            .post(`web/ActRegistration/deleteActivity/${activityGuid}`)
            .then((res) => {
                alert('刪除成功!');
                navigate(-1);
            })
            .catch((err) => console.log(err));
    };

    const handleActivityDataChange = (e) => {
        setActivityData({
            ...activityData,
            [e.target.name]: e.target.value,
        });
    };

    // edit getInfo
    useEffect(() => {
        if (activityGuid) {
            axios.get(`/web/ActRegistration/getActivityDetails/${activityGuid}`).then((res) => {
                // console.log(res.data.activity);

                const newActivityData = {
                    ...activityDataTemp,
                    ...res.data.activity,
                };

                const { listSchedule, selectSchedule, securityStamp } = newActivityData;

                newActivityData.listSchedule = listSchedule ? '1' : '0';
                newActivityData.selectSchedule = selectSchedule ? '1' : '0';
                newActivityData.securityStamp = securityStamp ? '1' : '0';

                setActivityData(newActivityData);
            });

            axios.get(`/web/ActRegistration/getScheduleList?guid=${activityGuid}`).then((res) => {
                console.log(res.data.scheduleList);

                setScheduleList(res.data.scheduleList);
            });
        } else {
            setActivityData(activityDataTemp);
        }
    }, []);

    // 即時計算活動天數
    useEffect(() => {
        const startDateNoTime = dayjs(activityData?.startDate).format('YYYY-MM-DD');
        const endDateNoTime = dayjs(activityData?.endDate).format('YYYY-MM-DD');
        const diff = dayjs(endDateNoTime).diff(startDateNoTime, 'day');

        setActivityData((prev) => ({ ...prev, days: diff }));
    }, [activityData?.startDate, activityData?.endDate]);

    // console.log(location);
    // console.log(activityData);

    return (
        <>
            <section id='section-main'>
                <div id='container-activityUpdate'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>{location?.state?.activity?.name || ''}</h5>

                        {activityGuid ? (
                            <>
                                <button
                                    className='btn btn-outline-danger px-2 me-2 ms-auto'
                                    onClick={deleteActivityAlert}
                                >
                                    <svg
                                        id='icn_Trash'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                    >
                                        <defs>
                                            <clipPath id='clipPath'>
                                                <rect
                                                    id='Rectangle_3041'
                                                    data-name='Rectangle 3041'
                                                    width='13.263'
                                                    height='14'
                                                    fill='#ea4132'
                                                />
                                            </clipPath>
                                        </defs>
                                        <g id='Group_6120' data-name='Group 6120' transform='translate(1 1)'>
                                            <g id='Group_6119' data-name='Group 6119' clipPath='url(#clipPath)'>
                                                <path
                                                    id='Path_1388'
                                                    data-name='Path 1388'
                                                    d='M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z'
                                                    fill='#ea4132'
                                                    fillRule='evenodd'
                                                />
                                                <rect
                                                    id='Rectangle_3039'
                                                    data-name='Rectangle 3039'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(4.421 5.158)'
                                                    fill='#ea4132'
                                                />
                                                <rect
                                                    id='Rectangle_3040'
                                                    data-name='Rectangle 3040'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(7.368 5.158)'
                                                    fill='#ea4132'
                                                />
                                            </g>
                                        </g>
                                        <rect
                                            id='Rectangle_3042'
                                            data-name='Rectangle 3042'
                                            width='16'
                                            height='16'
                                            fill='none'
                                        />
                                    </svg>
                                </button>
                                <div
                                    className='me-2'
                                    style={{
                                        height: '32px',
                                        borderLeft: '1px solid #DADADA',
                                    }}
                                ></div>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    onClick={handleUpdateActivity}
                                >
                                    儲存
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                                    onClick={(e) => {
                                        console.log(location.state);
                                        e.preventDefault();
                                        navigate(`/userDrill/${location.state.userGuid}`, {
                                            state: {
                                                fullname: location.state.fullname,
                                            },
                                        });
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 '
                                    onClick={handleCreateActivity}
                                >
                                    儲存
                                </button>
                            </>
                        )}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>活動主檔</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    活動代碼
                                </label>
                                <input
                                    id='code'
                                    name='code'
                                    className='form-control mb-3'
                                    value={activityData?.code || ''}
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    活動名稱
                                </label>
                                <input
                                    id='name'
                                    name='name'
                                    className='form-control mb-3'
                                    value={activityData?.name || ''}
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    攤位編號
                                </label>
                                <input
                                    id='boothNumber'
                                    name='boothNumber'
                                    className='form-control mb-3'
                                    type='text'
                                    value={activityData?.boothNumber || ''}
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    攤位名稱
                                </label>
                                <input
                                    id='boothName'
                                    name='boothName'
                                    className='form-control mb-3'
                                    type='text'
                                    value={activityData?.boothName || ''}
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='gender' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='status'
                                    id='status'
                                    value={activityData?.status || ''}
                                    onChange={handleActivityDataChange}
                                    required
                                >
                                    <option value='0'>"0"：未启用</option>
                                    <option value='1'>"1"：启用中</option>
                                    <option value='2'>"2"：停用中</option>
                                    <option value='4'>"4"：刪除</option>
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    活動起始日期
                                </label>
                                <input
                                    id='startDate'
                                    name='startDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        activityData?.startDate
                                            ? dayjs(activityData?.startDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    活動結束日期
                                </label>
                                <input
                                    id='endDate'
                                    name='endDate'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        activityData?.endDate
                                            ? dayjs(activityData?.endDate).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    活動天數
                                </label>
                                <input
                                    id='days'
                                    name='days'
                                    className='form-control mb-3'
                                    type='number'
                                    value={activityData?.days || 0}
                                    onChange={handleActivityDataChange}
                                    disabled
                                />
                                <span className='text-danger'>{frequencyWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='registrationKey' className='fontSize75Rem mb-2'>
                                    註冊方式
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='registrationKey'
                                    id='registrationKey'
                                    value={activityData?.registrationKey || ''}
                                    onChange={handleActivityDataChange}
                                    required
                                >
                                    <option value='0'>"0"：電話號碼</option>
                                    <option value='1'>"1"：郵件信箱</option>
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='listSchedule' className='fontSize75Rem mb-2'>
                                    顯示場次
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='listSchedule'
                                    id='listSchedule'
                                    value={activityData?.listSchedule || ''}
                                    onChange={handleActivityDataChange}
                                    required
                                >
                                    <option value='0'>"0"：不顯示</option>
                                    <option value='1'>"1"：顯示</option>
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='securityStamp' className='fontSize75Rem mb-2'>
                                    報名發送驗證碼
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='securityStamp'
                                    id='securityStamp'
                                    value={activityData?.securityStamp || ''}
                                    onChange={handleActivityDataChange}
                                    required
                                >
                                    <option value='0'>"0"：不需要發送驗證碼</option>
                                    <option value='1'>"1"：要發送驗證碼</option>
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='playLimit' className='fontSize75Rem mb-2'>
                                    限制活動參與次數
                                </label>
                                <input
                                    id='playLimit'
                                    name='playLimit'
                                    type='number'
                                    className='form-control mb-3'
                                    value={activityData?.playLimit || ''}
                                    onChange={handleActivityDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='selectSchedule' className='fontSize75Rem mb-2'>
                                    選擇場次
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='selectSchedule'
                                    id='selectSchedule'
                                    value={activityData?.selectSchedule || ''}
                                    onChange={handleActivityDataChange}
                                    required
                                >
                                    <option value='0'>"0"：不能選擇</option>
                                    <option value='1'>"1"：可以選擇</option>
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col'>
                                <label htmlFor='familyMax' className='fontSize75Rem mb-2'>
                                    註記
                                </label>
                                <input
                                    id='note'
                                    name='note'
                                    className='form-control mb-3'
                                    value={activityData?.note || ''}
                                    onChange={handleActivityDataChange}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className='searchTableWrapper bg-white p-4 rounded-4'>
                        <div className='searchTableTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>活動日程</h6>
                            <button
                                className='btn btn-primary text-white ms-auto'
                                onClick={() =>
                                    navigate('/admin/actSchedule/create', {
                                        state: {
                                            action: 'create',
                                            activityGuid,
                                        },
                                    })
                                }
                            >
                                <svg
                                    id='icn_Create'
                                    className='me-2 mb-1'
                                    viewBox='0 0 12 12'
                                    width='12'
                                    height='12'
                                    fill='#fff'
                                >
                                    <defs> </defs>
                                    <path
                                        className='cls-1'
                                        id='_Color'
                                        d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                        data-name=' ↳Color'
                                    ></path>
                                </svg>
                                <span>新增日程</span>
                            </button>
                        </div>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            <th scope='col' className='text-grey4 border-0' style={{ width: '20%' }}>
                                                活動開始時間<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                活動結束時間<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0' style={{ width: '20%' }}>
                                                報名開始時間<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                報名結束時間<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                叫號起始號碼<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                叫號結束號碼<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                每次叫號級距 <span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                預估排隊等待時間（分鐘/人）
                                                <span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                實際參與人數<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                狀態<span></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {scheduleList.length !== 0 ? (
                                            scheduleList
                                                ?.filter((el) => el?.action !== 'DELETE')
                                                ?.map((list) => {
                                                    return (
                                                        <tr
                                                            id={list.scheduleGuid}
                                                            key={list.scheduleGuid}
                                                            onClick={() =>
                                                                navigate(`/admin/actSchedule/${list.scheduleGuid}`, {
                                                                    state: {
                                                                        action: 'update',
                                                                        activityGuid,
                                                                        activityName: activityData.name,
                                                                        scheduleGuid: list.scheduleGuid,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <td>
                                                                {dayjs(list.startDate).format('YYYY-MM-DD HH:mm:ss')}
                                                            </td>
                                                            <td>{dayjs(list.endDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td>
                                                                {dayjs(list.startDate_Registry).format(
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {dayjs(list.endDate_Registry).format(
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                )}
                                                            </td>
                                                            <td>{list.lineupStart}</td>
                                                            <td>{list.lineupEnd}</td>
                                                            <td>{list.interval}</td>
                                                            <td>{list.waitingTime}</td>
                                                            <td>{list.attendance}</td>
                                                            <td>{statusList[list.status]}</td>
                                                        </tr>
                                                    );
                                                })
                                        ) : (
                                            <tr>
                                                <td colSpan='10'>無活動日程</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ActivityUpdate;
