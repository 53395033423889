import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PictureSection from '../components/PictureSection';
import Counter from '../components/Counter';
import LargeRoundedButton from '../components/LargeRoundedButton';
import { Link, useNavigate } from 'react-router-dom';

// #region styled-components
const PaymentSection = styled.section`
    background-color: #fff;
    // height: 570px;
    width: 368px;
    padding: 16px;

    // border: 1px solid blue
`;

const UnitPrice = styled.div`
    font-size: 12px;
    color: gray;
`;

const Spot = styled.div`
    background: pink;
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
`;

const Line = styled.div`
    font-size: 14px;
`;

const FeatureWrapper = styled.div`
    margin-bottom: 16px;
`;

const PaymentSectionOptionTitle = styled.div`
    color: gray;
    margin-bottom: 8px;
    font-size: 14px;
`;

const FeatureOptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const FeatureOption = styled.button`
    background-color: white;
    color: black;
    padding: 8px 16px;
    margin-right: 8px;

    &.active {
        background-color: var(--primary);
    }
`;

const TotalPriceWrapper = styled.div`
    color: #989898;
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
`;

const SelectFromText = styled.div`
    cursor: pointer;
    font-size: 12px;
    width: 72px;
`;

const ContentSection = styled.div`
    width: 100%;
    min-height: 250px;
    border: 1px solid #dee2e6;
`;
//#endregion

// `Page3` 組件用於編輯銷售活動的第三步，包含商品預設照片和商品描述的預覽和編輯。

// 主要功能：
// - 提供商品預設照片的預覽和編輯區域，包括圖片上傳、商品名稱、價格顯示、商品描述等。
// - 提供商品描述的編輯區域，可以修改商品描述內容。
// - 提供選擇規格的功能，並根據所選規格顯示相應的商品預覽和價格。
// - 提供保存和預覽的按鈕，具體操作由父組件控制。
// - 利用 `PictureSection`、`PaymentSection`、`FeatureWrapper` 等組件進行畫面的構建。

// 注意：
// - 商品的編輯和保存邏輯未完全提供，可能需要進一步根據項目需求進行實現。
// - 使用了 `axios` 库進行數據的獲取和保存，確保該庫的正確引入。
// - `PictureSection`、`PaymentSection`、`FeatureWrapper` 等組件未提供，需確保其正確引入。
// - 表單控件的值和變更事件由 `handleSelectSpec`、`handleMinus`、`handlePlus` 函數管理。
// - 利用 `localStorage` 保存預覽數據，並通過 `window.open` 方法在新窗口中預覽。

// 使用方式：
// - 作為 `SalSalesKitUpdate` 組件的子組件之一，通過父組件的 `setData`、`setPage`、`navigate` 等函數進行數據管理和頁面控制。
// - 通過 `data` 狀態獲取商品主檔的當前信息，通過 `setSelectedSpec`、`setCount`、`setSalesKitSetAppendixList`、`setSalesKitSetData` 等函數實現信息編輯。
// - 通過 `handleMinus` 和 `handlePlus` 函數實現數量的增減。
// - 通過 `handleSelectSpec` 函數實現選擇規格的功能。
// - 通過 `handleSubmit` 函數實現商品信息的保存。
// - 通過 `handlePreview` 函數實現預覽，使用 `localStorage` 保存數據，並在新窗口中打開預覽頁面。

export default function Page3({ salesKitName, salesKitGuid, setPage, data, setData, page, page3Data }) {
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const totalPrice = `未有價格(未選規格)`;
    const linesList = data?.salesKit?.linesList || [];
    const [salesKitSetAppendixList, setSalesKitSetAppendixList] = useState([]);
    const [isAllSpecHaveValue, setIsAllSpecHaveValue] = useState(false);

    const [salesKitSetData, setSalesKitSetData] = useState({});

    const numberOfFeatures = data?.salesKit?.featureQuantity;
    const [selectedSpec, setSelectedSpec] = useState({});
    const [selectedSetGuid, setSelectedSetGuid] = useState(null);

    const handleMinus = () => {
        setCount((prev) => prev - 1);
    };

    const handlePlus = () => {
        setCount((prev) => prev + 1);
    };

    const handleSelectSpec = (specGuid, row) => {
        const targetSpecListName = `spec${row}List`;
        const targetSpecGuidName = `spec${row}Guid`;
        const newSpecList = data[targetSpecListName];

        newSpecList.forEach((spec) => {
            if (spec.specGuid === specGuid) {
                spec.selected = !spec.selected;
            } else {
                spec.selected = false;
            }
        });

        const newSpecGuid = selectedSpec[targetSpecGuidName] === specGuid ? null : specGuid;

        setSelectedSpec({
            ...selectedSpec,
            [targetSpecGuidName]: newSpecGuid,
        });

        setData({ ...data, [targetSpecListName]: newSpecList });
    };

    const handleGetSalesKitSetDetails = async (query) => {
        const response = await axios.get(`web/SalSalesKit/getSalesKitSetDetails?salesKitGuid=${salesKitGuid}&${query}`);

        const { pingtexSalesKitSet, pingtexSalesKitSetAppendixList, erpSalesKitSetPrice } = response.data;

        const list = pingtexSalesKitSetAppendixList.map((el) => {
            el.action = 'origin';
            const newEl = { ...el, ...selectedSpec };

            return newEl;
        });

        const originalAppendixList = salesKitSetData[pingtexSalesKitSet.kitSetGuid];

        setSelectedSetGuid(pingtexSalesKitSet.kitSetGuid);
        setSalesKitSetAppendixList(originalAppendixList ? originalAppendixList : list);

        const currency = erpSalesKitSetPrice ? erpSalesKitSetPrice[0]?.currencyCode : null;

        const unitPrice = erpSalesKitSetPrice ? erpSalesKitSetPrice[0]?.unitPrice : null;

        setSalesKitSetData({
            ...salesKitSetData,
            ...pingtexSalesKitSet,
            [pingtexSalesKitSet.kitSetGuid]: originalAppendixList ? originalAppendixList : list,

            erpSalesKitSetPrice,
            currency,
            unitPrice,
        });
    };

    useEffect(() => {
        let allSpecsHaveValues = true;
        let query = '';

        for (let i = 1; i <= numberOfFeatures; i++) {
            if (!selectedSpec[`spec${i}Guid`]) {
                allSpecsHaveValues = false;
                break;
            }

            query += `spec${i}Guid=${selectedSpec[`spec${i}Guid`]}&`;
        }

        if (allSpecsHaveValues) {
            handleGetSalesKitSetDetails(query.slice(0, -1));
            setIsAllSpecHaveValue(true);
        }
    }, [selectedSpec, numberOfFeatures]);

    useEffect(() => {
        const defaultSelectedSpec = {};

        if (data.spec1List && data.spec1List.length) {
            defaultSelectedSpec.spec1Guid = data.spec1List[0].specGuid;
        }
        if (data.spec2List && data.spec2List.length) {
            defaultSelectedSpec.spec2Guid = data.spec2List[0].specGuid;
        }
        if (data.spec3List && data.spec3List.length) {
            defaultSelectedSpec.spec3Guid = data.spec3List[0].specGuid;
        }

        setSelectedSpec(defaultSelectedSpec);
    }, []);

    const handleSubmit = () => {
        console.log(data);
        console.log(salesKitSetData);
        console.log(salesKitSetAppendixList);
        axios.put(`web/SalSalesKit/updateSalesKit`, {
            ...data,
            salesKitSetAppendixList: salesKitSetAppendixList,
        });
    };

    const handlePreview = () => {
        let allSpecsHaveValues = true;
        let query = '';

        for (let i = 1; i <= numberOfFeatures; i++) {
            if (!selectedSpec[`spec${i}Guid`]) {
                allSpecsHaveValues = false;
                break;
            }

            query += `&spec${i}Guid=${selectedSpec[`spec${i}Guid`]}`;
        }

        if (allSpecsHaveValues) {
            const json = JSON.stringify({
                data,
                salesKitGuid,
            });

            localStorage.setItem('salesKitPreviewData', json);
            // axios
            //     .get(
            //         `web/SalSalesKit/getSalesKitSetPreview?kitSetGuid=${selectedSetGuid}`
            //     )
            //     .then((res) => {
            //         console.log(res);
            //     });
        }

        // 获取屏幕的宽度和高度
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        // 打开新窗口的URL
        const url = '/SalSalesKit/preview';

        // 使用 window.open() 方法打开新窗口
        // 将窗口的宽度和高度设置为屏幕的宽度和高度
        window.open(url, '_blank', `width=${screenWidth},height=${screenHeight}`);
    };

    return (
        <section id='section-main'>
            <div id='container-activityUpdate'>
                <div className='d-flex'>
                    <h5 className='fw-bold mb-4'>商品組合照片 - {salesKitName}</h5>
                    <button
                        className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                    >
                        取消
                    </button>
                    {/* <button
                        type="button"
                        className="btn btn-primary text-white px-4 me-2"
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={() => setPage(2)}
                    >
                        上一步
                    </button> */}
                    <button
                        type='button'
                        className='btn btn-primary text-white px-4 me-2'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={handleSubmit}
                    >
                        儲存
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary text-white px-4'
                        style={{
                            height: '32px',
                            paddingBlock: 0,
                            fontSize: '14px',
                        }}
                        onClick={handlePreview}
                    >
                        預覽
                    </button>
                </div>

                <div className='infoWrapper p-4 rounded-4 mb-3'>
                    <div className='infoTop d-flex align-items-center mb-3 '>
                        <div className='d-flex w-100'>
                            <PictureSection
                                appendixList={selectedSetGuid ? salesKitSetData[selectedSetGuid] : []}
                                setAppendixList={setSalesKitSetAppendixList}
                                data={data}
                                setData={setData}
                                isAllSpecHaveValue={isAllSpecHaveValue}
                                selectedSpec={selectedSpec}
                                page={page}
                                salesKitSetData={salesKitSetData}
                                setSalesKitSetData={setSalesKitSetData}
                                selectedSetGuid={selectedSetGuid}
                            />
                            <PaymentSection>
                                <h3>{data?.salesKit?.name_01}</h3>
                                <UnitPrice>
                                    {salesKitSetData?.unitPrice
                                        ? `${salesKitSetData?.currency} ${salesKitSetData?.unitPrice}`
                                        : 0}
                                </UnitPrice>
                                <hr />
                                {linesList.map((line, index) => (
                                    <div className='d-flex align-items-center mb-2'>
                                        <Spot />
                                        <Line key={index}>{line}</Line>
                                    </div>
                                ))}
                                <hr />
                                {data?.featureList.map((feature, idx) => (
                                    <FeatureWrapper key={idx}>
                                        <PaymentSectionOptionTitle>{feature.name01}</PaymentSectionOptionTitle>
                                        <FeatureOptionsWrapper>
                                            {data[`spec${idx + 1}List`]?.map((spec) => (
                                                <FeatureOption
                                                    className={`btn btn-sm ${
                                                        spec.specGuid === selectedSpec[`spec${idx + 1}Guid`]
                                                            ? 'active'
                                                            : 'btn-outline-primary'
                                                    }`}
                                                    key={spec.specGuid}
                                                    id={spec.specGuid}
                                                    onClick={() => handleSelectSpec(spec.specGuid, idx + 1)}
                                                >
                                                    {spec.name01}
                                                </FeatureOption>
                                            ))}
                                        </FeatureOptionsWrapper>
                                    </FeatureWrapper>
                                ))}
                                <hr />
                                <PaymentSectionOptionTitle>數量</PaymentSectionOptionTitle>
                                <Counter
                                    count={count}
                                    handleMinus={handleMinus}
                                    handlePlus={handlePlus}
                                    disabled={false}
                                />
                                <TotalPriceWrapper>
                                    <div>訂單總額</div>
                                    <div className='text-primary'>
                                        {salesKitSetData?.unitPrice
                                            ? `${salesKitSetData?.currency} ${salesKitSetData?.unitPrice * count}`
                                            : 0}
                                    </div>
                                </TotalPriceWrapper>
                                <div className='d-flex align-items-center justify-content-around'>
                                    <LargeRoundedButton text='加入購物車' className='btn-outline-primary text-dark' />
                                    <LargeRoundedButton text='直接購買' className='btn-primary text-white' />
                                </div>
                            </PaymentSection>
                        </div>
                    </div>
                    <ContentSection>
                        <section
                            className='not-found-controller'
                            dangerouslySetInnerHTML={{
                                __html: data?.salesKit?.content,
                            }}
                        />
                    </ContentSection>
                </div>
            </div>
        </section>
    );
}
