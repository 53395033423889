import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { axiosApi } from '../../../api';
import { Page1, Page2, Page3 } from './pages';

// 商品分類更新和編輯組件 SalSalesKitUpdate

// 組件狀態管理
// - `page`: 控制頁面顯示的狀態，1、2、3 分別對應不同的編輯頁面。
// - `navigate` 和 `location`: 使用 react-router-dom 提供的 `useNavigate` 和 `useLocation` 來進行路由導航和獲取當前路由信息。
// - `salesKitGuid`: 通過 `useParams` 從路由參數中獲取的商品分類唯一標識。
// - `data`: 保存商品分類詳細信息的狀態，包括商品分類、附件、特徵和規格等。
// - `page1Data`、`page2Data`、`page3Data`: 保存不同頁面的商品分類詳細信息的狀態，用於實現頁面之間的切換。

// 提示信息狀態
// - `nameWarning`: 商品分類名稱的提示信息狀態。
// - `frequencyWarning`: 提示信息狀態。

// 成功提示和確認刪除提示函數
// - `successAlert`: 用於顯示複製成功的提示。
// - `deleteActivityAlert`: 用於顯示刪除確認的提示，確認後調用 `handleDeleteActivity` 函數刪除商品分類。

// 商品分類操作函數
// - `handleCreateActivity`: 用於新增商品分類的函數。
// - `handleUpdateActivity`: 用於編輯商品分類的函數，調用後返回上一頁。
// - `handleDeleteActivity`: 用於刪除商品分類的函數，調用後返回上一頁。
// - `handleSalesKitDataChange`: 用於監聽商品分類信息的變化。

// 商品分類信息獲取
// - `useEffect`: 使用 useEffect 在組件初次渲染時獲取商品分類的詳細信息，包括商品分類、附件、特徵和規格等。

// 頁面切換
// - 通過 `page` 狀態實現不同頁面的顯示，包括 `Page1`、`Page2` 和 `Page3` 組件。

// 注意：組件中的 `Page1`、`Page2` 和 `Page3` 組件的詳細信息未提供，需要參考這些組件的具體實現。

const SalSalesKitUpdate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { salesKitGuid } = useParams('salesKitGuid');
    const [page, setPage] = useState(1);
    const salesKitName = location?.state?.salesKit?.name01;

    const [data, setData] = useState(null);
    const [page1Data, setPage1Data] = useState(null);
    const [page2Data, setPage2Data] = useState(null);
    const [page3Data, setPage3Data] = useState(null);

    // warning
    const [nameWarning, setNameWarning] = useState('');
    const [frequencyWarning, setFrequencyWarning] = useState('');

    // --複製成功dialog --
    const successAlert = () => {
        Swal.fire({
            title: '複製成功',
            html: `<h6>宾乐达球谱复制成功!</h6>`,
            icon: 'success',
            confirmButtonText: '返回',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // setModalShow(false);
            }
        });
    };

    // -- 確定刪除銷售 --
    const deleteActivityAlert = () => {
        Swal.fire({
            title: '確定要刪除此銷售嗎?',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,

            // i18n
            confirmButtonText: '確定',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteActivity();
            }
        });
    };

    // 新增銷售 POST
    const handleCreateActivity = () => {
        if (!data?.status) {
            data.status = '0';
        }

        axios
            .post(`web/ActRegistration/insertActivity`, data)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
    };

    // 編輯銷售 PUT
    const handleUpdateActivity = () => {
        console.log(data);

        axios
            .put(`web/ActRegistration/updateActivity`, data)
            .then((res) => navigate(-1))
            .catch((err) => console.log(err));
    };

    // 刪除銷售 POST
    const handleDeleteActivity = () => {
        axios
            .post(`web/ActRegistration/deleteActivity/${salesKitGuid}`)
            .then((res) => {
                alert('刪除成功!');
                navigate(-1);
            })
            .catch((err) => console.log(err));
    };

    const handleSalesKitDataChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    // edit getInfo
    useEffect(() => {
        axios.get(`/web/SalSalesKit/getSalesKitDetails/${salesKitGuid}`).then((res) => {
            const {
                salesKit,
                salesKitClassList,
                salesKitAppendixStoreList,
                pingtexSalesKitAppendixList,
                featureList,
                spec1List,
                spec2List,
                spec3List,
            } = res.data;

            pingtexSalesKitAppendixList?.map((appendix) => (appendix.action = 'origin'));

            const linesList = (salesKit?.note || '').split('<br>');

            setData({
                salesKit: {
                    ...salesKit,
                    linesList,
                    originalStatus: salesKit.status,
                },
                salesKitClassList,
                salesKitAppendixStoreList,
                pingtexSalesKitAppendixList,
                featureList,
                spec1List,
                spec2List,
                spec3List,
            });

            setPage1Data({
                salesKit: {
                    ...salesKit,
                    linesList,
                    originalStatus: salesKit.status,
                },
                salesKitClassList,
                salesKitAppendixStoreList,
                pingtexSalesKitAppendixList,
                featureList,
                spec1List,
                spec2List,
                spec3List,
            });
            setPage2Data({
                salesKit: {
                    ...salesKit,
                    linesList,
                    originalStatus: salesKit.status,
                },
                salesKitClassList,
                salesKitAppendixStoreList,
                pingtexSalesKitAppendixList,
                featureList,
                spec1List,
                spec2List,
                spec3List,
            });
            setPage3Data({
                salesKit: {
                    ...salesKit,
                    linesList,
                    originalStatus: salesKit.status,
                },
                salesKitClassList,
                salesKitAppendixStoreList,
                pingtexSalesKitAppendixList,
                featureList,
                spec1List,
                spec2List,
                spec3List,
            });
        });
    }, []);

    // console.log(page);
    console.log(data);

    return (
        <>
            {page === 1 && (
                <Page1
                    salesKitName={salesKitName}
                    salesKitGuid={salesKitGuid}
                    setPage={setPage}
                    data={data}
                    setData={setData}
                    page={page}
                    page1Data={page1Data}
                />
            )}
            {page === 2 && (
                <Page2
                    salesKitName={salesKitName}
                    salesKitGuid={salesKitGuid}
                    setPage={setPage}
                    data={data}
                    setData={setData}
                    page={page}
                    page2Data={page2Data}
                />
            )}
            {page === 3 && (
                <Page3
                    salesKitName={salesKitName}
                    salesKitGuid={salesKitGuid}
                    setPage={setPage}
                    data={data}
                    setData={setData}
                    page={page}
                    page3Data={page3Data}
                />
            )}
        </>
    );
};

export default SalSalesKitUpdate;
