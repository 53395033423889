import './checkoutInfo.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';
import { SimpleLoading } from '../../../components/simpleLoading/SimpleLoading';
import { ReactComponent as Icn_CheckBlueSmBtn } from '../../../assets/images/icn_CheckBlueSmBtn.svg';
import { axiosApi } from '../../../api';
import { AccountContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const CheckoutInfo = () => {
    const { t } = useTranslation('checkoutInfo'); // i18n

    const navigate = useNavigate();
    const location = useLocation();
    const { lang } = useContext(AccountContext);

    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);
    const [isTheSameBuyerInfo, setIsTheSameBuyerInfo] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [isNeedToPayDeliveryFee, setIsNeedToPayDeliveryFee] = useState(false); //透過收件人地址的鄉鎮區判斷是否需要付運費;
    const [donateList, setDonateList] = useState([]);
    const [salesOrder, setSalesOrder] = useState({});

    const accountContext = useContext(AccountContext);
    const { accountInfo } = accountContext; // 使用者資訊

    const defaultData = {
        invoiceFormat: '0', // 預設為個人
        paymentMethod: '0', // 預設為信用卡付款
        deliveryMethod: '1', // 預設宅配到府

        // orderDate: new Date().toISOString().split('T')[0],
        lastname: accountInfo.lastname ?? '',
        firstname: accountInfo.firstname ?? '',
        countryCode: accountInfo.phone1CountryCode ?? '886', // 預設台灣
        phone: accountInfo.phone1 ?? '',
        email: accountInfo.email1 ?? '',

        recipientLastname: '',
        recipientFirstname: '',
        recipientCountryCode: '886', // 預設台灣
        recipientPhone: '',

        recipientCountry: '',

        recipientProvince: '', // guid
        recipientCity: '', // guid
        recipientDistrict: '', // guid

        recipientStreet: '',

        note: '',

        // invoiceFormat 1
        companyName: '',
        taxID: '',
        // invoiceFormat 2
        donateCode: '',
        // invoiceFormat 3
        carrierId: '',
        // paymentMethod 1
        bankAccount: '',
    };

    const [data, setData] = useState(defaultData);

    // console.log(location.state?.checkOrderData);

    const handleCityChange = async (e) => {
        setData((prev) => {
            return {
                ...prev,
                recipientCity: e.target.value,
                recipientCityWarning: '',
            };
        });

        setIsNeedToPayDeliveryFee(false); // 先清空

        await axiosApi(`/web/user/getDistrictList?cityGuid=${e.target.value}`, 'get').then((res) => {
            if (res.status === 200) {
                // console.log(res.data.result);

                setDistrictList(res.data.result);
                setData((prev) => {
                    return {
                        ...prev,
                        recipientDistrict: '',
                    };
                });
            }
        });
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const verifyRequired = () => {
        let newData = { ...data };
        let faultCount = 0;

        const requiredValue = [
            'orderDate',
            'lastname',
            'firstname',
            'phone',
            'email',

            'recipientLastname',
            'recipientFirstname',
            'recipientPhone',

            'deliveryMethod',
            'invoiceFormat',
            'paymentMethod',
        ];

        const addressRequired = ['recipientCountry', 'recipientCity', 'recipientDistrict', 'recipientStreet'];

        const invoiceRequired = {
            0: [],
            1: ['companyName', 'taxID'],
            2: ['donateCode'],
            3: ['carrierId'],
        };
        const paymentRequired = {
            0: [], // 信用卡
            1: ['bankAccount'], // ATM
            2: [], // 現金
            3: [], // 貨到付款
        };

        // 1.必填欄位
        requiredValue.forEach((key) => {
            if (newData[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 2.選擇宅配到府，則地址必填
        if (data['deliveryMethod'] === '1') {
            addressRequired.forEach((key) => {
                if (data[key] === '') {
                    newData = {
                        ...newData,
                        [`${key}Warning`]: t('please_fill_out_information'),
                    };
                }
            });
        }

        // 3.選擇發票，則其對應的欄位必填
        invoiceRequired[data['invoiceFormat']].forEach((key) => {
            if (data[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 3.選擇付款方式，則其對應的欄位必填
        paymentRequired[data['paymentMethod']].forEach((key) => {
            if (data[key] === '') {
                newData = {
                    ...newData,
                    [`${key}Warning`]: t('please_fill_out_information'),
                };
            }
        });

        // 5.驗證是否所有warning都為空
        Object.keys(newData).forEach((key) => {
            if (key.includes('Warning')) {
                if (newData[key] !== '') {
                    faultCount++;
                }
            }
        });

        setData(newData);

        return faultCount;
    };

    // 信用卡付款開新分頁(合庫)
    const handleCreditCardFormSubmit = async (orderGuid) => {
        // 從環境變數中獲取值
        const {
            NODE_ENV,
            REACT_APP_MER_ID,
            REACT_APP_MERCHANT_ID,
            REACT_APP_TERMINAL_ID,
            REACT_APP_MERCHANT_NAME,
            REACT_APP_CUSTOMIZE,
            REACT_APP_AUTO_CAP,
            REACT_APP_PAY_TYPE,
            REACT_APP_PERIOD_NUM,
            REACT_APP_SUB_MERCH_ID,
            REACT_APP_EN_CODE_TYPE,
            REACT_APP_TIMEOUT_SECS,
            REACT_APP_THREE_DS_AUTH_IND,
            REACT_APP_REQ_TOKEN,
            REACT_APP_LAG_SELECT,
            REACT_APP_AUTH_RES_URL_PROD,
            REACT_APP_AUTH_RES_URL_DEV,
            REACT_APP_FRONT_FAIL_URL_PROD,
            REACT_APP_FRONT_FAIL_URL_DEV,
        } = process.env;

        try {
            const res = await axios.get(`/web/payment/getCreditCardInfo/${orderGuid}`);
            // console.log(res.data.result);

            const orderInfo = res.data.result;

            // 填充表单数据
            const keysToFrom = {
                MerchantID: REACT_APP_MERCHANT_ID,
                MerchantName: REACT_APP_MERCHANT_NAME,
                customize: REACT_APP_CUSTOMIZE,
                AutoCap: REACT_APP_AUTO_CAP,
                TerminalID: REACT_APP_TERMINAL_ID,
                frontFailUrl: NODE_ENV === 'development' ? REACT_APP_FRONT_FAIL_URL_DEV : REACT_APP_FRONT_FAIL_URL_PROD,
                AuthResURL: NODE_ENV === 'development' ? REACT_APP_AUTH_RES_URL_DEV : REACT_APP_AUTH_RES_URL_PROD,
                merID: REACT_APP_MER_ID,
                PayType: REACT_APP_PAY_TYPE,
                PeriodNum: REACT_APP_PERIOD_NUM,
                subMerchID: REACT_APP_SUB_MERCH_ID,
                enCodeType: REACT_APP_EN_CODE_TYPE,
                timeoutSecs: REACT_APP_TIMEOUT_SECS,
                lagSelect: lang === 'zh-TW' ? 0 : lang === 'zh-CN' ? 1 : lang === 'en' ? 2 : 0,
                reqToken: REACT_APP_REQ_TOKEN,
                threeDSAuthInd: REACT_APP_THREE_DS_AUTH_IND,

                lidm: orderInfo.lidm,
                purchAmt: orderInfo.purchAmt,
                LocalDate: orderInfo.localDate,
                LocalTime: orderInfo.localTime,
                timeoutDate: orderInfo.timeoutDate,
                timeoutTime: orderInfo.timeoutTime,
                mobileNbr: orderInfo.mobileNbr,
                walletVerifyCode: orderInfo.walletVerifyCode,
            };

            // 構建查詢參數
            const queryParams = new URLSearchParams(keysToFrom).toString();

            // 重定向到目標頁面
            window.location.href = `https://www.focas.fisc.com.tw/FOCAS_WEBPOS/online/?${queryParams}`;
        } catch (error) {
            console.error('Error fetching credit card info:', error);
            alert('Error fetching credit card info:', error);
            // 這裡可以添加錯誤處理的額外邏輯，例如顯示錯誤訊息給用戶
        }
    };

    const handleSubmit = async () => {
        const faultCount = verifyRequired();
        // console.log('faultCount', faultCount);
        if (faultCount !== 0) return;

        setIsLoading(true);

        let newSalesOrderItemList = location.state.checkOrderData.map((item) => {
            return {
                CartGuid: item.cartGuid,
                SalesKitGuid: item.salesKitGuid,
                Quantity: item.quantity,
                PromoteGrossAmount: item.promoteGrossAmount,
            };
        });

        const newData = {
            couponCode: location.state.promoCode,
            OrderDate: new Date().toISOString().split('T')[0],
            Lastname: data.lastname,
            Firstname: data.firstname,
            CountryCode: data.countryCode,
            Phone: data.phone,
            Email: data.email,
            RecipientLastname: data.recipientLastname,
            RecipientFirstname: data.recipientFirstname,
            RecipientCountryCode: data.recipientCountryCode,
            RecipientPhone: data.recipientPhone,
            RecipientCountry: data.recipientCountry,
            RecipientProvince: data.recipientProvince,
            RecipientCity: data.recipientCity,
            RecipientDistrict: data.recipientDistrict,
            RecipientStreet: data.recipientStreet,
            PaymentMethod: data.paymentMethod,
            BankAccount: data.bankAccount,
            DeliveryMethod: data.deliveryMethod,
            InvoiceFormat: data.invoiceFormat,
            TaxID: data.taxID,
            CompanyName: data.companyName,
            CarrierId: data.carrierId,
            NPOBAN: data.donateCode,
            Note: data.note,
            SalesOrderItemList: newSalesOrderItemList,
        };

        try {
            const res = await axios.post(`web/salSalesOrder/insertSalesOrder`, newData);
            // console.log(res.data);
            setIsLoading(false);
            const orderGuid = res.data.orderGuid;

            // 錯誤操作(使用者已送出訂單，又回到此頁面，再次送出訂單)
            if (res.data.status === '700') {
                Swal.fire({
                    title: t('order_or_cart_changed'),
                    icon: 'warning',
                    text: t('order_or_cart_changed_description'),
                    allowOutsideClick: false,
                    confirmButtonText: t('view_order_status'),
                    confirmButtonColor: 'var(--primary)',
                    cancelButtonText: t('back_to_shopping_cart'),
                    cancelButtonColor: 'var(--secondary)',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/orders');
                    } else if (result.isDismissed) {
                        navigate('/cart');
                    }
                });
                return;
            }

            // 正常操作
            switch (newData.PaymentMethod) {
                case '0': // 信用卡
                    handleSendEmail(orderGuid);
                    handleCreditCardFormSubmit(orderGuid);
                    break;

                case '2': // ATM
                    handleSendEmail(orderGuid);
                    navigate(`/PdUnpaid/${orderGuid}`);
                    break;

                case '3': // 貨到付款
                    handleSendEmail(orderGuid);
                    navigate(`/PdCheckout/${orderGuid}`);
                    break;

                default:
                    break;
            }
        } catch (err) {
            setIsLoading(false);
            Swal.fire({
                title: t('order_or_cart_changed'),
                icon: 'warning',
                text: t('order_or_cart_changed_description'),
                allowOutsideClick: false,
                confirmButtonText: t('view_order_status'),
                confirmButtonColor: 'var(--primary)',
                cancelButtonText: t('back_to_shopping_cart'),
                cancelButtonColor: 'var(--secondary)',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/orders');
                } else if (result.isDismissed) {
                    navigate('/cart');
                }
            });
            console.log(err);
        }
    };

    // 訂單建立(未付款)時寄信給使用者與管理員
    const handleSendEmail = (orderGuid) => {
        const locale = 'zh-TW'; // 單一字串資料

        axios
            .post(`/web/SalSalesOrder/sendOrderConfirmation/${orderGuid}`, locale, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': lang,
                },
            })
            .then((res) => {
                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    //#endregion

    //#region [useEffect]

    // 使用者資料
    useEffect(() => {
        // 確保 accountInfo 有值
        if (accountInfo) {
            setData((prevData) => ({
                ...prevData,
                lastname: accountInfo.lastname,
                firstname: accountInfo.firstname,
                countryCode: accountInfo.phone1CountryCode ?? '886',
                phone: accountInfo.phone1,
                email: accountInfo.email1,
            }));
        }
    }, [accountInfo]); // 只有當 accountInfo 更新時才執行

    useEffect(() => {
        // 如果沒有傳入資料(非正規從cart頁面下一步進到此頁面)，就跳轉回購物車
        // console.log(location.state);
        if (location.state == null) {
            Swal.fire({
                title: t('please_return_to_shopping_cart'),
                icon: 'warning',
                allowOutsideClick: false,
                confirmButtonText: t('confirm'),
                confirmButtonColor: 'var(--primary)',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/cart');
                }
            });
        }

        const getCountryList = async () => {
            // 取國家 目前國家台灣為預設
            await axios.get(`web/user/getCountryList`).then((res) => {
                // console.log(res.data.result);
                // 排序讓台灣在前面
                let newList = res.data.result.sort((a, b) => {
                    return b.countryCode.localeCompare(a.countryCode);
                });
                setCountryList(newList); // 供電話使用

                setData({
                    ...data,
                    recipientCountry: 'TW',
                });
            });

            // 取省州 目前台灣省為預設
            const defaultProvinceGuid = await axios.get(`/web/user/getProvinceList?countryCode=TW`).then((res) => {
                // console.log(res.data);

                setData((pre) => {
                    return {
                        ...pre,
                        recipientProvince: res.data.result[0].provinceGuid,
                    };
                });

                return res.data.result[0].provinceGuid;
            });

            // 取縣市 (預設臺灣省的guid)
            await axios.get(`/web/user/getProvinceCityList?provinceGuid=${defaultProvinceGuid}`).then((res) => {
                // console.log(res.data);
                setCityList(res.data.result);
            });
        };

        const getDonateList = async () => {
            await axios.get(`/web/SalSalesOrder/getDonationCodes`).then((res) => {
                // console.log(res.data);
                setDonateList(res.data);
            });
        };

        getCountryList();
        getDonateList();
    }, []);

    //#endregion

    return (
        <>
            {/* loading animate */}
            <SimpleLoading isLoading={isLoading} />

            <div
                className='container-fluid position-relative p-0 text-center bg-background'
                id='container-checkoutInfo'
            >
                <div className='topTagArea'></div>

                <div className='mainArea wrapper mx-auto d-flex flex-column flex-lg-row px-3 px-sm-5 px-lg-0'>
                    {/* 使用者資訊區塊 */}
                    <section className='userInfoSection d-flex flex-column w-100 mb-0 mb-lg-0'>
                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-4 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('billing_information')}</h6>
                            </div>
                            <div className='infoBottom px-4'>
                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='lastname' className='w-100 text-start mb-2'>
                                            {t('last_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='lastname'
                                            name='lastname'
                                            className={`form-control ${data.lastnameWarning ? 'border-danger' : ''}`}
                                            value={data.lastname}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    lastname: e.target.value,
                                                    fullname: `${e.target.value}${data.firstname ?? ''}`,
                                                    lastnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.lastnameWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0'>
                                        <label htmlFor='firstname' className='w-100 text-start mb-2'>
                                            {t('first_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='firstname'
                                            name='firstname'
                                            className={`form-control ${data.firstnameWarning ? 'border-danger' : ''}`}
                                            value={data.firstname || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    firstname: e.target.value,
                                                    fullname: `${data.lastname ?? ''}${e.target.value}`,
                                                    firstnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.firstnameWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='countryCode' className='w-100 text-start mb-2'>
                                            {t('contact_number')}
                                        </label>
                                        <select
                                            className='form-select'
                                            value={data.countryCode}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    countryCode: e.target.value,
                                                })
                                            }
                                        >
                                            {countryList.map((item) => (
                                                <option key={item.telCode} value={item.telCode}>
                                                    {`${item.name_ZF}(+${item.telCode}) `}
                                                </option>
                                            ))}
                                        </select>

                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.countryCodeWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0 mt-2 mt-sm-0'>
                                        <label htmlFor='phone' className='w-100 text-start mb-2 invisibleItem'>
                                            phone
                                        </label>
                                        <input
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            className={`form-control ${data.phoneWarning ? 'border-danger' : ''}`}
                                            value={data.phone || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    phone: e.target.value,
                                                    phoneWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.phoneWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0'>
                                        <label htmlFor='email' className='w-100 text-start mb-2'>
                                            {t('email')}
                                        </label>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className={`form-control ${data.emailWarning ? 'border-danger' : ''}`}
                                            value={data.email || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    email: e.target.value,
                                                    emailWarning: '',
                                                })
                                            }
                                            onBlur={(e) => {
                                                if (e.target.value !== '' && !validateEmail(e.target.value)) {
                                                    setData({
                                                        ...data,
                                                        emailWarning: t('please_enter_valid_email'),
                                                    });
                                                }
                                            }}
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.emailWarning}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('shipping_information')}</h6>
                            </div>
                            <div className='infoBottom px-4'>
                                <div className='row'>
                                    <div className='col-12 ps-0'>
                                        <p className='mb-0 text-start'>{t('recipient_information')}</p>
                                        <div className='d-flex align-items-center mb-0'>
                                            <input
                                                type='checkbox'
                                                id='isTheSameBuyerInfo'
                                                name='isTheSameBuyerInfo'
                                                className='form-check mb-0'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                checked={isTheSameBuyerInfo}
                                                onChange={(e) => {
                                                    setIsTheSameBuyerInfo(e.target.checked);
                                                    e.target.checked
                                                        ? setData((prev) => {
                                                              return {
                                                                  ...prev,
                                                                  recipientLastname: data.lastname,
                                                                  recipientFirstname: data.firstname,
                                                                  recipientCountryCode: data.countryCode,
                                                                  recipientPhone: data.phone,
                                                                  recipientLastnameWarning: '',
                                                                  recipientFirstnameWarning: '',
                                                                  recipientPhoneWarning: '',
                                                              };
                                                          })
                                                        : setData((prev) => {
                                                              return {
                                                                  ...prev,
                                                                  recipientLastname: '',
                                                                  recipientFirstname: '',
                                                                  recipientCountryCode: data.countryCode,
                                                                  recipientPhone: '',
                                                                  recipientLastnameWarning: '',
                                                                  recipientFirstnameWarning: '',
                                                                  recipientPhoneWarning: '',
                                                              };
                                                          });
                                                }}
                                            />
                                            <label
                                                htmlFor='isTheSameBuyerInfo'
                                                className='text-start ms-2'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {t('same_as_billing_information')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label htmlFor='recipientLastname' className=' w-100 text-start mb-2'>
                                            {t('last_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='recipientLastname'
                                            name='recipientLastname'
                                            className={`form-control ${
                                                data.recipientLastnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={data.recipientLastname || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    recipientLastname: e.target.value,
                                                    recipientLastnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientLastnameWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0'>
                                        <label htmlFor='recipientFirstname' className=' w-100 text-start mb-2'>
                                            {t('first_name')}
                                        </label>
                                        <input
                                            type='text'
                                            id='recipientFirstname'
                                            name='recipientFirstname'
                                            className={`form-control ${
                                                data.recipientFirstnameWarning ? 'border-danger' : ''
                                            }`}
                                            value={data.recipientFirstname || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    recipientFirstname: e.target.value,
                                                    recipientFirstnameWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientFirstnameWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-2 mb-sm-8 mb-lg-4'>
                                    <div className='col-12 col-sm-6 col-lg-3 ps-0'>
                                        <label
                                            htmlFor='recipientCountryCode
'
                                            className=' w-100 text-start mb-2'
                                        >
                                            {t('contact_number')}
                                        </label>

                                        <select
                                            className='form-select'
                                            value={data.recipientCountryCode}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    recipientCountryCode: e.target.value,
                                                })
                                            }
                                        >
                                            {countryList.map((item) => (
                                                <option key={item.telCode} value={item.telCode}>
                                                    {`${item.name_ZF}(+${item.telCode}) `}
                                                </option>
                                            ))}
                                        </select>

                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientCountryCodeWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6 col-lg-5 ps-0 mt-2 mt-sm-0'>
                                        <label
                                            htmlFor='recipientPhone'
                                            className=' w-100 text-start invisibleItem mb-2'
                                        >
                                            recipientPhone
                                        </label>
                                        <input
                                            type='text'
                                            id='recipientPhone'
                                            name='recipientPhone'
                                            className={`form-control ${
                                                data.recipientPhoneWarning ? 'border-danger' : ''
                                            }`}
                                            value={data.recipientPhone || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    recipientPhone: e.target.value,
                                                    recipientPhoneWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientPhoneWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-column'>
                                    <p className='text-start mb-2 ps-0'>{t('shipping_method')}</p>
                                    <div className='row'>
                                        {/* <div className="col-6 col-lg-4 ps-0">
												<button
													className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 me-2 mb-3"
													style={{
														borderColor:
															data.deliveryMethod ===
															'0'
																? 'var(--primary)'
																: 'var(--grey2)',
													}}
												>
													<label
														htmlFor="pickOnSite"
														className="d-flex align-items-center px-2"
														style={{
															cursor: 'pointer',
														}}
													>
														現場領貨
														<input
															type="checkbox"
															id="pickOnSite"
															name="pickOnSite"
															className="form-check ms-2 mb-0"
															style={{
																cursor: 'pointer',
															}}
															checked={
																data.deliveryMethod ===
																'0'
															}
															onChange={(e) => {
																setData({
																	...data,
																	deliveryMethod:
																		'0',
																	recipientAddrWarning:
																		'',
																});
															}}
														/>
													</label>
												</button>
											</div> */}
                                        <div className='col-6 col-lg-4 ps-0'>
                                            <button
                                                className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-5'
                                                style={{
                                                    border:
                                                        data.deliveryMethod === '1'
                                                            ? '1px solid var(--primary)'
                                                            : '1px solid var(--grey2)',
                                                }}
                                            >
                                                <label
                                                    htmlFor='homeDelivery'
                                                    className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <span>{t('home_delivery')}</span>
                                                    <input
                                                        type='checkbox'
                                                        id='homeDelivery'
                                                        name='homeDelivery'
                                                        className='form-check ms-2 mb-0 d-none'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        checked={data.deliveryMethod === '1'}
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                deliveryMethod: '1',
                                                            });
                                                        }}
                                                    />
                                                    {data.deliveryMethod === '1' && <Icn_CheckBlueSmBtn />}
                                                </label>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-0 mb-lg-4'>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 國家 */}
                                        <select
                                            className={`form-select ${
                                                data.recipientCountryWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    return {
                                                        ...prev,
                                                        recipientCountry: e.target.value,
                                                        recipientCountryWarning: '',
                                                    };
                                                });
                                            }}
                                        >
                                            <option value='TW'>{t('taiwan')}</option>
                                        </select>
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientCountryWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-0 mb-lg-4'>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 選擇縣市 */}
                                        <select
                                            className={`form-select ${
                                                data.recipientCityWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => handleCityChange(e)}
                                        >
                                            <option value='' disabled>
                                                {t('please_select')}
                                            </option>
                                            {cityList?.map((item) => (
                                                <option key={item.cityGuid} value={item.cityGuid}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientCityWarning}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-4 ps-0'>
                                        {/* 選擇鄉鎮市區 */}
                                        <select
                                            className={`form-select ${
                                                data.recipientDistrictWarning ? 'border-danger' : ''
                                            }`}
                                            defaultValue={''}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    return {
                                                        ...prev,
                                                        recipientDistrict: e.target.value,
                                                        recipientDistrictWarning: '',
                                                    };
                                                });

                                                setIsNeedToPayDeliveryFee(
                                                    districtList?.find((item) => item.districtGuid === e.target.value)
                                                        .deliveryFee
                                                );
                                            }}
                                        >
                                            <option value=''>{t('please_select')}</option>
                                            {districtList?.map((item) => (
                                                <option key={item.districtGuid} value={item.districtGuid}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientDistrictWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0 mb-3 mb-sm-6 mb-lg-10'>
                                        <input
                                            type='address'
                                            id='recipientStreet'
                                            name='recipientStreet'
                                            className={`form-control ${
                                                data.recipientStreetWarning ? 'border-danger' : ''
                                            }`}
                                            value={data.recipientStreet || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    recipientStreet: e.target.value,
                                                    recipientStreetWarning: '',
                                                })
                                            }
                                        />
                                        <div className='warningText text-danger text-start mb-3'>
                                            {data.recipientStreetWarning}
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-8 ps-0'>
                                        <label htmlFor='note' className=' w-100 text-start mb-2'>
                                            {t('order_notes')}
                                        </label>
                                        <textarea
                                            className='form-control mb-3'
                                            id='note'
                                            name='note'
                                            rows='7'
                                            value={data.note}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    note: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('invoice_information')}</h6>
                            </div>
                            <div className='infoBottom px-4 pb-2'>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 mb-3'
                                            style={{
                                                border:
                                                    data.invoiceFormat === '0'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='personalInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('personal_e_invoice')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='personalInvoice'
                                                    name='personalInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={data.invoiceFormat === '0'}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            invoiceFormat: '0',

                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {data.invoiceFormat === '0' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3'
                                            style={{
                                                border:
                                                    data.invoiceFormat === '1'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='companyInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>
                                                    {t('company_e_invoice')}
                                                    <span className='smDisplayNone'>({t('vat_number')})</span>
                                                </span>

                                                <input
                                                    type='checkbox'
                                                    id='companyInvoice'
                                                    name='companyInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={data.invoiceFormat === '1'}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            invoiceFormat: '1',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {data.invoiceFormat === '1' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active p-0 mb-3'
                                            style={{
                                                border:
                                                    data.invoiceFormat === '2'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='donateInvoice'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('donation_invoice')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='donateInvoice'
                                                    name='donateInvoice'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={data.invoiceFormat === '2'}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            invoiceFormat: '2',
                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            carrierId: '',
                                                            carrierIdWarning: '',
                                                        });
                                                    }}
                                                />
                                                {data.invoiceFormat === '2' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    <div className='col-6 col-lg-4 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3'
                                            style={{
                                                border:
                                                    data.invoiceFormat === '3'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='mobileCarrier'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('mobile_barcode_carrier')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='mobileCarrier'
                                                    name='mobileCarrier'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={data.invoiceFormat === '3'}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            invoiceFormat: '3',
                                                            companyName: '',
                                                            companyNameWarning: '',
                                                            taxID: '',
                                                            taxIDWarning: '',
                                                            donateCode: '',
                                                            donateCodeWarning: '',
                                                        });
                                                    }}
                                                />
                                                {data.invoiceFormat === '3' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {data.invoiceFormat === '1' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='taxID' className='w-100 text-start mb-2'>
                                                <span>{t('vat_number')}</span>
                                            </label>
                                            <input
                                                type='text'
                                                id='taxID'
                                                name='taxID'
                                                className={`form-control ${data.taxIDWarning ? 'border-danger' : ''}`}
                                                value={data.taxID || ''}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        taxID: e.target.value,
                                                        taxIDWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {data.taxIDWarning}
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='companyName' className='w-100 text-start mb-2'>
                                                {t('company_name')}
                                            </label>
                                            <input
                                                type='text'
                                                id='companyName'
                                                name='companyName'
                                                className={`form-control ${
                                                    data.companyNameWarning ? 'border-danger' : ''
                                                }`}
                                                value={data.companyName || ''}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        companyName: e.target.value,
                                                        companyNameWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {data.companyNameWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : data.invoiceFormat === '2' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-8 ps-0'>
                                            <label htmlFor='donateCode' className='w-100 text-start mb-2'>
                                                <span>{t('donation_code')}</span>
                                            </label>

                                            <input
                                                type='text'
                                                className={`form-control w-100 ${
                                                    data.donateCodeWarning ? 'border-danger' : ''
                                                }`}
                                                list='donateCodeOptions' // 與 <datalist> 元素關聯
                                                placeholder={t('enter_text_to_search')}
                                                onChange={(e) => {
                                                    setData({
                                                        ...data,
                                                        donateCode: e.target.value.split(' ')[0],
                                                        donateCodeWarning: '',
                                                    });
                                                }}
                                            />

                                            {/* 資料清單 */}
                                            <datalist id='donateCodeOptions'>
                                                {/* 渲染選項 */}
                                                {donateList.map((item) => (
                                                    <option
                                                        key={item.donateCode}
                                                        value={item.donateCode + ' ' + item.donateNm}
                                                    ></option>
                                                ))}
                                            </datalist>

                                            <div className='warningText text-danger text-start mb-3'>
                                                {data.donateCodeWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : data.invoiceFormat === '3' ? (
                                <div className='infoBottom border-top px-4 pt-3'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='carrierId' className='w-100 text-start mb-2'>
                                                <span>{t('mobile_barcode_carrier')}</span>
                                            </label>
                                            <input
                                                type='text'
                                                id='carrierId'
                                                name='carrierId'
                                                className={`form-control ${
                                                    data.carrierIdWarning ? 'border-danger' : ''
                                                }`}
                                                value={data.carrierId || ''}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        carrierId: e.target.value,
                                                        carrierIdWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {data.carrierIdWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className='infoWrapper bg-white py-3 mb-4'>
                            <div className='infoTop d-flex align-items-center pb-3 mb-5 border-bottom border-grey'>
                                <h6 className='px-4 mb-0'>{t('payment_method')}</h6>
                            </div>
                            <div className='infoBottom px-4 pb-2'>
                                <div className='row'>
                                    <div className='col-6 col-lg-3 ps-0'>
                                        <button
                                            className='w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 me-0 me-lg-3'
                                            style={{
                                                border:
                                                    data.paymentMethod === '0'
                                                        ? '1px solid var(--primary)'
                                                        : '1px solid var(--grey2)',
                                            }}
                                        >
                                            <label
                                                htmlFor='paymentCreditCard'
                                                className='w-100 h-100 d-flex justify-content-between align-items-center'
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <span>{t('credit_card_payment')}</span>
                                                <input
                                                    type='checkbox'
                                                    id='paymentCreditCard'
                                                    name='paymentCreditCard'
                                                    className='form-check ms-2 mb-0 d-none'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    checked={data.paymentMethod === '0'}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            paymentMethod: '0',
                                                            bankAccount: '',
                                                            bankAccountWarning: '',
                                                        });
                                                    }}
                                                />
                                                {data.paymentMethod === '0' && <Icn_CheckBlueSmBtn />}
                                            </label>
                                        </button>
                                    </div>
                                    {/* <div className="col-6 col-lg-3 ps-0">
										<button
											className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3 me-0 me-lg-3"
											style={{
												border:
													data.paymentMethod === '1'
														? '1px solid var(--primary)'
														: '1px solid var(--grey2)',
											}}
										>
											<label
												htmlFor="atmTransfer"
												className="w-100 h-100 d-flex justify-content-between align-items-center"
												style={{
													cursor: 'pointer',
												}}
											>
												<span>ATM轉帳</span>
												<input
													type="checkbox"
													id="atmTransfer"
													name="atmTransfer"
													className="form-check ms-2 mb-0 d-none"
													style={{
														cursor: 'pointer',
													}}
													checked={
														data.paymentMethod ===
														'1'
													}
													onChange={(e) => {
														setData({
															...data,
															paymentMethod: '1',
														});
													}}
												/>
												{data.paymentMethod === '1' && (
													<Icn_CheckBlueSmBtn />
												)}
											</label>
										</button>
									</div>
									<div className="col-6 col-lg-3 ps-0">
										<button
											className="w-100 d-flex align-items-center bg-transparent border-1 rounded-3 formBtn active px-0 mb-3 me-0 me-lg-3"
											style={{
												border:
													data.paymentMethod === '3'
														? '1px solid var(--primary)'
														: '1px solid var(--grey2)',
											}}
										>
											<label
												htmlFor="cashOnDelivery"
												className="w-100 h-100 d-flex justify-content-between align-items-center"
												style={{
													cursor: 'pointer',
												}}
											>
												<span>貨到付款</span>
												<input
													type="checkbox"
													id="cashOnDelivery"
													name="cashOnDelivery"
													className="form-check ms-2 mb-0 d-none"
													style={{
														cursor: 'pointer',
													}}
													checked={
														data.paymentMethod ===
														'3'
													}
													onChange={(e) => {
														setData({
															...data,
															paymentMethod: '3',
															bankAccount: '',
															bankAccountWarning:
																'',
														});
													}}
												/>
												{data.paymentMethod === '3' && (
													<Icn_CheckBlueSmBtn />
												)}
											</label>
										</button>
									</div> */}
                                </div>
                            </div>

                            {data.paymentMethod === '1' && (
                                <div className='infoBottom px-4 pt-3 border-top'>
                                    <div className='row ps-0'>
                                        <div className='col-12 col-lg-4 ps-0'>
                                            <label htmlFor='bankAccount' className='w-100 text-start mb-2'>
                                                {t('last_five_digits_of_bank_account')}
                                            </label>
                                            <input
                                                type='text'
                                                id='bankAccount'
                                                name='bankAccount'
                                                className={`form-control ${
                                                    data.bankAccountWarning ? 'border-danger' : ''
                                                }`}
                                                placeholder={t('please_fill_in_last_five_digits')}
                                                value={data.bankAccount || ''}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        bankAccount: e.target.value,
                                                        bankAccountWarning: '',
                                                    })
                                                }
                                            />
                                            <div className='warningText text-danger text-start mb-3'>
                                                {data.bankAccountWarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>

                    {/* 訂單區塊 */}
                    {location.state && (
                        <section className='orderSection d-flex flex-column align-items-center bg-white mb-14 mb-lg-0'>
                            <p className='title w-100 text-start px-4 py-3 mb-0'>{t('order_information')}</p>
                            <div className='content w-100 px-4 pt-4 pb-4 py-lg-3'>
                                <div className='priceWrap d-flex flex-column align-items-baseline pb-4 pb-lg-3 mb-4'>
                                    <div className='w-100 d-flex justify-content-between mb-1'>
                                        <span>{t('subtotal')}：</span>
                                        <span>
                                            NT$
                                            {location.state.price.netPrice.toLocaleString()}
                                        </span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <span>{t('discount')}：</span>
                                        <span>NT${location.state.price.discount}</span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <span>{t('shipping_fee')}：</span>
                                        <span>
                                            NT$
                                            {isNeedToPayDeliveryFee
                                                ? location.state.price.deliveryFee.toLocaleString()
                                                : 0}
                                        </span>
                                    </div>
                                </div>

                                <div className='totalPriceWrap d-flex flex-column align-items-baseline mb-6'>
                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                        <span>{t('total_amount')}</span>
                                        <span className='orderTotalPrice'>
                                            NT$
                                            {isNeedToPayDeliveryFee
                                                ? (
                                                      location.state.price.netPrice -
                                                      location.state.price.discount +
                                                      location.state.price.deliveryFee
                                                  ).toLocaleString()
                                                : (
                                                      location.state.price.netPrice - location.state.price.discount
                                                  ).toLocaleString()}
                                        </span>
                                    </div>
                                </div>

                                <div className='btnWrap d-flex flex-column w-100'>
                                    <button
                                        className='btn btn-primary w-100 d-flex align-items-center justify-content-center text-white text-center border-0'
                                        onClick={() => handleSubmit()}
                                    >
                                        {t('confirm_order')}
                                    </button>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default CheckoutInfo;
