import React, { useState } from 'react';
import styled from 'styled-components';

// #region styled-components
const Ul = styled.ul`
    border-bottom: 0.1px solid #dee2e6;
    border-radius: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    @media (min-width: 578px) {
        flex: 1;
    }
`;

const TabNavLi = styled.li`
    cursor: pointer;
    height: 48px;
    padding: 0 20px;
    border: none;
    border-radius: 0 !important;
    border-bottom: ${(props) => (props.isSelected ? '3px solid var(--primary)' : '1px solid #dee2e6')};
    color: ${(props) => (props.isSelected ? 'var(--primary)' : '#616e83')};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: var(--primary);
        border-bottom: 3px solid var(--primary);
    }

    &.text-primaryNew {
        color: var(--primary) !important;
    }

    @media (min-width: 576px) {
        flex: 1;
    }
    @media (max-width: 576px) {
        width: auto;
    }
`;
// #endregion

const TabPage = ({ wrapperStyle, liColWidth, tabNavList, tabContent, defaultPage, t }) => {
    // 初始顯示頁面
    const [tabNavIndex, setTabNavIndex] = useState(defaultPage);

    return (
        <div className='row bg-white rounded-xxl pb-0 mx-0' style={{ ...wrapperStyle }}>
            <div className='col-12 px-0'>
                <Ul className='tabNav list-group row flex-row px-0 mx-0'>
                    {tabNavList?.map((el, index) => {
                        return (
                            <TabNavLi
                                key={index}
                                className='tabNav-item list-group-item text-center fontSize1Rem'
                                isSelected={tabNavIndex === index}
                                onClick={(e) => setTabNavIndex(index)}
                            >
                                {el}
                            </TabNavLi>
                        );
                    })}
                </Ul>

                <div className='tabContent'>{tabContent[tabNavIndex] || tabContent}</div>
            </div>
        </div>
    );
};

export default TabPage;
