import './OTAAudioUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import axios from 'axios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import dayjs from 'dayjs';

const OTAAudioUpdateDetail = () => {
    const navigate = useNavigate();

    const [audioVersion, setAudioVersion] = useState('');
    const [audioGuid, setAudioGuid] = useState('');
    const [audioFile, setAudioFile] = useState(null);
    const [developNote, setDevelopNote] = useState('');
    const [updateTime, setUpdateTime] = useState('');
    const [hyperLink, setHyperLink] = useState('');
    const [status, setStatus] = useState('');
    const [rowStamp, setRowStamp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [otaGuid, setOtaGuid] = useState('');
    const [otaType, setOtaType] = useState('');
    const [machineName, setMachineName] = useState('');
    const [type, setType] = useState('');
    const [otaCode, setOtaCode] = useState('');

    useEffect(() => {
        setOtaGuid(window.location.search.split('?')[1].split('&')[0].split('=')[1]);
        setOtaType(window.location.search.split('?')[1].split('&')[1].split('=')[1]);
        setMachineName(decodeURI(window.location.search.split('?')[1].split('&')[2].split('=')[1]));
        setType(window.location.search.split('?')[1].split('&')[3].split('=')[1]);

        setAudioGuid(window.location.search.split('?')[1]?.split('&')[4].split('=')[1]);
    }, [window.location.search]);

    useEffect(() => {
        const getOtaCode = async () => {
            await axios
                .get(`web/sys/ota/${otaGuid}`)
                .then((res) => {
                    setOtaCode(res.data.result.otaCode);
                })
                .catch((err) => console.log(err));
        };

        getOtaCode();
    }, [otaGuid]);
    // console.log(otaCode);

    let isChecked = false;
    const checkAudioVersionValid = async (otaGuid, version) => {
        await axios.get(`web/sys/checkAudioVersionValid?OtaGuid=${otaGuid}&AudioVersion=${version}`).then((res) => {
            if (res.data === false && version !== '') {
                Swal.fire({
                    icon: 'error',
                    title: `${version} 版本號重複，請重新輸入`,
                });
                setAudioVersion('');

                isChecked = false;
            } else {
                isChecked = true;
            }
        });
    };

    //edit getInfo
    useEffect(() => {
        if (type === 'edit')
            (async () => {
                await axios
                    .get(`web/sys/audio/${audioGuid}`)
                    .then((res) => {
                        const sysAudio = res.data.result;
                        console.log(sysAudio);

                        setAudioVersion(sysAudio.audioVersion);
                        setAudioGuid(sysAudio.audioGuid);
                        setHyperLink(sysAudio.hyperLink);
                        setDevelopNote(sysAudio.developNote);
                        setStatus(sysAudio.status);
                        setUpdateTime(sysAudio.updateTime);
                        setRowStamp(sysAudio.rowStamp);
                    })
                    .catch((err) => console.log(err));
            })();
    }, [audioGuid]);

    // delete
    const handleDelete = async (e) => {
        e.preventDefault();

        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed delete');

                axios
                    .delete(`web/sys/audio/${audioGuid}`)
                    .then(() => {
                        navigate(`/otaaudioupdate?otaGuid=${otaGuid}&otatype=audio&machineName=${machineName}`);
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData(e.target);
        form.append('otaGuid', otaGuid);

        if (type === 'edit') {
            //edit
            await axios
                .put(`web/sys/audio/${audioGuid}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    // 追蹤上傳進度
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            const percent = (progressEvent.loaded / progressEvent.total) * 100;
                            setProgress(Math.round(percent));
                        }
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    navigate(`/otaaudioupdate?otaGuid=${otaGuid}&otatype=audio&machineName=${machineName}`);
                })
                .catch((err) => console.log(err));
        } else if (type === 'create') {
            //create
            await checkAudioVersionValid(otaGuid, audioVersion);

            if (isChecked === false) {
                // audioVersion是否重複
            } else if (audioFile === null) {
                Swal.fire({
                    icon: 'info',
                    title: '請上傳apk檔案',
                });
            } else {
                setIsLoading(true);
                await axios
                    .post(`web/sys/audio`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        // 追蹤上傳進度
                        onUploadProgress: (progressEvent) => {
                            if (progressEvent.lengthComputable) {
                                const percent = (progressEvent.loaded / progressEvent.total) * 100;
                                setProgress(Math.round(percent));
                            }
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        setIsLoading(false);
                        navigate(`/otaaudioupdate?otaGuid=${otaGuid}&otatype=audio&machineName=${machineName}`);
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: '網路不穩，儲存失敗',
                            text: '請再次儲存。',
                        });
                        console.log(err);
                    });
            }
        }
    };

    return (
        <>
            <LoadingAnimate isLoading={isLoading} progress={progress} />

            <section id='section-main'>
                <form id='container-audioUpdateDetail' onSubmit={handleSubmit}>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>Audio</h5>
                        <button
                            className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${
                                type === 'create' && 'd-none'
                            }`}
                            onClick={(e) => {
                                handleDelete(e);
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className={`btnCancel btn btn-outline-primary px-4 me-2 ${type === 'create' && 'ms-auto'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/otaaudioupdate?otaGuid=${otaGuid}&otatype=audio&machineName=${machineName}`);
                            }}
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4'>儲存</button>
                    </div>
                    <div className='manageFileWrapper bg-white p-4 rounded-4 mb-3'>
                        <input id='rowStamp' name='rowStamp' className='d-none' defaultValue={rowStamp} />
                        <h6 className='fw-bold mb-3'>{machineName}</h6>
                        <div className='row'>
                            <div className='col-10'>
                                <label htmlFor='audioVersion' className='fontSize75Rem mb-2'>
                                    Audio版本号：[主版本號].[次版本號].[修訂版本號]
                                </label>
                                <input
                                    id='audioVersion'
                                    name='audioVersion'
                                    className='form-control mb-3'
                                    value={audioVersion || ''}
                                    onChange={(e) => setAudioVersion(e.target.value)}
                                    // onBlur={(e) =>
                                    // 	checkAudioVersionValid(e.target.value)
                                    // }
                                    required
                                    disabled={type === 'edit'}
                                />
                            </div>
                        </div>

                        {/* audio file */}
                        <div className='row'>
                            <h6 className='fontSize75Rem text-grey4 mb-2'>Audio语音档下载路径(URL)</h6>
                            <div className='fileBox d-flex align-items-center mb-3'>
                                <div
                                    className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 rounded-3`}
                                    style={{ width: '72%' }}
                                >
                                    <span
                                        className={`fontSize75Rem ${
                                            audioFile === null && type === 'create' && 'd-none'
                                        }`}
                                    >
                                        {hyperLink}
                                    </span>
                                </div>
                                <label htmlFor='audioFile'>
                                    <div
                                        className={`btn btn-primary text-white px-2 py-1 fontSize875Rem`}
                                        style={{
                                            width: '94px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <svg
                                            id='icn_Create'
                                            className='me-2 mb-1'
                                            viewBox='0 0 12 12'
                                            width='12'
                                            height='12'
                                            fill='#fff'
                                        >
                                            <defs> </defs>
                                            <path
                                                className='cls-1'
                                                id='_Color'
                                                d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                                data-name=' ↳Color'
                                            ></path>
                                        </svg>
                                        <span>上傳檔案</span>
                                    </div>
                                    <input
                                        type='file'
                                        className='form-control d-none'
                                        id='audioFile'
                                        name='audioFile'
                                        onChange={(e) => {
                                            if (checkAndSetFile(e.target, ['.bin'])) {
                                                setAudioFile(e.target.files[0]);
                                                setHyperLink(
                                                    `${window.location.protocol + '\\\\' + window.location.host}${
                                                        window.location.hostname === 'localhost' && '\\storage'
                                                    }\\download\\ota\\${otaCode}\\audio\\${audioVersion || '版本號'}\\${
                                                        e.target.files[0]?.name
                                                    }`
                                                );
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-10'>
                                <label htmlFor='developNote' className='fontSize75Rem text-grey4 mb-2'>
                                    开发人员注记
                                </label>
                                <textarea
                                    type='text'
                                    id='developNote'
                                    name='developNote'
                                    className='form-control'
                                    rows='4'
                                    value={developNote || ''}
                                    onChange={(e) => setDevelopNote(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 版本資訊 */}
                    <div className='infoWrapper bg-white p-4 rounded-4'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold mb-0'>版本資訊</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-5'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='status'
                                    id='status'
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status || ''}
                                    required
                                >
                                    <option value='' disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value='0'>停用</option>
                                    <option value='1'>啟用</option>
                                </select>
                                <label htmlFor='updateTime' className='fontSize75Rem mb-2'>
                                    创建/修改时间
                                </label>
                                <input
                                    type='datetime-local'
                                    id='updateTime'
                                    name='updateTime'
                                    className='form-control mb-3'
                                    value={dayjs(updateTime || new Date()).format('YYYY-MM-DD HH:mm:ss')}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default OTAAudioUpdateDetail;
