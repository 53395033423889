import './pdCreditCardFail.scss';
import { useTranslation } from 'react-i18next';

function PdCreditCardFail() {
    const { t } = useTranslation('pdCheckoutInfo'); // i18n

    return (
        <div
            className='container-fluid position-relative p-0 text-center bg-background'
            id='container-pdCreditCardFail'
        >
            <div className='mainArea position-fixed d-flex justify-content-center align-items-center'>
                {/* 錯誤訊息視窗 */}
                <div className='errDialog d-flex flex-column'>
                    <div className='topCol text-start px-4 py-3'>
                        <p className='mb-0'>{t('payment_processing')}</p>
                    </div>
                    <div className='bottomCol h-100 d-flex flex-column justify-content-between align-items-start pt-3 pt-sm-5 pb-3 pb-sm-4 px-4'>
                        <p className='text-start mb-0'>{t('sorry_unable_to_receive_payment_confirmation')}</p>
                        <div className='w-100 text-end'>
                            <button className='btn btn-primary'>{t('confirm')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PdCreditCardFail;
