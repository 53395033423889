import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useInitialLocation from './useInitialLocation';

import Layout from './components/layout/Layout';
import LayoutAccount from './components/layoutAccount/LayoutAccount';
import Homepage from './pages/home/homepage/Homepage';
import AccountDelete from './pages/userManage/accountDelete/AccountDelete';
import BaseInfo from './pages/userManage/baseInfo/BaseInfo';
import ForgotPwd from './pages/userManage/forgotPwd/ForgotPwd';
import Login from './pages/userManage/login/Login';
import PwdReset from './pages/userManage/pwdReset/PwdReset';
import Register from './pages/userManage/register/Register';
// import ProductHint from './pages/productManage/productHint/ProductHint';
import AppDownload from './pages/home/appDownload/AppDownload';
import ProductFeature from './pages/productCenter/productFeature/ProductFeature';
import ProductStat from './pages/productCenter/productStat/ProductStat';
import MyProduct from './pages/productManage/myProduct/MyProduct';
import ProductRegister from './pages/productManage/productRegister/ProductRegister';
import ProductVip from './pages/productManage/productVip/ProductVip';
import ProductVipSearch from './pages/productManage/productVipSearch/ProductVipSearch';

import ErrorPage from './components/errorPage/ErrorPage';
import MaintenancePage from './components/maintenancePage/MaintenancePage';
import NotFoundPage from './components/notFoundPage/NotFoundPage';
import About from './pages/about/About';
import Explore from './pages/explore/Explore';
import PhysicalStore from './pages/purchaseCenter/physicalStore/PhysicalStore';
import OnlineStore from './pages/purchaseCenter/onlineStore/OnlineStore';
import ProductDetail from './pages/purchaseCenter/productDetail/ProductDetail';
import Cart from './pages/purchaseCenter/cart/Cart';

import SupportContactUs from './pages/support/supportContactUs/SupportContactUs';
import SupportDownloadCenter from './pages/support/supportDownloadCenter/SupportDownloadCenter';
import SupportQuestion from './pages/support/supportQuestion/SupportQuestion';
import OTAAudioUpdate from './pages/sysManage/OTAAudioUpdate/OTAAudioUpdate';
import OTAAudioUpdateDetail from './pages/sysManage/OTAAudioUpdateDetail/OTAAudioUpdateDetail';
import OTAFirmwareUpdate from './pages/sysManage/OTAFirmwareUpdate/OTAFirmwareUpdate';
import OTAFirmwareUpdateDetail from './pages/sysManage/OTAFirmwareUpdateDetail/OTAFirmwareUpdateDetail';
import OTAMainUpdate from './pages/sysManage/OTAMainUpdate/OTAMainUpdate';
import OTAMainUpdateDetail from './pages/sysManage/OTAMainUpdateDetail/OTAMainUpdateDetail';
import AppMainUpdate from './pages/sysManage/appMainUpdate/AppMainUpdate';
import AppMainUpdateDetail from './pages/sysManage/appMainUpdateDetail/AppMainUpdateDetail';
import AppUpdate from './pages/sysManage/appUpdate/AppUpdate';
import AppUpdateDetail from './pages/sysManage/appUpdateDetail/AppUpdateDetail';
import AuthUpdate from './pages/sysManage/authUpdate/AuthUpdate';
import AuthUpdateEdit from './pages/sysManage/authUpdateEdit/AuthUpdateEdit';
import AuthUpdateGroupName from './pages/sysManage/authUpdateGroupName/AuthUpdateGroupName';
import AuthUpdateMemberCreate from './pages/sysManage/authUpdateMemberCreate/AuthUpdateMemberCreate';
import Bldc from './pages/sysManage/bldc/Bldc';
import Cms from './pages/sysManage/cms/Cms';
import DownloadCenterUpdate from './pages/sysManage/downloadCenterUpdate/DownloadCenterUpdate';
import DownloadCenterUpdateDetail from './pages/sysManage/downloadCenterUpdateDetail/DownloadCenterUpdateDetail';
import DrillCategoryUpdate from './pages/sysManage/drillCategoryUpdate/DrillCategoryUpdate';
import DrillCategoryUpdateDetail from './pages/sysManage/drillCategoryUpdateDetail/DrillCategoryUpdateDetail';
import ExploreUpdate from './pages/sysManage/exploreUpdate/ExploreUpdate';
import ExploreUpdateChange from './pages/sysManage/exploreUpdateChange/ExploreUpdateChange';
import Fan from './pages/sysManage/fan/Fan';
import Feedback from './pages/sysManage/feedback/feedback';
import FileUpdateLayout from './pages/sysManage/fileUpdateLayout/FileUpdateLayout';
import PhysicalStoreUpdate from './pages/sysManage/physicalStoreUpdate/PhysicalStoreUpdate';
import PhysicalStoreUpdateChange from './pages/sysManage/physicalStoreUpdateChange/PhysicalStoreUpdateChange';
import PresetDrill from './pages/sysManage/presetDrill/PresetDrill';
import PresetDrillCategoryItemUpdateDetail from './pages/sysManage/presetDrillCategoryItemUpdateDetail/PresetDrillCategoryItemUpdateDetail';
import PresetDrillCategoryUpdateDetail from './pages/sysManage/presetDrillCategoryUpdateDetail/PresetDrillCategoryUpdateDetail';
import PresetDrillUpdate from './pages/sysManage/presetDrillUpdate/PresetDrillUpdate';
import QuestionCategoryUpdate from './pages/sysManage/questionCategoryUpdate/QuestionCategoryUpdate';
import QuestionCategoryUpdateDetail from './pages/sysManage/questionCategoryUpdateDetail/QuestionCategoryUpdateDetail';
import QuestionUpdate from './pages/sysManage/questionUpdate/QuestionUpdate';
import QuestionUpdateDetail from './pages/sysManage/questionUpdateDetail/QuestionUpdateDetail';
import Stp from './pages/sysManage/stp/Stp';
import UserDrill from './pages/sysManage/userDrill/UserDrill';
import UserDrillAll from './pages/sysManage/userDrillAll/UserDrillAll';
import UserDrillItem from './pages/sysManage/userDrillItem/UserDrillItem';
import UsersUpdate from './pages/sysManage/usersUpdate/UsersUpdate';
import UsersUpdateDetail from './pages/sysManage/usersUpdateDetail/UsersUpdateDetail';

import FactoryRecord from './pages/sysManage/prdManage/factoryRecord/FactoryRecord';
import FactoryRecordChange from './pages/sysManage/prdManage/factoryRecordChange/FactoryRecordChange';
import FactoryRecordLog from './pages/sysManage/prdManage/factoryRecordLog/FactoryRecordLog';
import ProductionRecord from './pages/sysManage/prdManage/productionRecord/ProductionRecord';
import ProductionRecordChange from './pages/sysManage/prdManage/productionRecordChange/ProductionRecordChange';
import ProductionRecordLog from './pages/sysManage/prdManage/productionRecordLog/ProductionRecordLog';

import { NewsList } from './pages/newsManage/newsList/NewsList';
import { News } from './pages/newsManage/news/News';
import NewsUpdateChange from './pages/sysManage/newsUpdateChange/NewsUpdateChange';
import NewsUpdate from './pages/sysManage/newsUpdate/NewsUpdate';

import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { axiosApi } from './api';
import UserDrillClass from './pages/sysManage/userDrillClass/UserDrillClass';
import UserDrillClassItem from './pages/sysManage/userDrillClassItem/UserDrillClassItem';

import ActRegister from './pages/actManage/actRegister/ActRegister';
import ActRegisterInfoForUser from './pages/actManage/actRegisterInfoForUser/ActRegisterInfoForUser';

//活動主檔
import ActActivity from './pages/actManage/actActivity/ActActivity';
import ActivityUpdate from './pages/actManage/actActivity/ActivityUpdate';

// 活動日程
import ActSchedule from './pages/actManage/actSchedule/ActSchedule';
import ActScheduleUpdate from './pages/actManage/actSchedule/ActScheduleUpdate';

// 活動日程的取號
import ActCallingSystem from './pages/actManage/actActivity/ActCallingSystem';

// 活動日程的報名表
import ActScheduleRegistration from './pages/actManage/actScheduleRegistration/ActScheduleRegistration';

// PD信用卡結帳
import PdCreditCard from './pages/PdCreditCard/Index';

// PD結帳
import PdCheckout from './pages/PdCheckout/Index';

// PD未付提醒
import PdUnpaid from './pages/PdUnpaid/Index';

import {
    SalSalesKitClass,
    SalSalesKitClassUpdate,
    SalSalesKitCreate,
    SalSalesKit,
    SalSalesKitUpdate,
    SalSalesKitAppendix,
    SalSalesKitAppendixUpdate,
    SalSalesKitPreview,
    QuickOrderIndex,
    QuickOrderUpdate,
    Privacy,
    Privacy_en,
    Agreement,
    Agreement_en,
    OrderOverview,
    OrderDetails,
} from './pages';
import Payout from './pages/payment/payout/Payout';
import QueryTransaction from './pages/payment/queryTransaction/QueryTransaction';
import axios from 'axios';
import CheckoutInfo from './pages/purchaseCenter/cart/CheckoutInfo';
import PdCreditCardFail from './pages/purchaseCenter/cart/PdCreditCardFail';
import OrderIssueInvoice from './pages/sysManage/orderIssueInvoice/OrderIssueInvoice';
import OrderIssueInvoiceChange from './pages/sysManage/orderIssueInvoiceChange/OrderIssueInvoiceChange';

// 建立登入共用資料context
export const AccountContext = React.createContext();

function App() {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const [cookies, setCookie, removeCookie] = useCookies(['loginUser', 'accessToken', 'refreshToken', 'page']);
    const [accountInfo, setAccountInfo] = useState('');
    const [homePageIndex, setHomePageIndex] = useState(0);
    const [appUrInfo, setAppUInfo] = useState({
        androidUrl: '',
        iosUrl: 'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
    });
    const navigate = useNavigate();
    const location = useLocation();
    const initialLocation = useInitialLocation();

    // get prevUrl
    const prevUrl = localStorage.getItem('prevUrl');

    // set new prevUrl
    localStorage.setItem('prevUrl', window.location.href);
    // console.log(prevUrl, window.location.href);
    if (window.location.pathname === '/') {
        if (prevUrl !== window.location.href) {
            window.scrollTo(0, initialLocation);
        }
    } else {
        window.scrollTo(0, initialLocation);
    }

    const getAccessToken = () => {
        axiosApi(`web/user/refreshToken`, 'post', {}).then((res) => {});
    };

    // cookies loginUser
    useEffect(() => {
        if (!cookies.loginUser) {
            setCookie('loginUser', { status: null }, { path: '/' });
            setCookie('loginAuto', 'false', { path: '/' });
            removeCookie('accessToken');
        }
        const accessToken = getCookie('accessToken');
        const refreshToken = getCookie('refreshToken');

        if (!accessToken && refreshToken) {
            getAccessToken();
        }
    });

    const accessToken = getCookie('accessToken');
    const guid = accessToken && jwt_decode(accessToken).UserGuid ? jwt_decode(accessToken).UserGuid : '';

    // 共用資料context
    const getAccountInfo = useCallback(() => {
        try {
            axiosApi(`web/user?languageCode=ZF`).then((res) => {
                setAccountInfo(res.data);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // 取得 android apk -> 以生成 QR code
    const getAndroidApkUrl = useCallback(() => {
        try {
            axiosApi('web/sys/appdownloadurl?OSCode=1').then((res) => {
                const url = res.data.result;
                setAppUInfo({ ...appUrInfo, androidUrl: url });
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // TODO: 放 login / app ?
    const getUserPermission = useCallback(() => {
        try {
            axiosApi('web/sysAuthorization/userPermission').then((res) => {
                // console.log(res);
                // const url = res.data.result;
                // setAppUInfo({ ...appUrInfo, androidUrl: url });
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        guid && getAccountInfo();
        guid && getUserPermission();
        // getAndroidApkUrl();
    }, [guid, getAndroidApkUrl, getUserPermission, getAccountInfo]);

    // 多國語言

    const [lang, setLang] = useState('zh-TW');
    const { i18n } = useTranslation();

    // 在事件方法中使用i18n.changeLanguage()方法
    const toggleI18n = () => {
        // localStorageLocale = 'en'; // 暫時強制為正體中文

        let locale = 'zh';

        // console.log(lang);

        if (lang === 'zh-CN') {
            locale = 'zh-CN';
        } else if (lang === 'zh-TW') {
            locale = 'zh';
        } else {
            locale = 'en';
        }

        i18n.changeLanguage(locale);
    };

    useEffect(() => {
        toggleI18n();
    }, [lang]);

    useEffect(() => {
        let localStorageLocale = // cookies 儲存的先前設定語言
            localStorage.getItem('i18n') || navigator.language || navigator.browserLanguage || 'zh';

        setLang(localStorageLocale);
        // devAutoLogin();
    }, []);

    const devAutoLogin = async () => {
        const emailIDPost = {
            accountType: '0',
            emailOrID: 'swiftadmin',
            password: '123456',
        };

        const res = await axios.post('/web/user/ValidateLogin', emailIDPost);

        setCookie('loginUser', res.data, { path: '/' });

        // console.log(res.data);
        getAccountInfo();
        // console.log(res.data.userGuid);

        // 儲存權限
        axiosApi('web/SysAuthorization/userPermission').then((res) => {
            // 取得TransactionCode List
            //   console.log(res.data.result);

            setCookie('page', res.data.result, {
                path: '/',
            });
            //   console.log(cookies.page);
        });

        // navigate(`/PdUnpaid/8a9aaa59-4bea-ee11-a190-00155d070505`);
        // navigate(`/orders`);
    };

    return (
        <AccountContext.Provider
            value={{
                accountInfo,
                getAccountInfo,
                homePageIndex,
                setHomePageIndex,
                appUrInfo,
                lang,
                setLang,
            }}
        >
            {cookies.loginUser && cookies.loginUser.status === '0' ? (
                <Routes>
                    {/* 首頁 */}
                    <Route
                        path='/'
                        element={
                            <Layout>
                                <Homepage />
                            </Layout>
                        }
                    />
                    {/* PD 信用卡結帳   */}
                    <Route
                        path='/PdCreditCard/:orderGuid'
                        element={
                            <Layout>
                                <PdCreditCard />
                            </Layout>
                        }
                    />
                    {/* PD 信用卡結帳失敗   */}
                    <Route
                        path='/PdCreditCardFail'
                        element={
                            <Layout>
                                <PdCreditCardFail />
                            </Layout>
                        }
                    />
                    {/* PD 訂單   */}
                    <Route
                        path='/PdOrder'
                        element={
                            <Layout>
                                <CheckoutInfo />
                            </Layout>
                        }
                    />
                    {/* PD 結帳   */}
                    <Route
                        path='/PdCheckout/:orderGuid'
                        element={
                            <Layout>
                                <PdCheckout />
                            </Layout>
                        }
                    />
                    {/* PD 未付提醒   */}
                    <Route
                        path='/PdUnpaid/:orderGuid'
                        element={
                            <Layout>
                                <PdUnpaid />
                            </Layout>
                        }
                    />
                    {/* 快速下訂-列表 */}
                    {/* <Route
						path="/order/:orderGuid"
						element={
							<Layout>
								<QuickOrderUpdate />
							</Layout>
						}
					/> */}
                    {/* 快速下訂-新增/修改 */}
                    {/* <Route
						path="/order"
						element={
							<Layout>
								<QuickOrderIndex />
							</Layout>
						}
					/> */}
                    {/* 產品中心 */}
                    <Route
                        path='/productcenter/productfeature'
                        element={
                            <Layout>
                                <ProductFeature />
                            </Layout>
                        }
                    />
                    <Route
                        path='/productcenter/productstat'
                        element={
                            <Layout>
                                <ProductStat />
                            </Layout>
                        }
                    />
                    {/* 購買渠道 */}
                    <Route
                        path='/onlineStore'
                        element={
                            <Layout>
                                <OnlineStore />
                            </Layout>
                        }
                    />
                    <Route
                        path='/physicalstore'
                        element={
                            <Layout>
                                <PhysicalStore />
                            </Layout>
                        }
                    />
                    <Route
                        path='/products/:productGuid'
                        element={
                            <Layout>
                                <ProductDetail />
                            </Layout>
                        }
                    />
                    <Route
                        path='/cart'
                        element={
                            <Layout>
                                <Cart />
                            </Layout>
                        }
                    />
                    {/* 服務支持 */}
                    <Route
                        path='/support/question'
                        element={
                            <Layout>
                                <SupportQuestion />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/ttrobot'
                        element={
                            <Layout>
                                <SupportQuestion />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/downloadcenter'
                        element={
                            <Layout>
                                <SupportDownloadCenter />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/contactus'
                        element={
                            <Layout>
                                <SupportContactUs />
                            </Layout>
                        }
                    />
                    {/* 關於賓樂達 */}
                    <Route
                        path='/about'
                        element={
                            <Layout>
                                <About />
                            </Layout>
                        }
                    />
                    {/* 賓樂達APP下載 */}
                    <Route
                        path='/app/download'
                        element={
                            <Layout>
                                <AppDownload />
                            </Layout>
                        }
                    />
                    {/* 用戶協議 */} {/* APP*/}
                    <Route
                        path='/agreement'
                        element={
                            <Layout>
                                <Agreement />
                            </Layout>
                        }
                    />
                    {/* 用戶協議 */} {/* zh-TW */}
                    <Route
                        path='/agreement/zh-TW'
                        element={
                            <Layout>
                                <Agreement />
                            </Layout>
                        }
                    />
                    {/* 用戶協議 */} {/* en */}
                    <Route
                        path='/agreement/en'
                        element={
                            <Layout>
                                <Agreement />
                            </Layout>
                        }
                    />
                    InvoiceType
                    {/* 隱私政策 */} {/* APP */}
                    <Route
                        path='/privacy'
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    />
                    {/* 隱私政策 */} {/* zh-TW */}
                    <Route
                        path='/privacy/zh-TW'
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    />
                    {/* 隱私政策 */} {/* en */}
                    <Route
                        path='/privacy/en'
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    />
                    {/* 网上展示厅 */}
                    <Route
                        path='/explore'
                        element={
                            <Layout>
                                <Explore />
                            </Layout>
                        }
                    />
                    {/* 新聞動態-列表 */}
                    <Route
                        path='/news'
                        element={
                            <Layout>
                                <NewsList />
                            </Layout>
                        }
                    />
                    {/* 新聞動態-內容 */}
                    <Route
                        path='/news/:bulletinGuid'
                        element={
                            <Layout>
                                <News />
                            </Layout>
                        }
                    />
                    {/* 訂單總覽 */}
                    <Route
                        path='/orders'
                        element={
                            <LayoutAccount>
                                <OrderOverview />
                            </LayoutAccount>
                        }
                    />
                    {/* 訂單詳情 */}
                    <Route
                        path='/orderDetails/:orderGuid'
                        element={
                            <LayoutAccount>
                                <OrderDetails />
                            </LayoutAccount>
                        }
                    />
                    {/* 登入註冊 */}
                    <Route path='/login' element={<Navigate to='/' />} />
                    <Route path='/register' element={<Navigate to='/' />} />
                    <Route path='/forgotpwd' element={<Navigate to='/' />} />
                    {/* ------登入後頁面------ */}
                    {/* 個人頁面 */}
                    <Route
                        path='/baseInfo'
                        element={
                            <LayoutAccount>
                                <BaseInfo />
                            </LayoutAccount>
                        }
                    />
                    {/* 修改密碼 */}
                    <Route
                        path='/pwdreset'
                        element={
                            <LayoutAccount>
                                <PwdReset />
                            </LayoutAccount>
                        }
                    />
                    {/* 刪除帳號 */}
                    <Route
                        path='/accountDelete'
                        element={
                            <LayoutAccount>
                                <AccountDelete />
                            </LayoutAccount>
                        }
                    />
                    {/* 我的產品 */}
                    <Route
                        path='/product'
                        element={
                            <LayoutAccount>
                                <MyProduct />
                            </LayoutAccount>
                        }
                    />
                    <Route
                        path='/product/productregister'
                        element={
                            <LayoutAccount>
                                <ProductRegister />
                            </LayoutAccount>
                        }
                    />
                    <Route
                        path='/member/productvip'
                        element={
                            <LayoutAccount>
                                <ProductVip />
                            </LayoutAccount>
                        }
                    />
                    <Route
                        path='/member/productvipsearch'
                        element={
                            <LayoutAccount>
                                <ProductVipSearch />
                            </LayoutAccount>
                        }
                    />
                    {/* 活動註冊 */}
                    <Route
                        path='/activity/:activityGuid'
                        element={
                            <Layout>
                                <ActRegister />
                            </Layout>
                        }
                    />
                    {/* 活動註冊成功頁面 */}
                    <Route
                        path='/actRegisterInfoForUser/:registrationGuid'
                        element={
                            <Layout>
                                <ActRegisterInfoForUser />
                            </Layout>
                        }
                    />
                    {/* 活動註冊查詢頁面 */}
                    <Route
                        path='/getRegistrationList/:scheduleGuid'
                        element={
                            <Layout>
                                <ActRegisterInfoForUser />
                            </Layout>
                        }
                    />
                    {/* 叫號系統 後台 */}
                    <Route
                        path='/admin/activity/callingSystem/:scheduleGuid'
                        element={
                            <Layout>
                                <ActCallingSystem />
                            </Layout>
                        }
                    />
                    {/* 活動列表 後台 */}
                    {cookies.page?.includes('authActivity') && (
                        <Route
                            path='/admin/actActivity'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ActActivity />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 活動註冊 後台 - 新增 */}
                    {cookies.page?.includes('authActivity') && (
                        <Route
                            path='/admin/actActivity/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ActivityUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 活動註冊 後台 */}
                    {cookies.page?.includes('authActivity') && (
                        <Route
                            path='/admin/actActivity/:activityGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ActivityUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 活動日程列表 後台 */}
                    <Route
                        path='/admin/actSchedule'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <ActSchedule />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 活動日程註冊 後台 - 新增 */}
                    <Route
                        path='/admin/actSchedule/create'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <ActScheduleUpdate />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 活動日程註冊 後台 - 編輯 */}
                    <Route
                        path='/admin/actSchedule/:scheduleGuid'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <ActScheduleUpdate />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 活動日程的報名表 後台 */}
                    {cookies.page?.includes('authActivity') && (
                        <Route
                            path='/admin/actscheduleregistration/:scheduleGuid'
                            element={
                                <Layout>
                                    <ActScheduleRegistration />
                                </Layout>
                            }
                        />
                    )}
                    {/* 工廠與韌體 */}
                    <Route path='/bldc' element={<Bldc />} />
                    <Route path='/stp' element={<Stp />} />
                    <Route path='/fan' element={<Fan />} />
                    <Route path='/feedback' element={<Feedback />} />
                    {/* 後台入口 */}
                    <Route
                        path='/cms'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <Cms />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 系統管理 */}
                    {/* APP基本设定 */}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/appMainUpdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppMainUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/appMainUpdate/edit/:appGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppMainUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/appMainUpdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppMainUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* OTA基本设定 */}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/OTAMainUpdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAMainUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/OTAMainUpdate/edit/:otaGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAMainUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/OTAMainUpdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAMainUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 權限設定 */}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate/authUpdateGroupName/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdateGroupName />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate/authUpdateGroupName/edit/:authorizationGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdateGroupName />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate/memberPage/edit'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdateEdit />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate/authPage/edit/:authorizationGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdateEdit />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authSystem') && (
                        <Route
                            path='/authUpdate/memberPage/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AuthUpdateMemberCreate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* APP管理 */}
                    {cookies.page?.includes('authApp') && (
                        /* APP管理 */
                        <Route
                            path='/appupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authApp') && (
                        <Route
                            path='/appupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authApp') && (
                        <Route
                            path='/appupdate/edit'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <AppUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* OTA管理 */}
                    {cookies.page?.includes('authOta') && (
                        /* OTA管理 - Firmware */
                        <Route
                            path='/otafirmwareupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAFirmwareUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authOta') && (
                        <Route
                            path='/otafirmwareupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAFirmwareUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authOta') && (
                        <Route
                            path='/otafirmwareupdate/edit'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAFirmwareUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* OTA管理 - Audio */}
                    {cookies.page?.includes('authOta') && (
                        <Route
                            path='/otaaudioupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAAudioUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authOta') && (
                        <Route
                            path='/otaaudioupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAAudioUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authOta') && (
                        <Route
                            path='/otaaudioupdate/edit'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OTAAudioUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 用户管理 */}
                    {cookies.page?.includes('authUser') && (
                        <Route
                            path='/usersUpdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UsersUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authUser') && (
                        <Route
                            path='/usersUpdate/edit/:userGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UsersUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 賓樂達球譜管理 */}
                    {/* 用戶球譜 */}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrill'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillAll />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrill/:userGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrill />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillItem/:userDrillGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillItem />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillItem/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillItem />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 用戶球譜分類 */}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillClass'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillAll />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillClass/:userGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillClass />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillClassItem/:classGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillClassItem />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/userDrillClassItem/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <UserDrillClassItem />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 賓樂達球譜 */}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrill />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill/drillCategory/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrillCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill/drillCategory/edit/:categoryGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrillCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill/drillCategoryItem/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrillCategoryItemUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill/drillCategoryItem/edit/:categoryItemGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrillCategoryItemUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/presetDrill/PresetDrillUpdate/edit/:presetDrillGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PresetDrillUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 球譜管理-宾乐达球谱型态 */}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/drillCategoryUpdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DrillCategoryUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/drillCategoryUpdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DrillCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authPresetDrill') && (
                        <Route
                            path='/drillCategoryUpdate/edit/:categoryCode'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DrillCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* authWeb */}
                    {/* 後台管理-网上展示厅 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/exploreupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ExploreUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/exploreupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ExploreUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/exploreupdate/edit/:videoGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ExploreUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 後台管理-新聞動態 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/newsupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <NewsUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/newsupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <NewsUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/newsupdate/edit/:bulletinGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <NewsUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 後台管理-销售网点 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/physicalstoreupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PhysicalStoreUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/physicalstoreupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PhysicalStoreUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/physicalstoreupdate/edit/:storeGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <PhysicalStoreUpdateChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 後台管理-下载中心 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/downloadcenterupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DownloadCenterUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/downloadcenterupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DownloadCenterUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/downloadcenterupdate/edit/:manualGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <DownloadCenterUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 後台管理-常见问题 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questionupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questionupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questionupdate/edit/:qaGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 後台管理-常见问题型态 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questioncategoryupdate'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionCategoryUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questioncategoryupdate/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/questioncategoryupdate/edit/:categoryCode'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <QuestionCategoryUpdateDetail />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* authWeb */}
                    {/* 發球機生管 */}
                    {/* TODO:authWeb */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/productionRecord'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ProductionRecord />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/productionRecord/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ProductionRecordChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/productionRecord/edit/:productionRecordGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ProductionRecordChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/productionRecord/log/:productionRecordGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <ProductionRecordLog />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 發球機生管 */}
                    {/* 出廠記錄 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/factoryRecord'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <FactoryRecord />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/factoryRecord/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <FactoryRecordChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/factoryRecord/edit/:factoryRecordGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <FactoryRecordChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/factoryRecord/log/:factoryRecordGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <FactoryRecordLog />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 商品管理 */}
                    {/* 商品分類 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKitClass'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitClass />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKitClass/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitClassUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 新增 商品列表*/}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKit/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitCreate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 編輯 商品列表*/}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKit'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKit />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 預覽 商品*/}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKit/preview'
                            element={
                                <Layout>
                                    <SalSalesKitPreview />
                                </Layout>
                            }
                        />
                    )}
                    {/* 編輯 商品列表*/}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKit/:salesKitGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKitClass/:salesKitClassGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitClassUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKitAppendix'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitAppendix />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/SalSalesKitAppendix/:salesKitGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <SalSalesKitAppendixUpdate />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 訂單管理 */}
                    {/* 訂單開發票 */}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/orderIssueInvoice'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OrderIssueInvoice />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/orderIssueInvoice/create'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OrderIssueInvoiceChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {cookies.page?.includes('authWeb') && (
                        <Route
                            path='/orderIssueInvoice/edit/:orderGuid'
                            element={
                                <Layout>
                                    <FileUpdateLayout>
                                        <OrderIssueInvoiceChange />
                                    </FileUpdateLayout>
                                </Layout>
                            }
                        />
                    )}
                    {/* 錯誤提示網頁  */}
                    <Route
                        path='/error'
                        element={
                            <Layout>
                                <ErrorPage />
                            </Layout>
                        }
                    />
                    {/* 維護中  */}
                    <Route
                        path='/maintenance'
                        element={
                            <Layout>
                                <MaintenancePage />
                            </Layout>
                        }
                    />
                    {/* 找不到網頁  */}
                    <Route
                        path='*'
                        element={
                            <Layout>
                                <NotFoundPage />
                            </Layout>
                        }
                    />
                </Routes>
            ) : (
                <Routes>
                    {/* 金流測試 */}
                    {/* <Route
						path="/payout"
						element={
							<Layout>
								<Payout />
							</Layout>
						}
					/> */}
                    {/* <Route
						path="/queryTransaction"
						element={
							<Layout>
								<QueryTransaction />
							</Layout>
						}
					/> */}
                    {/* 快速下訂-新增/修改 */}
                    {/* <Route
						path="/order/create"
						element={
							<Layout>
								<QuickOrderUpdate />
							</Layout>
						}
					/>
					<Route
						path="/order/:orderGuid"
						element={
							<Layout>
								<QuickOrderUpdate />
							</Layout>
						}
					/> */}
                    {/* 快速下訂-列表 */}
                    {/* <Route
						path="/order"
						element={
							<Layout>
								<QuickOrderIndex />
							</Layout>
						}
					/> */}
                    {/* 刪除帳號 */}
                    <Route
                        path='/accountDelete'
                        element={
                            <LayoutAccount>
                                <AccountDelete />
                            </LayoutAccount>
                        }
                    />
                    {/* 首頁 */}
                    <Route
                        path='/'
                        element={
                            <Layout>
                                <Homepage />
                            </Layout>
                        }
                    />
                    {/* 產品中心 */}
                    <Route
                        path='/productcenter/productfeature'
                        element={
                            <Layout>
                                <ProductFeature />
                            </Layout>
                        }
                    />
                    <Route
                        path='/productcenter/productstat'
                        element={
                            <Layout>
                                <ProductStat />
                            </Layout>
                        }
                    />
                    {/* 購買渠道 */}
                    <Route
                        path='/onlineStore'
                        element={
                            <Layout>
                                <OnlineStore />
                            </Layout>
                        }
                    />
                    <Route
                        path='/physicalstore'
                        element={
                            <Layout>
                                <PhysicalStore />
                            </Layout>
                        }
                    />
                    <Route
                        path='/products/:productGuid'
                        element={
                            <Layout>
                                <ProductDetail />
                            </Layout>
                        }
                    />
                    <Route
                        path='/cart'
                        element={
                            <Layout>
                                <Cart />
                            </Layout>
                        }
                    />
                    {/* 服務支持 */}
                    <Route
                        path='/support/question'
                        element={
                            <Layout>
                                <SupportQuestion />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/ttrobot'
                        element={
                            <Layout>
                                <SupportQuestion />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/downloadcenter'
                        element={
                            <Layout>
                                <SupportDownloadCenter />
                            </Layout>
                        }
                    />
                    <Route
                        path='/support/contactus'
                        element={
                            <Layout>
                                <SupportContactUs />
                            </Layout>
                        }
                    />
                    {/* 關於賓樂達 */}
                    <Route
                        path='/about'
                        element={
                            <Layout>
                                <About />
                            </Layout>
                        }
                    />
                    {/* 賓樂達APP下載 */}
                    <Route
                        path='/app/download'
                        element={
                            <Layout>
                                <AppDownload />
                            </Layout>
                        }
                    />
                    {/* 登入註冊 */}
                    <Route
                        path='/login'
                        element={
                            <Layout>
                                <Login />
                            </Layout>
                        }
                    />
                    <Route
                        path='/register'
                        element={
                            <Layout>
                                <Register />
                            </Layout>
                        }
                    />
                    <Route
                        path='/forgotpwd'
                        element={
                            <Layout>
                                <ForgotPwd />
                            </Layout>
                        }
                    />
                    {/* 工廠與韌體 */}
                    <Route path='/bldc' element={<Bldc />} />
                    <Route path='/stp' element={<Stp />} />
                    <Route path='/fan' element={<Fan />} />
                    <Route path='/feedback' element={<Feedback />} />
                    {/* 用戶協議 */} {/* APP */}
                    <Route
                        path='/agreement'
                        element={
                            <Layout>
                                <Agreement />
                            </Layout>
                        }
                    />
                    {/* 用戶協議 */} {/* zh-TW */}
                    <Route
                        path='/agreement/zh-TW'
                        element={
                            <Layout>
                                <Agreement />
                            </Layout>
                        }
                    />
                    {/* 用戶協議 */} {/* en */}
                    <Route
                        path='/agreement/en'
                        element={
                            <Layout>
                                <Agreement_en />
                            </Layout>
                        }
                    />
                    {/* 隱私政策 */} {/* APP */}
                    <Route
                        path='/privacy'
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    />
                    {/* 隱私政策 */} {/* zh-TW */}
                    <Route
                        path='/privacy/zh-TW'
                        element={
                            <Layout>
                                <Privacy />
                            </Layout>
                        }
                    />
                    {/* 隱私政策 */} {/* en */}
                    <Route
                        path='/privacy/en'
                        element={
                            <Layout>
                                <Privacy_en />
                            </Layout>
                        }
                    />
                    {/* 网上展示厅 */}
                    <Route
                        path='/explore'
                        element={
                            <Layout>
                                <Explore />
                            </Layout>
                        }
                    />
                    {/* 新聞動態-列表 */}
                    <Route
                        path='/news'
                        element={
                            <Layout>
                                <NewsList />
                            </Layout>
                        }
                    />
                    {/* 新聞動態-內容 */}
                    <Route
                        path='/news/:bulletinGuid'
                        element={
                            <Layout>
                                <News />
                            </Layout>
                        }
                    />
                    {/* 活動註冊 */}
                    <Route
                        path='/activity/:activityGuid'
                        element={
                            <Layout>
                                <ActRegister />
                            </Layout>
                        }
                    />
                    {/* 活動註冊成功頁面 */}
                    <Route
                        path='/actRegisterInfoForUser/:registrationGuid'
                        element={
                            <Layout>
                                <ActRegisterInfoForUser />
                            </Layout>
                        }
                    />
                    {/* 活動註冊查詢頁面 */}
                    <Route
                        path='/getRegistrationList/:scheduleGuid'
                        element={
                            <Layout>
                                <ActRegisterInfoForUser />
                            </Layout>
                        }
                    />
                    {/* 叫號系統 後台 */}
                    <Route
                        path='/admin/activity/callingSystem/:scheduleGuid'
                        element={
                            <Layout>
                                <ActCallingSystem />
                            </Layout>
                        }
                    />
                    {/* 活動列表 後台 */}
                    <Route
                        path='/admin/actActivity'
                        element={
                            <Layout>
                                <ActActivity />
                            </Layout>
                        }
                    />
                    {/* 活動註冊 後台 - 新增 */}
                    <Route
                        path='/admin/actActivity/create'
                        element={
                            <Layout>
                                <ActivityUpdate />
                            </Layout>
                        }
                    />
                    {/* 活動註冊 後台 - 編輯 */}
                    <Route
                        path='/admin/actActivity/:activityGuid'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <ActivityUpdate />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 活動日程列表 後台 */}
                    <Route
                        path='/admin/actSchedule'
                        element={
                            <Layout>
                                <ActSchedule />
                            </Layout>
                        }
                    />
                    {/* 活動日程註冊 後台 - 新增 */}
                    <Route
                        path='/admin/actSchedule/create'
                        element={
                            <Layout>
                                <ActScheduleUpdate />
                            </Layout>
                        }
                    />
                    {/* 活動日程註冊 後台 - 編輯 */}
                    <Route
                        path='/admin/actSchedule/:scheduleGuid'
                        element={
                            <Layout>
                                <FileUpdateLayout>
                                    <ActScheduleUpdate />
                                </FileUpdateLayout>
                            </Layout>
                        }
                    />
                    {/* 活動使用者主檔 後台 */}
                    {/* <Route
						path="/admin/actRegistration"
						element={
							<Layout>
								<ActRegistration />
							</Layout>
						}
					/> */}
                    {/* 錯誤提示網頁  */}
                    <Route
                        path='/error'
                        element={
                            <Layout>
                                <ErrorPage />
                            </Layout>
                        }
                    />
                    {/* 維護中  */}
                    <Route
                        path='/maintenance'
                        element={
                            <Layout>
                                <MaintenancePage />
                            </Layout>
                        }
                    />
                    {/* 找不到網頁  */}
                    <Route
                        path='*'
                        element={
                            <Layout>
                                <NotFoundPage />
                            </Layout>
                        }
                    />
                </Routes>
            )}
        </AccountContext.Provider>
    );
}

export default App;
