import { Link, useNavigate, useParams } from 'react-router-dom';
import './productCard.scss';
import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { CartDialog } from '../../../components/cartDialog/CartDialog';
import { useTranslation } from 'react-i18next';

const ProductCard = ({ pd }) => {
    const { t } = useTranslation('onlineStore'); // i18n

    const navigator = useNavigate();
    const [showCartInfo, setShowCartInfo] = useState(false);
    const [cartPdTypeQuantity, setCartPdTypeQuantity] = useState();

    const handleAddCart = async () => {
        // 1.存入cart
        await axios
            .post('/web/ShoppingCart/insertCart', {
                salesKitList: [
                    {
                        action: 'INSERT',
                        salesKitGuid: pd.salesKitGuid,
                        quantity: 1,
                        promoteGrossAmount: pd.promoteGrossAmount,
                    },
                ],
            })
            .then(async (res) => {
                // console.log(res.data);

                // 2.取得所有cart
                await axios.get('/web/ShoppingCart/getCartList').then((res2) => {
                    // console.log(res2.data.result);

                    setCartPdTypeQuantity(res2.data.result.length); // cart產品類型數量
                    setShowCartInfo(true); // 顯示彈出視窗
                });
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    Swal.fire({
                        title: t('please_login_first'),
                        icon: 'warning',
                        allowOutsideClick: true,
                        confirmButtonText: t('redirect_to_login_page'),
                        confirmButtonColor: 'var(--primary)',
                        allowEscapeKey: false,

                        cancelButtonText: t('cancel'),
                        cancelButtonColor: 'var(--secondary)',
                        showCancelButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigator('/login', {
                                state: { from: window.location.pathname },
                            });
                        }
                    });
                }
            });

        setTimeout(() => {
            setShowCartInfo(false);
        }, 2000);
    };

    return (
        <>
            {/* 購物車資訊 */}
            <CartDialog
                name={pd.name_01}
                addCartQuantity={1}
                showCartInfo={showCartInfo}
                setShowCartInfo={setShowCartInfo}
                cartPdTypeQuantity={cartPdTypeQuantity}
            />

            <Link
                className='container-productCard d-flex flex-column align-items-center bg-background'
                to={`/products/${pd.salesKitGuid}`}
            >
                <picture className='mb-3'>
                    <img className='d-block w-100' src={pd.coverURL} alt='pd1' />
                </picture>
                <p className='productTitle fw-bold'>{pd.name_01}</p>
                <div className='salePrice fw-bold'>
                    {pd.promoteGrossAmount >= 0
                        ? `NT$${pd.promoteGrossAmount.toLocaleString()}`
                        : `NT$${pd.grossAmount.toLocaleString()}`}
                </div>
                {pd.promoteGrossAmount >= 0 ? (
                    pd.promoteGrossAmount === pd.grossAmount ? null : (
                        <div className='unitPrice text-grey3'>{`NT$${pd.grossAmount.toLocaleString()}`}</div>
                    )
                ) : null}
                {/* <div className='unitPrice text-grey3'>NT${pd.grossAmount.toLocaleString()}</div> */}
                <button
                    className='btn btn-primary addCartBtn w-100 d-flex align-items-center justify-content-center text-white text-center border-0 mt-auto'
                    onClick={(e) => {
                        e.preventDefault();
                        handleAddCart(pd);
                    }}
                >
                    {t('add_to_cart')}
                    {/* 加入購物車 */}
                </button>
            </Link>
        </>
    );
};

export default ProductCard;
