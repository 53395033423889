import './footerApp.scss';
import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Accordion } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import { AccountContext } from '../../App';
import { useTranslation } from 'react-i18next';

function FooterApp() {
    const { t } = useTranslation('footer'); // i18n

    // Context of apk urls
    const accountContext = useContext(AccountContext);
    const { accountInfo, appUrInfo, getAccountInfo, homePageIndex, lang, setHomePageIndex, setLang } = accountContext;
    // const appUrInfo = accountContext?.appUrInfo;
    const getAndroidApkUrl = accountContext?.getAndroidApkUrl;
    const iosUrl = appUrInfo?.iosUrl;
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.synerter.SynerterSports&pli=1';

    // use pathname to decide if first session is d-none or not
    const [cookies] = useCookies(['loginUser']);
    const [isSimpleFooter, setIsSimpleFooter] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.split('/')[1];

    useEffect(() => {
        const pageWithSimpleFooter = [
            'login',
            'register',
            'forgotPwd',
            'exploreupdate',
            'physicalstoreupdate',
            'downloadcenterupdate',
            'questionupdate',
            'questioncategoryupdate',
        ];

        pageWithSimpleFooter.filter((e) => e === pathname).length > 0
            ? setIsSimpleFooter(true)
            : setIsSimpleFooter(false);
    }, [pathname, setIsSimpleFooter]);

    // //獲取最新版apk下載連結
    // const getNewestAndroid = async () => {
    //     await axios
    //         .get('web/sys/appdownloadurl?OSCode=1')
    //         .then((res) => {
    //             //console.log(res);
    //             const url = res.data.result;

    //             // window.location.href = url;
    //             window.open(url, '_blank');
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    return (
        <div className='container-fluid p-0'>
            <article id='container-footer'>
                <footer
                    id='footer'
                    className='text-white bg-grey5 d-flex  flex-column justify-content-center align-items-center'
                >
                    <section className={`footerTop w-100 h-100 py-2 py-md-4 ${isSimpleFooter && 'd-none'}`}>
                        <div className='container text-left text-md-center '>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Accordion
                                        flush
                                        className='d-flex flex-column flex-md-row align-items-start justify-content-md-center'
                                    >
                                        <Accordion.Item
                                            eventKey='0'
                                            className='bg-grey5 w-100 px-md-2 px-lg-4 border-0'
                                        >
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('product_center')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='list-unstyled  ps-md-0'>
                                                    <li>
                                                        <Link to='/productcenter/productfeature'>
                                                            {t('product_highlights')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/productcenter/productstat'>
                                                            {t('specifications')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/app/download'>{t('app_guide')}</Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            eventKey='1'
                                            className='bg-grey5 w-100 px-md-2 px-lg-4 border-0'
                                        >
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('online_showroom')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='list-unstyled  ps-md-0'>
                                                    <li>
                                                        <Link to='/explore'>{t('online_showroom')}</Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            eventKey='2'
                                            className='bg-grey5 w-100 px-md-2 px-lg-4  border-0'
                                        >
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('purchasingChannel')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='list-unstyled  ps-md-0'>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/onlineStore',
                                                            }}
                                                        >
                                                            {t('online_store')}
                                                        </Link>
                                                    </li>
                                                    {/* <li>
														<Link
															to={{
																pathname:
																	'/physicalstore',
																state: {
																	initialLocation:
																		'.section5',
																},
															}}
														>
															銷售據點
														</Link>
													</li> */}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            eventKey='3'
                                            className='bg-grey5 w-100 px-md-2 px-lg-4  border-0'
                                        >
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('support_services')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='list-unstyled  ps-md-0'>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/support/question',
                                                                state: {
                                                                    initialLocation: '.section2',
                                                                },
                                                            }}
                                                        >
                                                            {t('faq')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/support/downloadcenter',
                                                                state: {
                                                                    initialLocation: '.section2',
                                                                },
                                                            }}
                                                        >
                                                            {t('product_manual')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/support/downloadcenter',
                                                                state: {
                                                                    initialLocation: '.section4',
                                                                },
                                                            }}
                                                        >
                                                            {t('app_download')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/support/contactus',
                                                                state: {
                                                                    initialLocation: '.section2',
                                                                },
                                                            }}
                                                        >
                                                            {t('contact_us')}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <span
                                                            className='navFakeLink px-0 px-lg-3'
                                                            onClick={() => {
                                                                if (
                                                                    cookies.loginUser &&
                                                                    cookies.loginUser.status === '0'
                                                                ) {
                                                                    navigate('/product');
                                                                } else {
                                                                    navigate('/login?search=product');
                                                                }
                                                            }}
                                                        >
                                                            <span className='pb-1 nav-btn'>
                                                                {t('product_registration')}
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item
                                            eventKey='4'
                                            className='bg-grey5 w-100 px-md-2 px-lg-4  border-0'
                                        >
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('about_synerter')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='list-unstyled  ps-md-0'>
                                                    <li>
                                                        <Link to='/about'>{t('company_profile')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to={{
                                                                pathname: '/agreement',
                                                                state: {
                                                                    initialLocation: '.section10',
                                                                },
                                                            }}
                                                        >
                                                            {t('intellectual_property')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey='5' className='bg-grey5 w-100 h-100 px-md-2 px-lg-4'>
                                            <Accordion.Header className='text-white bg-grey5'>
                                                {t('synerter_app_download')}
                                            </Accordion.Header>
                                            <Accordion.Body className='bg-grey5'>
                                                <ul className='imgQRcode list-unstyled ps-md-0 d-flex flex-md-column flex-xl-row'>
                                                    <li>
                                                        <div
                                                            // to={{
                                                            //     pathname:
                                                            //         '/support/downloadcenter',
                                                            //     state: {
                                                            //         initialLocation:
                                                            //             '.section4',
                                                            //     },
                                                            // }}
                                                            onClick={() =>
                                                                window.open(
                                                                    'https://apps.apple.com/us/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id6470771883',
                                                                    '_blank'
                                                                )
                                                            }
                                                            style={{
                                                                cursor: 'pointer',
                                                                margin: '8px',
                                                            }}
                                                        >
                                                            <img
                                                                className='w-100'
                                                                src='./images/download_IOS.svg'
                                                                alt='iosQRcode'
                                                            />
                                                            <QRCodeSVG size='90' includeMargin={true} value={iosUrl} />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div
                                                            // to={{
                                                            //     pathname:
                                                            //         '/support/downloadcenter',
                                                            //     state: {
                                                            //         initialLocation:
                                                            //             '.section4',
                                                            //     },
                                                            // }}
                                                            onClick={() => window.open(`${androidUrl}`, '_blank')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                margin: '8px',
                                                            }}
                                                        >
                                                            <img
                                                                className='w-100'
                                                                src='./images/download_android.svg'
                                                                alt='anQRcode'
                                                            />
                                                            <QRCodeSVG
                                                                size='90'
                                                                includeMargin={true}
                                                                value={androidUrl}
                                                            />
                                                        </div>
                                                    </li>
                                                    {/* <li>
                                                        <div
                                                            // to={{
                                                            //     pathname:
                                                            //         '/support/downloadcenter',
                                                            //     state: {
                                                            //         initialLocation:
                                                            //             '.section4',
                                                            //     },
                                                            // }}
                                                            onClick={
                                                                getNewestAndroid
                                                            }
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <img
                                                                className="w-100 px-md-1"
                                                                src="./images/androidQRcode.png"
                                                                alt="androidQRcode"
                                                            />
                                                        </div>
                                                    </li> */}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='footerBottom w-100 px-5 py-3 border-0 border-top border-grey3 d-flex flex-column flex-lg-row align-items-center justify-content-center'>
                        <div className='wrapper w-100'>
                            <div className='border-0 d-flex flex-column flex-lg-row align-items-center justify-content-center'>
                                <div className='d-flex col-10 copyRightFooter'>
                                    <span className='text-center'>{t('copyright')}</span>
                                </div>
                                <div className='privacyFooter d-flex col-2 justify-content-end'>
                                    <div className='userAgreementHref'>
                                        <Link
                                            to={{
                                                pathname: `/agreement/${lang}`,
                                            }}
                                        >
                                            {t('user_agreement')}
                                        </Link>
                                    </div>
                                    <div className='privacyPolicyHref'>
                                        <Link
                                            to={{
                                                pathname: `/privacy/${lang}`,
                                            }}
                                        >
                                            {t('privacy_policy')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </footer>
            </article>
        </div>
    );
}

export default FooterApp;
