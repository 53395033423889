import './presetDrillUpdate.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';
import EditDrillItemModal from './Modals/EditDrillItemModal/EditDrillItemModal';
import InsertDrillItemModal from './Modals/InsertDrillItemModal/InsertDrillItemModal';

const PresetDrillUpdate = () => {
    const [presetDrillItemList, setPresetDrillItemList] = useState([]);
    const [drillCategoryList, setDrillCategoryList] = useState([]);
    const [drillCategoryItemList, setDrillCategoryItemList] = useState([]);
    const navigate = useNavigate();
    const paramsGuid = useParams('presetDrillGuid');
    const location = useLocation();

    // 新增球路 dialog
    const [insertDrillModalOpen, setInsertDrillModalOpen] = useState(false);
    // 新球路 data
    const [newDrillItemData, setNewDrillItemData] = useState({
        delay: 0,
        delayUI: null,
        displayOrder: null,
        powerBLDC1: null,
        powerBLDC2: null,
        rowStamp: null,
        spin: 0,
        spinPower: 0,
        maxSpinPower: null,
        userDrillItemGuid: null,
        vertical: 0,
        verticalDirection: 1,
        horizontal: 0,
        horizontalDirection: 1,
    });

    // 編輯球路 dialog
    const [editDrillModalOpen, setEditDrillModalOpen] = useState(false);
    const [editDrillItemData, setEditDrillItemData] = useState({
        delay: null,
        delayUI: null,
        displayOrder: null,
        horizontal: null,
        powerBLDC1: null,
        powerBLDC2: null,
        rowStamp: null,
        spin: null,
        spinPower: null,
        maxSpinPower: null,
        userDrillItemGuid: null,
        vertical: null,
    });

    // console.log(location.state);

    const [data, setData] = useState({
        description: '',
        displayOrder: '',
        frequency: '',
        frequencyVariable: '',
        name_ZH: '',
        name_ZF: '',
        name_EN: '',
        name_JA: '',
        quantity: '',
        serveAmount: '',
        serveOrder: '',
        speed: '',
        timer: '',
        presetDrillGuid: '',
        status: 0,
        drillCategoryGuid: '',
        drillCategoryItemGuid: '',
    });

    const spinNameTable = {
        0: '上旋',
        1: '左侧上旋↑',
        2: '左侧上旋',
        3: '左侧上旋↓',
        4: '左旋',
        5: '左側下旋↑',
        6: '左側下旋',
        7: '左側下旋↓',
        8: '下旋',
        9: '右側下旋↓',
        10: '右側下旋',
        11: '右側下旋↑',
        12: '右旋',
        13: '右側上旋↓',
        14: '右側上旋',
        15: '右側上旋↑',
    };

    // 前端給予新GUID
    function generateGuid() {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    const handleAngleDisplay = (type, val, direction, BLDC1, BLDC2) => {
        let result = '';

        // 水平角度
        if (type === 0) {
            if (direction > 0) {
                result = `右 ${Math.abs(val / 10)}`;
            } else if (direction < 0) {
                result = `左 ${Math.abs(val / 10)}`;
            }

            if (val === 0) {
                result = val / 10;
            }
        }

        // 俯仰角度
        if (type === 1) {
            if (direction > 0) {
                result = `上 ${Math.abs(val / 10)}`;
            } else if (direction < 0) {
                result = `下 ${Math.abs(val / 10)}`;
            }

            if (val === 0) {
                result = val / 10;
            }
        }

        // 旋球角度
        if (type === 2) {
            if (BLDC1 === BLDC2) {
                result = spinNameTable['noSpin'];
            } else {
                val = val / 225;
                result = spinNameTable[val];
            }
        }

        // console.log(result);

        return result;
    };

    const getDrillCategoryItemList = async (categoryGuid) => {
        await axios
            .get(`web/sysDrill/drillCategoryItem?guid=${categoryGuid}`)
            .then((res) => {
                const data = res.data.result;
                // console.log(data);

                setDrillCategoryItemList(data);
            })
            .catch((err) => console.log(err));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.keys(paramsGuid).length) {
            //edit
            await axios
                .put(`web/sysDrill/presetDrill/${paramsGuid.presetDrillGuid}`, {
                    ...data,
                    presetDrillItemList,
                })
                .then((res) => {
                    // console.log(res.data);
                    res.status === 200 &&
                        navigate(`/presetDrill`, {
                            state: {
                                categoryGuid: location.state.categoryGuid,
                                categoryItemGuid: location.state.categoryItemGuid,
                                presetDrillGuid: location.state.presetDrillGuid,
                            },
                        });
                })
                .catch((err) => console.log(err));
        } else {
            // //create
            // await axios
            //     .post(`web/sysDrill/drillGrade`, data)
            //     .then((res) => {
            //         // console.log(res.data);
            //         res.status === 200 && navigate('/drillGradeUpdate');
            //     })
            //     .catch((err) => console.log(err));
        }
    };

    // delete
    const handleDelete = async (e) => {
        e.preventDefault();

        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed delete');

                axiosApi(`web/sysDrill/presetDrill/${paramsGuid.presetDrillGuid}`, 'delete')
                    .then((res) => navigate(`/presetDrill`))
                    .catch((err) => console.log(err));
            }
        });
    };

    /// 新增修改球譜球路 相關功能

    const [userDrillInfo, setUserDrillInfo] = useState({
        drillClassGuid: null,
        description: null,
        displayOrder: null,
        frequency: null,
        serveOrder: null,
        name: null,
        quantity: null,
        serveAmount: null,
        speed: null,
        timer: null,
        userDrillGuid: null,
    });

    // warning
    const [displayOrderWarning, setDisplayOrderWarning] = useState('');
    const [nameWarning, setNameWarning] = useState('');
    const [frequencyWarning, setFrequencyWarning] = useState('');
    const [quantityWarning, setQuantityWarning] = useState('');
    const [timerWarning, setTimerWarning] = useState('');

    // 檢查必填
    let isValid = false;

    const isDisplayOrderValid = () => {
        if (userDrillInfo.displayOrder <= 0) {
            setDisplayOrderWarning('請輸入正確顯示順序 (大於 0)');
            return false;
        } else {
            return true;
        }
    };

    const isNameValid = () => {
        if (!userDrillInfo.name) {
            setNameWarning('請輸入球谱名称');
            return false;
        } else {
            return true;
        }
    };

    const isFrequencyValid = () => {
        if (userDrillInfo.frequency < 10 || userDrillInfo.frequency > 120 || !userDrillInfo.frequency) {
            console.log('isFrequencyValid');
            setFrequencyWarning('請輸入正確出球频率 (10 - 120)');
            return false;
        } else {
            return true;
        }
    };

    const isQuantityValid = () => {
        if (userDrillInfo.quantity < 0 || userDrillInfo.quantity > 60000 || !userDrillInfo.quantity) {
            console.log('isQuantityValid');
            setQuantityWarning('請輸入正確總球數 (最多 60000 球)');
            return false;
        } else {
            return true;
        }
    };

    const isTimerValid = () => {
        if (userDrillInfo.timer < 0 || userDrillInfo.timer > 480 || !userDrillInfo.timer) {
            console.log('isTimerValid');
            setTimerWarning('請輸入正確計時器 (最多 480 分鐘)');
            return false;
        } else {
            return true;
        }
    };

    // 新增球譜 確定
    const handleInsertUserDrillSubmit = () => {
        setDisplayOrderWarning('');
        setNameWarning('');
        setFrequencyWarning('');
        setQuantityWarning('');
        setTimerWarning('');
        const { action, userGuid } = location.state;
        console.log(userGuid, action);
        console.log(userDrillInfo);
        console.log(newDrillItemData);

        const copyInfo = { ...userDrillInfo, userGuid: userGuid };
        const { drillClassGuid, serveOrder } = copyInfo;
        if (!drillClassGuid) {
            copyInfo.drillClassGuid = location.state.userDrillClassList[0].classGuid;
        }
        if (!serveOrder) {
            copyInfo.serveOrder = '0';
        }

        // isDisplayOrderValid();
        isNameValid();
        isFrequencyValid();
        // isQuantityValid();
        // isTimerValid();

        if (
            // isDisplayOrderValid() &&
            isNameValid() &&
            isFrequencyValid()
            // isQuantityValid() &&
            // isTimerValid()
        ) {
            isValid = true;
        }

        if (isValid) {
            const newList = presetDrillItemList.map((el) => {
                if (el.verticalDirection < 0) {
                    el.vertical = el.vertical * -1;
                }
                if (el.horizontalDirection < 0) {
                    el.horizontal = el.horizontal * -1;
                }

                return el;
            });

            copyInfo.presetDrillItemList = newList;

            setUserDrillInfo(copyInfo);

            axiosApi(`web/sysDrill/userDrillItem`, 'post', copyInfo).then((res) => navigate('/userDrill'));
        }

        // axios
        //     .post(`web/sysDrill/userDrillItem`, data)
        //     .then(({ data }) => {
        //         console.log(data);
        //     })
        //     .catch((err) => console.log(err));
    };

    // 新增球路 確定
    const handleInsertDrillItemSubmit = () => {
        const newData = { ...newDrillItemData };
        newData.displayOrder = presetDrillItemList.length + 1;
        newData.userDrillItemGuid = generateGuid();
        newData.action = 'INSERT';
        newData.delay = newDrillItemData.delayUI * 10;

        const spin = newDrillItemData.spin;
        if (spin === 'noSpin') {
            console.log('noSpin');
            newData.powerBLDC1 = newDrillItemData.highSpeed;
            newData.powerBLDC2 = newDrillItemData.highSpeed;
            newData.lowSpeed = newDrillItemData.highSpeed;
            newData.spin = 0;
        } else if (spin >= 0 && spin <= 900) {
            console.log('BLCD1');
            newData.powerBLDC1 = newDrillItemData.highSpeed;
            newData.powerBLDC2 = newDrillItemData.lowSpeed;
        } else if (spin > 2700 && spin <= 3375) {
            console.log('BLCD1');
            newData.powerBLDC1 = newDrillItemData.highSpeed;
            newData.powerBLDC2 = newDrillItemData.lowSpeed;
        } else {
            console.log('BLCD2');
            newData.powerBLDC2 = newDrillItemData.highSpeed;
            newData.powerBLDC1 = newDrillItemData.lowSpeed;
        }

        const newList = [...presetDrillItemList, newData];

        setPresetDrillItemList(newList);
        setInsertDrillModalOpen(false);
        setNewDrillItemData({
            delay: 0,
            displayOrder: null,
            powerBLDC1: null,
            powerBLDC2: null,
            rowStamp: null,
            spin: 0,
            spinPower: 0,
            maxSpinPower: null,
            userDrillItemGuid: null,
            vertical: 0,
            verticalDirection: 1,
            horizontal: 0,
            horizontalDirection: 1,
        });
    };

    // 打開 新增球路 Modal
    const handleInsertDrillModalOpen = () => {
        setInsertDrillModalOpen(true);
    };

    // 打開 編輯球路 Modal
    const handleEditDrillModalOpen = (data) => {
        console.log(data);
        setEditDrillItemData({
            ...data,
        });

        const newData = { ...data };
        // newData.vertical = data.vertical;
        // newData.horizontal = data.horizontal;
        // newData.handleInsertDrillItemSubmit = data.handleInsertDrillItemSubmit > 0 ? 1 : -1;
        // newData.horizontalDirection = data.horizontal > 0 ? 1 : -1;
        console.log(data);

        newData.delayUI = data.delay / 10;

        if (data.powerBLDC1 === data.powerBLDC2) {
            newData.spin = 'noSpin';
        }

        let highSpeedWheel = null;
        let lowSpeedWheel = null;
        let highSpeed = 10;
        let lowSpeed = 0;
        if (data.spin >= 0 && data.spin <= 900) {
            console.log('BLCD1');
            highSpeedWheel = 'powerBLDC1';
            lowSpeedWheel = 'powerBLDC2';
            highSpeed = data.powerBLDC1;
            lowSpeed = data.powerBLDC2;
        } else if (data.spin > 2700 && data.spin <= 3375) {
            console.log('BLCD1');
            highSpeedWheel = 'powerBLDC1';
            lowSpeedWheel = 'powerBLDC2';
            highSpeed = data.powerBLDC1;
            lowSpeed = data.powerBLDC2;
        } else {
            console.log('BLCD2');
            highSpeedWheel = 'powerBLDC2';
            lowSpeedWheel = 'powerBLDC1';
            highSpeed = data.powerBLDC2;
            lowSpeed = data.powerBLDC1;
        }

        const maxSpinPower = Math.floor((highSpeed - 10) / 2 + 1);

        newData.highSpeedWheel = highSpeedWheel;
        newData.lowSpeedWheel = lowSpeedWheel;
        newData.maxSpinPower = maxSpinPower;
        newData.highSpeed = highSpeed;
        newData.speed = highSpeed;
        newData.lowSpeed = lowSpeed;

        console.log(maxSpinPower);

        setEditDrillItemData({ ...editDrillItemData, ...newData });
        setEditDrillModalOpen(true);
    };

    const handleEditDrillItemSubmit = () => {
        console.log(editDrillItemData);

        const submitData = { ...editDrillItemData };

        // Action
        // UserDrillItemGuid
        // DisplayOrder
        // Delay
        // Action
        // Horizontal
        // Vertical
        // Spin
        // PowerBLDC1
        // PowerBLDC2
        // RowStamp

        submitData.action = 'UPDATE';
        submitData.delay = editDrillItemData.delayUI * 10;

        const spin = editDrillItemData.spin;
        console.log(spin);

        if (spin === 'noSpin') {
            console.log('noSpin');
            submitData.powerBLDC1 = editDrillItemData.highSpeed;
            submitData.powerBLDC2 = editDrillItemData.highSpeed;
            submitData.lowSpeed = editDrillItemData.highSpeed;
            submitData.spin = 0;
        } else if (spin >= 0 && spin <= 900) {
            console.log('BLCD1');
            submitData.powerBLDC1 = editDrillItemData.highSpeed;
            submitData.powerBLDC2 = editDrillItemData.lowSpeed;
        } else if (spin > 2700 && spin <= 3375) {
            console.log('BLCD1');
            submitData.powerBLDC1 = editDrillItemData.highSpeed;
            submitData.powerBLDC2 = editDrillItemData.lowSpeed;
        } else {
            console.log('BLCD2');
            submitData.powerBLDC2 = editDrillItemData.highSpeed;
            submitData.powerBLDC1 = editDrillItemData.lowSpeed;
        }

        const foundIndex = presetDrillItemList.findIndex((el) => {
            return el.presetDrillItemGuid === submitData.presetDrillItemGuid;
        });

        const newList = [...presetDrillItemList];
        newList[foundIndex] = submitData;

        console.log(submitData);
        console.log(newList);

        // console.log(foundIndex);
        setPresetDrillItemList(newList);
        setEditDrillModalOpen(false);
    };

    const handleEditUserDrillSubmit = () => {
        const { action, userGuid } = location.state;
        // console.log(userDrillInfo);

        // UserDrillGuid
        // DisplayOrder
        // Name
        // Description
        // DrillClassGuid
        // Frequency
        // serveOrder
        // ServeAmount
        // ServeOrder
        // Timer
        // Quantity
        // RowStamp
        /// UserDrillItemList

        const copyInfo = { ...userDrillInfo, userGuid: userGuid };
        const { drillClassGuid, serveOrder } = copyInfo;

        isDisplayOrderValid();
        isNameValid();
        isFrequencyValid();
        // isQuantityValid();
        // isTimerValid();

        if (
            isDisplayOrderValid() &&
            isNameValid() &&
            isFrequencyValid()
            // isQuantityValid() &&
            // isTimerValid()
        ) {
            isValid = true;
        }

        if (isValid) {
            if (!drillClassGuid) {
                copyInfo.drillClassGuid = location.state.userDrillClassList[0].classGuid;
            }
            if (!serveOrder) {
                copyInfo.serveOrder = '0';
            }

            const newList = presetDrillItemList.map((el) => {
                if (el.verticalDirection < 0) {
                    el.vertical = el.vertical * -1;
                }
                if (el.horizontalDirection < 0) {
                    el.horizontal = el.horizontal * -1;
                }

                return el;
            });

            copyInfo.presetDrillItemList = newList;

            console.log(copyInfo);

            const guid = paramsGuid.userDrillGuid;
            axiosApi(`web/sysDrill/userDrillItem`, 'put', copyInfo).then((res) => navigate('/userDrill'));
        }
    };

    // 刪除球路
    const handleDeleteDrillItem = (guid) => {
        console.log(editDrillItemData);

        const newList = presetDrillItemList.map((el) => {
            if (el.presetDrillItemGuid === guid) {
                el.action = 'DELETE';
            }

            return el;
        });

        console.log(newList);
        setPresetDrillItemList(newList);
        setEditDrillModalOpen(false);
    };

    // 將 fetched data 轉換成 UI 所需要的 info
    const handleFromDataToUI = (data) => {
        // console.log(data);
        const newData = data.map((el) => {
            const { powerBLDC1, powerBLDC2 } = el;

            /// 1. 旋球強度
            // 使用 高速輪 & 低速輪 算出 旋球強度
            // 旋球強度   ((絕對值(BLDC1-BLDC2))-基本強度值)/強度單位 +1
            // powerBLDC1 & powerBLDC2 -> 誰大誰就是 高速輪
            let spinPower = Math.floor((Math.abs(powerBLDC1 - powerBLDC2) - 10) / 2 + 1);

            // 如果 powerBLDC1 等於 powerBLDC2 -> 一定是沒旋 -> 沒有旋球強度
            if (powerBLDC1 === powerBLDC2) {
                spinPower = 0;
            }

            el.spinPower = spinPower;

            /// 2. 水平角度 & 俯仰角度
            // vertical : Top = +ve, Down = -ve
            // 如果 vertical 少於 0 (-ve), 那方向就是下
            // horizontal : Right = +ve, Left = -ve
            // 如果 horizontal 少於 0 (-ve), 那方向就是左

            // 以 verticalDirection & horizontalDirection 代表其方向
            // 1 = 上 / 右
            // -1 = 下 / 左
            el.verticalDirection = el.vertical > 0 ? 1 : -1;
            el.horizontalDirection = el.horizontal > 0 ? 1 : -1;
            el.vertical = Math.abs(el.vertical);
            el.horizontal = Math.abs(el.horizontal);

            /// 3. 旋球角度
            // 以 四象限 為準, 以決定 powerBLDC1 & powerBLDC2 誰才是高速輪
            // 第一象限, 第二象限 -> powerBLDC1 是高速輪
            // 第三象限, 第四象限 -> powerBLDC2 是高速輪

            // 簡單來說, 就是抄 Gino 在 app 那邊的寫法的 :)
            const spin = el.spin;
            if (spin >= 0 && spin <= 900) {
                el.highSpeed = el.powerBLDC1;
                el.lowSpeed = el.powerBLDC2;
            } else if (spin > 2700 && spin <= 3375) {
                el.highSpeed = el.powerBLDC1;
                el.lowSpeed = el.powerBLDC2;
            } else {
                el.highSpeed = el.powerBLDC2;
                el.lowSpeed = el.powerBLDC1;
            }

            return el;
        });

        return newData;
    };

    //edit getInfo
    useEffect(() => {
        if (Object.keys(paramsGuid).length)
            (async () => {
                await axios
                    .get(`web/sysDrill/presetDrill/${paramsGuid.presetDrillGuid}`)
                    .then((res) => {
                        const data = res.data.result;
                        console.log(data);

                        const {
                            description,
                            displayOrder,
                            frequency,
                            frequencyVariable,
                            name_ZH,
                            name_ZF,
                            name_EN,
                            name_JA,
                            quantity,
                            serveAmount,
                            serveOrder,
                            speed,
                            timer,
                            presetDrillGuid,
                            drillCategoryGuid,
                            drillCategoryItemGuid,
                        } = data;

                        setData({
                            ...data,
                            description,
                            displayOrder,
                            frequency,
                            frequencyVariable,
                            name_ZH,
                            name_ZF,
                            name_EN,
                            name_JA,
                            quantity,
                            serveAmount,
                            serveOrder,
                            speed,
                            timer,
                            presetDrillGuid,
                            drillCategoryGuid,
                            drillCategoryItemGuid,
                        });

                        const UIUserDrillItemList = handleFromDataToUI(data.presetDrillItemList);

                        console.log(data);

                        // setPresetDrillItemList(UIUserDrillItemList);
                        // setDrillClassList(data.userDrillClassList);

                        getDrillCategoryItemList(data.drillCategoryGuid);
                        setPresetDrillItemList(UIUserDrillItemList);
                    })
                    .catch((err) => navigate(`/presetDrill`));
            })();

        const getDrillCategoryList = async () => {
            await axios
                .get(`web/sysDrill/drillCategory`)
                .then((res) => {
                    const data = res.data.result;
                    // console.log(data);

                    setDrillCategoryList(data);
                    // getDrillCategoryItemList(data[0].categoryGuid);
                })
                .catch((err) => console.log(err));
        };
        getDrillCategoryList();
    }, [paramsGuid]);

    return (
        <>
            <section id='section-main'>
                <div id='container-presetDrillUpdate'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>宾乐达球谱</h5>
                        {Object.keys(paramsGuid).length && (
                            <button
                                className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2`}
                                onClick={(e) => {
                                    handleDelete(e);
                                }}
                            >
                                刪除
                            </button>
                        )}
                        <button
                            className='btnCancel btn btn-outline-primary px-4 me-2'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/presetDrill`, {
                                    state: {
                                        categoryGuid: location.state.categoryGuid,
                                        categoryItemGuid: location.state.categoryItemGuid,
                                        presetDrillGuid: location.state.presetDrillGuid,
                                    },
                                });
                            }}
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white px-4' onClick={handleSubmit}>
                            储存
                        </button>
                    </div>
                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>球谱管理</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='displayOrder' className='fontSize75Rem mb-2'>
                                    显示顺序
                                </label>
                                <input
                                    id='displayOrder'
                                    name='displayOrder'
                                    type='number'
                                    className={`form-control mb-3`}
                                    value={data.displayOrder}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            displayOrder: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='type' className='fontSize75Rem mb-2'>
                                    球谱类型
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='type'
                                    id='type'
                                    value={data.type}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            type: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value='0'>系統預設免費球譜</option>
                                    <option value='1'>名人免費球譜</option>
                                    <option value='2'>名人付費球譜</option>
                                    <option value='7'>比賽球譜</option>
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='frequency' className='fontSize75Rem mb-2'>
                                    出球频率(球/分钟)
                                </label>
                                <input
                                    id='frequency'
                                    name='frequency'
                                    type='number'
                                    className={`form-control mb-3`}
                                    value={data.frequency}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            frequency: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='name_ZH' className='fontSize75Rem mb-2'>
                                    球谱名称(簡體)
                                </label>
                                <input
                                    id='name_ZH'
                                    name='name_ZH'
                                    className={`form-control mb-3`}
                                    value={data.name_ZH}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZH: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='drillCategoryGuid' className='fontSize75Rem mb-2'>
                                    球谱型态
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='drillCategoryGuid'
                                    id='drillCategoryGuid'
                                    value={data.drillCategoryGuid}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            drillCategoryGuid: e.target.value,
                                        });
                                        getDrillCategoryItemList(e.target.value);
                                    }}
                                    required
                                >
                                    {drillCategoryList.map((el) => (
                                        <option key={el.categoryGuid} value={el.categoryGuid}>
                                            {el.name_ZH}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-2'>
                                <label htmlFor='serveAmount' className='fontSize75Rem mb-2'>
                                    出球量
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='serveAmount'
                                    id='serveAmount'
                                    value={data.serveAmount}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            serveAmount: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value='0'>0 计时</option>
                                    <option value='1'>1 总球数</option>
                                </select>
                            </div>
                            <div className='col-2'>
                                {data.serveAmount === '1' ? (
                                    <React.Fragment>
                                        <label htmlFor='quantity' className='fontSize75Rem mb-2'>
                                            计量 (球)
                                        </label>
                                        <input
                                            id='quantity'
                                            name='quantity'
                                            type='number'
                                            className={`form-control mb-3`}
                                            value={data.quantity}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    quantity: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <label htmlFor='timer' className='fontSize75Rem mb-2'>
                                            计时（分钟）
                                        </label>
                                        <input
                                            id='timer'
                                            name='timer'
                                            type='number'
                                            className={`form-control mb-3`}
                                            value={data.timer}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    timer: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='name_ZF' className='fontSize75Rem mb-2'>
                                    球谱名称(繁體)
                                </label>
                                <input
                                    id='name_ZF'
                                    name='name_ZF'
                                    className={`form-control mb-3`}
                                    value={data.name_ZF}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZF: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='drillCategoryItemGuid' className='fontSize75Rem mb-2'>
                                    子型态
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='drillCategoryItemGuid'
                                    id='drillCategoryItemGuid'
                                    value={data.drillCategoryItemGuid}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            drillCategoryItemGuid: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value=''>请选择</option>
                                    {drillCategoryItemList.map((el) => (
                                        <option key={el.categoryItemGuid} value={el.categoryItemGuid}>
                                            {el.name_ZH}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-4'>
                                <label htmlFor='frequencyVariable' className='fontSize75Rem mb-2'>
                                    出球频率变化
                                </label>
                                <select
                                    className='form-control mb-3'
                                    name='frequencyVariable'
                                    id='frequencyVariable'
                                    value={data.frequencyVariable}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            frequencyVariable: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value='0'>稳定</option>
                                    <option value='1'>忽快忽慢(随机)</option>
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='name_EN' className='fontSize75Rem mb-2'>
                                    球谱名称(英文)
                                </label>
                                <input
                                    id='name_EN'
                                    name='name_EN'
                                    className={`form-control mb-3`}
                                    value={data.name_EN}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_EN: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-4'>
                                <label htmlFor='name_JP' className='fontSize75Rem mb-2'>
                                    球谱名称(日文)
                                </label>
                                <input
                                    id='name_JP'
                                    name='name_JP'
                                    className={`form-control mb-3`}
                                    value={data.name_JP}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_JP: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <hr className='bg-grey3 mt-2'></hr>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='description' className='fontSize75Rem mb-2'>
                                    说明
                                </label>
                                <input
                                    id='description'
                                    name='description'
                                    className={`form-control mb-3`}
                                    value={data.description}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            description: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-4'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control'
                                    name='status'
                                    id='status'
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            status: e.target.value,
                                        })
                                    }
                                    value={data.status || ''}
                                    required
                                >
                                    <option value='0'>停用</option>
                                    <option value='1'>啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='searchTableWrapper bg-white p-4 rounded-4'>
                        <div className='searchTableTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>球路资讯</h6>
                            <button className='btn btn-primary text-white ms-auto' onClick={handleInsertDrillModalOpen}>
                                <svg
                                    id='icn_Create'
                                    className='me-2 mb-1'
                                    viewBox='0 0 12 12'
                                    width='12'
                                    height='12'
                                    fill='#fff'
                                >
                                    <defs> </defs>
                                    <path
                                        className='cls-1'
                                        id='_Color'
                                        d='M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z'
                                        data-name=' ↳Color'
                                    ></path>
                                </svg>
                                <span>新增球路</span>
                            </button>
                        </div>
                        <div className='searchTableBottom'>
                            <section>
                                <table className='UserDrillListTable table table-hover text-center' id='table-drill'>
                                    <thead>
                                        <tr className='bg-background'>
                                            <th scope='col' className='text-grey4 border-0'>
                                                顺序<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0' style={{ width: '15%' }}>
                                                水平角度<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0' style={{ width: '15%' }}>
                                                俯仰角度<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                出球速度<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                旋球强度<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0' style={{ width: '20%' }}>
                                                旋球角度<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                高速轮<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                低速轮<span></span>
                                            </th>
                                            <th scope='col' className='text-grey4 border-0'>
                                                出球延迟<span></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='border-top-0'>
                                        {presetDrillItemList.length !== 0 ? (
                                            presetDrillItemList
                                                ?.filter((el) => el?.action !== 'DELETE')
                                                ?.map((list) => {
                                                    return (
                                                        <tr
                                                            id={list.presetDrillItemGuid}
                                                            key={list.presetDrillItemGuid}
                                                            onClick={() => handleEditDrillModalOpen(list)}
                                                        >
                                                            <td>{list.displayOrder}</td>
                                                            <td>
                                                                {handleAngleDisplay(
                                                                    0,
                                                                    list.horizontal,
                                                                    list.horizontalDirection
                                                                )}
                                                            </td>
                                                            <td>
                                                                {handleAngleDisplay(
                                                                    1,
                                                                    list.vertical,
                                                                    list.verticalDirection
                                                                )}
                                                            </td>
                                                            <td>{list.highSpeed}</td>
                                                            <td>
                                                                {list.powerBLDC1 === list.powerBLDC2
                                                                    ? '不旋球'
                                                                    : list.spinPower}
                                                            </td>
                                                            <td>
                                                                {handleAngleDisplay(
                                                                    2,
                                                                    list.spin,
                                                                    undefined,
                                                                    list.powerBLDC1,
                                                                    list.powerBLDC2
                                                                )}
                                                            </td>
                                                            <td>{list.highSpeed}</td>
                                                            <td>{list.lowSpeed}</td>
                                                            <td>{list.delay / 10}</td>
                                                        </tr>
                                                    );
                                                })
                                        ) : (
                                            <tr>
                                                <td colSpan='9'>無球谱紀錄</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </section>
                        </div>
                    </div>
                </div>

                {/* 新增球路 */}
                {insertDrillModalOpen && (
                    <InsertDrillItemModal
                        insertDrillModalOpen={insertDrillModalOpen}
                        setInsertDrillModalOpen={setInsertDrillModalOpen}
                        newDrillItemData={newDrillItemData}
                        setNewDrillItemData={setNewDrillItemData}
                        drillCategoryItemList={drillCategoryItemList}
                        spinNameTable={spinNameTable}
                        handleInsertDrillItemSubmit={handleInsertDrillItemSubmit}
                        // handleSpinPowerChange={handleSpinPowerChange}
                        // handleHighSpeedChange={handleHighSpeedChange}
                        // handleSpinChange={handleSpinChange}
                    />
                )}

                {/* 編輯球路 */}
                {editDrillModalOpen && (
                    <EditDrillItemModal
                        editDrillModalOpen={editDrillModalOpen}
                        setEditDrillModalOpen={setEditDrillModalOpen}
                        editDrillItemData={editDrillItemData}
                        setEditDrillItemData={setEditDrillItemData}
                        drillCategoryItemList={drillCategoryItemList}
                        spinNameTable={spinNameTable}
                        handleEditDrillItemSubmit={handleEditDrillItemSubmit}
                        handleDeleteDrillItem={handleDeleteDrillItem}
                        // handleSpinPowerChange={handleSpinPowerChange}
                        // handleHighSpeedChange={handleHighSpeedChange}
                        // handleSpinChange={handleSpinChange}
                    />
                )}
            </section>
        </>
    );
};

export default PresetDrillUpdate;
