import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { axiosApi } from '../../../api';

const SalesKitClassUpdate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const statusList = {
        0: '"0"：未啟用',
        1: '"1"：啟用中',
        2: '"2"：鎖定（停用）',
        4: '"4"：刪除 ',
    };

    const [salesKitClassData, setSalesKitClassData] = useState(null);
    const [salesKitClassList, setSalesKitClassList] = useState([]);

    const { salesKitClassGuid } = useParams('salesKitClassGuid');

    // warning
    const [nameWarning, setNameWarning] = useState('');
    const [statusWarning, setStatusWarning] = useState('');

    // -- 刪除成功 dialog --
    const successAlert = () => {
        Swal.fire({
            title: '刪除成功',
            html: `<h6>成功刪除商品分類</h6>`,
            icon: 'success',
            confirmButtonText: '確定',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1);
            }
        });
    };

    // -- 確定刪除商品分類 dialog --
    const deleteSalesKitClassAlert = () => {
        Swal.fire({
            title: '確定要刪除此商品分類嗎?',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,

            // i18n
            confirmButtonText: '確定',
            cancelButtonText: '取消',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteSalesKitClass();
            }
        });
    };

    // 新增商品分類 POST
    const handleCreateSalesKitClass = () => {
        const isDataValid = checkDataValid();

        if (isDataValid) {
            axios
                .post(`web/SalSalesKitClass/insertSalesKitClass`, salesKitClassData)
                .then((res) => navigate(-1))
                .catch((err) => console.log(err));
        }
    };

    // 編輯商品分類 PUT
    const handleUpdateSalesKitClass = () => {
        const isDataValid = checkDataValid();

        if (isDataValid) {
            axios
                .put(`web/SalSalesKitClass/updateSalesKitClass`, salesKitClassData)
                .then((res) => navigate(-1))
                .catch((err) => console.log(err));
        }
    };

    // 刪除商品分類 POST
    const handleDeleteSalesKitClass = () => {
        axios
            .post(`web/SalSalesKitClass/deleteSalesKitClass/${salesKitClassGuid}`)
            .then((res) => {
                successAlert();
            })
            .catch((err) => console.log(err));
    };

    // 監聽 class 內容更改
    const handleSalesKitClassDataChange = (e) => {
        setSalesKitClassData({
            ...salesKitClassData,
            [e.target.name]: e.target.value,
        });
    };

    const checkDataValid = () => {
        if (!salesKitClassData) {
            setNameWarning('請輸入商品分類名稱');
            setStatusWarning('請輸入商品分類狀態');
            return false;
        }

        if (!salesKitClassData.name_01 || !salesKitClassData.status) {
            if (!salesKitClassData?.name_01) {
                console.log('請輸入商品分類名稱');
                setNameWarning('請輸入商品分類名稱');
            }

            if (!salesKitClassData?.status) {
                console.log('請請輸入商品分類狀態');
                setStatusWarning('請輸入商品分類狀態');
            }

            return false;
        } else {
            return true;
        }
    };

    // edit getInfo
    useEffect(() => {
        if (salesKitClassGuid) {
            axios.get(`/web/SalSalesKitClass/getSalesKitClassDetails/${salesKitClassGuid}`).then((res) => {
                console.log(res.data.salesKitClass);

                setSalesKitClassData(res.data.salesKitClass);
            });
        }

        axios
            .get(`web/SalSalesKitClass/getSalesKitClassList`)
            .then((res) => {
                // console.log(res.data.salesKitClassList);
                setSalesKitClassList(res.data.salesKitClassList);
            })
            .catch((err) => console.log(err));
    }, []);

    console.log(salesKitClassData);
    console.log(nameWarning);
    console.log(statusWarning);

    return (
        <>
            <section id='section-main'>
                <div id='container-aaaaa'>
                    <div className='d-flex'>
                        <h5 className='fw-bold mb-4'>商品分類</h5>

                        {salesKitClassGuid ? (
                            <>
                                <button
                                    className={`btn btn-outline-danger px-2 me-2 ms-auto ${
                                        salesKitClassData?.status !== '0' ? 'disabled' : ''
                                    }`}
                                    onClick={deleteSalesKitClassAlert}
                                >
                                    <svg
                                        id='icn_Trash'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                    >
                                        <defs>
                                            <clipPath id='clipPath'>
                                                <rect
                                                    id='Rectangle_3041'
                                                    data-name='Rectangle 3041'
                                                    width='13.263'
                                                    height='14'
                                                    fill='#ea4132'
                                                />
                                            </clipPath>
                                        </defs>
                                        <g id='Group_6120' data-name='Group 6120' transform='translate(1 1)'>
                                            <g id='Group_6119' data-name='Group 6119' clipPath='url(#clipPath)'>
                                                <path
                                                    id='Path_1388'
                                                    data-name='Path 1388'
                                                    d='M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z'
                                                    fill='#ea4132'
                                                    fillRule='evenodd'
                                                />
                                                <rect
                                                    id='Rectangle_3039'
                                                    data-name='Rectangle 3039'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(4.421 5.158)'
                                                    fill='#ea4132'
                                                />
                                                <rect
                                                    id='Rectangle_3040'
                                                    data-name='Rectangle 3040'
                                                    width='1.474'
                                                    height='5.895'
                                                    transform='translate(7.368 5.158)'
                                                    fill='#ea4132'
                                                />
                                            </g>
                                        </g>
                                        <rect
                                            id='Rectangle_3042'
                                            data-name='Rectangle 3042'
                                            width='16'
                                            height='16'
                                            fill='none'
                                        />
                                    </svg>
                                </button>
                                <div className='me-2'></div>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleUpdateSalesKitClass}
                                >
                                    儲存
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className='btnCancel btn btn-outline-primary px-4 me-2 ms-auto'
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    取消
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary text-white px-4 '
                                    style={{
                                        height: '32px',
                                        paddingBlock: 0,
                                        fontSize: '14px',
                                    }}
                                    onClick={handleCreateSalesKitClass}
                                >
                                    儲存
                                </button>
                            </>
                        )}
                    </div>

                    <div className='infoWrapper bg-white p-4 rounded-4 mb-3'>
                        <div className='infoTop d-flex align-items-center mb-3 '>
                            <h6 className='fw-bold'>分類管理</h6>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='name_01' className='fontSize75Rem mb-2'>
                                    分類名稱
                                </label>
                                <input
                                    id='name_01'
                                    name='name_01'
                                    className='form-control mb-3'
                                    value={salesKitClassData?.name_01 || ''}
                                    onChange={handleSalesKitClassDataChange}
                                />
                                <span className='text-danger'>{nameWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='name_02' className='fontSize75Rem mb-2'>
                                    分類名稱(簡中)
                                </label>
                                <input
                                    id='name_02'
                                    name='name_02'
                                    className='form-control mb-3'
                                    value={salesKitClassData?.name_02 || ''}
                                    onChange={handleSalesKitClassDataChange}
                                />
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='name_03' className='fontSize75Rem mb-2'>
                                    分類名稱(英文)
                                </label>
                                <input
                                    id='name_03'
                                    name='name_03'
                                    className='form-control mb-3'
                                    value={salesKitClassData?.name_03 || ''}
                                    onChange={handleSalesKitClassDataChange}
                                />
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='upperGuid' className='fontSize75Rem mb-2'>
                                    父層
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='upperGuid'
                                    id='upperGuid'
                                    value={salesKitClassData?.upperGuid || ''}
                                    onChange={handleSalesKitClassDataChange}
                                    required
                                >
                                    <option value=''>--- 請選擇 ---</option>
                                    {salesKitClassList.map((skClass, idx) => (
                                        <option value={skClass.salesKitClassGuid}>{skClass.name_01}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='status' className='fontSize75Rem mb-2'>
                                    狀態
                                </label>
                                <select
                                    className='form-control mb-3 w-100'
                                    name='status'
                                    id='status'
                                    value={salesKitClassData?.status || ''}
                                    onChange={handleSalesKitClassDataChange}
                                    required
                                >
                                    <option value=''>--- 請選擇 ---</option>
                                    <option value='0'>"0"：未启用</option>
                                    <option value='1'>"1"：启用中</option>
                                    <option value='2'>"2"：停用中</option>
                                    {/* <option value="4">"4"：刪除</option> */}
                                </select>
                                <span className='text-danger'>{statusWarning}</span>
                            </div>
                        </div>
                        <div className='infoBottom row'>
                            <div className='col-8'>
                                <label htmlFor='statusTime' className='fontSize75Rem mb-2'>
                                    创建/修改时间
                                </label>
                                <input
                                    id='statusTime'
                                    name='statusTime'
                                    className='form-control mb-3'
                                    type='datetime-local'
                                    step='1'
                                    value={
                                        salesKitClassData?.statusTime
                                            ? dayjs(salesKitClassData?.statusTime).format('YYYY-MM-DD HH:mm:ss')
                                            : ''
                                    }
                                    onChange={handleSalesKitClassDataChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SalesKitClassUpdate;
