import './authUpdateMemberCreate.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function AuthUpdateMemberCreate() {
    const navigate = useNavigate();
    const location = useLocation();

    // 搜尋
    const [memberList, setMemberList] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    // checkbox
    const [memberCheckObj, setMemberCheckObj] = useState({});
    const [memberCheckSubmit, setMemberCheckSubmit] = useState([]);

    const authorizationGuid = location.search.slice(1).split('&')[0].split('authorizationGuid=')[1];

    const groupName = location.search.slice(1).split('&')[1].split('authGroupName=')[1];

    const getMemberNotIncludeList = useCallback((query) => {
        const gettingMemberNotIncludeList = async (requestBody) => {
            await axios
                .post(`web/SysAuthorization/authorizationUserFilter?guid=${authorizationGuid}`, requestBody)
                .then((res) => {
                    // console.log(res.data.result);
                    setMemberList(res.data.result);
                })
                .catch((err) => console.log(err));
        };

        gettingMemberNotIncludeList(query);
    }, []);

    useEffect(() => {}, [getMemberNotIncludeList]);
    console.log(memberList);

    // 過濾搜尋
    const filterMemberList = () => {
        if (searchText) {
            console.log(searchText, searchFilter);
            let requestBody = {};
            if (searchFilter === 'all') {
                requestBody = {
                    searchLogic: ['', 'Or', 'Or', 'Or'],
                    searchColumn: ['userid', 'fullname', 'email1', 'phone1'],
                    searchSymbol: ['contains', 'contains', 'contains', 'contains'],
                    searchInput: [searchText, searchText, searchText, searchText],
                };
            } else {
                requestBody = {
                    searchLogic: [''],
                    searchColumn: [searchFilter],
                    SearchSymbol: ['contains'],
                    searchInput: [searchText],
                };
            }

            getMemberNotIncludeList(requestBody);
        }
    };

    //過濾搜尋reset
    const resetFilterMemberList = () => {
        setSearchText('');
        setMemberList('');
        // 是否全選判斷
        isCheckboxAll('memberCheckbox');
    };

    // 判斷是否全選checkbox要勾選
    const isCheckboxAll = (checkboxName) => {
        let isNoneChecked = 0;
        let isAllChecked = 1;
        document.querySelectorAll(`.${checkboxName}Item`).forEach((el) => {
            // console.log(el.checked);
            isNoneChecked = el.checked ? isNoneChecked + 1 : isNoneChecked + 0;
            isAllChecked = el.checked ? isAllChecked * 1 : isAllChecked * 0;
        });
        // console.log(isAllChecked);
        if (isNoneChecked === 0) {
            document.querySelector(`.${checkboxName}All`).indeterminate = false;
            document.querySelector(`.${checkboxName}All`).checked = false;
        } else if (isAllChecked === 1) {
            document.querySelector(`.${checkboxName}All`).indeterminate = false;
            document.querySelector(`.${checkboxName}All`).checked = true;
        } else {
            document.querySelector(`.${checkboxName}All`).indeterminate = true;
            document.querySelector(`.${checkboxName}All`).checked = false;
        }
    };

    // 成員checkboxItem控制
    const changeMemberCheckObj = (e) => {
        const key = e.target.value;

        // checkbox對應資料
        let checkObj = {
            ...memberCheckObj,
            [key]: !memberCheckObj[key],
        };
        setMemberCheckObj(checkObj);

        // 是否全選判斷
        isCheckboxAll('memberCheckbox');

        // submit回傳資料
        let transactionCodeList = [];
        Object.keys(checkObj).forEach((key) => {
            // console.log(key);
            // console.log(checkObj[key]);
            checkObj[key] && transactionCodeList.push(key);
        });
        setMemberCheckSubmit(transactionCodeList);
    };

    console.log(memberCheckObj);
    console.log(memberCheckSubmit);

    // 成員checkboxAll控制
    let changeMemberCheckAll = (e) => {
        let allCheckedCodeList = [];

        document.querySelectorAll('.memberCheckboxItem').forEach((el) => {
            // console.log(el.checked);
            // console.log(el);
            // checkItem全選/全不選
            e.target.checked ? (el.checked = true) : (el.checked = false);

            allCheckedCodeList.push(el.value);
        });

        const checkObj = {};
        if (e.target.checked) {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = true;
            });
            setMemberCheckObj(checkObj);

            // submit回傳資料
            setMemberCheckSubmit(allCheckedCodeList);
        } else {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = false;
            });
            setMemberCheckObj(checkObj);

            // submit回傳資料
            setMemberCheckSubmit([]);
        }
    };

    // 儲存送出
    const handleSubmit = async () => {
        await axios
            .post(`/web/SysAuthorization/authorizationUser`, {
                authorizationGuid: authorizationGuid,
                userGuidList: memberCheckSubmit,
            })
            .then(() => {
                navigate(
                    `/authUpdate/memberPage/edit?authorizationGuid=${authorizationGuid}&authGroupName=${groupName}`
                );
            })
            .catch((err) => console.log(err));
    };

    //表格排序
    function sortTable(n, alwaysDesc) {
        //(必填)n:表格欄位(0,1,2...)
        //(選填)alwaysDesc:切換不同欄位排序時，永遠為desc
        let table,
            rows,
            switching,
            i,
            x,
            y,
            shouldSwitch,
            dir,
            switchCount = 0,
            isFirstTimeSort = false;

        //sort which table?
        table = document.querySelector('.table');

        switching = true;

        //若欄位在sort前就是asc排列，switchCount將為0，將造成類似double click的誤判
        //因此記錄isFirstTimeSort，透過該th span是否已經有排序符號來判別
        if (table.rows[0].querySelectorAll('th span')[n].innerHTML === '') {
            isFirstTimeSort = true;
        }

        //Set the sorting direction to ascending:(初始排序方向)
        dir = 'desc';
        //清空排序圖示
        table.rows[0].querySelectorAll('th span').forEach((i) => (i.innerHTML = ''));
        //加入desc排序圖示
        table.rows[0].querySelectorAll('th span')[n].innerHTML = `
					<svg className="align-bottom ml-1" viewBox="0 0 17 20" width="17" height="20">
                        <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" transform="matrix(1 0 0 -1 0 20)" data-name=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
                    </svg>
        `;

        /*Make a loop that will continue until no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the first, which contains table headers):*/
            for (i = 1; i < rows.length - 1; i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
            one from current row and one from the next:*/
                x = rows[i].querySelectorAll('td')[n];
                y = rows[i + 1].querySelectorAll('td')[n];
                /*check if the two rows should switch place,
            based on the direction, asc or desc:*/
                if (dir === 'asc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === 1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === 'desc') {
                    if (x.innerHTML.toLowerCase().localeCompare(y.innerHTML.toLowerCase()) === -1) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }

            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                //Each time a switch is done, increase this count by 1:
                switchCount++;
            } else {
                //在切換不同分組時，邏輯判斷永遠為desc排序
                if (alwaysDesc === 'desc') {
                    //console.log("desc");
                }
                //第一次點擊時，已經desc，完成排序
                else if (switchCount === 0 && dir === 'desc' && isFirstTimeSort === true) {
                    //console.log("FirstTimeSort");
                }
                //desc後，第二次點擊轉換為asc
                /*If no switching has been done AND the direction is "desc",
            set the direction to "asc" and run the while loop again.*/
                else if (switchCount === 0 && dir === 'desc') {
                    dir = 'asc';
                    switching = true;
                    table.rows[0].querySelectorAll('th span')[n].innerHTML = `
                       <svg className="align-bottom ml-1" viewBox="0 0 18 20" width="18" height="20">
                    <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" dataName=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
            		</svg>
                `;
                }
            }
        }
    }

    return (
        <section id='section-main'>
            <div id='container-authUpdateMemberCreate' className='container'>
                <div className='d-flex align-items-center mb-3'>
                    <h5 className='fw-bold mb-0'>权限设定</h5>
                    <div className='submitBtnWrapper ms-auto'>
                        <button
                            className='btn btn-outline-primary me-2'
                            onClick={() =>
                                navigate(
                                    `/authUpdate/memberPage/edit?authorizationGuid=${authorizationGuid}&authGroupName=${groupName}`
                                )
                            }
                        >
                            取消
                        </button>
                        <button className='btn btn-primary text-white' onClick={() => handleSubmit()}>
                            儲存
                        </button>
                    </div>
                </div>

                <div className='authUpdateWrapper row'>
                    <div className={`memberPageWrapper col-12 px-2`}>
                        <div className='bg-white px-4 py-3 rounded-4'>
                            <div className='row pb-3'>
                                <div className='col-4'>
                                    <h6 className='fw-bold mb-0'>新增成员</h6>
                                </div>
                            </div>

                            <div className='searchBox row'>
                                <div className='col-8'>
                                    <div className='d-flex'>
                                        <select
                                            className='form-control py-0 me-2'
                                            style={{
                                                width: '160px',
                                                height: '32px',
                                            }}
                                            name='searchColumn'
                                            id='searchColumn'
                                            onChange={(e) => setSearchFilter(e.target.value)}
                                            value={searchFilter}
                                            required
                                        >
                                            {/* 可以用 電話、郵件、姓名、用戶名 查詢 */}
                                            <option value='all'>全部</option>
                                            <option value='fullname' id='fullnameSearch'>
                                                显示名称
                                            </option>
                                            <option value='userID' id='userIDSearch'>
                                                用户名(ID)
                                            </option>
                                            <option value='phone1' id='phone1Search'>
                                                手機號碼
                                            </option>
                                            <option value='email1' id='email1Search'>
                                                邮箱
                                            </option>
                                        </select>
                                        <input
                                            className='searchInput form-control'
                                            style={{
                                                height: '32px',
                                            }}
                                            placeholder='搜寻'
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && filterMemberList()}
                                        />
                                    </div>
                                </div>
                                <div className='col-4 ps-0'>
                                    <button
                                        className='btn btn-outline-primary btn-size-s me-2'
                                        style={{
                                            width: '88px',
                                            height: '32px',
                                            lineHeight: '0',
                                        }}
                                        onClick={resetFilterMemberList}
                                    >
                                        重置
                                    </button>
                                    <button
                                        className='btn btn-primary btn-size-s text-white'
                                        style={{
                                            width: '88px',
                                            height: '32px',
                                            lineHeight: '0',
                                        }}
                                        onClick={filterMemberList}
                                    >
                                        查询
                                    </button>
                                </div>
                            </div>

                            <div className='row pb-4 mt-4'>
                                <div className='col-8'>
                                    <div className='tableWrapper'>
                                        <table className='table table-hover text-center' id='table-memberList'>
                                            <thead>
                                                <tr className='bg-background'>
                                                    <th scope='col' className='text-grey4 border-0 p-0'>
                                                        <label
                                                            htmlFor='memberCheckboxAll'
                                                            className='w-100 h-100 px-2 py-3'
                                                        >
                                                            <input
                                                                id='memberCheckboxAll'
                                                                className='memberCheckboxAll'
                                                                type='checkbox'
                                                                onClick={(e) => changeMemberCheckAll(e)}
                                                            />
                                                        </label>
                                                    </th>
                                                    <th
                                                        scope='col'
                                                        className='text-start text-grey4 border-0'
                                                        onClick={() => sortTable(0)}
                                                    >
                                                        用户名称
                                                        <span></span>
                                                    </th>
                                                    <th
                                                        scope='col'
                                                        className='text-start text-grey4 border-0'
                                                        onClick={() => sortTable(1)}
                                                    >
                                                        手機號碼<span></span>
                                                    </th>
                                                    <th
                                                        scope='col'
                                                        className='text-start text-grey4 border-0'
                                                        onClick={() => sortTable(2)}
                                                    >
                                                        邮箱<span></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='border-top-0'>
                                                {memberList === '' ? (
                                                    <tr></tr>
                                                ) : memberList.length !== 0 ? (
                                                    memberList.map((list) => {
                                                        return (
                                                            <tr key={list.userGuid}>
                                                                <td className='text-center memberCheckboxWrapper p-0'>
                                                                    <label
                                                                        htmlFor={list.userGuid}
                                                                        className='w-100 h-100 px-2 py-3'
                                                                    >
                                                                        <input
                                                                            id={list.userGuid}
                                                                            className='memberCheckboxItem'
                                                                            type='checkbox'
                                                                            // checked={
                                                                            // 	memberCheckObj[
                                                                            // 		list
                                                                            // 			.userGuid
                                                                            // 	]
                                                                            // }
                                                                            value={list.userGuid}
                                                                            onChange={(e) => changeMemberCheckObj(e)}
                                                                        />
                                                                    </label>
                                                                </td>
                                                                <td className='text-start'>{list.fullname}</td>
                                                                <td className='text-start'>{list.phone1}</td>
                                                                <td className='text-start'>{list.email1}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan='4'>
                                                                查無資料
                                                                <br />
                                                                或者 該使用者已為權限成員
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AuthUpdateMemberCreate;
